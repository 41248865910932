import { FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { Label11Medium } from "../Typography";
import { ArrowDown, ArrowRight } from "../Atoms/assets";
import React from "react";

export const ReportingDropdown = ({
    text,
    isOpen = true,
    setIsOpen,
}: {
    text: string;
    isOpen?: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    return isOpen ? (
        <FlexRowAlignCenter gridColumn={" 2 / -1"} backgroundColor={"greenWhite"} height={"38px"} width={"100%"} pl={8}>
            <FlexRowAlignCenter cursor={"pointer"} onClick={() => setIsOpen(false)}>
                <Label11Medium color={"forrestGreen"} uppercase>
                    {text}
                </Label11Medium>
                <XSpacer spaceX={5} />
                <ArrowDown fill={"forrestGreen"} />
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    ) : (
        <FlexRowAlignCenter
            gridColumn={2}
            maxWidth={"375px"}
            justifyContent={"space-between"}
            pl={8}
            pr={10}
            height={"38px"}
            backgroundColor={"aquaHaze"}
            cursor={"pointer"}
            mb={5}
            onClick={() => setIsOpen(true)}
        >
            <Label11Medium color={"auratiumGreen"} uppercase>
                {text}
            </Label11Medium>
            <ArrowRight fill={"auratiumGreen"} />
        </FlexRowAlignCenter>
    );
};
