import { Box } from "../styledSystemUtilities";
import React from "react";
import _ from "lodash/fp";
import {
    Body,
    Body12,
    Body12Bold,
    Body12Medium,
    Body14,
    Body14Medium,
    Header27,
    Label,
    LabelBold,
} from "../Typography";
import { Color } from "../../@types/color";
const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

type CellProps = {
    value: number | string;
    label: string;
    gridColumn?: number | string;
    color?: Color;
};

type OperatorType = "/" | "+" | "x" | "=";

export const BlueCell = ({ value, label, gridColumn }: CellProps): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <LabelBold color={"petrolBlue"}>{label}</LabelBold>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"#BFE0E0"}
                height={"24px"}
                width={"100%"}
                mt={2}
            >
                <Body12Bold color={"petrolBlue"}>{value}</Body12Bold>
            </Box>
        </Box>
    );
};

export const BoldWhiteCell = ({ value, label, gridColumn }: CellProps): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <LabelBold color={"petrolBlue"}>{label}</LabelBold>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"24px"}
                width={"100%"}
                border={"petrolBlue"}
                mt={2}
            >
                <Body14Medium color={"sienna"}>{value}</Body14Medium>
            </Box>
        </Box>
    );
};

export const WhiteCell = ({ value, label, color, gridColumn }: CellProps): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <LabelBold color={"petrolBlue"}>{label}</LabelBold>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"white"}
                height={"24px"}
                width={"100%"}
                border={"blueLine"}
                mt={2}
            >
                <Body12Medium color={color ? color : "agateGray"}>{value}</Body12Medium>
            </Box>
        </Box>
    );
};

export const Cell = ({ value, label, color, gridColumn }: CellProps): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gridColumn={gridColumn} width={"100%"}>
            <Label color={"petrolBlue"}>{label}</Label>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"24px"}
                width={"100%"}
                border={"lightBlue"}
                mt={2}
            >
                <Body12 color={color ? color : "agateGray"} fontWeight={color ? "medium" : "regular"}>
                    {value}
                </Body12>
            </Box>
        </Box>
    );
};

export const TotalCell = ({ value, label }: { value: number; label: string }): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Body14 color={"ipanemaBlue"}>{label}</Body14>
            <Box mb={1} />
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                height={14}
                width={"160px"}
                backgroundColor={"white"}
                border={"gray"}
            >
                <Header27 color={"petrolBlue"}>{formatter.format(_.round(value))}</Header27>
            </Box>
        </Box>
    );
};

export const Operator = ({ operator }: { operator: OperatorType }): JSX.Element => {
    return (
        <Box pb={"2px"}>
            <Body color={"deepPetrol"}>{operator === "/" ? "÷" : operator}</Body>
        </Box>
    );
};

export const Row = ({ children }: { children?: React.ReactNode }): JSX.Element => {
    return (
        <Box
            display={"grid"}
            gridTemplateColumns={"1fr 20px 1fr 20px 1fr"}
            alignItems={"end"}
            justifyItems={"center"}
            flexGrow={1}
        >
            {children}
        </Box>
    );
};

export const RightCornerLine = (): JSX.Element => {
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"end"}
            alignItems={"flex-end"}
            alignSelf={"flex-end"}
        >
            <Box width={4} height={"1px"} backgroundColor={"northernLights"} />
            <Box width={"1px"} height={"8px"} backgroundColor={"northernLights"} />
        </Box>
    );
};

export const MiddleLine = (): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"} ml={"3px"}>
            <Box height={"8px"} width={"1px"} backgroundColor={"northernLights"} alignSelf={"flex-end"} />
            <Box justifySelf={"center"} width={"100%"} height={"1px"} backgroundColor={"northernLights"} />
            <Box height={"8px"} width={"1px"} backgroundColor={"northernLights"} />
        </Box>
    );
};

export const LeftCornerLine = ({
    operator,
    height,
}: {
    operator: OperatorType;
    height?: string | number;
}): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"} mr={2}>
            <Box width={"1px"} height={height ? height : "20px"} ml={"3px"} backgroundColor={"northernLights"} />
            <Operator operator={operator} />
        </Box>
    );
};
