import { KeywordSetLabel, SearchAreaType } from "../@types/reports/Online";

export type KeywordSetOption = { value: KeywordSetLabel; label: string };
export type SearchAreaOption = { value: SearchAreaType; label: string };

export const practiceTypeOptions: KeywordSetOption[] = [
    { value: "generalDentist", label: "General Dentist" },
    { value: "cosmeticDentist", label: "Cosmetic Dentist" },
    { value: "pediatricDentist", label: "Pediatric Dentist" },
    { value: "prosthodontist", label: "Prosthodontist" },
    { value: "periodontist", label: "Periodontist" },
    { value: "orthodontist", label: "Orthodontist" },
    { value: "oralSurgeon", label: "Oral Surgeon" },
];

export const searchAreaOptions: SearchAreaOption[] = [
    { value: "zipCode", label: "ZIP" },
    { value: "city", label: "City" },
];
