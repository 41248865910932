import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTemplate } from "./FormTemplate";
import { Box, YSpacer } from "../../styledSystemUtilities";
import { Body, Body12, Header } from "../../Typography";
import { RegisteredInput, TwoOptionCheckbox } from "../../Molecules";
import {
    FacebookAcceptPopup,
    FacebookContinuePopup,
    FacebookLogo,
    FacebookNotifications,
    FacebookNotificationsTwo,
    FacebookPageRoles,
    FacebookPasswordPopup,
} from "../../Atoms/assets";
import { RightButton } from "../../Atoms";
import { useAppSelector } from "../../../redux/hooks";
import { SocialMediaForm } from "../../../@types/forms/Types";
import { useProductFormSubmit } from "../../../hooks/useProductFormSubmit";

const schema = object().shape({ currentWebsiteAddress: string().trim() });

const formDefaultValues = {
    hasFacebookAccount: null,
    hasInstagramAccount: null,
    instagramUsername: "",
    instagramPassword: "",
};

export function SocialMedia(): JSX.Element {
    const form = useAppSelector((state) => state.form);
    const superviseSubmit = useProductFormSubmit<SocialMediaForm>("online", "socialMediaForms");
    const { register, errors, handleSubmit, control, reset } = useForm<SocialMediaForm>({
        resolver: yupResolver(schema),
        defaultValues: form || formDefaultValues,
    });

    useEffect(() => {
        reset(form || formDefaultValues);
    }, [form, reset]);

    return (
        <FormTemplate
            productName={"Social Media Channel Access"}
            taskName={""}
            redLabel={"Required Information"}
            blackLabel={"Info we need to promote your practice"}
            onSubmit={handleSubmit(superviseSubmit)}
        >
            <Box>
                <Header color={"graphiteGray"}>Facebook</Header>
                <YSpacer spaceY={2} />
                <Body12>
                    Access to your practice’s Facebook account allows Ascent to create posts, run ads, track data and
                    optimize your Facebook page for improved quality and results.{" "}
                </Body12>
                <YSpacer spaceY={7} />
                <Body color={"graphiteGray"}>Does your practice have a Facebook Account?</Body>
                <YSpacer spaceY={5} />
                <TwoOptionCheckbox
                    name={"hasFacebookAccount"}
                    control={control}
                    yesText={
                        "(If you check this box, please scroll down to view how to provide Ascent access to your account.)"
                    }
                    noText={"(If you check this box, an Ascent representative will create one for you.)"}
                />
                <YSpacer spaceY={8} />
                <Body color={"graphiteGray"}>How to accept request for Facebook access:</Body>
                <YSpacer spaceY={4} />
                <Body12>
                    1. Our social media team will send you a request for access to your practice’s Facebook page via
                    Facebook.
                </Body12>
                <YSpacer spaceY={6} />
                <Body12>2. Sign into your practice’s Facebook page.</Body12>
                <YSpacer spaceY={6} />
                <Box pl={6}>
                    <FacebookLogo />
                </Box>
                <YSpacer spaceY={6} />
                <Body12>3. Click on the “Notifications” button.</Body12>
                <YSpacer spaceY={6} />
                <Box ml={"-8px"}>
                    <FacebookNotifications />
                </Box>
                <YSpacer spaceY={6} />
                <Body12>4. Click on the notification we sent in your list of new notifications. </Body12>
                <YSpacer spaceY={6} />
                <FacebookNotificationsTwo />
                <YSpacer spaceY={6} />
                <Body12>5. You’ll be taken to the Page Roles screen. Click on The “Respond to Request” button.</Body12>
                <YSpacer spaceY={6} />
                <FacebookPageRoles />
                <YSpacer spaceY={6} />
                <Body12>6. In the pop-up window that appears click “continue”.</Body12>
                <YSpacer spaceY={6} />
                <FacebookContinuePopup />
                <YSpacer spaceY={6} />
                <Body12>
                    7. A new pop-up window will appear describing the access that Ascent will have. Click “Accept
                    Request” in the lower right corner of the pop-up window.
                </Body12>
                <YSpacer spaceY={6} />
                <FacebookAcceptPopup />
                <YSpacer spaceY={6} />
                <Body12>
                    8a. A final pop-up window will appear requiring you to enter your Facebook password for final
                    authorization. Enter your password 8b. Click “Submit”.
                </Body12>
                <YSpacer spaceY={6} />
                <FacebookPasswordPopup />
            </Box>
            <Box>
                <Header color={"graphiteGray"}>Instagram</Header>
                <YSpacer spaceY={2} />
                <Body12>
                    Access to your practice’s Instagram account allows Ascent to create posts, run ads, track data and
                    optimize your Instagram account for improved quality and results.
                </Body12>
                <YSpacer spaceY={7} />
                <Body color={"graphiteGray"}>Does your practice have an Instagram Account?</Body>
                <YSpacer spaceY={5} />
                <TwoOptionCheckbox
                    name={"hasInstagramAccount"}
                    control={control}
                    yesText={
                        "(If you check this box, please scroll down to provide Ascent with your username and password.)"
                    }
                    noText={"(If you check this box, an Ascent representative will create one for you.)"}
                />
                <YSpacer spaceY={8} />
                <Body color={"graphiteGray"}>We need your instagram login.</Body>
                <YSpacer spaceY={4} />
                <Body12>What is your username for your Instagram account?</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"instagramUsername"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body12>What is your password for your Instagram account?</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"instagramPassword"} placeholder={""} />
                <YSpacer spaceY={13} />
                <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                    save & continue
                </RightButton>
            </Box>
        </FormTemplate>
    );
}
