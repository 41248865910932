import React, { useEffect } from "react";
import { GrowthTemplate } from "../Templates";
import { Box, Grid, YSpacer } from "../styledSystemUtilities";
import { Body14, Header27 } from "../Typography";
import { LoadingWrapper } from "../Molecules";
import _ from "lodash/fp";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions } from "../../redux";
import { GoogleAdsCampaign } from "../Organisms/GrowthPageComponents";
import { growthTasks } from "../../constants/tasks";
import { TaskBanner } from "../Organisms";
import { formatTasks } from "../../utils/formatTasks";

export const Growth = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const location = useAppSelector((state) => state.openPractice);
    const growthReport = useAppSelector((state) => state.growthReport);
    const reportIsLoading = useAppSelector((state) => state.loading.growthReport);

    useEffect(() => {
        dispatch(actions.getGrowthReport(location.id));
    }, [dispatch, location.id]);

    return (
        <GrowthTemplate>
            <LoadingWrapper color={"petrolBlue"} isLoading={reportIsLoading}>
                <Grid gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"}>
                    <Box gridColumn={2}>
                        <TaskBanner
                            primaryColor={"deepPetrol"}
                            secondaryColor={"petrolBlue"}
                            productTasks={formatTasks(_.reject("completed", location.tasks), "sienna", growthTasks)}
                            completedProductTasks={formatTasks(
                                _.filter("completed", location.tasks),
                                "petrolBlue",
                                growthTasks
                            )}
                        />
                        {/*<FlexRowAlignCenter*/}
                        {/*    maxWidth={"375px"}*/}
                        {/*    justifyContent={"space-between"}*/}
                        {/*    pl={8}*/}
                        {/*    pr={15}*/}
                        {/*    height={"38px"}*/}
                        {/*    backgroundColor={"aquaBlue"}*/}
                        {/*    cursor={"pointer"}*/}
                        {/*>*/}
                        {/*    <Label11Medium color={"ipanemaBlue"} uppercase>*/}
                        {/*        add a new growth campaign*/}
                        {/*    </Label11Medium>*/}
                        {/*    <AddIcon fill={"ipanemaBlue"} />*/}
                        {/*</FlexRowAlignCenter>*/}
                        <YSpacer spaceY={8} />
                        {growthReport.activeCampaigns.length ? (
                            <Box pl={8}>
                                <Header27 color={"petrolBlue"}>Active Campaigns</Header27>
                                <Body14 color={"deepPetrol"}>Select a campaign to view and manage:</Body14>
                            </Box>
                        ) : null}

                        <YSpacer spaceY={6} />
                    </Box>
                    <Box gridColumn={"1 / -1"} data-testid={"activeCampaigns"}>
                        {growthReport.activeCampaigns.map((campaign, index) => {
                            return <GoogleAdsCampaign campaign={campaign} index={index} />;
                        })}
                    </Box>
                    {growthReport.pastCampaigns.length ? (
                        <Box gridColumn={2}>
                            <YSpacer spaceY={13} />
                            <Box pl={8}>
                                <Header27 color={"petrolBlue"}>Past Campaigns</Header27>
                                <Body14 color={"deepPetrol"}>Select a campaign to view:</Body14>
                            </Box>
                            <YSpacer spaceY={6} />
                        </Box>
                    ) : null}

                    <Box gridColumn={"1 / -1"} data-testid={"pastCampaigns"}>
                        {growthReport.pastCampaigns.map((campaign, index) => {
                            return <GoogleAdsCampaign campaign={campaign} index={index} initialOpenState={false} />;
                        })}
                        <YSpacer spaceY={13} />
                    </Box>
                </Grid>
            </LoadingWrapper>
        </GrowthTemplate>
    );
};
