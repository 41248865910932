import { Body12Bold, Body14Medium } from "../Typography";
import { FlexRowAlignCenter } from "../styledSystemUtilities";
import React from "react";
import { Color } from "../../@types/color";

export const BoldDataLabelAndCell = ({
    label,
    data,
    color,
    border,
    testId,
}: {
    label: string;
    data: string | number;
    color: Color;
    border: string;
    testId?: string;
}): JSX.Element => (
    <FlexRowAlignCenter justifyContent={"space-between"}>
        <Body12Bold color={color}>{label}</Body12Bold>
        <FlexRowAlignCenter justifyContent={"center"} border={border} width={"100px"} height={9} data-testid={testId}>
            <Body14Medium color={color}>{data}</Body14Medium>
        </FlexRowAlignCenter>
    </FlexRowAlignCenter>
);
