import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import React from "react";

export function ProfitModalContainer({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <FlexRowAlignCenter
            top={0}
            left={0}
            justifyContent={"center"}
            height={"100vh"}
            width={"100vw"}
            position={"fixed"}
            zIndex={1000}
            backgroundColor={"translucentWhite"}
        >
            <Box width={"375px"} backgroundColor={"porcelain"} overflow={"auto"} maxHeight={"75%"}>
                {children}
            </Box>
        </FlexRowAlignCenter>
    );
}
