import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { actions, slices } from "../redux";
import { routerHistory } from "../routerHistory";
import { PracticeFrontEnd } from "../@types/shared";

export function useLocationOverviewLinks(
    phase: "online" | "growth" | "profit",
    currentPractice?: PracticeFrontEnd
): () => void {
    const dispatch = useAppDispatch();
    const openPractice = useAppSelector((state) => state.openPractice);

    return () => {
        if (currentPractice) {
            dispatch(slices.openPractice.actions.set(currentPractice));
            dispatch(actions.getActiveCoupons(currentPractice.id));
            if (currentPractice.id !== openPractice.id) {
                dispatch(slices.onlineReport.actions.unset());
                dispatch(slices.growthReport.actions.unset());
                dispatch(slices.profitReport.actions.unset());
            }
            routerHistory.push(`/dash/${phase}`);
        }
    };
}
