import React, { useEffect, useState } from "react";
import { OverviewTemplate } from "../Templates";
import { LocationMenu, LocationTrends, TaskBanner } from "../Organisms";
import { Box, Grid, Span, YSpacer } from "../styledSystemUtilities";
import { Header27, Label11 } from "../Typography";
import { IconLabel } from "../Atoms";
import { ComputerIcon, GrowthIcon, ProfitIcon } from "../Atoms/assets";
import { useWindowSize } from "react-use";
import theme from "../../theme";
import { useAppSelector } from "../../redux/hooks";
import { LoadingWrapper } from "../Molecules";
import { formatTasks } from "../../utils/formatTasks";
import _ from "lodash/fp";
import { globalTasks } from "../../constants/tasks";

export const LocationOverview = (): JSX.Element => {
    const { width } = useWindowSize();
    const locationOverviewReports = useAppSelector((state) => state.locationOverviewReports.locationReports);
    const reportIsLoading = useAppSelector((state) => state.loading.locationOverviewReports);
    const practices = useAppSelector((state) => state.accountPractices);
    const [openReports, setOpenReports] = useState<{ [key: string]: boolean }>({});

    const reportIsOpen = !!locationOverviewReports?.find((report) => openReports[report.locationId]);

    const tasks = practices.flatMap((practice) => practice.tasks);

    useEffect(() => {
        locationOverviewReports.map((report, index) =>
            setOpenReports((prevState) => ({ ...prevState, [report.locationId]: index === 0 }))
        );
    }, [locationOverviewReports]);

    return (
        <OverviewTemplate Menu={LocationMenu}>
            <LoadingWrapper isLoading={reportIsLoading} color={"ipanemaBlue"}>
                <Grid gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"}>
                    <TaskBanner
                        primaryColor={"forrestGreen"}
                        secondaryColor={"auratiumGreen"}
                        productTasks={formatTasks(_.reject("completed", tasks), "sienna", globalTasks)}
                        completedProductTasks={formatTasks(_.filter("completed", tasks), "auratiumGreen", globalTasks)}
                    />
                    <Box gridColumn={2}>
                        <YSpacer spaceY={5} />
                    </Box>
                    <Box gridColumn={2}>
                        <Box px={8} py={4} backgroundColor={"rgba(255, 255, 255, .8)"}>
                            <Header27 color={"graphiteGray"}>Location Overview</Header27>
                            <Label11>
                                # of Locations:{" "}
                                <Span fontSize={"12px"} fontWeight={"medium"}>
                                    {locationOverviewReports.length}
                                </Span>
                            </Label11>
                        </Box>
                        <YSpacer spaceY={6} />
                        {width >= theme.breakpoints[2] && reportIsOpen ? (
                            <Grid
                                gridTemplateColumns={"254px 254px 254px"}
                                justifyItems={"center"}
                                data-testid={"phaseLogos"}
                            >
                                <IconLabel Icon={ComputerIcon} label={"online"} color={"forrestGreen"} />
                                <IconLabel Icon={GrowthIcon} label={"growth"} color={"deepPetrol"} />
                                <IconLabel Icon={ProfitIcon} label={"profit"} color={"nauticBlue"} />
                            </Grid>
                        ) : null}
                        <YSpacer spaceY={5} />
                    </Box>

                    <Box gridColumn={"1 / -1"} height={"100%"}>
                        {locationOverviewReports.map((report, index) => {
                            return (
                                <Box key={index}>
                                    <LocationTrends
                                        locationOverviewReport={report}
                                        openReports={openReports}
                                        setOpenReports={setOpenReports}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                </Grid>
            </LoadingWrapper>
            <YSpacer spaceY={15} />
        </OverviewTemplate>
    );
};
