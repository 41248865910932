import { Box, FlexRowAlignCenter, Grid, Span, YSpacer } from "../../styledSystemUtilities";
import React from "react";
import { AverageValueReport } from "../../../@types/reports/Profit";
import _ from "lodash/fp";
import { Body12, Body14Medium } from "../../Typography";
import { formatAsDollars } from "../../../utils/formatter";

export function ValueAverageTable({
    valueAverages,
    title,
}: {
    valueAverages: AverageValueReport;
    title: string;
}): JSX.Element {
    return (
        <Grid
            mt={8}
            gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
            justifyItems={"center"}
            backgroundColor={"porcelain"}
        >
            <Box width={"100%"} py={6} pl={6} pr={10} data-testid={_.camelCase(title)} gridColumn={2}>
                <Body14Medium color={"nauticBlue"}>{title}</Body14Medium>
                <YSpacer spaceY={6} />
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <Body12 color={"graphiteGray"}>
                        Current:{" "}
                        <Span fontSize={"10px"} color={"agateGray"}>
                            (Based on rolling 12 months)
                        </Span>
                    </Body12>
                    <FlexRowAlignCenter
                        height={8}
                        width={"90px"}
                        justifyContent={"center"}
                        backgroundColor={"white"}
                        border={"1px solid #29425B"}
                        data-testid={"currentPerUnitValue"}
                    >
                        <Body12 color={"balticBlue"}>
                            {formatAsDollars(_.round(valueAverages.current.perUnitValue))}
                        </Body12>
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
                <YSpacer spaceY={4} />
                <Body12 color={"graphiteGray"}>Previous 5 years:</Body12>
                <YSpacer spaceY={3} />
                <Box width={"100%"} height={"1px"} backgroundColor={"agateGray"} />
                <Box data-testid={"previous5Years"}>
                    {valueAverages.previousFiveYears.map((year, index) => (
                        <FlexRowAlignCenter
                            borderBottom={index === valueAverages.previousFiveYears.length - 1 ? "dark" : "mid"}
                            height={8}
                            key={year.year}
                            data-testid={year.year}
                            justifyContent={"space-between"}
                            px={11}
                        >
                            <Body12>{year.year}</Body12>
                            <Box data-testid={"perUnitValue"}>
                                <Body12 color={"balticBlue"}>{formatAsDollars(_.round(year.perUnitValue))}</Body12>
                            </Box>
                        </FlexRowAlignCenter>
                    ))}
                </Box>
            </Box>
        </Grid>
    );
}
