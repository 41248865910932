import React from "react";
import { Box, FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { ProductBottomRow, ProductDetails, ProductCardSelect, PaymentsRow, PastProductDetails } from "../Atoms";
import { useCart } from "../../hooks/useCart";
import { PrintProduct } from "../../@types/reports/ProfitCenters";
import { Body12, Label } from "../Typography";
import { useSetPopup } from "../../hooks/useSetPopup";

export const PrintProductCard = ({
    product,
    isPastProduct = false,
}: {
    product: PrintProduct;
    isPastProduct?: boolean;
}): JSX.Element => {
    const [isAdded, toggleProduct, updateProduct, watchedProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    if (watchedProduct.type === "print") {
        return (
            <Box data-testid={watchedProduct.recurlyCode} key={product.recurlyCode} mb={5} backgroundColor={"white"}>
                {isPastProduct ? <PastProductDetails product={product} /> : <ProductDetails product={watchedProduct} />}
                {product.payments ? (
                    <PaymentsRow payments={product.payments} productCode={product.recurlyCode} />
                ) : null}
                {isPastProduct ? null : (
                    <Box display={"flex"} justifyContent={"space-between"} px={"13px"} pb={3}>
                        <FlexRowAlignCenter>
                            <Label color={"balticBlue"}>Quantity</Label>
                            <XSpacer spaceX={2} />
                            <Box width={"93px"} data-testid={"quantitySelect"}>
                                <ProductCardSelect
                                    onChange={(option) => {
                                        if (option !== null) {
                                            updateProduct({
                                                ...watchedProduct,
                                                price: option.price,
                                                recurlyCode: option.recurlyCode,
                                                quantity: option,
                                            });
                                        }
                                    }}
                                    defaultValue={watchedProduct.quantity}
                                    options={watchedProduct.quantityOptions}
                                />
                            </Box>
                            <XSpacer spaceX={"34px"} />
                        </FlexRowAlignCenter>
                        <Box width={"132px"} textAlign={"center"}>
                            <FlexRowAlignCenter justifyContent={"flex-end"} height={"100%"}>
                                <Body12 color={"nauticBlue"}>{watchedProduct.paperType} Paper</Body12>
                            </FlexRowAlignCenter>
                        </Box>
                    </Box>
                )}
                <ProductBottomRow
                    toggleProduct={toggleProduct}
                    isAdded={isAdded}
                    isReOrder={isPastProduct}
                    setPopup={setPopup}
                />
            </Box>
        );
    }

    return <Box />;
};
