import React from "react";
import styled from "styled-components";
import theme from "../../theme";

import { getFontSize } from "../../helpers/themeGetters";

const ChartContainer = styled.div`
    grid-column: 1;
    margin-top: -10px;
    display: grid;
    grid-template-columns: auto 1fr;
`;

const BaseLabel = styled.div`
    color: ${theme.colors.agateGray};
    text-align: center;
    font-size: ${getFontSize(1)};
`;

const YLabel = styled(BaseLabel)`
    grid-column: 1;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    grid-row: 1;
`;

const XLabel = styled(BaseLabel)`
    grid-column: 2;
    grid-row: 2;
`;

const ChartWrap = styled.div`
    grid-column: 2;
    grid-row: 1;
`;

export const Chart = ({
    renderPlot,
    xLabel,
    yLabel,
}: {
    renderPlot: () => JSX.Element;
    xLabel?: string;
    yLabel?: string;
}): JSX.Element => {
    return (
        <ChartContainer>
            <YLabel>{yLabel}</YLabel>
            <XLabel>{xLabel}</XLabel>
            <ChartWrap>{renderPlot()}</ChartWrap>
        </ChartContainer>
    );
};
