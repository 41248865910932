import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const getAllKpiMonths = (locationId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/kpi/get-all-months-for-location/${locationId}`,
            method: "get",
        },
        success: slices.kpiMonths.actions.set,
        label: "getKpiMonths",
    });

export default getAllKpiMonths;
