import React from "react";
import { Body12, Label } from "../Typography";
import { InfoTableRow } from "../../@types/infoTableRow";

export const InfoTable = ({ rows }: { rows: InfoTableRow[] }): JSX.Element => (
    <table cellPadding={0} style={{ paddingLeft: "20px", marginTop: "-5px", borderSpacing: "0 10px" }} width={"280px"}>
        <tbody>
            {rows.map((row, index) =>
                row.data ? (
                    <tr key={index}>
                        <td style={{ verticalAlign: "top", whiteSpace: "nowrap" }}>
                            <Label lineHeight={"16px"} pr={3}>
                                {row.label}
                            </Label>
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                            <Body12 lineHeight={"15px"} color={"graphiteGray"}>
                                {row.data}
                            </Body12>
                        </td>
                    </tr>
                ) : null
            )}
        </tbody>
    </table>
);
