import React, { useEffect } from "react";
import { OnlineTemplate } from "../Templates";
import { Box, Grid, YSpacer } from "../styledSystemUtilities";
import {
    GoogleLocalDropdown,
    GoogleOrganicDropdown,
    InternetActionsDropdown,
    OnlineSubscriptions,
    OnlineSummary,
    ReviewsDropdown,
    RoiDropdown,
} from "../Organisms/OnlinePageComponents";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions } from "../../redux";
import { LoadingWrapper } from "../Molecules";
import { useWindowSize } from "react-use";
import theme from "../../theme";
import { scroller } from "react-scroll";
import { TaskBanner } from "../Organisms";
import _ from "lodash/fp";
import { formatTasks } from "../../utils/formatTasks";
import { onlineTasks } from "../../constants/tasks";
import { GoogleMyBusinessDropdown } from "../Organisms/OnlinePageComponents/GoogleMyBusinessDropdown";

export const Online = (): JSX.Element => {
    const { width } = useWindowSize();
    const dispatch = useAppDispatch();
    const location = useAppSelector((state) => state.openPractice);
    const report = useAppSelector((state) => state.onlineReport);
    const reportIsLoading = useAppSelector((state) => state.loading.onlineReport);

    useEffect(() => {
        if (!report.localSeo) {
            dispatch(actions.getOnlineReport(location.id));
        }
    }, [dispatch, location.id, report.localSeo]);

    const scrollToLocalSeo = () => {
        scroller.scrollTo("local-seo", {
            offset: -224,
        });
    };

    const scrollToInternetActions = () => {
        scroller.scrollTo("internet-actions", {
            offset: -224,
        });
    };

    return (
        <>
            <OnlineTemplate>
                <LoadingWrapper isLoading={reportIsLoading} color={"auratiumGreen"}>
                    <Grid gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"}>
                        <TaskBanner
                            primaryColor={"forrestGreen"}
                            secondaryColor={"auratiumGreen"}
                            productTasks={formatTasks(_.reject("completed", location.tasks), "sienna", onlineTasks)}
                            completedProductTasks={formatTasks(
                                _.filter("completed", location.tasks),
                                "auratiumGreen",
                                onlineTasks
                            )}
                        />
                        <Box gridColumn={2}>
                            <YSpacer spaceY={5} />
                        </Box>

                        <Box gridColumn={2}>
                            <Grid
                                gridTemplateColumns={width > theme.breakpoints[0] ? "1fr 1fr" : "1fr"}
                                justifyItems={"center"}
                                gridColumnGap={5}
                            >
                                <OnlineSummary
                                    onlineReport={report}
                                    scrollToLocalSeo={scrollToLocalSeo}
                                    scrollToInternetActions={scrollToInternetActions}
                                />
                                <OnlineSubscriptions
                                    onlineSubscriptions={report.subscriptions}
                                    otherSubscriptions={report.availableProducts.other}
                                    recommendedSubscriptions={report.availableProducts.recommended}
                                />
                            </Grid>
                        </Box>
                        {report.seoCalls && report.seoCalls.months.length > 0 ? (
                            <RoiDropdown seoCallsReport={report.seoCalls} />
                        ) : null}
                        {report.googleMyBusiness ? (
                            <GoogleMyBusinessDropdown googleMyBusinessReport={report.googleMyBusiness} />
                        ) : null}
                        <Box className={"internet-actions"} gridColumn={2} />
                        {report.adviceLocalReport || report.googleAnalytics ? (
                            <InternetActionsDropdown
                                googleAnalyticsReport={report.googleAnalytics}
                                adviceLocalReport={report.adviceLocalReport}
                            />
                        ) : null}
                        <Box className={"local-seo"} gridColumn={2} />
                        {report.localSeo ? (
                            <GoogleLocalDropdown
                                localSeoReport={report.localSeo}
                            />
                        ) : null}
                        {report.searchConsole ? (
                            <GoogleOrganicDropdown searchConsoleReport={report.searchConsole} />
                        ) : null}
                        {report.localSeo ? (
                            <ReviewsDropdown localSeoReport={report.localSeo} practiceName={location.practiceName} />
                        ) : null}
                    </Grid>
                </LoadingWrapper>
            </OnlineTemplate>
        </>
    );
};
