import { Box, FlexColAlignCenter, FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { ReviewsBoxLabel } from "../Atoms/BoxLabel";
import { ReviewsRankingPlot } from "../Molecules";
import { Chart } from "./Chart";
import React from "react";
import { ReviewsChartData } from "../../@types/reviewsChartData";
import { Body14Medium, Label } from "../Typography";
import { Color } from "../../@types/color";

const Dot = ({ fill, flexShrink }: { fill: Color; flexShrink?: number }): JSX.Element => (
    <Box height={5} width={5} flexShrink={flexShrink} backgroundColor={fill} borderRadius={"50%"} />
);

const LegendDot = ({ label, fill }: { label: string; fill: Color }): JSX.Element => (
    <FlexRowAlignCenter>
        <Dot fill={fill} />
        <XSpacer spaceX={3} />
        <Label>{label}</Label>
    </FlexRowAlignCenter>
);

export const ReviewsChartCard = ({ reviewsChartData }: { reviewsChartData: ReviewsChartData }): JSX.Element => {
    return (
        <Box pb={7} pt={5} display={"flex"} flexDirection={"column"} backgroundColor={"white"}>
            <Box px={8}>
                <Body14Medium>Google Reviews Ranking</Body14Medium>
                <Box mb={3} />
                <Box display={"flex"} alignItems={"center"}>
                    <LegendDot fill={"sienna"} label={"Your Current Rank"} />
                    <Box ml={8} />
                    <LegendDot fill={"auratiumGreen"} label={"Top Competitor"} />
                    <Box ml={8} />
                    <LegendDot fill={"slateGray"} label={"Other Competitors"} />
                </Box>
            </Box>

            <FlexColAlignCenter>
                <Chart
                    xLabel={"Number of Google Reviews"}
                    yLabel={"Average rating"}
                    renderPlot={() => <ReviewsRankingPlot data={reviewsChartData} />}
                />
                <Box mb={6} />
                <Box display={"flex"}>
                    <ReviewsBoxLabel fill={"seaGreen"} opacity={".6"}>
                        POOR
                    </ReviewsBoxLabel>
                    <Box ml={14} />
                    <ReviewsBoxLabel fill={"seaGreen"} opacity={".17"}>
                        AVERAGE
                    </ReviewsBoxLabel>
                    <Box ml={14} />
                    <ReviewsBoxLabel fill={"white"} opacity={1}>
                        IDEAL
                    </ReviewsBoxLabel>
                </Box>
            </FlexColAlignCenter>
        </Box>
    );
};
