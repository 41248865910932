import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Body12 } from "../Typography";
import React from "react";

export const LineTable = ({ rows }: { rows: { label: string; value: string }[] }): JSX.Element => {
    return (
        <Box width={"320px"}>
            {rows.map(({ label, value }, index) => (
                <FlexRowAlignCenter key={index} height={8} justifyContent={"space-between"} borderBottom={"green"}>
                    <Body12>{label}</Body12>
                    <Body12 color={"auratiumGreen"}>{value}</Body12>
                </FlexRowAlignCenter>
            ))}
        </Box>
    );
};
