import { routerHistory } from "../routerHistory";
import { actions, slices } from "../redux";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Form, FormCollectionName } from "../@types/forms/Types";

export const useProductFormSubmit = <FormType extends Form>(
    onSubmitPath: string,
    formCollectionName: FormCollectionName
): ((formData: FormType) => void) => {
    const dispatch = useAppDispatch();
    const practiceId = useAppSelector((state) => state.openPractice.id);
    const openTask = useAppSelector((state) => state.openTask);

    return (formData: FormType) => {
        window.scrollTo(0, 0);
        routerHistory.push(onSubmitPath);
        dispatch(slices.openTask.actions.unset());
        dispatch(actions.saveForm(practiceId, openTask, formData, true, onSubmitPath + "Report", formCollectionName));
    };
};
