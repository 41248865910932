import { createSlice } from "@reduxjs/toolkit";
import { RawKpiMonth } from "../../@types/reports/Profit";

const initialState: RawKpiMonth[] = [];

export default createSlice({
    name: "kpiMonths",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
        unset: () => [],
    },
});
