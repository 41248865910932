import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import { RawCoupon } from "../../@types/Coupon";

const getActiveCoupons = (locationId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/recurly/get-active-coupons/${locationId}`,
            method: "get",
        },
        success: (coupons: RawCoupon[]) => slices.activeCoupons.actions.set(coupons),
        label: "getActiveCoupons",
    });

export default getActiveCoupons;
