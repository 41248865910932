import { parseISO } from "date-fns/fp";
import getCalenderMonths from "./getCalanderMonths";
import formatAdditionalProducts from "./formatAdditionalProducts";
import getOnlineSubscriptionsWithDiscounts from "./getOnlineSubscriptionsWithDiscounts";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function getMonthAbbreviation(index: number): string {
    return monthNames[index];
}

function useDateParser(key: string, value: string): unknown {
    if (key === "date" || key === "createdAt" || key === "purchaseDate") {
        const date = parseISO(value);
        return getUserTimezoneAdjustedDate(date);
    }
    return value;
}

function getUserTimezoneAdjustedDate(date: Date): Date {
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}

const utils = {
    useDateParser,
    getCalenderMonths,
    getUserTimezoneAdjustedDate,
    formatAdditionalProducts,
    getMonthAbbreviation,
    getOnlineSubscriptionsWithDiscounts,
};

export default utils;
