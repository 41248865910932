import { useTheme } from "styled-components";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { ArrowRight, ComputerIcon, GrowthIcon, LargeDashboardIcon, LargeProfileIcon, ProfitIcon } from "./assets";
import React from "react";
import { Label11Bold } from "../Typography";
import { ZIndexProps } from "styled-system";
import { Color } from "../../@types/color";
import { PageName } from "../../@types/pageName";
import { routerHistory } from "../../routerHistory";

export const MobileOnlinePrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobilePrimaryMenuItem
        color={"forrestGreen"}
        Icon={ComputerIcon}
        name={"online"}
        backgroundColor={"seaGreen"}
        zIndex={zIndex}
    />
);

export const MobileGrowthPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobilePrimaryMenuItem
        color={"deepPetrol"}
        Icon={GrowthIcon}
        name={"growth"}
        backgroundColor={"ipanemaBlue"}
        zIndex={zIndex}
    />
);

export const MobileProfitPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobilePrimaryMenuItem
        color={"nauticBlue"}
        Icon={ProfitIcon}
        name={"profit"}
        backgroundColor={"balticBlue"}
        zIndex={zIndex}
    />
);

export const MobileAccountPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobilePrimaryMenuItem
        color={"graphiteGray"}
        Icon={LargeProfileIcon}
        name={"account"}
        backgroundColor={"slateGray"}
        zIndex={zIndex}
    />
);

export const MobileDashboardPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobilePrimaryMenuItem
        color={"graphiteGray"}
        Icon={LargeDashboardIcon}
        name={"dashboard"}
        backgroundColor={"slateGray"}
        zIndex={zIndex}
    />
);

export const DesktopOnlinePrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <DesktopPrimaryMenuItem
        color={"forrestGreen"}
        Icon={ComputerIcon}
        name={"online"}
        backgroundColor={"seaGreen"}
        zIndex={zIndex}
    />
);

export const DesktopGrowthPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <DesktopPrimaryMenuItem
        color={"deepPetrol"}
        Icon={GrowthIcon}
        name={"growth"}
        backgroundColor={"ipanemaBlue"}
        zIndex={zIndex}
    />
);

export const DesktopProfitPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <DesktopPrimaryMenuItem
        color={"nauticBlue"}
        Icon={ProfitIcon}
        name={"profit"}
        backgroundColor={"balticBlue"}
        zIndex={zIndex}
    />
);

export const DesktopAccountPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <DesktopPrimaryMenuItem
        color={"graphiteGray"}
        Icon={LargeProfileIcon}
        name={"account"}
        backgroundColor={"slateGray"}
        zIndex={zIndex}
    />
);

export const DesktopDashboardPrimary = ({ zIndex }: ZIndexProps): JSX.Element => (
    <DesktopPrimaryMenuItem
        color={"graphiteGray"}
        Icon={LargeDashboardIcon}
        name={"dashboard"}
        backgroundColor={"slateGray"}
        zIndex={zIndex}
        ml={"58px"}
    />
);

type PrimaryMenuItemProps = ZIndexProps & {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    color: Color;
    backgroundColor: Color;
    name: PageName;
    ml?: string;
};

const MobilePrimaryMenuItem = ({ zIndex, Icon, color, backgroundColor, name }: PrimaryMenuItemProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Box
            position={"relative"}
            width={"84px"}
            height={"112px"}
            background={`linear-gradient(115deg, transparent 0, ${theme.colors[backgroundColor]} 0 75px, transparent 75px 84px)}`}
            zIndex={zIndex}
        >
            <FlexRowAlignCenter justifyContent={"center"} height={11} cursor={"pointer"} onClick={() => null}>
                <Box ml={"-8px"}>
                    <Label11Bold color={color} uppercase>
                        {name}
                    </Label11Bold>
                </Box>
            </FlexRowAlignCenter>

            <FlexRowAlignCenter height={16} pl={3}>
                <Icon fill={color} />
            </FlexRowAlignCenter>
        </Box>
    );
};

const DesktopPrimaryMenuItem = ({
    zIndex,
    Icon,
    color,
    backgroundColor,
    name,
    ml = "68px",
}: PrimaryMenuItemProps): JSX.Element => {
    const theme = useTheme();
    return (
        <Box
            position={"relative"}
            width={"142px"}
            height={"112px"}
            background={`linear-gradient(115deg, transparent 49px, ${theme.colors[backgroundColor]} 49px 128px, transparent 128px 142px)}`}
            zIndex={zIndex}
        >
            <Box pt={"10px"} ml={ml} cursor={"pointer"} onClick={() => null}>
                <Label11Bold color={color} uppercase>
                    {name}
                </Label11Bold>
            </Box>

            <Box pl={"52px"} pt={"38px"}>
                <Icon fill={color} />
            </Box>
        </Box>
    );
};

export const MobileGrowthMenuItem = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobileMenuItem
        backgroundColor={"ipanemaBlue"}
        label={"growth"}
        zIndex={zIndex}
        onClick={() => routerHistory.push("/dash/growth")}
    />
);

export const MobileProfitMenuItem = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobileMenuItem
        backgroundColor={"balticBlue"}
        label={"profit"}
        zIndex={zIndex}
        onClick={() => routerHistory.push("/dash/profit")}
    />
);

export const MobileOnlineMenuItem = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MobileMenuItem
        backgroundColor={"seaGreen"}
        label={"online"}
        zIndex={zIndex}
        onClick={() => routerHistory.push("/dash/online")}
    />
);

export const GrowthMenuItem = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MenuItem
        backgroundColor={"ipanemaBlue"}
        label={"growth"}
        zIndex={zIndex}
        onClick={() => routerHistory.push("/dash/growth")}
    />
);

export const ProfitMenuItem = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MenuItem
        backgroundColor={"balticBlue"}
        label={"profit"}
        zIndex={zIndex}
        onClick={() => routerHistory.push("/dash/profit")}
    />
);

export const OnlineMenuItem = ({ zIndex }: ZIndexProps): JSX.Element => (
    <MenuItem
        backgroundColor={"seaGreen"}
        label={"online"}
        zIndex={zIndex}
        onClick={() => routerHistory.push("/dash/online")}
    />
);

type MenuItemProps = ZIndexProps & {
    backgroundColor: Color;
    label: PageName;
    onClick: () => void;
};

const MenuItem = ({ backgroundColor, label, zIndex, onClick }: MenuItemProps): JSX.Element => {
    const theme = useTheme();
    return (
        <FlexRowAlignCenter
            position={"relative"}
            zIndex={zIndex}
            width={"106px"}
            height={11}
            background={`linear-gradient(115deg, transparent 0, ${theme.colors[backgroundColor]} 0 96px, transparent 96px 106px)}`}
            justifyContent={"center"}
            cursor={"pointer"}
            ml={"-16px"}
            onClick={onClick}
        >
            <Label11Bold color={"white"} uppercase>
                {label}
            </Label11Bold>
            <Box pl={3} />
            <ArrowRight fill={"white"} />
        </FlexRowAlignCenter>
    );
};

const MobileMenuItem = ({ backgroundColor, label, zIndex, onClick }: MenuItemProps): JSX.Element => {
    const theme = useTheme();
    return (
        <FlexRowAlignCenter
            position={"relative"}
            zIndex={zIndex}
            width={"94px"}
            height={11}
            background={`linear-gradient(115deg, transparent 0, ${theme.colors[backgroundColor]} 0 85px, transparent 85px 94px)}`}
            justifyContent={"center"}
            cursor={"pointer"}
            ml={"-16px"}
            onClick={onClick}
        >
            <Label11Bold color={"white"} uppercase>
                {label}
            </Label11Bold>
            <Box pl={3} />
            <ArrowRight fill={"white"} />
        </FlexRowAlignCenter>
    );
};
