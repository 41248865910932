import React from "react";
import { FlexColAlignCenter, YSpacer } from "../styledSystemUtilities";
import styled, { keyframes } from "styled-components";
import theme from "../../theme";
import { Color } from "../../@types/color";
import { Body14 } from "../Typography";

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    
`;

const Spinner = styled.div`
    border: 8px solid hsla(0, 0%, 80%);
    border-radius: 50%;
    border-top: ${(props: { color: Color }) => `8px solid ${theme.colors[props.color]}`};
    width: 100px;
    height: 100px;
    animation: ${spin} 2s linear infinite;
`;

export function LoadingWheel({ color }: { color: Color }): JSX.Element {
    return (
        <FlexColAlignCenter data-testid={"loadingWheel"}>
            <Spinner color={color} />
            <YSpacer spaceY={6} />
            <Body14>Gathering data. This may take a few moments.</Body14>
        </FlexColAlignCenter>
    );
}
