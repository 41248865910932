import React from "react";
import { Box, FlexColAlignCenter, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Gradient } from "../Atoms";
import { FlexCol } from "../styledSystemUtilities";
import { GreenPin, SiennaUpsideDownPin } from "../Atoms/assets";
import { Body12, Header, Header27 } from "../Typography";

export const GradientRankIndicator = ({
    rank,
    originalRank,
    originalDate,
}: {
    rank: number;
    originalRank: number;
    originalDate: string;
}): JSX.Element => {
    return (
        <FlexColAlignCenter justifyContent={"center"} width={"100%"}>
            <Box width={"92%"}>
                <Box width={"88%"} pl={"20px"} data-testid={"currentRank"}>
                    <StartFromLeftPinPlacement
                        percent={(1 - rank / 60) * 100}
                        formatter={(score) => score}
                        score={rank}
                    />
                </Box>

                <FlexRowAlignCenter justifyContent={"space-between"} height={"10px"}>
                    <Body12 color={"auratiumGreen"}>Low</Body12>
                    <Gradient width={"80%"} />
                    <Body12 color={"auratiumGreen"}>Ideal</Body12>
                </FlexRowAlignCenter>

                <Box width={"88%"} pl={"20px"}>
                    <Box mx={"16px"}>
                        <FlexCol
                            position={"relative"}
                            alignItems={"center"}
                            width={"72px"}
                            left={`${(1 - originalRank / 60) * 100}%`}
                            transform={"translate(-26px)"}
                            data-testid={"originalRank"}
                        >
                            <SiennaUpsideDownPin />
                            <Header color={"sienna"}>{originalRank}</Header>
                            <Body12 color={"sienna"} lineHeight={"16px"}>
                                Original Rank
                            </Body12>
                            <Body12 color={"sienna"} lineHeight={"16px"}>
                                {originalDate}
                            </Body12>
                        </FlexCol>
                    </Box>
                </Box>
            </Box>
        </FlexColAlignCenter>
    );
};

export const StartFromLeftPinPlacement = ({
    percent,
    score,
    formatter,
}: {
    percent: number;
    score: number;
    formatter: (score: number) => string | number;
}): JSX.Element => <PinPlacement percent={percent} score={score} formatter={formatter} />;

export const PinPlacement = ({
    percent,
    score,
    formatter,
}: {
    percent: number;
    score: number;
    formatter: (score: number) => string | number;
}): JSX.Element => {
    return (
        <Box mx={"16px"}>
            <Box
                position={"relative"}
                transform={"translate(-8px)"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                width={"36px"}
                left={`${percent}%`}
            >
                <Header27 color={"forrestGreen"}>{formatter(score)}</Header27>
                <GreenPin />
            </Box>
        </Box>
    );
};
