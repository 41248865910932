import axios from "axios";
import { slices } from "../index";
import { api } from "../utilityActions";
import { Middleware } from "redux";
import utils from "../../utils";
import logout from "../../utils/logout";

// "https://ascent-client-server.herokuapp.com"

const apiMiddleware: Middleware = ({ dispatch }) => (next) => async (action) => {
    if (action.type !== api.type) {
        return next(action);
    }
    const { call, success, failure, label, continueLoading, scrollToOnLoad } = action.payload;

    try {
        dispatch(slices.loading.actions.set(label));
        const { data } = await axios({
            baseURL: process.env.REACT_APP_BASE_URL,
            ...call,
            transformResponse: (res) => {
                return JSON.parse(res, utils.useDateParser);
            },
        });
        if (success) {
            dispatch(success(data));
        }
        dispatch(slices.serverErrors.actions.unset(label));
        if (!continueLoading) {
            dispatch(slices.loading.actions.unset(label));
        }
        if (scrollToOnLoad) {
            window.scroll(0, scrollToOnLoad);
        }
    } catch (e: any) {
        dispatch(slices.loading.actions.unset(label));
        console.log(e);
        if (e.response) {
            dispatch(slices.serverErrors.actions.set(label, e.response.data));
        }
        if (failure) {
            dispatch(failure(e.response));
        }
        console.log(e.response);
    }
};

export default apiMiddleware;
