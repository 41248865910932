import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { AbsolutePositionLogo, FormError, Gradient, Input, RightButton } from "../Atoms";
import { object, string, ref } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { actions } from "../../redux";
import { Header27 } from "../Typography";
import useErrors from "../../hooks/useErrors";
import { useAppDispatch } from "../../redux/hooks";
import { useParams } from "react-router-dom";

const schema = object().shape({
    password: string()
        .required("Please enter a password")
        .min(8, "Password must be at least 8 characters long.")
        .matches(/^.*[A-Z].*$/, "Password must contain a capitol letter.")
        .matches(/^.*\d.*$/, "Password must contain a number."),
    confirmPassword: string()
        .required("Please confirm your password")
        .when("password", {
            is: (password) => !!(password && password.length > 0),
            then: string().oneOf([ref("password")], "Password doesn't match"),
        }),
});

export const SetNewPassword = (): JSX.Element => {
    const { passwordResetToken } = useParams<{ passwordResetToken: string }>();
    const dispatch = useAppDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: { password: string; confirmPassword: string }) =>
        dispatch(actions.setNewPassword(data.password, passwordResetToken));

    const error = useErrors("setNewPassword");

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"64px"} />
                    <Header27 color={"graphiteGray"} textAlign={"center"}>
                        Choose New Password
                    </Header27>
                    <Box pb={"20px"} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            data-testid={"password"}
                            ref={register}
                            name={"password"}
                            type={"password"}
                            placeholder="New Password"
                            hasError={errors.password}
                        />
                        <FormError error={errors.password} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"confirmPassword"}
                            ref={register}
                            name={"confirmPassword"}
                            type={"password"}
                            placeholder="Confirm New Password"
                            hasError={errors.confirmPassword}
                        />
                        <FormError error={errors.confirmPassword} />
                        <Box pb={"26px"} />
                        <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                            submit
                        </RightButton>
                        <FormError error={error} />
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
