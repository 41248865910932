import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const getLocationOverviewReports = (accountId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/reports/get-location-overviews/${accountId}`,
            method: "get",
        },
        success: slices.locationOverviewReports.actions.set,
        label: "locationOverviewReports",
    });

export default getLocationOverviewReports;
