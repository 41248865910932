import React, { useEffect, useState } from "react";
import { ProfitTemplate } from "../Templates";
import { Box, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../styledSystemUtilities";
import { Body14, Header27 } from "../Typography";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions } from "../../redux";
import {
    Center,
    DynamicCallTrackingTable,
    ProfitChart,
    ProfitPaymentModal,
    ProfitTable,
    ValueAverageTable,
} from "../Organisms/ProfitPageComponents";
import { formatAsDollars } from "../../utils/formatter";
import { Dropdown, LoadingWrapper } from "../Molecules";
import { useWindowSize } from "react-use";
import theme from "../../theme";
import { KpiDataEntryForm } from "../Organisms/KpiDataEntryForm";
import { Modal } from "../Molecules/Modal";
import { ActiveProfitCenterIcon } from "../Atoms/assets";
import { PopupContainer, TaskBanner } from "../Organisms";
import { formatTasks } from "../../utils/formatTasks";
import _ from "lodash/fp";
import { profitTasks } from "../../constants/tasks";

// const productTasks = [
//     {
//         name: "1 — Influence Perceptions [Branding]",
//         tasks: [
//             {
//                 name: "brand questionnaire",
//                 Icon: () => <ClipboardIcon fill={"sienna"} width={"13px"} height={"18px"} />,
//             },
//         ],
//     },
//     {
//         name: "2 — Improve Phone Skills",
//         tasks: [
//             {
//                 name: "download audio training",
//                 Icon: () => <AudioFileIcon />,
//             },
//             {
//                 name: "download pdf workbook",
//                 Icon: () => <PdfFileIcon />,
//             },
//         ],
//     },
// ];

export const Profit = (): JSX.Element => {
    const { width } = useWindowSize();
    const dispatch = useAppDispatch();
    const practice = useAppSelector((state) => state.openPractice);
    const report = useAppSelector((state) => state.profitReport);
    const profitCenters = useAppSelector((state) => state.profitCenters);
    const popup = useAppSelector((state) => state.popup);
    const reportIsLoading = useAppSelector((state) => state.loading.profitReport);
    const profitCentersLoading = useAppSelector((state) => state.loading.profitCenters);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        dispatch(actions.getProfitReport(practice.id));
        dispatch(actions.getProfitCenters(practice.id));
    }, [dispatch, practice.id]);
    return (
        <>
            <Modal title={practice.practiceName} setIsOpen={setIsModalOpen} isOpen={isModalOpen}>
                <KpiDataEntryForm setClosed={() => setIsModalOpen(false)} />
            </Modal>
            <ProfitPaymentModal />
            {popup ? <PopupContainer popupId={popup} /> : null}
            <ProfitTemplate>
                <LoadingWrapper color={"balticBlue"} isLoading={reportIsLoading || profitCentersLoading}>
                    <Grid gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"}>
                        <Box gridColumn={2}>
                            <TaskBanner
                                primaryColor={"nauticBlue"}
                                secondaryColor={"balticBlue"}
                                productTasks={formatTasks(_.reject("completed", practice.tasks), "sienna", profitTasks)}
                                completedProductTasks={formatTasks(
                                    _.filter("completed", practice.tasks),
                                    "balticBlue",
                                    profitTasks
                                )}
                            />
                            <YSpacer spaceY={8} />
                        </Box>
                        <Box gridColumn={2} pl={7} pb={8}>
                            <Box pl={2}>
                                <Header27 color={"nauticBlue"}>7 Profit Centers</Header27>
                                <Body14 color={"nauticBlue"}>Select a profit center to improve or manage.</Body14>
                            </Box>
                            <FlexRowAlignCenter>
                                <ActiveProfitCenterIcon />
                                <XSpacer spaceX={2} />
                                <Body14 color={"nauticBlue"}>= Profit center with purchased products.</Body14>{" "}
                            </FlexRowAlignCenter>
                        </Box>
                        {profitCenters.map((profitCenter) => (
                            <Grid
                                key={profitCenter.key}
                                gridColumn={"1 / -1"}
                                gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"}
                            >
                                <Dropdown
                                    name={profitCenter.name}
                                    closedColor={"balticBlue"}
                                    closedBackgroundColor={"porcelain"}
                                    openColor={"nauticBlue"}
                                    openBackgroundColor={"ghost"}
                                    isActiveProfitCenter={
                                        profitCenter.currentPurchases.length + profitCenter.pastPurchases.length > 0
                                    }
                                >
                                    <Center profitCenter={profitCenter} />
                                </Dropdown>
                            </Grid>
                        ))}
                        <Box gridColumn={2} pl={8}>
                            <Header27 color={"nauticBlue"}>Practice Production Data</Header27>
                        </Box>
                        <Box gridColumn={"2 / -1"} height={"38px"} backgroundColor={"porcelain"} />
                        <Grid
                            gridColumn={2}
                            gridTemplateColumns={width > theme.breakpoints[0] ? "1fr 1fr" : "1fr"}
                            pb={16}
                            gridColumnGap={6}
                        >
                            {report.callTrackingReport && report.callTrackingReport.length > 0 ? (
                                <DynamicCallTrackingTable report={report.callTrackingReport} />
                            ) : null}
                            {report.perPatientValueReport ? (
                                <ValueAverageTable
                                    valueAverages={report.perPatientValueReport}
                                    title={"Annual Per-Patient Value"}
                                />
                            ) : (
                                <Box />
                            )}
                            {report.perVisitValueReport ? (
                                <ValueAverageTable
                                    valueAverages={report.perVisitValueReport}
                                    title={"Per-Visit Value Average"}
                                />
                            ) : (
                                <Box />
                            )}
                            {report.years.length > 0 ? (
                                <>
                                    <ProfitTable
                                        kpiYears={report.years}
                                        label={"revenue"}
                                        formatter={formatAsDollars}
                                        title={"Production By Month"}
                                    />
                                    <ProfitChart
                                        years={report.years}
                                        label={"revenue"}
                                        name={"Monthly Production"}
                                        yLabelName={"Production"}
                                        setIsOpen={setIsModalOpen}
                                    />
                                    <ProfitTable
                                        kpiYears={report.years}
                                        label={"newPatients"}
                                        title={"New Patients By Month"}
                                    />
                                    <ProfitChart
                                        years={report.years}
                                        label={"newPatients"}
                                        name={"New Patients"}
                                        yLabelName={"New Patients"}
                                        setIsOpen={setIsModalOpen}
                                    />
                                    {practice.pmsIntegrationId ? null : (
                                        <>
                                            <ProfitTable
                                                kpiYears={report.years}
                                                label={"referrals"}
                                                title={"Patient Referrals By Month"}
                                            />
                                            <ProfitChart
                                                years={report.years}
                                                label={"referrals"}
                                                name={"Patient Referrals"}
                                                yLabelName={"Patient Referrals"}
                                                setIsOpen={setIsModalOpen}
                                            />
                                        </>
                                    )}
                                    <ProfitTable
                                        kpiYears={report.years}
                                        label={"visits"}
                                        title={"Patient Visits By Month"}
                                    />
                                    <ProfitChart
                                        years={report.years}
                                        label={"visits"}
                                        name={"Patient Visits"}
                                        yLabelName={"Patient Visits"}
                                        setIsOpen={setIsModalOpen}
                                    />
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </LoadingWrapper>
            </ProfitTemplate>
        </>
    );
};
