import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const getProfitCenters = (locationId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/recurly/profit/get-profit-centers/${locationId}`,
            method: "get",
        },
        success: slices.profitCenters.actions.set,
        label: "profitCenters",
    });

export default getProfitCenters;
