import { api } from "../utilityActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const resetPassword = (data: { email: string }): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: "/open-routes/authentication/reset-password",
            method: "post",
            data: data,
        },
        label: "resetPassword",
    });

export default resetPassword;
