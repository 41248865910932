import { Product } from "../../@types/reports/ProfitCenters";
import { PrintCheckoutCard, StandardCheckoutCard, TrainingCheckoutCard } from "./CheckoutCards";
import React from "react";

export function CheckoutCard(
    product: Product,
    checkedProducts: Product[],
    setChecked: React.Dispatch<React.SetStateAction<Product[]>>
): JSX.Element {
    switch (product.type) {
        case "standard":
            return <StandardCheckoutCard product={product} checkedProducts={checkedProducts} setChecked={setChecked} />;
        case "print":
            return <PrintCheckoutCard product={product} checkedProducts={checkedProducts} setChecked={setChecked} />;
        case "training":
            return <TrainingCheckoutCard product={product} checkedProducts={checkedProducts} setChecked={setChecked} />;
    }
}
