import { createSlice } from "@reduxjs/toolkit";
import { Task } from "../../@types/shared";

const initialState: Task = {
    taskKey: "domain",
    parentProduct: "",
    taskName: "",
    completed: false,
};

export default createSlice({
    name: "openTask",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
        unset: (state) => ({
            s3FolderName: "",
            taskKey: "domain",
            parentProduct: "",
            taskName: "",
            completed: false,
        }),
    },
});
