import { Color } from "../../@types/color";
import { useWindowSize } from "react-use";
import theme from "../../theme";
import { Box } from "../styledSystemUtilities";
import React from "react";

export const GridSpaceBox = ({
    backgroundColor = "transparent",
}: {
    backgroundColor?: Color | "transparent";
}): JSX.Element | null => {
    const { width } = useWindowSize();
    return width > theme.breakpoints[0] ? null : <Box backgroundColor={backgroundColor} height={"38px"} />;
};
