import React from "react";
import { PhaseName } from "../../@types/phaseName";
import { FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { Header22 } from "../Typography";
import { Color } from "../../@types/color";
import _ from "lodash/fp";

export const IconLabel = ({
    Icon,
    label,
    color,
}: {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    label: PhaseName;
    color: Color;
}): JSX.Element => (
    <FlexRowAlignCenter>
        <Icon fill={color} />
        <XSpacer spaceX={5} />
        <Header22 color={color}>{_.capitalize(label)}</Header22>
    </FlexRowAlignCenter>
);
