import { Box } from "../styledSystemUtilities";
import React from "react";
import { Body14 } from "../Typography";
import { FieldError } from "react-hook-form";

export const FormError = ({ error }: { error: FieldError | undefined }): JSX.Element | null => {
    return error ? (
        <Box mt={5} ml={5}>
            <Body14 color={"sienna"}>{error.message}</Body14>
        </Box>
    ) : null;
};
