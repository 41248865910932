import { Box, FlexRow, FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import React from "react";
import { MenuItemComponent, NavItemComponent } from "../../@types/components";

type MenuButtonRowProps = {
    menuItems: MenuItemComponent[];
    navItems: NavItemComponent[];
};

export const MenuButtonRow = ({ menuItems, navItems }: MenuButtonRowProps): JSX.Element => (
    <FlexRow justifyContent={"space-between"} gridColumn={2} width={"100%"}>
        <FlexRow>
            {menuItems.map((MenuItem, index) => (
                <Box key={index}>
                    <MenuItem zIndex={30 - index * 10} />
                </Box>
            ))}
        </FlexRow>
        <FlexRowAlignCenter height={11} justifyContent={"flex-end"}>
            {navItems.map((NavItem, index) => (
                <FlexRow key={index}>
                    <NavItem />
                    <XSpacer spaceX={6} />
                </FlexRow>
            ))}
        </FlexRowAlignCenter>
    </FlexRow>
);
