import { Body, Label11 } from "../Typography";
import { Box, Span, YSpacer } from "../styledSystemUtilities";
import React from "react";
import { Color } from "../../@types/color";

type MenuHeaderProps = {
    name: string;
    labelColor: Color;
    dateColor: Color;
    date: string;
    pl?: string | number;
};

export const MenuHeader = ({ name, labelColor, dateColor, date, pl, ...props }: MenuHeaderProps): JSX.Element => (
    <Box pl={pl} {...props}>
        <Body color={"graphiteGray"}>{name}</Body>
        <YSpacer spaceY={1} />
        <Box data-testid={"startDate"}>
            <Label11 color={labelColor}>
                Active Since:&emsp;
                <Span fontSize={"12px"} fontWeight={"medium"} color={dateColor}>
                    {date}
                </Span>
            </Label11>
        </Box>
    </Box>
);
