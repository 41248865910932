import React from "react";
import { Box } from "../styledSystemUtilities";
import { useWindowSize } from "react-use";
import { useTheme } from "styled-components";
import { Header100, Header200 } from "../Typography";
import { Color } from "../../@types/color";
import { PhaseName } from "../../@types/phaseName";
import _ from "lodash/fp"

type PhaseLabelProps = {
    textColor: Color;
    numberColor: Color;
    text: PhaseName;
    number: 1 | 2 | 3;
}

const PhaseLabel = ({ textColor, numberColor, text, number }: PhaseLabelProps) => {
    const { width } = useWindowSize();
    const theme = useTheme();
    return width > theme.breakpoints[1] ? (
        <Box position={"fixed"} top={"136px"} zIndex={-100}>
            <Box position={"relative"} top={"-18px"} left={"56px"}>
                <Header200 color={numberColor}>{number}</Header200>
            </Box>

            <Box
                position={"relative"}
                top={"-160px"}
                left={"-26px"}
                zIndex={-1}
                writingMode={"vertical-rl"}
                textOrientation={"mixed"}
            >
                <Header100 color={textColor}>{_.capitalize(text)}</Header100>
            </Box>
        </Box>
    ) : null;
};

export const OnlinePhaseLabel = (): JSX.Element => {
    return <PhaseLabel text={"online"} number={1} textColor={"auratiumGreen"} numberColor={"seaGreen"} />;
};
export const GrowthPhaseLabel = (): JSX.Element => {
    return <PhaseLabel text={"growth"} number={2} textColor={"petrolBlue"} numberColor={"ipanemaBlue"} />;
};
export const ProfitPhaseLabel = (): JSX.Element => {
    return <PhaseLabel text={"profit"} number={3} textColor={"nauticBlue"} numberColor={"balticBlue"} />;
};
