import { createSlice } from "@reduxjs/toolkit";
import { ClientAccountFrontEnd } from "../../@types/shared";

const initialState: ClientAccountFrontEnd = {
    accountName: "",
    websiteAddress: "",
    address: "",
    state: "",
    streetAddress: "",
    city: "",
    zipCode: "",
    strategist: {
        email: "",
        name: "",
        calendlyLink: "",
        id: "",
    },
    contacts: [],
    id: "",
    primaryContact: {
        name: "",
        phone: "",
        email: "",
        label: "",
    },
    startDate: "",
};

export default createSlice({
    name: "clientAccount",
    initialState,
    reducers: {
        set: (state, action) => ({ ...state, ...action.payload }),
        unset: () => initialState
    },
});
