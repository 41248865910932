import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { Body12, Body12Bold } from "../Typography";
import { Color } from "../../@types/color";

type Column = {
    renderLabel: () => JSX.Element;
};

type Cell = {
    value: string | number;
    color?: Color;
};

type RowType = Cell[];

export const PrimaryTable = ({
    columns,
    rows,
    width,
    footerRows,
    footerRowTopBorder,
    footerRowIsBold,
    firstColumnAlign,
    backgroundColor,
    rowBorder,
    rowHeight,
    headerRowBorder,
    rowTestIds,
    footerRowBackgroundColor = "transparent",
}: {
    columns: Column[];
    rows: RowType[];
    footerRows: RowType[];
    footerRowTopBorder?: string;
    width: number | string;
    firstColumnAlign?: string;
    footerRowIsBold?: boolean;
    rowBorder: string;
    headerRowBorder: string;
    rowHeight?: string | number;
    backgroundColor: Color;
    rowTestIds: string[];
    footerRowBackgroundColor?: Color;
}): JSX.Element => {
    return (
        <Box width={width}>
            <HeaderRow
                columns={columns}
                firstColumnAlign={firstColumnAlign}
                border={headerRowBorder}
                backgroundColor={backgroundColor}
            />
            {rows.map((row, index) => {
                return (
                    <Box key={rowTestIds[index]}>
                        <Row
                            row={row}
                            columns={columns}
                            firstColumnAlign={firstColumnAlign}
                            borderBottom={index === rows.length - 1 ? "none" : rowBorder}
                            height={rowHeight}
                            backgroundColor={backgroundColor}
                            testId={rowTestIds[index]}
                        />
                    </Box>
                );
            })}
            {footerRows.map((row, index) => {
                return (
                    <Box key={"footerRow" + (index + 1)}>
                        <FooterRow
                            testId={"footerRow" + (index + 1)}
                            isBold={footerRowIsBold}
                            topBorder={footerRowTopBorder}
                            row={row}
                            columns={columns}
                            backgroundColor={footerRowBackgroundColor}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

const HeaderRow = ({
    columns,
    firstColumnAlign = "center",
    border,
    backgroundColor,
}: {
    columns: Column[];
    firstColumnAlign?: string;
    border: string;
    backgroundColor: Color;
}) => {
    return (
        <Grid
            height={"22px"}
            backgroundColor={backgroundColor}
            borderBottom={border}
            alignItems={"center"}
            justifyItems={"center"}
            pl={firstColumnAlign === "start" ? 7 : 0}
            gridTemplateColumns={`${columns
                .map((Column, index) => (index === 0 && firstColumnAlign === "start" ? "1.8fr" : "1fr"))
                .join(" ")}`}
        >
            {columns.map((column) => column.renderLabel())}
        </Grid>
    );
};

const Row = ({
    row,
    columns,
    borderBottom,
    firstColumnAlign = "center",
    height = "22px",
    backgroundColor,
    testId,
}: {
    row: RowType;
    columns: Column[];
    borderBottom: string;
    backgroundColor: string;
    firstColumnAlign?: string;
    height?: number | string;
    testId: string;
}) => {
    return (
        <Grid
            height={height}
            backgroundColor={backgroundColor}
            borderBottom={borderBottom}
            alignItems={"center"}
            justifyItems={"center"}
            gridTemplateColumns={`${columns
                .map((Column, index) => (index === 0 && firstColumnAlign === "start" ? "1.8fr" : "1fr"))
                .join(" ")}`}
            pl={firstColumnAlign === "start" ? 7 : 0}
            data-testid={testId}
        >
            {row.map((cell, index) => (
                <Box justifySelf={index === 0 ? firstColumnAlign : "center"} key={index}>
                    <Body12 color={cell.color}>{cell.value}</Body12>
                </Box>
            ))}
        </Grid>
    );
};

const FooterRow = ({
    row,
    columns,
    topBorder = "none",
    isBold = true,
    testId,
    backgroundColor,
}: {
    row: RowType;
    columns: Column[];
    topBorder?: string;
    isBold?: boolean;
    testId: string;
    backgroundColor: Color;
}) => {
    return (
        <Grid
            height={"22px"}
            backgroundColor={backgroundColor}
            alignItems={"center"}
            justifyItems={"center"}
            borderTop={topBorder}
            gridTemplateColumns={`${columns.map(() => "1fr").join(" ")}`}
            data-testid={testId}
        >
            {row.map((cell, index) => (
                <Box key={index}>
                    {isBold ? (
                        <Body12Bold color={cell.color}>{cell.value}</Body12Bold>
                    ) : (
                        <Body12 color={cell.color}>{cell.value}</Body12>
                    )}
                </Box>
            ))}
        </Grid>
    );
};
