import React from "react";
import { Box } from "../../styledSystemUtilities";
import {
    Audio,
    Cart,
    MagnifyingGlass,
    Pdf,
    PlusFour,
    ScreenShare,
    TwoLevelsEngagement,
    Video,
} from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { LionSpeakHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const PhoneSkills = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <LionSpeakHeader setPopup={setPopup} name={"Phone Skills"} tagline={'Have them at "Hello."'} />
            <GrayBackground>
                <SellingPoint
                    header={"The most critical skill for effective marketing"}
                    body={
                        "Generating phone calls is an expensive process. Even using the best (Ascent) at efficiently driving quality phone calls into your practice, each new phone call represents an investment. Even small improvements in call closure rates make big differences in your per-patient acquisition costs."
                    }
                />
                <Box display={"flex"} justifyContent={"center"} pb={8}>
                    <TwoLevelsEngagement />
                </Box>
            </GrayBackground>
            <WhiteBackground>
                <SellingPoint
                    header={"1. Essential"}
                    body={"Our entry level phone skills training gives you the knowledge to do it right."}
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={Audio}
                    header={"Audio Download"}
                    body={"All the keys to great phone technique."}
                />
                <IconBulletPoint Icon={Pdf} header={"PDF Workbook"} body={"Your “doing it right” resource."} />
                <IconBulletPoint
                    Icon={Cart}
                    header={"1 Mystery Shopper Call"}
                    body={"We’ll report on how your team did."}
                />
            </WhiteBackground>
            <WhiteBackground>
                <SellingPoint
                    header={"2. Enhanced"}
                    body={"A deep dive to build correct habits and create an effective system."}
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={ScreenShare}
                    header={"90-minute Virtual Kickoff"}
                    body={"Interactive and immersive."}
                />
                <IconBulletPoint
                    Icon={PlusFour}
                    header={"4 Virtual Training Sessions"}
                    body={"Building habits and correcting faults."}
                />
                <IconBulletPoint
                    Icon={MagnifyingGlass}
                    header={"Onboarding Discovery Call"}
                    body={"So we can tailor everything to your needs."}
                />
                <IconBulletPoint
                    Icon={Cart}
                    header={"20 Mystery Shopper Calls"}
                    body={"We’ll report on how your team did."}
                />
                <IconBulletPoint
                    Icon={Video}
                    header={"Access to Video Library"}
                    body={"A wealth of knowledge at your fingertips."}
                />
                <IconBulletPoint
                    Icon={Audio}
                    header={"Audio Download"}
                    body={"All the keys to great phone technique."}
                />
                <IconBulletPoint Icon={Pdf} header={"PDF Workbook"} body={"Your “doing it right” resource."} />
            </WhiteBackground>
            <GrayBackground>
                <Box pb={4} />
                <SellingPoint
                    header={"About LionSpeak"}
                    body={
                        "LionSpeak is a communications coaching company dedicated to teaching the powerful art of un-scripted, authentic communication and personal leadership skills to professionals who strive to speak with greater clarity and inspiration to achieve extraordinary results. Master speaker, trainer, and coach, Katherine Eitel Belt founded the company in 1990. Katherine and her team of experienced coaches, provide customized coaching programs to hundreds of dental teams worldwide."
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};
