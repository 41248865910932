import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux";
import { Store } from "redux";
import { History } from "history";

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

declare global {
    interface Window {
        Cypress: unknown;
        store: Store;
        tgHistory: History;
    }
}

if (window.Cypress) {
    window.store = store;
}
