import React from "react";
import { FieldErrors } from "react-hook-form";
import { Box } from "../styledSystemUtilities";
import { FormError } from "../Atoms";
import { BigInput } from "../Atoms/Inputs";

export function RegisteredBigInput({
    register,
    errors,
    name,
    placeholder,
    defaultValue,
}: {
    register:
        | ((instance: HTMLTextAreaElement | null) => void)
        | React.RefObject<HTMLTextAreaElement>
        | null
        | undefined;
    errors: FieldErrors;
    name: string;
    placeholder: string;
    defaultValue?: string;
}): JSX.Element {
    return (
        <Box pb={6}>
            {defaultValue ? (
                <BigInput
                    ref={register}
                    name={name}
                    placeholder={placeholder}
                    hasError={errors[name]}
                    defaultValue={defaultValue}
                    data-testid={name}
                />
            ) : (
                <BigInput
                    ref={register}
                    name={name}
                    placeholder={placeholder}
                    hasError={errors[name]}
                    data-testid={name}
                />
            )}
            <FormError error={errors[name]} />
        </Box>
    );
}
