import React from "react";
import { FieldErrors } from "react-hook-form";
import { Box } from "../styledSystemUtilities";
import { FormError, Input } from "../Atoms";

export function RegisteredInput({
    register,
    errors,
    name,
    placeholder,
    defaultValue,
}: {
    register: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
    errors: FieldErrors;
    name: string;
    placeholder: string;
    defaultValue?: string;
}): JSX.Element {
    return (
        <Box pb={6}>
            {defaultValue ? (
                <Input
                    ref={register}
                    name={name}
                    placeholder={placeholder}
                    hasError={errors[name]}
                    defaultValue={defaultValue}
                    data-testid={name}
                />
            ) : (
                <Input
                    ref={register}
                    name={name}
                    placeholder={placeholder}
                    hasError={errors[name]}
                    data-testid={name}
                />
            )}
            <FormError error={errors[name]} />
        </Box>
    );
}
