import { ReportingDropdown, ReportingDropdownContent } from "../../Molecules";
import { Box, FlexColAlignCenter, FlexRow, FlexRowAlignCenter, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body12, Body14Medium, Label } from "../../Typography";
import { Chart } from "../Chart";
import { VariableSetPlot } from "../../Molecules/Plots";
import React, { useState } from "react";
import {
    GmbMonth,
    GoogleMyBusinessReport,
    ImpressionsByType,
    NumberOfSearchesByType
} from "../../../@types/reports/Online";
import { Color } from "../../../@types/color";
import { DotLegend } from "../../Molecules/DotLegend";
import { GmbActionsIcons } from "../../Atoms/assets";
import _ from "lodash/fp";
import { LegendDot } from "../../Atoms";
import { LineTable } from "../../Molecules/LineTable";

export const GoogleMyBusinessDropdown = ({
    googleMyBusinessReport,
}: {
    googleMyBusinessReport: GoogleMyBusinessReport;
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <>
            <ReportingDropdown text={"Google My Business Data"} isOpen={isOpen} setIsOpen={setIsOpen} />
            {isOpen ? (
                <ReportingDropdownContent testId={"gmbDropdown"}>
                    <ActionsReport googleMyBusinessReport={googleMyBusinessReport} />
                    <CustomerSearchReport googleMyBusinessReport={googleMyBusinessReport} />
                </ReportingDropdownContent>
            ) : null}
        </>
    );
};

const impressionKeyToLabel: {[key in keyof ImpressionsByType]: string} = {
    desktopMapsImpressions: "desktop maps impressions",
    desktopSearchImpressions: "desktop search impressions",
    mobileMapsImpressions: "mobile maps impressions",
    mobileSearchImpressions: "mobile search impressions",
    total: "total impressions"
}

const CustomerSearchReport = ({
    googleMyBusinessReport,
}: {
    googleMyBusinessReport: GoogleMyBusinessReport;
}): JSX.Element => {
    const [selectedSearchType, setSelectedSearchType] = useState<keyof ImpressionsByType>("total");

    const handleMouseEvent = (searchType: keyof ImpressionsByType) => {
        setSelectedSearchType(searchType);
    };

    return (
        <Box pt={11} px={8}>
            <Body14Medium>How Customers Search For Your Business</Body14Medium>
            <YSpacer spaceY={3} />
            <FlexRowAlignCenter height={"26px"} width={"100%"}>
                <MeterSegment
                    handleMouseEvent={handleMouseEvent}
                    searchType={"mobileSearchImpressions"}
                    report={googleMyBusinessReport.impressionsByType}
                    color={"auratiumGreen"}
                />
                <MeterSegment
                    handleMouseEvent={handleMouseEvent}
                    searchType={"mobileMapsImpressions"}
                    report={googleMyBusinessReport.impressionsByType}
                    color={"darkSeaGreen"}
                />
                <MeterSegment
                    handleMouseEvent={handleMouseEvent}
                    searchType={"desktopSearchImpressions"}
                    report={googleMyBusinessReport.impressionsByType}
                    color={"dimGray"}
                />
                <MeterSegment
                    handleMouseEvent={handleMouseEvent}
                    searchType={"desktopMapsImpressions"}
                    report={googleMyBusinessReport.impressionsByType}
                    color={"nobelGray"}
                />
            </FlexRowAlignCenter>
            <YSpacer spaceY={3} />
            <Body12>
                Last 3 months — {googleMyBusinessReport.impressionsByType[selectedSearchType]}{" "}
                {impressionKeyToLabel[selectedSearchType]}
            </Body12>
            <YSpacer spaceY={4} />
            <FlexRowAlignCenter width={"100%"} pb={2}>
                <Box width={"50%"}><Body14Medium color={"auratiumGreen"}>Mobile</Body14Medium></Box>
                <Box width={"50%"}><Body14Medium color={"agateGray"}>Desktop</Body14Medium></Box>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter width={"100%"}>
                <Box width={"50%"}><SearchTypeDescription
                    label={"Google Search"}
                    body={`${googleMyBusinessReport.impressionsByType.mobileSearchImpressions} Impressions`}
                    color={"auratiumGreen"}
                /></Box>
                <Box width={"50%"}><SearchTypeDescription
                    label={"Google Search"}
                    body={`${googleMyBusinessReport.impressionsByType.desktopSearchImpressions} Impressions`}
                    color={"dimGray"}
                /></Box>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter width={"100%"}>
                <Box width={"50%"}><SearchTypeDescription
                    label={"Google Maps"}
                    body={`${googleMyBusinessReport.impressionsByType.mobileMapsImpressions} Impressions`}
                    color={"darkSeaGreen"}
                /></Box>
                <Box width={"50%"}><SearchTypeDescription
                    label={"Google Maps"}
                    body={`${googleMyBusinessReport.impressionsByType.desktopMapsImpressions} Impressions`}
                    color={"nobelGray"}
                /></Box>
            </FlexRowAlignCenter>
        </Box>
    );
};

const MeterSegment = ({
    searchType,
    report,
    color,
    handleMouseEvent,
}: {
    searchType: keyof ImpressionsByType;
    report: ImpressionsByType;
    handleMouseEvent: (searchType: keyof ImpressionsByType) => void;
    color: Color;
}) => {
    return (
        <Box
            data-testid={searchType + "SearchMeterSegment"}
            onMouseOver={() => handleMouseEvent(searchType)}
            onMouseOut={() => handleMouseEvent("total")}
            height={"100%"}
            width={`${_.round((report[searchType] / report.total) * 100)}%`}
            backgroundColor={color}
        />
    );
};

const SearchTypeDescription = ({ label, body, color }: { label: string; body: string; color: Color }): JSX.Element => {
    return (
        <Box pl={"18px"} pb={5}>
            <LegendDot label={label} fill={color} labelFill={color} />
            <Label>{body}</Label>
        </Box>
    );
};

const ActionsReport = ({ googleMyBusinessReport }: { googleMyBusinessReport: GoogleMyBusinessReport }): JSX.Element => {
    return (
        <Box>
            <YSpacer spaceY={11} />
            <Box pl={8}>
                <FlexRowAlignCenter>
                    <Body14Medium>Google My Business Actions</Body14Medium>
                    <XSpacer spaceX={5} />
                    <GmbActionsIcons />
                </FlexRowAlignCenter>

                <YSpacer spaceY={2} />
                <DotLegend
                    dots={[
                        {
                            label: "Call you",
                            color: "auratiumGreen",
                        },
                        {
                            label: "Visit your website",
                            color: "midGray",
                        },
                        {
                            label: "Request directions",
                            color: "orangePlot",
                        },
                    ]}
                />
            </Box>

            <YSpacer spaceY={4} />
            <FlexColAlignCenter>
                <Chart
                    yLabel={"Actions"}
                    renderPlot={() => (
                        <VariableSetPlot
                            dataSetsWithFormat={[
                                getDataSetWithFormat(googleMyBusinessReport, "drivingDirections", "orangePlot"),
                                getDataSetWithFormat(googleMyBusinessReport, "websiteActions", "midGray"),
                                getDataSetWithFormat(googleMyBusinessReport, "calls", "auratiumGreen"),
                            ]}
                            width={300}
                        />
                    )}
                />
            </FlexColAlignCenter>
        </Box>
    );
};

const getDataSetWithFormat = (
    googleMyBusinessReport: GoogleMyBusinessReport,
    metric: keyof Omit<GmbMonth, "date">,
    color: Color
) => {
    return {
        dataSet: googleMyBusinessReport.months.map((month) => ({
            x: month.date,
            y: month[metric],
        })),
        color: color,
    };
};
