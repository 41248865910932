import { Box } from "../styledSystemUtilities";
import { Checkbox } from "../Atoms";
import { Control, Controller } from "react-hook-form";
import React from "react";

export function ControlledCheckbox({ name, control }: { name: string; control: Control }): JSX.Element {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={name}
            render={({ onChange, value }) => (
                <Box onClick={() => onChange(!value)} data-testid={name}>
                    <Checkbox isChecked={value} fill={"sienna"} />
                </Box>
            )}
        />
    );
}
