import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientUserFrontEnd, Id } from "../../@types/shared";

type CurrentUserState = {
    user: ClientUserFrontEnd;
    isAuthenticated: boolean;
};

const initialState: CurrentUserState = {
    user: {
        id: "",
        firstName: "",
        lastName: "",
        clientAccountId: "",
        type: "CLIENT",
    },
    isAuthenticated: false,
};

export default createSlice({
    name: "currentUser",
    initialState,
    reducers: {
        set: (state, action) => ({ ...state, isAuthenticated: true, user: action.payload }),
        setClientAccountId: (state, action: PayloadAction<Id>) => ({
            ...state,
            user: { ...state.user, clientAccountId: action.payload },
        }),
    },
});
