import React from "react";
import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { useTheme } from "styled-components";

import { Color } from "../../@types/color";
import { ZIndexProps } from "styled-system";

export const OnlineWedge = ({ zIndex }: ZIndexProps): JSX.Element => <Wedge zIndex={zIndex} color={"seaGreen"} />;

export const GrowthWedge = ({ zIndex }: ZIndexProps): JSX.Element => <Wedge zIndex={zIndex} color={"ipanemaBlue"} />;

export const ProfitWedge = ({ zIndex }: ZIndexProps): JSX.Element => <Wedge zIndex={zIndex} color={"balticBlue"} />;

const Wedge = ({ zIndex, color }: ZIndexProps & { color: Color }): JSX.Element => {
    const theme = useTheme();
    return (
        <FlexRowAlignCenter
            position={"relative"}
            zIndex={zIndex}
            width={"54px"}
            height={11}
            background={`linear-gradient(115deg, transparent 0, ${theme.colors[color]} 0 49px, transparent 49px 54px)}`}
            justifyContent={"center"}
            ml={"-16px"}
        />
    );
};
