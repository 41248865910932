import { Box, FlexCol, FlexRowAlignCenter, Grid, YSpacer } from "../styledSystemUtilities";
import { Body12Medium, Body14Medium, Label, LabelMedium } from "../Typography";
import { ArrowDown, ArrowRight } from "../Atoms/assets";
import React, { Dispatch, SetStateAction } from "react";
import { formatAsDollars } from "../../utils/formatter";
import theme from "../../theme";
import { useWindowSize } from "react-use";
import { LocationReport } from "../../@types/components/LocationOverviewReport";
import { useAppSelector } from "../../redux/hooks";
import { LastThreeMonthProfitTrends } from "./LastThreeMonthProfitTrends";
import { LastThreeMonthOnlineTrends } from "./LastThreeMonthOnlineTrends";
import { LastThreeMonthGrowthTrends } from "./LastThreeMonthGrowthTrends";
import _ from "lodash/fp";

export const LocationTrends = ({
    locationOverviewReport,
    openReports,
    setOpenReports,
}: {
    locationOverviewReport: LocationReport;
    openReports: { [key: string]: boolean };
    setOpenReports: React.Dispatch<React.SetStateAction<{ [p: string]: boolean }>>;
}): JSX.Element => {
    const { width } = useWindowSize();
    const accountPractices = useAppSelector((state) => state.accountPractices);

    const currentPractice = accountPractices.find((practice) => practice.id === locationOverviewReport.locationId);

    const isOpen = openReports[locationOverviewReport.locationId];
    const setIsOpen = () =>
        setOpenReports((prevState) => ({
            ...prevState,
            [locationOverviewReport.locationId]: !prevState[locationOverviewReport.locationId],
        }));

    return isOpen ? (
        <Grid gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"} pb={8}>
            {width >= theme.breakpoints[2] ? (
                <DesktopLocationDropdown
                    setIsOpen={setIsOpen}
                    costPerPatient={locationOverviewReport.sixMonthAverages.costPerPatient}
                    perPatientValue={locationOverviewReport.perPatientValue}
                    totalRoi={locationOverviewReport.sixMonthAverages.roi}
                    practiceName={locationOverviewReport.locationName}
                />
            ) : (
                <>
                    <MobileLocationDropdown
                        setIsOpen={setIsOpen}
                        costPerPatient={locationOverviewReport.sixMonthAverages.costPerPatient}
                        perPatientValue={locationOverviewReport.perPatientValue}
                        totalRoi={locationOverviewReport.sixMonthAverages.roi}
                        practiceName={locationOverviewReport.locationName}
                    />
                    <Box height={"38px"} width={"100%"} backgroundColor={"greenWhite"} />
                </>
            )}
            <Grid gridColumn={2} gridTemplateColumns={width > theme.breakpoints[0] ? "254px 254px 254px" : "1fr"}>
                <LastThreeMonthOnlineTrends
                    onlineReport={locationOverviewReport.online}
                    currentPractice={currentPractice}
                    lastThreeMonthDates={locationOverviewReport.lastThreeMonthDates}
                />
                <LastThreeMonthGrowthTrends
                    growthReport={locationOverviewReport.growth}
                    currentPractice={currentPractice}
                    lastThreeMonthDates={locationOverviewReport.lastThreeMonthDates}
                />
                <LastThreeMonthProfitTrends
                    profitReport={locationOverviewReport.profit}
                    currentPractice={currentPractice}
                    lastThreeMonthDates={locationOverviewReport.lastThreeMonthDates}
                />
            </Grid>
            {width >= theme.breakpoints[2] ? (
                <Box height={"38px"} width={"100%"} backgroundColor={"midGray"} />
            ) : (
                <Box height={"38px"} width={"100%"} backgroundColor={"ghost"} />
            )}
        </Grid>
    ) : (
        <Grid gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"} gridColumn={"1 / -1"} pb={5}>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                backgroundColor={"pearlGray"}
                height={"38px"}
                px={"82px"}
                cursor={"pointer"}
                maxWidth={"381px"}
                gridColumn={2}
                onClick={() => setIsOpen()}
            >
                <Body12Medium color={"agateGray"} uppercase>
                    {locationOverviewReport.locationName}
                </Body12Medium>
                <ArrowRight fill={"agateGray"} />
            </FlexRowAlignCenter>
        </Grid>
    );
};

type DropdownProps = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    practiceName: string;
    perPatientValue: number;
    totalRoi: number;
    costPerPatient: number;
};

const MobileLocationDropdown = ({
    setIsOpen,
    costPerPatient,
    perPatientValue,
    practiceName,
    totalRoi,
}: DropdownProps) => {
    return (
        <>
            <Box backgroundColor={"midGray"} height={"38px"} />
            <Box
                gridColumn={2}
                backgroundColor={"midGray"}
                height={"38px"}
                cursor={"pointer"}
                onClick={() => setIsOpen(false)}
            >
                <FlexRowAlignCenter justifyContent={"space-between"} maxWidth={"381px"} px={"82px"} height={"100%"}>
                    <Body12Medium color={"graphiteGray"} uppercase>
                        {practiceName}
                    </Body12Medium>
                    <ArrowDown fill={"graphiteGray"} />
                </FlexRowAlignCenter>
            </Box>
            <Box backgroundColor={"midGray"} height={"38px"} />
            <FlexCol pr={14} pt={6} pb={8} alignItems={"flex-end"} gridColumn={2} maxWidth={"381px"}>
                <SummaryRow label={"Annual Per Patient Value"} data={perPatientValue} />
                <YSpacer spaceY={3} />
                <LabelMedium color={"graphiteGray"} uppercase>
                    6 month averages
                </LabelMedium>
                <YSpacer spaceY={3} />
                <SummaryRow label={"Total ROI"} data={totalRoi} />
                <YSpacer spaceY={3} />
                <SummaryRow label={"Patient Acquisition Costs"} data={costPerPatient} />
            </FlexCol>
            <Box gridColumn={-1} />
        </>
    );
};

const DesktopLocationDropdown = ({
    setIsOpen,
    perPatientValue,
    practiceName,
    costPerPatient,
    totalRoi,
}: DropdownProps) => {
    return (
        <Box>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                backgroundColor={"midGray"}
                height={"38px"}
                pr={6}
                pl={10}
                cursor={"pointer"}
                onClick={() => setIsOpen(false)}
            >
                <Body12Medium color={"graphiteGray"} uppercase>
                    {practiceName}
                </Body12Medium>
                <ArrowDown fill={"graphiteGray"} />
            </FlexRowAlignCenter>
            <FlexCol pr={6} pt={4} alignItems={"flex-end"}>
                <SummaryRow label={"Annual Per Patient Value"} data={perPatientValue} />
                <YSpacer spaceY={3} />
                <LabelMedium color={"graphiteGray"} uppercase>
                    6 month averages
                </LabelMedium>
                <YSpacer spaceY={3} />
                <SummaryRow label={"ROI"} data={totalRoi} />
                <YSpacer spaceY={3} />
                <SummaryRow label={"Patient Acquisition Costs"} data={costPerPatient} />
            </FlexCol>
        </Box>
    );
};

const SummaryRow = ({ label, data }: { label: string; data: number }): JSX.Element => (
    <Grid justifyItems={"end"} alignItems={"center"} gridTemplateColumns={"1fr 72px"}>
        <Label>{label}</Label>
        <Body14Medium>{formatAsDollars(_.round(data))}</Body14Medium>
    </Grid>
);
