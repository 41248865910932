import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import { Form, FormCollectionName } from "../../@types/forms/Types";
import { Task } from "../../@types/shared";

const saveForm = (
    practiceId: string,
    task: Task,
    formObject: Form,
    isComplete: boolean,
    label: string,
    formCollectionName: FormCollectionName
): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/product-forms/save-form`,
            data: {
                practiceId,
                task,
                formObject,
                isComplete,
                formCollectionName,
            },
            method: "post",
        },
        success: slices.openPractice.actions.setTasks,
        label: label,
    });

export default saveForm;
