import { LocationOverviewTrendRow } from "../../@types/components/LocationOverviewReport";
import { Color } from "../../@types/color";
import { Grid } from "../styledSystemUtilities";
import { Label } from "../Typography";
import React from "react";
import { ThreeMonthTrendCells } from "../Atoms";

type ChartRowProps = {
    dataSet: LocationOverviewTrendRow | null;
    labelLeft: string;
    rightLabelFormatter?: (data: number) => string;
};

export const OnlineThreeMonthTrendRow = ({ dataSet, labelLeft, rightLabelFormatter }: ChartRowProps): JSX.Element => (
    <ChartRow
        strokeColor={"deepGreen"}
        backgroundColor={"aquaHaze"}
        colorLeft={"auratiumGreen"}
        colorRight={"forrestGreen"}
        dotColor={"forrestGreen"}
        labelLeft={labelLeft}
        dataSet={dataSet}
        rightLabelFormatter={rightLabelFormatter}
    />
);

export const GrowthThreeMonthTrendRow = ({ dataSet, labelLeft, rightLabelFormatter }: ChartRowProps): JSX.Element => (
    <ChartRow
        strokeColor={"casal"}
        backgroundColor={"aquaBlue"}
        colorLeft={"ipanemaBlue"}
        colorRight={"deepPetrol"}
        dotColor={"petrolBlue"}
        labelLeft={labelLeft}
        dataSet={dataSet}
        rightLabelFormatter={rightLabelFormatter}
    />
);

export const ProfitThreeMonthTrendRow = ({ dataSet, labelLeft, rightLabelFormatter }: ChartRowProps): JSX.Element => (
    <ChartRow
        strokeColor={"bluewood"}
        backgroundColor={"porcelain"}
        colorLeft={"balticBlue"}
        colorRight={"nauticBlue"}
        dotColor={"nauticBlue"}
        labelLeft={labelLeft}
        dataSet={dataSet}
        rightLabelFormatter={rightLabelFormatter}
    />
);

const ChartRow = ({
    colorLeft,
    colorRight,
    backgroundColor,
    strokeColor,
    dotColor,
    labelLeft,
    dataSet,
    rightLabelFormatter,
}: {
    colorLeft: Color;
    colorRight: Color;
    backgroundColor: Color;
    strokeColor: Color;
    dotColor: Color;
    labelLeft: string;
    rightLabelFormatter?: (data: number) => string;
    dataSet: LocationOverviewTrendRow | null;
}): JSX.Element | null =>
    dataSet ? (
        <Grid gridTemplateColumns={"1fr 114px 0.7fr"} width={"100%"} alignItems={"center"} pb={"3px"}>
            <Label justifySelf={"end"} color={colorLeft}>
                {labelLeft}
            </Label>
            <ThreeMonthTrendCells
                lastThreeMonths={dataSet.lastThreeMonthPercentages}
                strokeColor={strokeColor}
                dotColor={dotColor}
                backgroundColor={backgroundColor}
            />
            <Label pl={1} color={colorRight}>
                {rightLabelFormatter ? rightLabelFormatter(dataSet.lastMonthValue) : dataSet.lastMonthValue}
            </Label>
        </Grid>
    ) : null;
