import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Body14, Label11 } from "../Typography";
import { LearnMore, SelectBox } from "../Atoms";
import React from "react";
import { FormattedAdditionalProduct } from "../../@types/reports/Online";
import { useSetPopup } from "../../hooks/useSetPopup";

export const AdditionalSubscriptionCard = ({
    subscription,
    selectedProducts,
    setSelectedProducts,
}: {
    subscription: FormattedAdditionalProduct;
    selectedProducts: string[];
    setSelectedProducts: (value: ((prevState: string[]) => string[]) | string[]) => void;
}): JSX.Element => {
    const setPopup = useSetPopup(subscription.popup);

    return (
        <Box data-testid={subscription.recurlyCode}>
            <FlexRowAlignCenter
                justifyContent={"space-between"}
                backgroundColor={"white"}
                pl={"16px"}
                pr={"25px"}
                py={"8px"}
            >
                <Box>
                    <Body14 color={"auratiumGreen"}>{subscription.name}</Body14>
                    <Label11 color={"seaGreen"}>{subscription.description}</Label11>
                </Box>
                <Body14>{subscription.formattedMonthlyPrice}</Body14>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter
                px={"15px"}
                height={"28px"}
                backgroundColor={"#CCE2D7"}
                justifyContent={"space-between"}
                width={"100%"}
            >
                <LearnMore color={"forrestGreen"} setPopup={setPopup} />
                <Box />
                <SelectBox
                    color={"forrestGreen"}
                    recurlyCode={subscription.recurlyCode}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            </FlexRowAlignCenter>
        </Box>
    );
};
