import { slices } from "../redux";
import { useAppDispatch } from "../redux/hooks";
import { popups } from "../constants/popups";

export const useSetPopup = (popupId: keyof typeof popups | null): (() => void) => {
    const dispatch = useAppDispatch();
    return () => {
        dispatch(slices.popup.actions.setPopup(popupId));
    };
};
