import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { AbsolutePositionLogo, FormError, Gradient, Input, RightButton } from "../Atoms";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { actions } from "../../redux";
import { Body12, BodyMedium, Header27 } from "../Typography";
import useErrors from "../../hooks/useErrors";
import { LoginInfo } from "../../@types/shared";
import { useAppDispatch } from "../../redux/hooks";
import { routerHistory } from "../../routerHistory";

const schema = object().shape({
    email: string().required("Please enter your email address").email("Please enter a valid email.").trim(),
    password: string().required("Please enter your password").trim(),
});

export const Login = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: LoginInfo) => dispatch(actions.login(data));

    const error = useErrors("login");

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                <Box gridColumn={2} px={"10px"}>
                    <Box pb={"64px"} />
                    <Header27 color={"graphiteGray"} textAlign={"center"}>
                        Account Login
                    </Header27>
                    <Box pb={"20px"} />
                    <BodyMedium color={"graphiteGray"} textAlign={"center"}>
                        Welcome to Insight. Welcome to success!
                    </BodyMedium>
                    <Box pb={"19px"} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            data-testid={"email"}
                            ref={register}
                            name={"email"}
                            placeholder="Email Address"
                            hasError={errors.email}
                        />
                        <FormError error={errors.email} />
                        <Box pb={"13px"} />
                        <Input
                            data-testid={"password"}
                            ref={register}
                            name={"password"}
                            type={"password"}
                            placeholder="Password"
                            hasError={errors.password}
                        />
                        <FormError error={errors.password} />
                        <Box pb={"26px"} />
                        <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                            login
                        </RightButton>
                        <FormError error={error} />
                        <Box cursor={"pointer"} onClick={() => routerHistory.push("/reset-password")}>
                            <Body12 textAlign={"end"}>Forgot password?</Body12>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Box>
    );
};
