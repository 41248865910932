import { ProfitLocationOverviewReport } from "../../@types/components/LocationOverviewReport";
import { PracticeFrontEnd } from "../../@types/shared";
import { Box, FlexColAlignCenter, YSpacer } from "../styledSystemUtilities";
import {
    GridSpaceBox,
    LocationOverviewLayerButton,
    LocationTrendsContainer,
    ProfitThreeMonthTrendRow,
    ThreeMonthTrendChartHeader,
    TodoLink,
} from "../Molecules";
import { formatAsDollars } from "../../utils/formatter";
import { Label11 } from "../Typography";
import React from "react";
import { useLocationOverviewLinks } from "../../hooks/useLocationOverviewLinks";
import { profitTasks } from "../../constants/tasks";

export const LastThreeMonthProfitTrends = ({
    profitReport,
    currentPractice,
    lastThreeMonthDates,
}: {
    profitReport: ProfitLocationOverviewReport;
    currentPractice?: PracticeFrontEnd;
    lastThreeMonthDates: string[];
}): JSX.Element => {
    const onClick = useLocationOverviewLinks("profit", currentPractice);

    return (
        <Box data-testid={"profitReport"}>
            <GridSpaceBox />
            <Box pb={5}>
                <LocationOverviewLayerButton backgroundColor={"ghost"} buttonColor={"balticBlue"} onClick={onClick} />
                <TodoLink
                    numUncompletedTasks={
                        currentPractice?.tasks
                            .filter((task) => profitTasks.has(task.taskKey))
                            .filter((task) => !task.completed).length ?? 0
                    }
                    onClick={onClick}
                />
                <YSpacer spaceY={5} />
                <LocationTrendsContainer
                    color={"nauticBlue"}
                    name={"practice revenue data"}
                    backgroundColor={"porcelain"}
                >
                    <YSpacer spaceY={4} />
                    <FlexColAlignCenter>
                        <ThreeMonthTrendChartHeader
                            color={"balticBlue"}
                            finalColor={"nauticBlue"}
                            lastThreeMonthDates={lastThreeMonthDates}
                        />
                        <ProfitThreeMonthTrendRow
                            labelLeft={"Revenue/mo."}
                            dataSet={profitReport.revenue}
                            rightLabelFormatter={formatAsDollars}
                        />
                        <ProfitThreeMonthTrendRow dataSet={profitReport.visits} labelLeft={"Patient Visits"} />
                        <ProfitThreeMonthTrendRow dataSet={profitReport.newPatients} labelLeft={"New Patients"} />
                        {profitReport.referrals ? (
                            <ProfitThreeMonthTrendRow dataSet={profitReport.referrals} labelLeft={"Pt. Referrals"} />
                        ) : null}
                    </FlexColAlignCenter>
                </LocationTrendsContainer>
                <YSpacer spaceY={5} />
                <Box data-testid={"activeProfitCenters"}>
                    <LocationTrendsContainer
                        color={"nauticBlue"}
                        name={
                            profitReport.activeProfitCenters.length > 0
                                ? "active profit centers"
                                : "no active profit centers"
                        }
                        backgroundColor={"porcelain"}
                    >
                        {profitReport.activeProfitCenters.map((profitCenter, index) => {
                            return (
                                <Box key={index} pl={10} pt={3}>
                                    <Label11 color={"nauticBlue"}>{profitCenter}</Label11>
                                </Box>
                            );
                        })}
                    </LocationTrendsContainer>
                </Box>
            </Box>
            <GridSpaceBox backgroundColor={"ghost"} />
        </Box>
    );
};
