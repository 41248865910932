import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalSeoReport, OnlineReport, OnlineSubscription } from "../../@types/reports/Online";
import { MenuTableData } from "../../@types/components/MenuTable";

const roiTableRows = {
    average: 0,
    sinceActive: 0,
    lastMonth: 0,
};

const roiTable: MenuTableData = {
    roiTotals: roiTableRows,
    revenueGenerated: roiTableRows,
    newPatients: roiTableRows,
};

const initialState: OnlineReport = {
    googleAnalytics: null,
    googleMyBusiness: null,
    localSeo: null,
    roiTable,
    searchConsole: null,
    seoCalls: null,
    adviceLocalReport: null,
    subscriptions: [],
    availableProducts: {
        recommended: [],
        other: [],
    },
    monthsActive: 0,
};

export default createSlice({
    name: "onlineReport",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<OnlineReport>) => action.payload,
        unset: () => initialState,
        setSubscriptions: (
            state,
            action: PayloadAction<{
                subscriptions: OnlineSubscription[];
                availableProducts: { recommended: OnlineSubscription[]; other: OnlineSubscription[] };
            }>
        ) => ({
            ...state,
            subscriptions: action.payload.subscriptions,
            availableProducts: action.payload.availableProducts,
        }),
        setLocalSeoReport: (state, action: PayloadAction<LocalSeoReport>) => ({
            ...state,
            localSeo: action.payload,
        }),
    },
});
