import React from "react";
import { OverviewTemplate } from "../Templates";
import { AccountPageBanner, LocationsInfo, AccountMenu } from "../Organisms";
import { Box, Grid, YSpacer } from "../styledSystemUtilities";
import { useWindowSize } from "react-use";
import { useTheme } from "styled-components";
import { AccountInfo } from "../Organisms/AccountInfo";
import { LoadingWrapper } from "../Molecules";
import { useAppSelector } from "../../redux/hooks";

export const AccountOverview = (): JSX.Element => {
    const { width } = useWindowSize();
    const loading = useAppSelector((state) => state.loading);
    const theme = useTheme();
    return (
        <OverviewTemplate Menu={AccountMenu}>
            <LoadingWrapper color={"ipanemaBlue"} isLoading={loading.accountPractices || loading.clientAccount}>
                <Grid
                    pb={15}
                    gridTemplateColumns={
                        width > theme.breakpoints[0] ? "1fr minmax(650px, 760px) 1fr" : "1fr minmax(320px, 375px) 1fr"
                    }
                >
                    <Box gridColumn={2}>
                        <AccountPageBanner />
                        <YSpacer spaceY={5} />
                        <Grid
                            gridTemplateColumns={width > theme.breakpoints[0] ? "1fr 1fr" : "1fr"}
                            gridColumnGap={"10px"}
                            gridRowGap={7}
                        >
                            <AccountInfo />
                            <LocationsInfo />
                        </Grid>
                    </Box>
                </Grid>
            </LoadingWrapper>
        </OverviewTemplate>
    );
};
