import { addMonths, format, getMonth, subMonths } from "date-fns/fp";
import _ from "lodash/fp";
import React, { useMemo } from "react";
import {
    HorizontalGridLines,
    LineMarkSeries,
    LineMarkSeriesPoint,
    LineSeries,
    MarkSeries,
    PolygonSeries,
    VerticalGridLines,
    XAxis,
    XYPlot,
    YAxis,
} from "react-vis";
import "react-vis/dist/style.css";
import { useTheme } from "styled-components";
import { Color } from "../../@types/color";
import { SeoChartData } from "../../@types/seoChartData";
import { ReviewsChartData } from "../../@types/reviewsChartData";

const monthAbbreviation = format("MMM");

export const getDomains = (array: LineMarkSeriesPoint[]): { xDomain: number[]; yDomain: number[] } => {
    return {
        xDomain: [_.get("x", _.minBy("x", array)), _.get("x", _.maxBy("x", array))],
        yDomain: [_.get("y", _.minBy("y", array)), _.get("y", _.maxBy("y", array))],
    };
};

const getGuidelines = (numberOfMonths: number, array: { x: Date; y: number }[]) => {
    const oldestX = _.getOr(subMonths(11, new Date()), "x", _.head(array));
    const paddedDataMonths = _.map((x: number) => addMonths(x, oldestX))(_.range(0, numberOfMonths));

    const tickValues = _.take(numberOfMonths, paddedDataMonths);
    return {
        tickValues,
        xMax: _.last(tickValues),
        xMin: _.head(tickValues),
        yMax: _.getOr(10, "y", _.maxBy("y", array)),
        yMin: _.getOr(10, "y", _.minBy("y", array)),
    };
};

const getTickValueForSmall = (x: number, width: number) => {
    if (width < 315) {
        if (getMonth(x) % 2 === 0) {
            return "";
        } else {
            return monthAbbreviation(x);
        }
    }
    return monthAbbreviation(x);
};

const listOfMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const TwoYearComparisonPlot = ({
    width,
    currentYear,
    lastYear,
    yScalar = 1,
}: {
    width: number;
    currentYear: LineMarkSeriesPoint[];
    lastYear: LineMarkSeriesPoint[];
    yScalar?: number;
}): JSX.Element => {
    const theme = useTheme();

    const getMonthName = (monthIndex: number) => {
        if (width < 315) {
            if (monthIndex % 2 === 0) {
                return listOfMonthNames[monthIndex];
            } else {
                return "";
            }
        } else {
            return listOfMonthNames[monthIndex];
        }
    };

    const yMax = useMemo(() => _.maxBy("y", _.concat(currentYear, lastYear)) || 0, [currentYear, lastYear]);
    return (
        <XYPlot
            xDomain={[0, 11]}
            width={width}
            getY={(d: { y: number }) => d.y * yScalar}
            height={width / 1.8}
            yDomain={[0, _.get("y", yMax) * yScalar > 0 ? _.get("y", yMax) * yScalar : 100]}
            margin={{ left: 40, right: 10, top: 20, bottom: 30 }}
        >
            <VerticalGridLines tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]} />
            <HorizontalGridLines />
            <XAxis tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]} tickFormat={(d) => getMonthName(d)} />
            <YAxis />
            <LineMarkSeries
                lineStyle={{
                    stroke: theme.colors.pearlGray,
                    strokeWidth: "2px",
                }}
                markStyle={{
                    stroke: theme.colors.pearlGray,
                    fill: theme.colors.pearlGray,
                }}
                data={lastYear}
            />

            <LineMarkSeries
                lineStyle={{ stroke: theme.colors.balticBlue, strokeWidth: "2px" }}
                markStyle={{
                    stroke: theme.colors.balticBlue,
                    fill: theme.colors.balticBlue,
                }}
                data={currentYear}
            />
        </XYPlot>
    );
};

type MultiYearPlotData = { year: number; total: number; average: number; months: { x: number; y: number }[] };

export const MultiYearPlot = ({
    width,
    data,
    yScalar = 1,
    colors,
}: {
    width: number;
    data: MultiYearPlotData[];
    yScalar: number;
    colors: Color[];
}): JSX.Element => {
    const getMonthName = (monthIndex: number) => {
        if (width < 315) {
            if (monthIndex % 2 === 0) {
                return listOfMonthNames[monthIndex];
            } else {
                return "";
            }
        } else {
            return listOfMonthNames[monthIndex];
        }
    };

    const yMax = useMemo(() => _.maxBy("y", _.flatMap(_.get("months"), data)), [data]);

    return (
        <XYPlot
            xDomain={[0, 11]}
            width={width}
            getY={(d: { y: number }) => d.y * yScalar}
            height={width / 1.8}
            yDomain={[0, yMax.y * yScalar > 0 ? yMax.y * yScalar : 100]}
            //yDomain={[0, _.last(yTicks) * yScalar > 0 ? _.last(yTicks) * yScalar : 100]}
            margin={{ left: 40, right: 10, top: 20, bottom: 30 }}
        >
            <VerticalGridLines tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]} />
            <HorizontalGridLines />
            <XAxis tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]} tickFormat={(d) => getMonthName(d)} />
            <YAxis />

            {data.map((year, index) => {
                const color = _.nth(index, colors);
                return (
                    <LineMarkSeries
                        key={index}
                        lineStyle={{
                            stroke: color,
                            strokeWidth: "2px",
                        }}
                        markStyle={{
                            stroke: color,
                            fill: color,
                        }}
                        data={year.months}
                    />
                );
            })}
        </XYPlot>
    );
};
export const OverallRankOverTimePlot = ({
    width,
    data: { dataMonths, originalLocalRankData },
}: {
    width: number;
    data: { dataMonths: { x: Date; y: number }[]; originalLocalRankData: { x: Date; y: number }[] };
}): JSX.Element => {
    const theme = useTheme();
    const colors = theme.colors;
    const { tickValues, xMin, xMax, yMax } = getGuidelines(6, _.concat(dataMonths, originalLocalRankData));

    const yTicks = _.concat(
        1,
        _.dropRightWhile((n) => n > yMax + 9 && n > 30, _.map(_.multiply(10), _.range(1, 7)))
    );
    return (
        <>
            <XYPlot
                xType={"time"}
                yDomain={[_.last(yTicks), 1]}
                width={width}
                height={width / 1.8}
                xDomain={[xMin, xMax]}
                margin={{ left: 40, right: 10, top: 20, bottom: 30 }}
            >
                <VerticalGridLines tickValues={tickValues} style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <HorizontalGridLines tickValues={yTicks} style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <XAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickValues={tickValues}
                    tickFormat={monthAbbreviation}
                    tickSize={0}
                />
                <YAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickValues={yTicks}
                    tickSize={0}
                />

                <LineMarkSeries
                    lineStyle={{
                        stroke: theme.colors.auratiumGreen,
                        strokeWidth: 1,
                    }}
                    markStyle={{
                        stroke: theme.colors.auratiumGreen,
                        fill: theme.colors.auratiumGreen,
                    }}
                    _sizeValue={4}
                    data={dataMonths}
                />
                <MarkSeries
                    data={originalLocalRankData}
                    style={{
                        fill: theme.colors.sienna,
                        stroke: theme.colors.sienna,
                    }}
                    _sizeValue={4}
                />
            </XYPlot>
        </>
    );
};

function getSeoTickValues(highY: number) {
    const divisions = highY / 3;
    return [_.round(divisions * 3), _.round(divisions * 2), _.round(divisions), 1];
}

export const LocalRankingPlot = ({ data }: { data: SeoChartData }): JSX.Element => {
    const theme = useTheme();
    const colors = theme.colors;
    const { competitors, userData } = data;

    const competitorArray = _.take(29, _.orderBy("y", "asc", competitors));

    const everyone = _.orderBy("y", "asc", _.concat(competitors, userData));

    const yTicks = getSeoTickValues(_.takeLast(1, everyone)[0].y);
    // console.log(yTicks);
    const tickValues = [30, 20, 10, 1];

    return (
        <React.Fragment>
            <XYPlot
                xDomain={[30, 1]}
                yDomain={[yTicks[0], 1]}
                height={160}
                width={308}
                margin={{ left: 30, right: 20, top: 30, bottom: 20 }}
            >
                <VerticalGridLines tickValues={tickValues} style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <HorizontalGridLines tickValues={yTicks} style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <XAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickValues={tickValues}
                    tickSize={0}
                />
                <YAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: 1 },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickValues={yTicks}
                    tickSize={0}
                />
                <PolygonSeries
                    color="#99C2AD"
                    data={[
                        {
                            x: 30,
                            y: yTicks[1],
                        },
                        {
                            x: 20,
                            y: yTicks[1],
                        },
                        {
                            x: 20,
                            y: yTicks[0],
                        },
                        {
                            x: 30,
                            y: yTicks[0],
                        },
                    ]}
                />
                <PolygonSeries
                    color="hsl(149, 21%, 76%, .5)"
                    data={[
                        {
                            x: 30,
                            y: yTicks[2],
                        },
                        {
                            x: 10,
                            y: yTicks[2],
                        },
                        {
                            x: 10,
                            y: yTicks[0],
                        },
                        {
                            x: 20,
                            y: yTicks[0],
                        },
                        {
                            x: 20,
                            y: yTicks[1],
                        },
                        {
                            x: 30,
                            y: yTicks[1],
                        },
                    ]}
                />
                {!_.isEmpty(competitorArray.slice(1)) ? (
                    <MarkSeries
                        strokeWidth={3}
                        opacity={0.5}
                        _sizeValue={3}
                        data={competitorArray.slice(1)}
                        color="#A7A9AC"
                    />
                ) : null}

                <MarkSeries strokeWidth={3} _sizeValue={3} data={userData} color="#E27867" />
                {!_.isEmpty(competitorArray) ? (
                    <MarkSeries strokeWidth={3} _sizeValue={3} data={[competitorArray[0]]} color="#74AD85" />
                ) : null}
            </XYPlot>
        </React.Fragment>
    );
};

export const VariableSetPlot = ({
    dataSetsWithFormat,
    width,
}: {
    dataSetsWithFormat: { dataSet: { x: Date; y: number }[]; color: Color }[];
    width: number;
}): JSX.Element => {
    const { colors } = useTheme();
    const { tickValues, xMin, xMax, yMax } = getGuidelines(
        12,
        _.flatMap(({ dataSet }) => dataSet, dataSetsWithFormat)
    );
    return (
        <>
            <XYPlot
                xType="time"
                width={width}
                height={width / 1.8}
                yDomain={[0, yMax > 0 ? yMax : 100]}
                xDomain={[xMin, xMax]}
                margin={{ left: 40, right: 10, top: 20, bottom: 30 }}
            >
                <VerticalGridLines tickValues={tickValues} style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <HorizontalGridLines style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <XAxis
                    tickValues={tickValues}
                    tickFormat={(x) => getTickValueForSmall(x, width)}
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickSize={0}
                />
                <YAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickSize={0}
                />

                {dataSetsWithFormat.map(({ dataSet, color }, index) => (
                    <LineMarkSeries
                        key={index}
                        lineStyle={{ stroke: colors[color], strokeWidth: 1 }}
                        markStyle={{
                            stroke: colors[color],
                            fill: colors[color],
                        }}
                        _sizeValue={4}
                        data={dataSet}
                    />
                ))}
            </XYPlot>
        </>
    );
};

export const TwoSetPlot = ({
    firstDataSet,
    secondDataSet,
    color,
    width,
}: {
    firstDataSet: { x: Date; y: number }[];
    secondDataSet: { x: Date; y: number }[];
    color?: Color;
    width: number;
}): JSX.Element => {
    const { colors } = useTheme();
    const { tickValues, xMin, xMax, yMax } = getGuidelines(12, _.concat(firstDataSet, secondDataSet));
    return (
        <>
            <XYPlot
                xType="time"
                width={width}
                height={width / 1.8}
                yDomain={[0, yMax > 0 ? yMax : 100]}
                xDomain={[xMin, xMax]}
                margin={{ left: 40, right: 10, top: 20, bottom: 30 }}
            >
                <VerticalGridLines tickValues={tickValues} style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <HorizontalGridLines style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <XAxis
                    tickValues={tickValues}
                    tickFormat={(x) => getTickValueForSmall(x, width)}
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickSize={0}
                />
                <YAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickSize={0}
                />

                <LineMarkSeries
                    lineStyle={{ stroke: colors.midGray, strokeWidth: 1 }}
                    markStyle={{
                        stroke: colors.midGray,
                        fill: colors.midGray,
                    }}
                    _sizeValue={4}
                    data={secondDataSet}
                />
                <LineMarkSeries
                    lineStyle={{
                        stroke: color ? colors[color] : colors.balticBlue,
                        strokeWidth: 1,
                    }}
                    markStyle={{
                        stroke: color ? colors[color] : colors.balticBlue,
                        fill: color ? colors[color] : colors.balticBlue,
                    }}
                    _sizeValue={4}
                    data={firstDataSet}
                />
            </XYPlot>
        </>
    );
};

export const OneSetPlot = ({
    data,
    width,
    color = "balticBlue",
}: {
    data: { x: Date; y: number }[];
    width: number;
    color?: Color;
}): JSX.Element => {
    const { colors } = useTheme();
    const { tickValues, xMin, xMax, yMax } = getGuidelines(12, data);

    return (
        <>
            <XYPlot
                xType="time"
                width={width}
                height={width / 1.8}
                yDomain={[0, yMax > 0 ? yMax : 100]}
                xDomain={[xMin, xMax]}
                margin={{ left: 40, right: 10, top: 20, bottom: 30 }}
            >
                <VerticalGridLines tickValues={tickValues} style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <HorizontalGridLines style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
                <XAxis
                    tickValues={tickValues}
                    tickFormat={(x) => getTickValueForSmall(x, width)}
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickSize={0}
                />
                <YAxis
                    style={{
                        line: { stroke: "#A7A9AC", strokeWidth: "1px" },
                        text: { fill: colors.agateGray, fontSize: "10px" },
                    }}
                    tickSize={0}
                />

                <LineMarkSeries
                    lineStyle={{
                        stroke: colors[color],
                        strokeWidth: 1,
                    }}
                    markStyle={{
                        stroke: colors[color],
                        fill: colors[color],
                    }}
                    _sizeValue={4}
                    data={data}
                />
            </XYPlot>
        </>
    );
};

export const ReviewsRankingPlot = ({ data: { userData, compData } }: { data: ReviewsChartData }): JSX.Element => {
    const { colors } = useTheme();

    const everyone = _.concat(userData, compData);

    const maxX = _.getOr(0, "x", _.maxBy("x", everyone));
    const maxY = _.getOr(0, "y", _.maxBy("y", everyone));
    const minY = _.getOr(0, "y", _.minBy("y", everyone));

    const xDomain = [0, maxX];
    const yDomain = [minY, maxY];

    const avgRating = _.meanBy("y", everyone);
    const avgNumReviews = _.meanBy("x", everyone);

    const verticalLineData = [
        { x: xDomain[0], y: avgRating },
        { x: xDomain[1], y: avgRating },
    ];
    const horizontalLineData = [
        { x: avgNumReviews, y: yDomain[0] },
        { x: avgNumReviews, y: yDomain[1] },
    ];
    const quadrant2Data = [
        { x: xDomain[0], y: avgRating },
        { x: xDomain[0], y: yDomain[1] },
        { x: avgNumReviews, y: yDomain[1] },
        { x: avgNumReviews, y: avgRating },
    ];

    const quadrant3Data = [
        { x: xDomain[0], y: avgRating },
        { x: avgNumReviews, y: avgRating },
        { x: avgNumReviews, y: yDomain[0] },
        { x: xDomain[0], y: yDomain[0] },
    ];
    const quadrant4Data = [
        {
            x: avgNumReviews,
            y: yDomain[0],
        },
        {
            x: avgNumReviews,
            y: avgRating,
        },
        { x: xDomain[1], y: avgRating },
        { x: xDomain[1], y: yDomain[0] },
    ];

    return (
        <XYPlot
            margin={{ left: 50, right: 20, top: 20, bottom: 30 }}
            width={308}
            height={254}
            yDomain={yDomain}
            xDomain={xDomain}
        >
            <VerticalGridLines style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
            <HorizontalGridLines style={{ stroke: colors.pearlGray, strokeWidth: 1 }} />
            <XAxis
                style={{
                    text: { fill: colors.agateGray, fontSize: "10px" },
                }}
                tickSize={0}
            />
            <YAxis
                style={{
                    text: { fill: colors.agateGray, fontSize: "10px" },
                }}
                tickSize={0}
            />
            <LineSeries
                data={verticalLineData}
                color={colors.agateGray}
                style={{ strokeLinejoin: "round", strokeWidth: "2" }}
            />
            <LineSeries
                data={horizontalLineData}
                color={colors.agateGray}
                style={{ strokeLinejoin: "round", strokeWidth: "2" }}
            />

            <PolygonSeries data={quadrant2Data} color={colors.seaGreen} style={{ opacity: ".17" }} />
            <PolygonSeries data={quadrant3Data} color={colors.seaGreen} style={{ opacity: ".6" }} />
            <PolygonSeries data={quadrant4Data} color={colors.seaGreen} style={{ opacity: ".17" }} />
            {!_.isEmpty(compData.slice(1)) ? (
                <MarkSeries
                    _sizeValue={3}
                    strokeWidth={4}
                    color={colors.slateGray}
                    data={compData.slice(1)}
                    opacity={0.5}
                />
            ) : null}
            {!_.isEmpty(compData) ? (
                <MarkSeries _sizeValue={3} strokeWidth={4} color={colors.auratiumGreen} data={compData.slice(0, 1)} />
            ) : null}

            <MarkSeries _sizeValue={3} strokeWidth={4} color={colors.sienna} data={userData} />
        </XYPlot>
    );
};
