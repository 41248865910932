import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuTableData } from "../../@types/components/MenuTable";
import { GrowthReport } from "../../@types/reports/Growth";

const roiTableRows = {
    average: 0,
    sinceActive: 0,
    lastMonth: 0,
};

const roiTable: MenuTableData = {
    roiTotals: roiTableRows,
    revenueGenerated: roiTableRows,
    newPatients: roiTableRows,
};

const initialState: GrowthReport = {
    activeCampaigns: [],
    pastCampaigns: [],
    roiTable
};

export default createSlice({
    name: "growthReport",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<GrowthReport>) => action.payload,
        unset: () => initialState,
    },
});
