import React from "react";
import { Box } from "../../styledSystemUtilities";
import { BoxArrow, HalfCircle, ListCheck, UnitasHero } from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { ProfitHeader, WhiteBackground } from "../../Molecules/PopupComponents";
import { Body, Body14Bold } from "../../Typography";

export const InsuranceManagement = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={UnitasHero} />
            <GrayBackground>
                <SellingPoint
                    header={"4 or More PPOs? "}
                    body={
                        "Our PPO Management service can help your established dental practice improve your low PPO Insurance reimbursements through PPO Negotiations & Optimization."
                    }
                />
                <IconContainer name={"What We Do"} />
                <IconBulletPoint
                    Icon={ListCheck}
                    body={"Find opportunities for improvement."}
                    header={"Evaluate PPO Participation"}
                />
                <IconBulletPoint
                    Icon={BoxArrow}
                    body={"Get the best rate for you."}
                    header={"Negotiate Higher Reimbursements"}
                />
                <IconBulletPoint
                    Icon={HalfCircle}
                    body={"To obtain higher reimbursements."}
                    header={"Adjust PPO Participation"}
                />
            </GrayBackground>
            <WhiteBackground>
                <Body color={"balticBlue"} lineHeight={"21px"}>
                    “I have been amazed by what Unitas has been able to accomplish for my practice. Not only have they
                    been able to increase my revenue but they have allowed my staff to be more efficient.”
                </Body>
                <Body14Bold fontWeight={"bold"} textAlign={"end"}>
                    Dr. Ryan D. DDS
                </Body14Bold>
            </WhiteBackground>
            <GrayBackground>
                <Box pb={9} />
                <SellingPoint
                    header={"About Unitas"}
                    body={
                        "Unitas has helped thousands of dental practices increase their reimbursement rates, initiate effective PPO participation, and establish credentialing. Our goal is to ease the burden of effective PPO management so the practice can focus on patient care."
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};
