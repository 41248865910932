import { OnlineSubscription } from "../@types/reports/Online";
import { Coupon } from "../@types/Coupon";
import _ from "lodash/fp";

function getOnlineSubscriptionsWithDiscounts(
    subscriptions: OnlineSubscription[],
    activeCoupons: Coupon[]
): OnlineSubscription[] {
    return subscriptions.map((subscription) => ({
        ...subscription,
        monthlyPrice:
            activeCoupons.length > 0
                ? _.flow(
                      _.map((coupon: Coupon) => {
                          if (coupon.applicableItemCodes.includes(subscription.recurlyCode)) {
                              if (coupon.discount.percent) {
                                  return _.round(subscription.monthlyPrice * (100 - coupon.discount.percent)) / 100;
                              }
                              return subscription.monthlyPrice;
                          }
                          return subscription.monthlyPrice;
                      }),
                      _.sum
                  )(activeCoupons)
                : subscription.monthlyPrice,
    }));
}

export default getOnlineSubscriptionsWithDiscounts;
