import currentUser from "./currentUser";
import serverErrors from "./serverErrors";
import loading from "./loading";
import clientAccount from "./clientAccount";
import accountPractices from "./accountPractices";
import locationOverviewReports from "./locationOverviewReports";
import openPractice from "./openPractice";
import onlineReport from "./onlineReport";
import growthReport from "./growthReport";
import profitReport from "./profitReport";
import isBestForDentistry from "./isBestForDentistry";
import paymentModal from "./paymentModal";
import clientPractices from "./clientPractices";
import kpiMonths from "./kpiMonths";
import profitCenters from "./profitCenters";
import cart from "./cart";
import popup from "./popup";
import activeCoupons from "./activeCoupons";
import form from "./form";
import openTask from "./openTask";
import websiteFiles from "./websiteFiles";
import isHpa from "./isHpa";

const slices = {
    profitCenters,
    currentUser,
    serverErrors,
    loading,
    clientAccount,
    accountPractices,
    locationOverviewReports,
    openPractice,
    onlineReport,
    growthReport,
    profitReport,
    isBestForDentistry,
    paymentModal,
    clientPractices,
    kpiMonths,
    cart,
    popup,
    activeCoupons,
    form,
    openTask,
    websiteFiles,
    isHpa,
};

export default slices;
