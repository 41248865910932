import React from "react";
import "rc-slider/assets/index.css";
import { Box } from "../styledSystemUtilities";
import RcSlider from "rc-slider";
import { LabelBlack } from "../Typography";

export const GrowthSlider = ({
    defaultValue,
    setNewGoal,
}: {
    defaultValue: number;
    setNewGoal: React.Dispatch<React.SetStateAction<number>>;
}): JSX.Element => {
    return (
        <Box height={"40px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <RcSlider
                min={0}
                max={20.6}
                defaultValue={defaultValue}
                dotStyle={{
                    height: "10px",
                    bottom: "-8px",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "default",
                }}
                marks={{
                    1: "",
                    2: "",
                    3: "",
                    4: "",
                    5: "",
                    6: "",
                    7: "",
                    8: "",
                    9: "",
                    10: "",
                    11: "",
                    12: "",
                    13: "",
                    14: "",
                    15: "",
                    16: "",
                    17: "",
                    18: "",
                    19: "",
                    20: "",
                }}
                step={null}
                onChange={(value: number) => setNewGoal(value)}
                handleStyle={{
                    backgroundColor: "#329998",
                    border: "none",
                    width: "24px",
                    height: "24px",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                trackStyle={{
                    backgroundColor: "transparent",
                }}
                railStyle={{
                    background: "linear-gradient(90deg, #329998 0%, #CAEBEC 100%)",
                    height: "10px",
                    borderRadius: "0",
                    marginTop: "2px",
                }}
            />
            <Box display={"flex"} justifyContent={"space-between"} width={"98%"}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Box height={"18px"} width={"1px"} backgroundColor={"midGray"} mb={"1px"} />
                    <LabelBlack>1</LabelBlack>
                </Box>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Box height={"18px"} width={"1px"} backgroundColor={"midGray"} mb={"1px"} />
                    <LabelBlack>5</LabelBlack>
                </Box>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Box height={"18px"} width={"1px"} backgroundColor={"midGray"} mb={"1px"} />
                    <LabelBlack>10</LabelBlack>
                </Box>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Box height={"18px"} width={"1px"} backgroundColor={"midGray"} mb={"1px"} />
                    <LabelBlack>15</LabelBlack>
                </Box>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Box height={"18px"} width={"1px"} backgroundColor={"midGray"} mb={"1px"} />
                    <LabelBlack>20</LabelBlack>
                </Box>
            </Box>
        </Box>
    );
};
