import { Body12, Body14Medium } from "../Typography";
import { FlexRowAlignCenter } from "../styledSystemUtilities";
import React from "react";
import { Color } from "../../@types/color";

export const DataLabelAndCell = ({
    label,
    data,
    color,
    dataColor,
    cellColor,
    testId,
}: {
    label: string;
    data: string | number;
    color: Color;
    dataColor: Color;
    cellColor: Color;
    testId?: string;
}): JSX.Element => (
    <FlexRowAlignCenter justifyContent={"space-between"}>
        <Body12 color={color}>{label}</Body12>
        <FlexRowAlignCenter
            justifyContent={"center"}
            backgroundColor={cellColor}
            width={"100px"}
            height={9}
            data-testid={testId}
        >
            <Body14Medium color={dataColor}>{data}</Body14Medium>
        </FlexRowAlignCenter>
    </FlexRowAlignCenter>
);
