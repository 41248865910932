import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import { KeywordSetLabel, SearchAreaType } from "../../@types/reports/Online";

const getSeoSummaryReport = (
    locationId: string,
    keywordSetLabel: KeywordSetLabel,
    searchAreaType: SearchAreaType,
    scrollPosition: number
): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/reports/get-local-seo-report/${locationId}/${keywordSetLabel}/${searchAreaType}`,
            method: "get",
        },
        scrollToOnLoad: scrollPosition,
        success: slices.onlineReport.actions.setLocalSeoReport,
        label: "onlineReport",
    });

export default getSeoSummaryReport;
