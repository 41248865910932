import _ from "lodash/fp";

const addPx = (number: number) => `${number}px`;

const baseNumberGetter = _.curry((name: string, key: number) => _.flow([_.get(["theme", name, key]), addPx]));

export const getSize = baseNumberGetter("sizes");

export const getSpace = baseNumberGetter("space");

export const getFontSize = baseNumberGetter("fontSizes");
