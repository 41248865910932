import React from "react";
import { Box, FlexRow, XSpacer, YSpacer } from "../styledSystemUtilities";
import { Header, Header16 } from "../Typography";
import { ProfileIcon } from "../Atoms/assets";
import { InfoTable } from "../Molecules";
import { useAppSelector } from "../../redux/hooks";

export const AccountInfo = (): JSX.Element => {
    const account = useAppSelector((state) => state.clientAccount);

    return (
        <Box data-testid={"accountInfo"} height={"fit-content"} pt={5} pb={7} px={8} backgroundColor={"chalk"}>

            <Header color={"graphiteGray"}>Account</Header>
                    <YSpacer spaceY={3} />
                    <Box>
                        <InfoTable
                            rows={[
                                { label: "Account Name:", data: account.accountName },
                                { label: "Website Address:", data: account.websiteAddress },
                                { label: "Main Address:", data: account.address },
                            ]}
                        />
                    </Box>
                    <YSpacer spaceY={3} />
                    <FlexRow>
                        <ProfileIcon />
                        <XSpacer spaceX={4} />
                        <Header16 color={"graphiteGray"}>Account Owner</Header16>
                    </FlexRow>
                    <Box data-testid={"accountOwner"}>
                        <InfoTable
                            rows={[
                                { label: "Name:", data: account.primaryContact.name },
                                { label: "Role:", data: account.primaryContact.label },
                                { label: "Phone:", data: account.primaryContact.phone },
                                { label: "Email:", data: account.primaryContact.email },
                            ]}
                        />
                    </Box>

                    <Box data-testid={"contacts"}>
                        {account.contacts.map((contact, index) => {
                            return (
                                <Box key={index}>
                                    <Header16 color={"graphiteGray"}>Secondary Contact</Header16>
                                    <InfoTable
                                        rows={[
                                            { label: "Name:", data: contact.name },
                                            { label: "Role:", data: contact.label },
                                            { label: "Phone:", data: contact.phone },
                                            { label: "Email:", data: contact.email },
                                        ]}
                                    />
                                </Box>
                            );
                        })}
                    </Box>

                    <YSpacer spaceY={5} />
        </Box>
    );
};
