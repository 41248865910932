import { PrimaryTable } from "../PrimaryTable";
import { Box, FlexColAlignCenter, FlexRowAlignCenter, Grid } from "../../styledSystemUtilities";
import { LegendDot } from "../../Atoms";
import React from "react";
import { KpiYear } from "../../../@types/reports/Profit";
import utils from "../../../utils";
import { format } from "date-fns/fp";
import { Color } from "../../../@types/color";
import _ from "lodash/fp";
import { Body14Medium } from "../../Typography";

export function ProfitTable({
    kpiYears,
    label,
    formatter = (value) => value,
    title,
}: {
    kpiYears: KpiYear[];
    label: "revenue" | "newPatients" | "visits" | "referrals";
    formatter?: (value: number) => number | string;
    title: string;
}): JSX.Element {
    return (
        <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
            <Box width={"100%"} pt={7} data-testid={label + "Table"} gridColumn={2}>
                <FlexColAlignCenter>
                    <FlexRowAlignCenter height={11} pl={7} backgroundColor={"chalk"} width={"300px"}>
                        <Body14Medium color={"nauticBlue"}>{title}</Body14Medium>
                    </FlexRowAlignCenter>
                    <PrimaryTable
                        columns={[
                            { renderLabel: () => <Box /> },
                            {
                                renderLabel: () => (
                                    <LegendDot
                                        label={kpiYears[0].yearName}
                                        fill={"balticBlue"}
                                        labelFill={"balticBlue"}
                                    />
                                ),
                            },
                            { renderLabel: () => <LegendDot label={kpiYears[1].yearName} fill={"pearlGray"} /> },
                        ]}
                        rows={getRows(kpiYears, label, formatter)}
                        footerRows={[
                            [
                                { value: "Avg." },
                                { value: formatter(_.round(kpiYears[0].averages[label])), color: "balticBlue" },
                                { value: formatter(_.round(kpiYears[1].averages[label])) },
                            ],
                            [
                                { value: "Total" },
                                { value: formatter(_.round(kpiYears[0].totals[label])), color: "balticBlue" },
                                { value: formatter(_.round(kpiYears[1].totals[label])) },
                            ],
                        ]}
                        width={"300px"}
                        rowBorder={"gray"}
                        headerRowBorder={"dark"}
                        backgroundColor={"chalk"}
                        footerRowBackgroundColor={"chalk"}
                        rowTestIds={utils.getCalenderMonths()}
                        footerRowTopBorder={"dark"}
                        footerRowIsBold={false}
                    />
                </FlexColAlignCenter>
            </Box>
        </Grid>
    );
}

function getRows(
    kpiYears: KpiYear[],
    label: "revenue" | "newPatients" | "visits" | "referrals",
    formatter: (value: number) => string | number
): { value: string | number; color?: Color }[][] {
    const calenderMonths = utils.getCalenderMonths();

    return calenderMonths.map((monthString) => {
        const thisYearMonth = kpiYears[0].months.find((month) => monthString === format("MMM", month.date));
        const lastYearMonth = kpiYears[1].months.find((month) => monthString === format("MMM", month.date));

        return [
            {
                value: monthString,
            },
            {
                value: thisYearMonth ? formatter(_.round(thisYearMonth[label] ?? 0)) : "",
                color: "balticBlue",
            },
            {
                value: lastYearMonth ? formatter(_.round(lastYearMonth[label] ?? 0)) : "",
            },
        ];
    });
}
