import { createSlice } from "@reduxjs/toolkit";
import { Form } from "../../@types/forms/Types";

const initialState: Form = null;

export default createSlice({
    name: "form",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
        unset: () => null,
    },
});
