import { LocalKeywordsTable, ReportingDropdown, ReportingDropdownContent } from "../../Molecules";
import { Box, FlexColAlignCenter, FlexRow, FlexRowAlignCenter, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { ArrowDown, ArrowRight } from "../../Atoms/assets";
import { Body12, Body14Medium, Label11Medium } from "../../Typography";
import { SeoChartCard } from "../SeoChartCard";
import { CompetitorsTable } from "../../Molecules/CompetitorsTable";
import React, { useState } from "react";
import { LocalSeoReport } from "../../../@types/reports/Online";
import { LegendDot, SeoBoxLabel } from "../../Atoms";
import { getSeoSearchParamsOptionFromReport } from "../../../utils/getSeoSearchParamsOptionFromReport";
import { useAppSelector } from "../../../redux/hooks";
import { KeywordParamsSelect } from "../../Molecules/KeywordParamsSelect";

export const GoogleLocalDropdown = ({
    localSeoReport,
}: {
    localSeoReport: LocalSeoReport;
}): JSX.Element => {
    const location = useAppSelector((state) => state.openPractice);
    const [isOpen, setIsOpen] = useState(true);
    return (
        <>
            <ReportingDropdown text={"Google Local – Top 5 Keywords"} isOpen={isOpen} setIsOpen={setIsOpen} />
            {isOpen ? (
                <ReportingDropdownContent testId={"googleLocalDropdown"}>
                    <Box px={8} gridColumn={"1 / -1"}>
                        <YSpacer spaceY={8} />
                        <Body12>
                            Top 5 keywords are the base keywords that are searched the most in local dental search.They
                            are the most desirable to rank well for, but also the most difficult. The goal at Ascent is
                            to rank you in the top 10% for these keywords, however the more competitive your area, the
                            more difficult that is.
                        </Body12>
                        <YSpacer spaceY={4} />
                        <DescriptionDropdown label={"What if my practice isn’t ranking well for top 5 keywords?"}>
                            <Body12>
                                Poor top 5 keyword ranking can be attributed to a combination of two main factors:{" "}
                                <NumberedPointAndSubPoints
                                    number={1}
                                    pointText={
                                        "Quality SEO efforts are too new – For the average website it takes from 3-8 months to see movement and sometimes up to 12 months."
                                    }
                                    subPoints={["Solution: Unfortunately, patience."]}
                                />
                                <NumberedPointAndSubPoints
                                    number={2}
                                    pointText={
                                        "The desired ranking area is too competitive/sophisticated for the current level of SEO to make the difference."
                                    }
                                    subPoints={[
                                        " Solution: Increase the level of sophistication for your SEO efforts. Adding the additional recommended products under the ”Your Current Subscriptions” section above should help.",
                                    ]}
                                />
                            </Body12>
                            <YSpacer spaceY={4} />

                            <Label11Medium color={"forrestGreen"} uppercase>
                                the good news?
                            </Label11Medium>
                            <YSpacer spaceY={4} />
                            <Body12>
                                Even if we’re struggling to generate the desired results in the top 5 keyword rankings,
                                it doesn’t mean your SEO efforts are in vain. Since less desirable keywords are much
                                easier to rank with, we will focus on producing results with other keywords to increase
                                traffic, and internet actions to drive in new patients. You can see those results in
                                other data sections.
                            </Body12>
                        </DescriptionDropdown>
                        <YSpacer spaceY={10} />
                    </Box>
                    <Box pt={7} backgroundColor={"aquaHaze"}>
                        <Box px={8} pb={11}>
                            <KeywordParamsSelect localSeoReport={localSeoReport} locationId={location.id} />
                        </Box>

                        <Box data-testid={"currentResultsVsCompetitionGraph"}>
                            <Box px={8}>
                                <Body14Medium color={"forrestGreen"}>Current Results vs. Your Competition</Body14Medium>
                                <Box mb={3} />
                                <Body12>Moving up this graph requires out-performing those ahead.</Body12>
                                <YSpacer spaceY={7} />
                                <SearchParamsRow localSeoReport={localSeoReport} />
                                <Box display={"flex"} alignItems={"center"}>
                                    <LegendDot fill={"sienna"} label={"Your Practice"} />
                                    <Box ml={11} />
                                    <LegendDot fill={"auratiumGreen"} label={"Top Competitor"} />
                                    <Box ml={11} />
                                    <LegendDot fill={"slateGray"} label={"Top 30"} />
                                </Box>
                            </Box>
                            <FlexColAlignCenter>
                                <SeoChartCard
                                    seoChartData={{
                                        competitors: localSeoReport.competitors.map((competitor) => ({
                                            x: competitor.overallRank,
                                            y: competitor.averageRank,
                                        })),
                                        userData: [
                                            {
                                                x: localSeoReport.overallRank,
                                                y: localSeoReport.averageRank,
                                            },
                                        ],
                                    }}
                                />
                            </FlexColAlignCenter>
                            <Box px={8}>
                                <YSpacer spaceY={6} />
                                <Box display={"flex"} justifyContent={"center"}>
                                    <SeoBoxLabel fill={"seaGreen"}>LOW</SeoBoxLabel>
                                    <Box ml={14} />
                                    <SeoBoxLabel fill={"translucentChartGreen"}>AVERAGE</SeoBoxLabel>
                                    <Box ml={14} />
                                    <SeoBoxLabel fill={"white"}>IDEAL</SeoBoxLabel>
                                </Box>
                                <YSpacer spaceY={9} />
                            </Box>
                        </Box>
                    </Box>
                    <Box backgroundColor={"aquaHaze"} pt={7} px={8}>
                        <Box data-testid={"seoCompetitorTable"}>
                            <Body14Medium color={"forrestGreen"}>Your Market's Top 5 Competitors & You</Body14Medium>
                            <YSpacer spaceY={7} />
                            <SearchParamsRow localSeoReport={localSeoReport} />
                            <CompetitorsTable
                                competitors={localSeoReport.competitors.map((practice, index) => ({
                                    practiceName: practice.practiceName,
                                    placeId: `${index}`,
                                    x: practice.overallRank,
                                    y: practice.averageRank,
                                }))}
                                target={[
                                    {
                                        practiceName: location.practiceName,
                                        placeId: "targetPractice",
                                        x: localSeoReport.overallRank,
                                        y: localSeoReport.averageRank,
                                    },
                                ]}
                                headings={["competitors:", "avg:", "rank:"]}
                                dataTestId={"localCompetitors"}
                            />
                            <YSpacer spaceY={12} />
                        </Box>

                        <Box data-testid={"localKeywordTable"}>
                            <Body14Medium color={"forrestGreen"}>Top Keyword Rankings</Body14Medium>
                            <YSpacer spaceY={2} />
                            <Body12>Where do you rank for the most searched keywords in Google Local searches?</Body12>
                            <YSpacer spaceY={7} />
                            <SearchParamsRow localSeoReport={localSeoReport} />
                            <LocalKeywordsTable keywords={localSeoReport.rankingsFromGoogleSearchTerms} />
                            <YSpacer spaceY={10} />
                        </Box>
                    </Box>
                </ReportingDropdownContent>
            ) : null}
        </>
    );
};

function SearchParamsRow({ localSeoReport }: { localSeoReport: LocalSeoReport }) {
    const options = getSeoSearchParamsOptionFromReport(localSeoReport);

    return (
        <FlexRowAlignCenter data-testid={"searchParamsRow"} pb={5}>
            <Body12>Area:</Body12>
            <XSpacer spaceX={3} />
            <Body14Medium color={"auratiumGreen"}>{options.searchAreaOption.label}</Body14Medium>
            <XSpacer spaceX={8} />
            <Body12>Keywords:</Body12>
            <XSpacer spaceX={3} />
            <Body14Medium color={"auratiumGreen"}>{options.keywordSetOption.label}</Body14Medium>
        </FlexRowAlignCenter>
    );
}

function NumberedPointAndSubPoints({
    number,
    pointText,
    subPoints,
}: {
    number: number;
    pointText: string;
    subPoints: string[];
}): JSX.Element {
    return (
        <FlexRow>
            <Box width={7}>
                <Body12>{number}. </Body12>
            </Box>

            <Body12>
                {pointText}
                {subPoints.map((text, index) => (
                    <Box key={index} ml={6}>
                        <Body12>{text}</Body12>
                    </Box>
                ))}
            </Body12>
        </FlexRow>
    );
}

function DescriptionDropdown({ label, children }: { label: string; children: React.ReactNode }): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <FlexRowAlignCenter cursor={"pointer"} onClick={() => setIsOpen(!isOpen)}>
                <Label11Medium color={"forrestGreen"} uppercase>
                    {label}
                </Label11Medium>
                <XSpacer spaceX={6} />
                {isOpen ? <ArrowDown fill={"forrestGreen"} /> : <ArrowRight fill={"forrestGreen"} />}
            </FlexRowAlignCenter>
            {isOpen ? (
                <>
                    <YSpacer spaceY={4} />
                    {children}
                </>
            ) : null}
        </>
    );
}
