import { Box, FlexRowAlignCenter, XSpacer, YSpacer } from "../styledSystemUtilities";
import { ArrowDown, Check, ExclamationCircle } from "../Atoms/assets";
import { Body14, Header, Label11Bold } from "../Typography";
import { PageBanner } from "./PageBanner";
import React from "react";
import { Color } from "../../@types/color";
import { routerHistory } from "../../routerHistory";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions, slices } from "../../redux";
import _ from "lodash/fp";
import { ProductTasks } from "../../@types/components";
import { TaskKeys } from "../../@types/shared";
import { FormCollectionName } from "../../@types/forms/Types";

const formModelNames: Record<TaskKeys, FormCollectionName> = {
    domain: "domainForms",
    seo: "seoForms",
    socialMedia: "socialMediaForms",
    googleAds: "googleAdsForms",
    website: "websiteForms",
    brand: "brandForms",
};

export const TaskBanner = ({
    primaryColor,
    secondaryColor,
    productTasks,
    completedProductTasks,
}: {
    primaryColor: Color;
    secondaryColor: Color;
    productTasks: ProductTasks;
    completedProductTasks: ProductTasks;
}): JSX.Element | null => {
    const practiceId = useAppSelector((state) => state.openPractice.id);
    const dispatch = useAppDispatch();

    return _.isEmpty([...productTasks, ...completedProductTasks]) ? null : (
        <Box data-testid={"tasks"} gridColumn={2}>
            <PageBanner>
                <Box pl={11} pt={5} pb={7} data-testid={"toDo"}>
                    <FlexRowAlignCenter>
                        <ExclamationCircle />
                        <XSpacer spaceX={11} />
                        <Header color={"sienna"}>Your To Do Items:</Header>
                    </FlexRowAlignCenter>
                    <YSpacer spaceY={4} />
                    <Box pl={6}>
                        {productTasks.map((product, index) => {
                            return (
                                <Box key={index}>
                                    <Body14 color={"amaranthRed"}>{product.name}</Body14>
                                    <Box pl={13}>
                                        {product.tasks.map((task, index) => {
                                            return (
                                                <Box key={index}>
                                                    <YSpacer spaceY={3} />
                                                    <FlexRowAlignCenter
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            dispatch(slices.form.actions.unset());
                                                            dispatch(slices.openTask.actions.set(task));
                                                                dispatch(
                                                                    actions.getForm(
                                                                        task.formId,
                                                                        formModelNames[task.taskKey],
                                                                        task.taskKey === "website"
                                                                    )
                                                                );
                                                            if (task.taskKey === "website")
                                                                dispatch(actions.getWebsiteFiles(practiceId));
                                                            window.scrollTo(0, 0);
                                                            console.log(task.formLink);
                                                            routerHistory.push(task.formLink);
                                                        }}
                                                    >
                                                        <Label11Bold color={"sienna"} uppercase>
                                                            {task.taskName}
                                                        </Label11Bold>
                                                        <XSpacer spaceX={4} />
                                                        <task.Icon />
                                                    </FlexRowAlignCenter>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    <YSpacer spaceY={2} />
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Box pt={5} pb={7} pl={9} data-testid={"completed"}>
                    <FlexRowAlignCenter cursor={"pointer"}>
                        <Header color={primaryColor}>Completed Items</Header>
                        <XSpacer spaceX={6} />
                        <ArrowDown fill={primaryColor} />
                    </FlexRowAlignCenter>
                    <YSpacer spaceY={4} />
                    {completedProductTasks.map((product, index) => {
                        return (
                            <Box key={index}>
                                <Body14 color={primaryColor}>{product.name}</Body14>
                                <Box pl={13}>
                                    {product.tasks.map((task, index) => {
                                        return (
                                            <Box key={index}>
                                                <YSpacer spaceY={3} />
                                                <FlexRowAlignCenter
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        dispatch(slices.form.actions.unset());
                                                        dispatch(slices.openTask.actions.set(task));
                                                            dispatch(
                                                                actions.getForm(
                                                                    task.formId,
                                                                    formModelNames[task.taskKey],
                                                                    task.taskKey === "website"
                                                                )
                                                            );
                                                        if (task.taskKey === "website")
                                                            dispatch(actions.getWebsiteFiles(practiceId));
                                                        window.scrollTo(0, 0);
                                                        routerHistory.push(task.formLink);
                                                    }}
                                                >
                                                    <Check fill={secondaryColor} />
                                                    <XSpacer spaceX={3} />
                                                    <Label11Bold color={secondaryColor} uppercase>
                                                        {task.taskName}
                                                    </Label11Bold>
                                                    <XSpacer spaceX={4} />
                                                    <task.Icon />
                                                </FlexRowAlignCenter>
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <YSpacer spaceY={2} />
                            </Box>
                        );
                    })}
                </Box>
            </PageBanner>
        </Box>
    );
};
