import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import hooks from "../hooks";
import {
    AccountOverview,
    Brand,
    Domain,
    GoogleAds,
    Growth,
    LocationOverview,
    Online,
    Profit,
    Seo,
    SocialMedia,
    Website,
} from "../Components/Pages";

export const ClientMain = (): JSX.Element => {
    hooks.useSetup();

    return (
        <Switch>
            <Route path={"/dash/location-overview"} render={() => <LocationOverview />} />
            <Route path={"/dash/account-overview"} render={() => <AccountOverview />} />
            <Route path={"/dash/online"} render={() => <Online />} />
            <Route path={"/dash/profit"} render={() => <Profit />} />
            <Route path={"/dash/growth"} render={() => <Growth />} />
            <Route path={"/dash/domain-form"} render={() => <Domain />} />
            <Route path={"/dash/seo-form"} render={() => <Seo />} />
            <Route path={"/dash/social-media-form"} render={() => <SocialMedia />} />
            <Route path={"/dash/google-ads-form"} render={() => <GoogleAds />} />
            <Route path={"/dash/website-form"} render={() => <Website />} />
            <Route path={"/dash/brand-form"} render={() => <Brand />} />
            <Route>
                <Redirect to={"/dash/location-overview"} />
            </Route>
        </Switch>
    );
};
