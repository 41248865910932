import React, { useState } from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { AbsolutePositionLogo, FormError, Gradient, Input, RightButton } from "../Atoms";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { actions } from "../../redux";
import { BodyMedium, Header27 } from "../Typography";
import useErrors from "../../hooks/useErrors";
import { useAppDispatch } from "../../redux/hooks";

const schema = object().shape({
    email: string().required("Please enter your email address").email("Please enter a valid email.").trim(),
});

export const ResetPassword = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onSubmit = (data: { email: string }) => {
        setIsSubmitted(true);
        dispatch(actions.resetPassword(data));
    };

    const error = useErrors("resetPassword");

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}>
                {isSubmitted ? (
                    <Box gridColumn={2} px={"10px"}>
                        <Box pb={"64px"} />
                        <Header27 color={"graphiteGray"} textAlign={"center"}>
                            Password Reset Sent
                        </Header27>
                        <Box pb={"20px"} />
                        <BodyMedium color={"graphiteGray"} textAlign={"center"}>
                            We just sent a message to the email you provided with a link to reset your password. Please
                            check your inbox and follow the instructions in the email.
                        </BodyMedium>
                        <Box pb={"19px"} />
                    </Box>
                ) : (
                    <Box gridColumn={2} px={"10px"}>
                        <Box pb={"64px"} />
                        <Header27 color={"graphiteGray"} textAlign={"center"}>
                            Reset Password
                        </Header27>
                        <Box pb={"20px"} />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Input
                                data-testid={"email"}
                                ref={register}
                                name={"email"}
                                placeholder="Email"
                                hasError={errors.email}
                            />
                            <FormError error={errors.email} />
                            <Box pb={"26px"} />
                            <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                                reset password
                            </RightButton>
                            <FormError error={error} />
                        </form>
                    </Box>
                )}
            </Grid>
        </Box>
    );
};
