import { createSlice } from "@reduxjs/toolkit";
import { popups } from "../../constants/popups";

const initialState: keyof typeof popups | null = null;

export default createSlice({
    name: "popup",
    initialState: initialState,
    reducers: {
        setPopup: (state, action) => action.payload,
    },
});
