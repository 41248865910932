import _ from "lodash/fp";
import React, { useMemo, useState } from "react";
import { Box, FlexRow, Grid } from "../styledSystemUtilities";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Id, PracticeBackend } from "../../@types/shared";
import { Color } from "../../@types/color";
import { ArrowRight } from "../Atoms/assets";
import { Body14, Header22, Header27, Label11Medium } from "../Typography";
import { Checkbox } from "../Atoms";
import styled from "styled-components";
import theme from "../../theme";
import { slices } from "../../redux";
import { routerHistory } from "../../routerHistory";
import { space } from "styled-system";

const BaseTextInput = styled.input`
    display: block;
    box-sizing: border-box;
    height: ${theme.sizes[13]};
    width: 100%;
    padding: 0 18px;
    border: none;
    font-size: ${theme.fontSizes[3]};
    font-family: Roboto;
    color: ${theme.colors.balticBlue};
    ::placeholder {
        color: ${theme.colors.midGray};
        font-size: ${theme.fontSizes[3]};
    }
    :focus {
        outline: none;
    }
    ${space};
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
`;

export const SmallTextInput = styled(BaseTextInput)`
    height: ${theme.sizes[11]};
    font-size: ${theme.fontSizes[2]};
    ::placeholder {
        font-size: ${theme.fontSizes[2]};
    }
    border: 1px solid ${theme.colors.pearlGray};
`;

const Selector = styled.div`
    cursor: pointer;
    font-family: ${theme.fonts.roboto};
    text-transform: uppercase;
    font-size: 10px;
    flex-basis: 160px;
    color: ${theme.colors.sienna};
    ${(props: { selected: boolean }) => (props.selected ? { borderBottom: `4px solid ${theme.colors.sienna}` } : null)};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 28px;
    user-select: none;
`;

export const SelectionBar = ({
    items,
    defaultSelected = 0,
}: {
    items: { handleClick: () => void; testid: string; text: string }[];
    defaultSelected?: number;
}): JSX.Element => {
    const [selected, setSelected] = useState(defaultSelected);

    return (
        <FlexRow px={8} borderBottom={"gray"} borderTop={"gray"} height={9} alignItems={"center"} gridColumn={"1 / -1"}>
            {items.map((item, index) => (
                <Selector
                    key={index}
                    data-testid={item.testid}
                    onClick={() => {
                        item.handleClick();
                        setSelected(index);
                    }}
                    selected={selected === index}
                >
                    {item.text}
                </Selector>
            ))}
        </FlexRow>
    );
};

const Base = (props: {
    "data-testid"?: string;
    onClick?: () => void;
    children?: React.ReactNode;
    cursor?: string;
    backgroundColor?: Color;
    color?: Color;
    fontSize?: number;
}) => <FlexRow alignItems={"center"} height={"45px"} px={8} justifyContent={"space-between"} {...props} />;

const Left = (props: { "data-testid"?: string; onClick?: () => void; children?: React.ReactNode }) => (
    <Base cursor={"pointer"} backgroundColor={"chalk"} color={"balticBlue"} fontSize={3} {...props} />
);

const List = ({
    practices,
    handleClick,
}: {
    practices: (PracticeBackend & { clientAccountId: Id })[];
    handleClick: (id: Id) => void;
}): JSX.Element => {
    return (
        <>
            {practices.map((practice) => {
                return (
                    <React.Fragment key={practice.id}>
                        <Left data-testid={practice.id} onClick={() => handleClick(practice.clientAccountId)}>
                            {practice.practiceName} <ArrowRight fill={"balticBlue"} />
                        </Left>

                        <Base>
                            <FlexRow alignItems="center">
                                <Label11Medium>CONTACT</Label11Medium>
                                <Box ml={6} />
                                <Body14>{practice.primaryContact.name}</Body14>
                            </FlexRow>

                            <FlexRow alignItems={"center"}>
                                <FlexRow ml={6} alignItems="center">
                                    <Body14>Active</Body14>
                                    <Box ml={2} />
                                    <Checkbox isChecked={practice.active} fill={"sienna"} />
                                </FlexRow>
                            </FlexRow>
                        </Base>
                    </React.Fragment>
                );
            })}
        </>
    );
};
function filterBySearchTerm(searchTerm: string) {
    const lowercasedSearchTerm = _.lowerCase(searchTerm);
    return _.filter(
        (practice: PracticeBackend) =>
            _.includes(lowercasedSearchTerm, _.lowerCase(practice.practiceName)) ||
            _.includes(lowercasedSearchTerm, _.lowerCase(_.get("primaryContact.name", practice)))
    );
}

const buildTransformer = (
    isFilteredByActive: boolean,
    searchTerm: string,
    sortPath: string,
    sortDirection: "desc" | "asc"
) =>
    _.flow(
        isFilteredByActive ? _.filter("active") : _.identity,
        !_.isEmpty(searchTerm) ? filterBySearchTerm(searchTerm) : _.identity,
        _.orderBy([sortPath], [sortDirection])
    );

export const PracticeList = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((state) => state.currentUser);
    const practices = useAppSelector((state) => state.clientPractices);

    const [searchTerm, setSearchTerm] = useState("");
    const [isFilteredByActive, setIsFilteredByActive] = useState(false);
    const [sortPath, setSortPath] = useState("practiceName");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

    const transformedPractices = useMemo(() => {
        return buildTransformer(isFilteredByActive, searchTerm, sortPath, sortDirection)(practices);
    }, [practices, isFilteredByActive, sortPath, sortDirection, searchTerm]);

    const handleSort = (path: string) => {
        if (_.isEqual(sortPath, path)) {
            if (sortDirection === "asc") {
                setSortDirection("desc");
            } else {
                setSortDirection("asc");
            }
        } else {
            setSortDirection("asc");
            setSortPath(path);
        }
    };

    const handlePracticeSelect = (id: Id) => {
        routerHistory.push("/dash/location-overview");
        dispatch(slices.currentUser.actions.setClientAccountId(id));
    };
    return (
        <Box px={10} py={10}>
            <Box gridColumn={"1 / -1"}>
                <FlexRow justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Box>
                        <Header27 data-testid={"pageHeadline"}>{"Practice List"}</Header27>
                        <Box mb={2} />
                        <Header22 data-testid={"pageSubheader"}>{_.get("user.name", currentUser)}</Header22>
                    </Box>
                </FlexRow>
            </Box>

            <SelectionBar
                items={[
                    {
                        text: "practice",
                        handleClick: () => handleSort("practiceName"),
                        testid: "sortByPractice",
                    },
                    {
                        text: "contact",
                        handleClick: () => handleSort("primaryContact.name"),
                        testid: "sortByContactName",
                    },
                ]}
            />
            <Box gridColumn={"1/-1"} mb={6} />
            <FlexRow
                flexWrap={"wrap"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gridColumn={"1/-1"}
                pl={8}
                mb={6}
            >
                <FlexRow alignItems={"center"}>
                    <Box onClick={() => setIsFilteredByActive((s) => !s)}>
                        <Checkbox isChecked={isFilteredByActive} fill={"sienna"} />
                    </Box>
                    <Box mr={6} />
                    <Body14>Show only active clients</Body14>
                </FlexRow>
                <Box width={19}>
                    <SmallTextInput
                        data-testid={"practiceListSearchInput"}
                        value={searchTerm}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                        placeholder={"search..."}
                    />
                </Box>
            </FlexRow>

            <Grid gridTemplateColumns={"1fr 1fr"}>
                {transformedPractices.length > 0 ? (
                    <List practices={transformedPractices} handleClick={handlePracticeSelect} />
                ) : null}
            </Grid>
        </Box>
    );
};
