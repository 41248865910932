import React from "react";
import { Box, Grid } from "../styledSystemUtilities";
import { useSetPopup } from "../../hooks/useSetPopup";
import { popups } from "../../constants/popups";

export const PopupContainer = ({ popupId }: { popupId: keyof typeof popups }): JSX.Element => {
    const setPopup = useSetPopup(null);
    const Popup = popups[popupId];

    return (
        <Grid
            position={"fixed"}
            gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
            width={"100vw"}
            height={"100vh"}
            zIndex={"1000"}
            backgroundColor={"translucentWhite"}
            top={0}
            left={0}
        >
            <Box
                gridColumn={2}
                boxShadow={"0 4px 8px 0 rgba(0, 0, 0, 0.2)"}
                backgroundColor={"white"}
                overflow={"auto"}
                maxHeight={"95%"}
                alignSelf={"center"}
            >
                {popupId ? <Popup setPopup={setPopup} /> : null}
            </Box>
        </Grid>
    );
};
