import { api } from "../utilityActions";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { AnyAction } from "redux";
import slices from "../slices";
import { Competitor, OnlineSubscription } from "../../@types/reports/Online";
import _ from "lodash/fp"

const getOnlineProducts = (): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
    const store = getState();
    dispatch(
        api({
            call: {
                url: "/secure-routes/recurly/get-online-products",
                method: "post",
                data: {
                    practiceId: store.openPractice.id,
                    reportData: {
                        seoRank: store.onlineReport.localSeo?.overallRank ?? 61,
                        numCompetitors: store.onlineReport.localSeo?.competitors.length ?? 60,
                        competitionLevel: store.onlineReport.localSeo?.competitionLevel ?? 1,
                        reviewsRank: getReviewsRank(
                            store.onlineReport.localSeo?.rating ?? 0,
                            store.onlineReport.localSeo?.reviewCount ?? 0,
                            store.onlineReport.localSeo?.competitors ?? []
                        ),
                    },
                },
            },
            success: (subscriptions: {
                subscriptions: OnlineSubscription[];
                availableProducts: {
                    recommended: OnlineSubscription[];
                    other: OnlineSubscription[];
                };
            }) => [
                slices.onlineReport.actions.setSubscriptions(subscriptions),
                slices.paymentModal.actions.set({ openModal: "onlineThankYou" }),
            ],
            label: "onlineProducts",
        })
    );
};

export default getOnlineProducts;

function getReviewsRank(
    rating: number,
    reviewCount: number,
    competitors: Competitor[]
): number {
    const allLocations = competitors.concat({
        rating,
        reviewCount,
        placeId: "user-practice",
        practiceName: "User Practice",
        overallRank: 0,
        averageRank: 0,
    });

    const locationsWithTotalStars = allLocations.map((location) => ({
        ...location,
        totalStars: location.rating * location.reviewCount,
    }));
    const orderedLocations = _.orderBy(
        "totalStars",
        "asc",
        locationsWithTotalStars
    );
    const locationsWithRank = orderedLocations.map((location, index) => ({
        ...location,
        rank: index + 1,
    }));
    const userLocation = locationsWithRank.find(
        (location) => location.placeId === "user-practice"
    );

    return userLocation?.rank ?? 61;
}
