import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuTableData } from "../../@types/components/MenuTable";
import { ProfitReport } from "../../@types/reports/Profit";

const roiTableRows = {
    average: 0,
    sinceActive: 0,
    lastMonth: 0,
};

const roiTable: MenuTableData = {
    roiTotals: roiTableRows,
    revenueGenerated: roiTableRows,
    newPatients: roiTableRows,
};

const initialState: ProfitReport = {
    roiTable,
    years: [],
};

export default createSlice({
    name: "profitReport",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<ProfitReport>) => action.payload,
        unset: () => initialState,
    },
});
