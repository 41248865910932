import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Coupon } from "../../@types/Coupon";

const initialState: Coupon[] = [];

export default createSlice({
    name: "activeCoupons",
    initialState: initialState,
    reducers: {
        set: (state, action: PayloadAction<Coupon[]>) => action.payload,
    },
});
