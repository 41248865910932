import { BestForDentistryBlackLogo, HPALogo, Logo } from "./assets";
import React from "react";
import { useAppSelector } from "../../redux/hooks";

export function MenuLogo(): JSX.Element {
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);
    const isHpa = useAppSelector((state) => state.isHpa);

    return isBestForDentistry ? <BestForDentistryBlackLogo /> : isHpa ? <HPALogo /> : <Logo width={"158px"} />;
}
