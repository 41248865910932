import styled from "styled-components";
import { all } from "../styledSystemUtilities";
import { AllStyledSystemProps } from "../styledSystemAll";

export const Input = styled.input<{ hasError: boolean }>`
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #e6e7e8;
    border-radius: 0;
    width: 100%;
    height: 50px;
    color: ${(props) => (props.hasError ? "#E27867" : "#7F7F7F")};
    font-size: 14px;
    padding-left: 20px;
    ::placeholder {
        color: ${(props) => (props.hasError ? "#E27867" : "#A7A9AC")};
    }
    :focus {
        outline: none;
    }
    ${all}
`;

export const BigInput = styled.textarea<{ hasError: boolean }>`
    box-sizing: border-box;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #e6e7e8;
    border-radius: 0;
    width: 100%;
    height: 130px;
    color: ${(props) => (props.hasError ? "#E27867" : "#7F7F7F")};
    font-size: 14px;
    padding: 12px;
    ::placeholder {
        color: ${(props) => (props.hasError ? "#E27867" : "#A7A9AC")};
    }
    :focus {
        outline: none;
    }
    ${all}
`;

export const HalfInput = styled.input<{ hasError: boolean }>`
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #cccccc;
    border-radius: 0;
    width: 100%;
    height: 50px;
    color: #343434;
    font-size: 14px;
    text-align: center;
    ::placeholder {
        color: #a7a9ac;
    }
    :focus {
        outline: none;
    }
    :focus::placeholder {
        color: transparent;
    }
    ${all}
`;

export const SmallTextInput = styled.input<AllStyledSystemProps>`
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    width: 100%;
    color: #7f7f7f;
    padding-left: 20px;
    ::placeholder {
        color: #a7a9ac;
    }
    :focus {
        outline: none;
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0;
    }
    height: 32px;
    font-size: 12px;
    ::placeholder {
        font-size: 12px;
    }
    border: 1px solid #e6e7e8;
    ${all}
`;
