import { Color } from "../../@types/color";
import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { Label9Medium } from "../Typography";
import React from "react";

export const ThreeMonthTrendChartHeader = ({
    color,
    finalColor,
    lastThreeMonthDates,
}: {
    color: Color;
    finalColor: Color;
    lastThreeMonthDates: string[];
}): JSX.Element => {
    return (
        <FlexRowAlignCenter justifyContent={"space-between"} width={"114px"} ml={8} pb={3}>
            <Label9Medium color={color} uppercase>
                {lastThreeMonthDates[0]}
            </Label9Medium>
            <Label9Medium color={color} uppercase>
                {lastThreeMonthDates[1]}
            </Label9Medium>
            <Label9Medium color={finalColor} uppercase>
                {lastThreeMonthDates[2]}
            </Label9Medium>
        </FlexRowAlignCenter>
    );
};
