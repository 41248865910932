import React from "react";
import { Box } from "../styledSystemUtilities";
import {
    CampaignSummaryRaw,
    CostPerClickChartRaw,
    GaGraphRaw,
    GmbGraphRaw,
    GoalPlanningRaw,
    MonthToDateRaw,
    PpcCallTableRaw,
    PpcCostPerPatientRaw,
    PpcTableRaw,
    ReviewsOverTimeRaw,
    ReviewsOverTimeTableRaw,
    ReviewsPlotRaw,
    ReviewsTableRaw,
    SeoOverTimeRaw,
    SeoPlotRaw,
    SeoTableRaw,
    VisibilityTableRaw,
    VisibilityWheelRaw,
} from "./assets";
import { Body14Medium, Label9Medium } from "../Typography";

export const GaGraphSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleChartTemplate name={name}>
            <GaGraphRaw />
        </SampleChartTemplate>
    );
};

export const GmbGraphSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleChartTemplate name={name}>
            <GmbGraphRaw />
        </SampleChartTemplate>
    );
};

export const ReviewsPlotSample = (): JSX.Element => {
    return (
        <SampleChartTemplate>
            <ReviewsPlotRaw />
        </SampleChartTemplate>
    );
};

export const ReviewsOverTimeSample = (): JSX.Element => {
    return (
        <SampleChartTemplate>
            <ReviewsOverTimeRaw />
        </SampleChartTemplate>
    );
};

export const ReviewsOverTimeTableSample = (): JSX.Element => {
    return (
        <SampleTableTemplate>
            <ReviewsOverTimeTableRaw />
        </SampleTableTemplate>
    );
};

export const ReviewsTableSample = (): JSX.Element => {
    return (
        <SampleTableTemplate>
            <ReviewsTableRaw />
        </SampleTableTemplate>
    );
};

export const SeoOverTimeSample = (): JSX.Element => {
    return (
        <SampleChartTemplate>
            <SeoOverTimeRaw />
        </SampleChartTemplate>
    );
};

export const SeoPlotSample = (): JSX.Element => {
    return (
        <SampleChartTemplate>
            <SeoPlotRaw />
        </SampleChartTemplate>
    );
};

export const SeoTableSample = (): JSX.Element => {
    return (
        <SampleTableTemplate>
            <SeoTableRaw />
        </SampleTableTemplate>
    );
};

export const VisibilityWheelSample = (): JSX.Element => {
    return (
        <SampleChartTemplate>
            <Box ml={"-50px"}>
                <VisibilityWheelRaw />
            </Box>
        </SampleChartTemplate>
    );
};

export const VisibilityTableSample = (): JSX.Element => {
    return (
        <SampleTableTemplate>
            <VisibilityTableRaw />
        </SampleTableTemplate>
    );
};

export const CampaignSummarySample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleChartTemplate name={name}>
            <CampaignSummaryRaw />
        </SampleChartTemplate>
    );
};

export const GoalPlanningSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleChartTemplate name={name}>
            <GoalPlanningRaw />
        </SampleChartTemplate>
    );
};

export const MonthToDateSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleChartTemplate name={name}>
            <MonthToDateRaw />
        </SampleChartTemplate>
    );
};

export const CostPerClickSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleChartTemplate name={name}>
            <CostPerClickChartRaw />
        </SampleChartTemplate>
    );
};

export const PpcTableSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleTableTemplate name={name}>
            <PpcTableRaw />
        </SampleTableTemplate>
    );
};

export const PpcCallSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleTableTemplate name={name}>
            <PpcCallTableRaw />
        </SampleTableTemplate>
    );
};

export const PpcCostPerPatientSample = ({ name }: { name: string }): JSX.Element => {
    return (
        <SampleTableTemplate name={name}>
            <PpcCostPerPatientRaw />
        </SampleTableTemplate>
    );
};

function SampleChartTemplate({ children, name }: { name?: string; children: React.ReactNode }): JSX.Element {
    return (
        <Box mb={6}>
            {name ? (
                <Box mb={2}>
                    <Body14Medium>{name}</Body14Medium>
                </Box>
            ) : null}
            {children}
            <Label9Medium fontWeight={"medium"}>(Sample of Ascent Reporting)</Label9Medium>
        </Box>
    );
}

function SampleTableTemplate({ children, name }: { name?: string; children: React.ReactNode }): JSX.Element {
    return (
        <>
            {name ? (
                <Box mb={2}>
                    <Body14Medium>{name}</Body14Medium>
                </Box>
            ) : null}
            <Box mb={6} display={"flex"} justifyContent={"center"}>
                {children}
            </Box>
        </>
    );
}
