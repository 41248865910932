import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const getClientAccount = (accountId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/client-accounts/get-account/${accountId}`,
            method: "get",
        },
        success: slices.clientAccount.actions.set,
        label: "clientAccount",
    });

export default getClientAccount;
