import { Box, FlexCol, YSpacer } from "../styledSystemUtilities";
import { Body12Bold, Body14Bold } from "../Typography";
import { Select } from "../Atoms/Select";
import { SmallButton } from "../Atoms";
import React, { useEffect, useState } from "react";
import { KeywordSetLabel, LocalSeoReport, SearchAreaType } from "../../@types/reports/Online";
import { actions } from "../../redux";
import { useAppDispatch } from "../../redux/hooks";
import { getSeoSearchParamsOptionFromReport } from "../../utils/getSeoSearchParamsOptionFromReport";
import { practiceTypeOptions, searchAreaOptions } from "../../constants/seoSearchParamsOptions";

export function KeywordParamsSelect({
    localSeoReport,
    locationId,
}: {
    localSeoReport: LocalSeoReport;
    locationId: string;
}): JSX.Element {
    const dispatch = useAppDispatch();
    const [keywordParameters, setKeywordParameters] = useState<{
        keywordSetLabel: KeywordSetLabel;
        searchAreaType: SearchAreaType;
    }>({
        keywordSetLabel: localSeoReport.keywordSetLabel,
        searchAreaType: localSeoReport.searchAreaType,
    });
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleSubmit = () => {
        dispatch(
            actions.getSeoSummaryReport(
                locationId,
                keywordParameters.keywordSetLabel,
                keywordParameters.searchAreaType,
                scrollPosition
            )
        );
    };

    const defaultOptions = getSeoSearchParamsOptionFromReport(localSeoReport);

    const handleScroll = () => {
        setScrollPosition(document.body.scrollTop || document.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Box data-testid={"keywordParamsSelect"}>
            <Body14Bold color={"forrestGreen"}>Keyword Parameters:</Body14Bold>
            <YSpacer spaceY={2} />
            <FlexCol px={5}>
                <Body12Bold color={"forrestGreen"}>Area</Body12Bold>
                <YSpacer spaceY={2} />
                <Select
                    onChange={(option) => {
                        if (option !== null) {
                            setKeywordParameters((prevState) => ({
                                ...prevState,
                                searchAreaType: option.value,
                            }));
                        }
                    }}
                    defaultValue={defaultOptions.searchAreaOption}
                    options={searchAreaOptions}
                />
                <YSpacer spaceY={3} />
                <Body12Bold color={"forrestGreen"}>Keywords</Body12Bold>
                <YSpacer spaceY={2} />
                <Select
                    onChange={(option) => {
                        if (option !== null) {
                            setKeywordParameters((prevState) => ({
                                ...prevState,
                                keywordSetLabel: option.value,
                            }));
                        }
                    }}
                    defaultValue={defaultOptions.keywordSetOption}
                    options={practiceTypeOptions}
                />
                <YSpacer spaceY={7} />
                <Box>
                    <SmallButton backgroundColor={"auratiumGreen"} onClick={handleSubmit}>
                        get results
                    </SmallButton>
                </Box>
            </FlexCol>
            <YSpacer spaceY={8} />
        </Box>
    );
}
