import React from "react";
import { Box, FlexColAlignCenter, FlexRow, FlexRowAlignCenter, XSpacer, YSpacer } from "../styledSystemUtilities";
import { Body12, Label } from "../Typography";
import { Color } from "../../@types/color";
import { GoogleAdsCampaignAverages } from "../../@types/reports/Growth";
import { formatAsDollars } from "../../utils/formatter";
import _ from "lodash/fp";
import constants from "../../constants";

export const CampaignMonthlyAverages = ({
    campaignAverages,
}: {
    campaignAverages: GoogleAdsCampaignAverages;
}): JSX.Element => {
    return (
        <Box backgroundColor={"white"} pb={6} pt={3} data-testid={"campaignMonthlyAverages"} width={"320px"}>
            <FlexColAlignCenter>
                <Box>
                    <FlexRow alignItems={"flex-end"}>
                        <CellAndLabel
                            label={"Price\nPer Click"}
                            data={formatAsDollars(_.round(campaignAverages.averageCostPerClick * 100) / 100)}
                            width={"60px"}
                            testId={"pricePerClick"}
                        />
                        <XSpacer spaceX={"1px"} />
                        <CellAndLabel
                            label={"Clicks"}
                            data={_.round(campaignAverages.averageClicks)}
                            width={"40px"}
                            testId={"clicks"}
                        />
                        <XSpacer spaceX={"1px"} />
                        <CellAndLabel
                            label={"Calls"}
                            data={_.round(campaignAverages.averageCalls)}
                            width={"40px"}
                            testId={"calls"}
                        />
                        <XSpacer spaceX={"1px"} />
                        <CellAndLabel
                            label={"Click\nTo Call"}
                            data={_.round(campaignAverages.averageClickToCall * 100) + "%"}
                            width={"50px"}
                            testId={"clickToCall"}
                        />
                        <XSpacer spaceX={"1px"} />
                        <CellAndLabel
                            label={"New Patients"}
                            data={_.round(campaignAverages.averageNewPatients)}
                            width={"50px"}
                            testId={"newPatients"}
                        />
                        <XSpacer spaceX={"1px"} />
                        <CellAndLabel
                            label={"Call Conv."}
                            data={_.round(campaignAverages.averageCallConversion * 100) + "%"}
                            width={"53px"}
                            testId={"callConversion"}
                        />
                    </FlexRow>
                    <YSpacer spaceY={1} />
                    <Label color={"ipanemaBlue"}>Ascent's National Client Averages:</Label>
                    <YSpacer spaceY={1} />
                    <FlexRow>
                        <Box width={"60px"}>
                            <Cell
                                data={formatAsDollars(constants.ascentGoogleAds.nationalAverages.COST_PER_CLICK)}
                                color={"ipanemaBlue"}
                                testId={"ascentAveragePricePerClick"}
                            />
                        </Box>
                        <Box width={"83px"} />
                        <Box width={"50px"}>
                            <Cell
                                data={_.round(constants.ascentGoogleAds.nationalAverages.CLICK_TO_CALL * 100) + "%"}
                                color={"ipanemaBlue"}
                                testId={"ascentAverageClickToCall"}
                            />
                        </Box>
                        <Box width={"52px"} />
                        <Box width={"53px"}>
                            <Cell
                                data={_.round(constants.ascentGoogleAds.nationalAverages.CALL_CONVERSION * 100) + "%"}
                                color={"ipanemaBlue"}
                                testId={"ascentAverageCallConversion"}
                            />
                        </Box>
                    </FlexRow>
                </Box>
            </FlexColAlignCenter>
        </Box>
    );
};

const CellAndLabel = ({
    label,
    data,
    width,
    testId,
}: {
    label: string;
    data: string | number;
    width: string | number;
    testId?: string;
}) => (
    <Box width={width}>
        <FlexColAlignCenter>
            <Label textAlign={"center"} color={"petrolBlue"}>
                {label}
            </Label>
        </FlexColAlignCenter>
        <Cell data={data} color={"deepPetrol"} testId={testId} />
    </Box>
);

const Cell = ({ data, color, testId }: { data: string | number; color: Color; testId?: string }) => (
    <FlexRowAlignCenter
        width={"100%"}
        backgroundColor={"aquaBlue"}
        justifyContent={"center"}
        height={"25px"}
        data-testid={testId}
    >
        <Body12 color={color}>{data}</Body12>{" "}
    </FlexRowAlignCenter>
);
