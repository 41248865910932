import React from "react";
import { Box, FlexRowAlignCenter } from "../../styledSystemUtilities";
import {
    At,
    Domain,
    EssentialWebsiteHero,
    EssentialWebsiteSample1,
    EssentialWebsiteSample2,
    EssentialWebsiteSample3,
    HostingIcon,
    Lock,
    Maintenance,
} from "../../Atoms/assets";
import {
    Art,
    CopyWriting,
    Facebook,
    GaGraphSample,
    GmbGraphSample,
    Google,
    Phone,
    Photo,
    Responsive,
    Strategy,
    WebsiteSamples,
} from "../../Atoms";
import {
    GrayBackground,
    IconBulletPoint,
    IconContainer,
    NumberedParagraph,
    OnlineHeader,
    Paragraph,
    PricingNotes,
    ReportingCard,
    ReportingHeader,
    SampleContainer,
    SellingPoint,
} from "../../Molecules";
import { Header27 } from "../../Typography";

export const EssentialWebsite = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <OnlineHeader setPopup={setPopup} Hero={EssentialWebsiteHero} />
            <GrayBackground>
                <SellingPoint
                    header={"A Website Designed to be better."}
                    body={
                        "Our subscription website is designed to accomplish three primary goals:\n" +
                        "1. Allow Ascent to ensure an ideal online visibility.\n" +
                        "2. Allow for correct tracking and reporting.\n" +
                        "3. Communicate correctly to convert patients.\n" +
                        "\n" +
                        "How do we do that?"
                    }
                />
                <SellingPoint header={"Better design"} body={"We pride ourselves on design excellence."} />
                <SellingPoint
                    header={"Better user interaction"}
                    body={
                        "Because we have a better understanding of how potential patients use dental websites, we move them to places where they can make “buying” decisions."
                    }
                />
                <SellingPoint
                    header={"Better conversion messaging"}
                    body={"Our websites sell your unique brand, experience, and relationships."}
                />
                <SellingPoint
                    header={"Better optimization strategy"}
                    body={
                        "Our sites are optimized to Google’s highest standards and allow us to move you up the rankings more easily."
                    }
                />
                <SellingPoint
                    header={"Better technology"}
                    body={
                        "We integrate Google Analytics, Facebook Pixel and dynamic call tracking for maximum analytical reporting. Our sites are also built to the latest responsive standards."
                    }
                />
                <IconContainer name={"Technology"}>
                    <Google name={"Google Analytics & Optimized"} fill={"auratiumGreen"} />
                    <Facebook name={"Facebook Pixel"} />
                    <Box />
                    <Responsive name={"Responsive Site"} />
                    <Phone name={"Call Tracking"} />
                </IconContainer>
                <IconContainer name={"Fulfillment"}>
                    <Art name={"Art Direction"} />
                    <CopyWriting name={"Copywriting"} fill={"auratiumGreen"} />
                    <Box />
                    <Photo name={"Photo Library"} />
                    <Strategy name={"Strategy"} fill={"auratiumGreen"} />
                </IconContainer>
            </GrayBackground>
            <FlexRowAlignCenter height={"44px"} backgroundColor={"backgroundGreen"} pl={"22px"}>
                <Header27 color={"forrestGreen"}>Includes:</Header27>
            </FlexRowAlignCenter>
            <GrayBackground>
                <Box pt={"14px"} pb={"14px"}>
                    <Header27 color={"forrestGreen"}>Site Upgrades</Header27>
                </Box>
                <IconBulletPoint
                    Icon={HostingIcon}
                    body={"Ensure the latest technology & web optimization"}
                    header={"Annual upgrades"}
                />
                <IconBulletPoint
                    Icon={Maintenance}
                    header={"Website maintenance"}
                    body={"12 hours of website changes by web professionals per hosting year."}
                />
                <IconBulletPoint
                    Icon={Lock}
                    header={"Includes SSL Certificate"}
                    body={"Adds security and improves your ranking."}
                />
                <Box pt={"14px"} pb={"14px"}>
                    <Header27 color={"forrestGreen"}>Hosting</Header27>
                </Box>
                <IconBulletPoint
                    Icon={HostingIcon}
                    header={"Ultra reliable website hosting"}
                    body={"Industry standard 99.9% Reliability. "}
                />
                <IconBulletPoint
                    Icon={At}
                    header={"Domain branded email address"}
                    body={"5 addresses included, more can be purchased for a small fee."}
                />
                <IconBulletPoint
                    Icon={Domain}
                    header={"Domain management"}
                    body={"We manage and update settings and, if necessary, can acquire your domain."}
                />

                <ReportingHeader product={"website"} color={"forrestGreen"} />
            </GrayBackground>
            <ReportingCard>
                <NumberedParagraph
                    header={"1. Phone Calls"}
                    body={
                        "How many phone calls were generated from the website? We track phone calls and conversions through a dynamic call tracking number."
                    }
                />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"Is it generating enough traffic to help with your ranking?"}
                    header={"2. Online ranking"}
                />
                <Paragraph>
                    We track new users and total users. This allows us to report how many new users are being driven to
                    the site and how many visitors return each month.
                </Paragraph>
                <GaGraphSample name={""} />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    header={"3. Google My Business integration"}
                    body={"Are we seeing enough interaction with your GMB account?"}
                />
                <Paragraph>
                    Tracking web link clicks and phone actions from your Google My Business listing help determine where
                    your opportunity lies.
                </Paragraph>
                <GmbGraphSample name={""} />
            </ReportingCard>
            <Box height={"18px"} backgroundColor={"chalk"} />
            <FlexRowAlignCenter height={"44px"} backgroundColor={"backgroundGreen"} pl={"22px"}>
                <Header27 color={"forrestGreen"}>Samples:</Header27>
            </FlexRowAlignCenter>
            <SampleContainer>
                <Box pb={"17px"} />
                <WebsiteSamples samples={[EssentialWebsiteSample1, EssentialWebsiteSample2, EssentialWebsiteSample3]} />
            </SampleContainer>
            <PricingNotes>
                Essential Websites are purchased on a subscription basis and requires 30 days notice to cancel.
            </PricingNotes>
        </Box>
    );
};
