import _ from "lodash/fp";
import { format } from "date-fns/fp";

function getCalenderMonths(): string[] {
    const now = new Date();
    const thisYear = now.getFullYear();
    return _.range(0, 12).map((month) => format("MMM", new Date(thisYear, month)));
}

export default getCalenderMonths;
