import React from "react";
import { Box, Span, YSpacer } from "../../styledSystemUtilities";
import {
    Handshake,
    ConversationBubbles,
    ComputerWithRing,
    DesktopComputer,
    PuzzlePieces,
    DentalMenuStandardHero,
} from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { ProfitHeader, WhiteBackground } from "../../Molecules/PopupComponents";
import { Body, Body14 } from "../../Typography";

export const StandardDentalMenu = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={DentalMenuStandardHero} />
            <GrayBackground>
                <SellingPoint
                    header={"An automated, flexible, in-house membership plan that’s proven to increase retention."}
                    body={
                        "88% of new patients without dental insurance don’t come back! Don’t let your uninsured patients flow through your practice! Leverage our expertise and software to create a unique solution for you uninsured patients.\n\nEasily create a ‘menu’ of services for your patients, empowering them to select and build a plan that suits them. What does that mean? A simple path to more patients on your plans! Finally a solid alternative to dental insurance for the uninsured."
                    }
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={ConversationBubbles}
                    body={"Ensure your plans are set up correctly."}
                    header={"Plan Consultation"}
                />
                <IconBulletPoint
                    Icon={ComputerWithRing}
                    body={"Help your front office team be proficient."}
                    header={"Software Training"}
                />
                <IconBulletPoint
                    Icon={DesktopComputer}
                    body={"Streamline your enrollment process."}
                    header={"Branded Landing Page & Online Setup"}
                />
                <Box display={"flex"} pt={5} pb={10}>
                    <Box width={"36px"} display={"flex"} justifyContent={"center"}>
                        <PuzzlePieces />
                    </Box>

                    <Box ml={"13px"} />
                    <Box>
                        <Body fontWeight={"bold"}>Dental Menu Software Solutions</Body>
                        <YSpacer spaceY={3} />
                        <BulletPoint
                            header={"Member Management"}
                            body={"All pertinent membership info at your fingertips"}
                        />
                        <BulletPoint header={"Automated Payments"} body={"Automatically collect membership payments"} />
                        <BulletPoint
                            header={"Churn Management"}
                            body={"Automatically follow up with delinquent payments"}
                        />
                        <BulletPoint
                            header={"Patient Rewards Platform"}
                            body={"Incentivize timely preventive visits to increase retention and treatment acceptance"}
                        />
                        <BulletPoint
                            header={"Benefit Usage and Eligibility"}
                            body={"Keep track of patients benefits and eligibility"}
                        />
                        <BulletPoint header={"Ledger Reconciliation"} body={"Keep your ledger accurate"} />
                        <BulletPoint
                            header={"Provider Compensation"}
                            body={"Ensure providers are receiving credit for services rendered"}
                        />
                        <BulletPoint
                            header={"Plan Usage and Profitability"}
                            body={"Accurate reporting on plan profitability"}
                        />
                    </Box>
                </Box>
                <IconBulletPoint Icon={Handshake} body={"Always here for peace of mind."} header={"Friendly Support"} />
                <IconContainer name={"Payment Information"} />
                <Box ml={8} pb={10}>
                    <Body14>
                        Your initial payment of 99 will be charged through the insight app. All future monthly payments
                        and a $2 per member fee will be charged directly through Dental Menu.
                    </Body14>
                </Box>
            </GrayBackground>
            <WhiteBackground>
                <Body color={"balticBlue"} lineHeight={"21px"}>
                    “We have been using Dental Menu for a long time. It has generated so many new patients over the
                    years. Their membership solution has been great for my front office staff. If you are in need of
                    starting or improving a membership program, or need new patients, I would highly recommend them.”
                </Body>
                <Body14 fontWeight={"bold"} textAlign={"end"}>
                    Brent S. DDS
                </Body14>
            </WhiteBackground>
            <GrayBackground>
                <YSpacer spaceY={9} />
                <SellingPoint
                    header={"About Dental Menu"}
                    body={
                        "Dental Menu is a complete platform that allows dental providers to offer subscription plans directly to their patients. They help offices create a unique menu of services that allows patients to customize their membership plan. This increases the number of patients that enroll in your plans, the length of time they are members, and the amount of revenue they spend on service each month."
                    }
                />
                <YSpacer spaceY={6} />
            </GrayBackground>
        </Box>
    );
};

function BulletPoint({ header, body }: { header: string; body: string }): JSX.Element {
    return (
        <Box pb={7} ml={7}>
            <Body14>
                • <Span fontWeight={"bold"}>{header}</Span> - {body}
            </Body14>
        </Box>
    );
}
