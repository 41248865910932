import React, { useState } from "react";
import { ReportingDropdown, ReportingDropdownContent } from "../../Molecules";
import { Box, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { ReviewsStars } from "../../Atoms/assets";
import { Body12, Body14Bold, Body14Medium, Header } from "../../Typography";
import { ReviewsChartCard } from "../ReviewsChartCard";
import { CompetitorsTable } from "../../Molecules/CompetitorsTable";
import { LocalSeoReport } from "../../../@types/reports/Online";

const round = (n: number) => Math.round(n * 10) / 10

export const ReviewsDropdown = ({
    localSeoReport,
    practiceName,
}: {
    localSeoReport: LocalSeoReport;
    practiceName: string;
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(true);

    return (
        <>
            <ReportingDropdown text={"reviews data details — google reviews"} isOpen={isOpen} setIsOpen={setIsOpen} />
            {isOpen ? (
                <ReportingDropdownContent testId={"reviewsDropdown"}>
                    <Box gridColumn={"1 / -1"} pl={8} pr={8}>
                        <YSpacer spaceY={5} />
                        <FlexRowAlignCenter>
                            <ReviewsStars />
                            <XSpacer spaceX={5} />
                            <Box>
                                <Header color={"forrestGreen"}>Google Reviews</Header>
                                <Body14Bold color={"forrestGreen"} lineHeight={"14px"}>
                                    Ensure trust and improve visibility.
                                </Body14Bold>
                            </Box>
                        </FlexRowAlignCenter>
                        <YSpacer spaceY={4} />
                        <Body12>
                            Google reviews are, by far, the most important reviews you can generate. Why? There are two
                            main reasons. First, potential patients see them more than any other type of review, and
                            reviews are the primary catalyst in building trust. Second, they are a critical part of
                            determining your Google Local ranking.
                        </Body12>
                        <YSpacer spaceY={6} />
                    </Box>
                    <Box>
                        <ReviewsChartCard
                            reviewsChartData={{
                                userData: [{ x: localSeoReport.reviewCount, y: localSeoReport.rating }],
                                compData: localSeoReport.competitors
                                    .map((competitor) => ({
                                        x: competitor.reviewCount,
                                        y: competitor.rating,
                                    }))
                                    .slice()
                                    .sort((a, b) => b.x * b.y - a.x * a.y),
                            }}
                        />
                    </Box>
                    <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
                        <Box backgroundColor={"aquaHaze"} px={8} pt={6} width={"100%"} gridColumn={2}>
                            <Body14Medium color={"auratiumGreen"}>
                                Review Rating, Totals & Your Competition
                            </Body14Medium>
                            <YSpacer spaceY={3} />
                            <Body12>
                                The quality and quantity of your reviews compared to your competition affect your
                                ranking and perceived trust level.{" "}
                            </Body12>
                            <YSpacer spaceY={5} />
                            <CompetitorsTable
                                competitors={localSeoReport.competitors.map((competitor) => ({
                                    practiceName: competitor.practiceName,
                                    x: round(competitor.reviewCount),
                                    y: round(competitor.rating),
                                    placeId: competitor.placeId,
                                }))}
                                target={[
                                    {
                                        practiceName: practiceName,
                                        x: round(localSeoReport.reviewCount),
                                        y: round(localSeoReport.rating),
                                        placeId: "targetPractice",
                                    },
                                ]}
                                headings={["COMPETITORS:", "RATING", "TOTAL #"]}
                                order={"desc"}
                                dataTestId={"reviewsCompetitorTable"}
                            />
                            <YSpacer spaceY={"68px"} />
                        </Box>
                    </Grid>
                </ReportingDropdownContent>
            ) : null}
        </>
    );
};
