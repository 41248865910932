import React from "react";
import { Box, FlexCol, FlexColAlignCenter, FlexRowAlignCenter, Grid, Span, YSpacer } from "../styledSystemUtilities";
import { AddToCart } from "./ProductButtons";
import { Body12, Body14, Label, Label11 } from "../Typography";
import { Payments, Product } from "../../@types/reports/ProfitCenters";
import { formatAsCents, formatAsDollars } from "../../utils/formatter";
import { format } from "date-fns/fp";
import { LearnMore } from "./SubscriptionCardButtons";
import { useAppSelector } from "../../redux/hooks";
import _ from "lodash/fp";
import bestDiscountedProductCodes from "../../constants/bestDiscountedProductCodes";
import { BEST_DISCOUNT } from "../../constants/bestDiscount";

export const ProductBottomRow = ({
    toggleProduct,
    isAdded,
    isReOrder = false,
    setPopup,
    learnMoreText,
}: {
    toggleProduct: () => void;
    isAdded: boolean;
    isReOrder?: boolean;
    setPopup: () => void;
    learnMoreText?: string;
}): JSX.Element => (
    <FlexRowAlignCenter
        justifyContent={"space-between"}
        backgroundColor={"mercury"}
        height={"28px"}
        pl={"12px"}
        pr={"16px"}
        mt={3}
    >
        <LearnMore color={"nauticBlue"} setPopup={setPopup} learnMoreText={learnMoreText} />
        <Box />
        <AddToCart toggleProduct={toggleProduct} isAdded={isAdded} isReOrder={isReOrder} />
    </FlexRowAlignCenter>
);

export function PaymentsRow({ payments, productCode }: { payments: Payments; productCode: string }): JSX.Element {
    const activeCoupons = useAppSelector((state) => state.activeCoupons);

    const discount = _.sum(
        activeCoupons.map((coupon) => {
            const doesApply = coupon.applicableItemCodes.includes(productCode);
            if (coupon.discount.percent && doesApply) {
                return _.round(coupon.discount.percent * payments.balance) / 100;
            }

            return 0;
        })
    );

    return (
        <Box pl={6} pr={7} pb={2}>
            <Body12 color={"nauticBlue"}>Payments</Body12>
            <YSpacer spaceY={2} />
            <Grid gridTemplateColumns={"auto auto auto auto"} gridColumnGap={3}>
                <CellAndLabel label={"Purchase Date"} data={format("P", payments.purchaseDate)} />
                <CellAndLabel label={"Made"} data={payments.madePayments} />
                <CellAndLabel label={"Remaining"} data={payments.remainingPayments} />
                <CellAndLabel label={"Balance"} data={formatAsCents(payments.balance - discount)} />
            </Grid>
        </Box>
    );
}

export const ProductDetails = ({ product }: { product: Product }): JSX.Element => {
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);

    const discount =
        bestDiscountedProductCodes.has(product.recurlyCode) && isBestForDentistry ? product.price * BEST_DISCOUNT : 0;

    return (
        <FlexRowAlignCenter justifyContent={"space-between"} px={"13px"} py={"6px"}>
            <Box>
                <Body14 color={"nauticBlue"}>{product.name}</Body14>
                <Box pb={"1px"} />
                <Label11 color={"balticBlue"}>{product.description}</Label11>
            </Box>
            <FlexCol alignItems={"flex-end"}>
                <Body14>{formatAsCents(product.price - discount)}</Body14>
                <Box pb={"1px"} />
                {product.subPrice ? <Label11> {product.subPrice} </Label11> : null}
            </FlexCol>
        </FlexRowAlignCenter>
    );
};

export const PastProductDetails = ({ product }: { product: Product }): JSX.Element => (
    <FlexRowAlignCenter justifyContent={"space-between"} px={"13px"} py={"6px"}>
        <Box>
            <Body14 color={"nauticBlue"}>{product.name}</Body14>
            <Box pb={1} />
            <Label color={"slateGray"}>
                <Span fontSize={4} color={"agateGray"}>
                    {formatAsDollars(product.price)}
                </Span>
                &nbsp;&nbsp;Original Purchase Price
            </Label>
        </Box>
        <FlexCol alignItems={"flex-end"}>
            <Body12 color={"nauticBlue"}>
                {product.type === "training" ? (
                    product.trainingLevel.label
                ) : product.type === "print" ? (
                    <>
                        <Span fontSize={"10px"} color={"balticBlue"}>
                            Quantity
                        </Span>
                        &nbsp;&nbsp;
                        {product.quantity.label}
                    </>
                ) : null}
            </Body12>
            <Box pb={2} />
            <Label color={"slateGray"}>{product.type === "print" ? product.paperType + " Paper" : null}</Label>
        </FlexCol>
    </FlexRowAlignCenter>
);

const CellAndLabel = ({ label, data }: { label: string; data: string | number }) => (
    <Box>
        <FlexColAlignCenter>
            <Label textAlign={"center"} color={"balticBlue"}>
                {label}
            </Label>
        </FlexColAlignCenter>
        <Cell data={data} />
    </Box>
);

const Cell = ({ data }: { data: string | number }) => (
    <FlexRowAlignCenter width={"100%"} backgroundColor={"porcelain"} justifyContent={"center"} height={"22px"}>
        <Body12 color={"balticBlue"}>{data}</Body12>{" "}
    </FlexRowAlignCenter>
);
