import React from "react";
import { Grid } from "../styledSystemUtilities";
import { useWindowSize } from "react-use";
import theme from "../../theme";

export const ReportingDropdownContent = ({
    children,
    testId,
}: {
    children: React.ReactNode;
    testId?: string;
}): JSX.Element => {
    const { width } = useWindowSize();
    return (
        <Grid
            gridColumn={2}
            gridTemplateColumns={width > theme.breakpoints[0] ? "1fr 1fr" : "1fr"}
            gridColumnGap={5}
            data-testid={testId}
            pb={12}
        >
            {children}
        </Grid>
    );
};
