import { Box, Grid, YSpacer } from "../../styledSystemUtilities";
import { Body12, Body12Medium, Body14Bold, Header, Label } from "../../Typography";
import { GrowthSlider } from "../../Atoms";
import { DataLabelAndCell } from "../../Molecules";
import { CalendarIcon } from "../../Atoms/assets";
import { PPCDataPath } from "../PPCDataPath";
import { SmallIconButton } from "../../Atoms/Buttons";
import React, { useState } from "react";
import { formatAsDollars } from "../../../utils/formatter";
import _ from "lodash/fp";
import { GoogleAdsCampaignReport } from "../../../@types/reports/Growth";
import constants from "../../../constants";
import { RecurlyGrowthLoadingModal } from "../../Molecules/RecurlyGrowthLoadingModal";
import { useAppSelector } from "../../../redux/hooks";

export function AdsCampaignManager({
    googleAdsCampaignReport,
}: {
    googleAdsCampaignReport: GoogleAdsCampaignReport;
}): JSX.Element {
    const [newGoal, setNewGoal] = useState(googleAdsCampaignReport.newPatientGoal);
    const locationId = useAppSelector((state) => state.openPractice.id);
    const calendlyLink = useAppSelector((state) => state.clientAccount.strategist.calendlyLink);

    const safeAverageNewPatients = googleAdsCampaignReport.averages.averageCallConversion
        ? googleAdsCampaignReport.averages.averageNewPatients
        : googleAdsCampaignReport.averages.averageCalls * 0.1;

    const newGoogleAdsBudget = _.round(
        (googleAdsCampaignReport.averages.averageAdSpend / safeAverageNewPatients) * newGoal
    );

    const newSpend = _.round(
        newGoogleAdsBudget +
            constants.ascentGoogleAds.ASCENT_MANAGEMENT_FEE +
            constants.ascentGoogleAds.CALL_TRACKING_FEE
    );

    return (
        <>
            <RecurlyGrowthLoadingModal
                practiceId={locationId}
                recurlyCampaignId={googleAdsCampaignReport.recurlyCampaignId}
                newGoal={newGoal}
                currentPayment={googleAdsCampaignReport.currentRecurlySpend}
                newPayment={newSpend}
            />
            <Grid
                gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
                justifyItems={"center"}
                data-testid={"manageCampaign"}
            >
                <Box backgroundColor={"aquaBlue"} pt={8} gridColumn={2} width={"100%"}>
                    <Box px={8}>
                        <Header color={"petrolBlue"}>Manage Campaign</Header>
                        <YSpacer spaceY={6} />
                        <Body14Bold color={"deepPetrol"}>Adjust your average new patient goal:</Body14Bold>
                        <Body12 color={"deepPetrol"} lineHeight={"12px"}>
                            Projections are based on this campaign's monthly averages.
                        </Body12>
                        <YSpacer spaceY={5} />
                        <Box pl={6} pr={7}>
                            <GrowthSlider
                                setNewGoal={setNewGoal}
                                defaultValue={googleAdsCampaignReport.newPatientGoal}
                            />
                        </Box>
                        <YSpacer spaceY={6} />
                        <Body14Bold color={"deepPetrol"}>New Monthly</Body14Bold>
                        <YSpacer spaceY={4} />
                        <DataLabelAndCell
                            label={"New patients average:"}
                            data={newGoal}
                            color={"petrolBlue"}
                            dataColor={"sienna"}
                            cellColor={"white"}
                            testId={"newPatientGoal"}
                        />
                        <YSpacer spaceY={5} />
                        <DataLabelAndCell
                            label={"New Google Ads Spend:"}
                            data={formatAsDollars(newGoogleAdsBudget)}
                            color={"petrolBlue"}
                            dataColor={"white"}
                            cellColor={"ipanemaBlue"}
                            testId={"newGoogleAdsSpend"}
                        />
                        <YSpacer spaceY={3} />
                        <Label color={"petrolBlue"}>
                            + Ascent's fixed management (${constants.ascentGoogleAds.ASCENT_MANAGEMENT_FEE}) & call
                            tracking (${constants.ascentGoogleAds.CALL_TRACKING_FEE}) fees:
                        </Label>
                        <YSpacer spaceY={3} />
                        <DataLabelAndCell
                            label={"New Total Cost:"}
                            data={formatAsDollars(newSpend)}
                            color={"petrolBlue"}
                            dataColor={"deepPetrol"}
                            cellColor={"edgeWater"}
                            testId={"newTotalCost"}
                        />
                        <YSpacer spaceY={8} />
                    </Box>
                    <Box pt={6}>
                        <Box pl={8}>
                            <Body14Bold color={"deepPetrol"}>Detailed Data Path for Campaign Adjustments</Body14Bold>
                        </Box>
                        <YSpacer spaceY={5} />
                        <PPCDataPath campaignReport={googleAdsCampaignReport} newGoal={newGoal} />
                    </Box>
                    <Box px={8}>
                        <YSpacer spaceY={7} />
                        <Body12Medium color={"deepPetrol"}>
                            If you have questions about current subscriptions, strategy, recommendations, or to cancel
                            any subscriptions, schedule a call with your Ascent Marketing Strategist.
                        </Body12Medium>
                        <YSpacer spaceY={6} />
                        <SmallIconButton
                            backgroundColor={"ipanemaBlue"}
                            onClick={() => window.open(calendlyLink, "_blank")}
                            Icon={CalendarIcon}
                            color={"white"}
                        >
                            schedule call
                        </SmallIconButton>
                    </Box>
                </Box>
            </Grid>
        </>
    );
}
