import { Color } from "../../@types/color";
import { FlexRow } from "../styledSystemUtilities";
import { SmallButton } from "../Atoms";
import React from "react";

export const LocationOverviewLayerButton = ({
    backgroundColor,
    buttonColor,
    onClick,
}: {
    backgroundColor: Color;
    buttonColor: Color;
    onClick: () => void;
}): JSX.Element => {
    return (
        <FlexRow alignItems={"flex-end"} backgroundColor={backgroundColor} width={"100%"} height={"38px"}>
            <SmallButton backgroundColor={buttonColor} onClick={onClick}>
                manage / details
            </SmallButton>
        </FlexRow>
    );
};
