import React, { useEffect } from "react";
import { actions, slices } from "../../redux";
import { useFieldArray, useForm } from "react-hook-form";
import { Box, FlexRow, Grid } from "../styledSystemUtilities";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RawKpiMonth, UpdateMonth } from "../../@types/reports/Profit";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, array } from "yup";
import utils from "../../utils";
import { Body14 } from "../Typography";
import { SmallTextInput } from "../Atoms/Inputs";
import { SmallButton } from "../Atoms";

export const KpiDataEntryForm = ({ setClosed }: { setClosed: () => void }): JSX.Element => {
    const dispatch = useAppDispatch();
    const kpiMonths = useAppSelector((state) => state.kpiMonths);
    const locationId = useAppSelector((state) => state.openPractice.id);

    useEffect(() => {
        dispatch(slices.kpiMonths.actions.unset());
        dispatch(actions.getAllKpiMonths(locationId));
    }, [dispatch, locationId]);

    const superviseSubmit = (updates: UpdateMonth[], shouldUpdate: boolean) => {
        if (shouldUpdate) {
            dispatch(actions.updateKpiMonths(updates, locationId));
        }
        setClosed();
    };

    return (
        <Box>{kpiMonths.length > 0 ? <Form initialState={kpiMonths} superviseSubmit={superviseSubmit} /> : null}</Box>
    );
};

export const kpiDataEntrySchema = yupResolver(
    object().shape({
        root: array().of(
            object().shape({
                newPatients: number(),
                referrals: number(),
                revenue: number(),
                visits: number(),
            })
        ),
    })
);

const Form = ({
    initialState,
    superviseSubmit,
}: {
    initialState: RawKpiMonth[];
    superviseSubmit: (updates: UpdateMonth[], shouldUpdate: boolean) => void;
}) => {
    const { register, control, handleSubmit, formState } = useForm({
        defaultValues: {
            root: initialState,
        },
        resolver: kpiDataEntrySchema,
    });
    const { fields } = useFieldArray({
        control,
        name: "root",
        keyName: "rhfId",
    });

    const { dirtyFields, isDirty } = formState;

    const filterUntouchedFields = (arrayOfMonthsOfData: UpdateMonth[]) => {
        if (!isDirty) {
            return superviseSubmit([], false);
        }

        const filtered = arrayOfMonthsOfData.filter((value, index) => {
            return dirtyFields.root[index];
        }, arrayOfMonthsOfData);

        superviseSubmit(filtered, true);
    };

    return (
        <Box px={8} pt={"80px"} pb={10} data-testid={"kpiDataEntry"}>
            <form onSubmit={handleSubmit((data) => filterUntouchedFields(data.root))}>
                <Grid gridTemplateColumns={"80px 1fr 1fr 1fr 1fr"} alignItems={"center"}>
                    {fields.map((field, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Box pr={6} justifySelf={"end"}>
                                    <Body14>
                                        {utils.getMonthAbbreviation(field.period.month) + " " + field.period.year}
                                    </Body14>
                                </Box>
                                <SmallTextInput
                                    px={2}
                                    defaultValue={field.revenue}
                                    type={"number"}
                                    step={"any"}
                                    name={`root[${index}].revenue`}
                                    ref={register()}
                                />
                                <SmallTextInput
                                    px={2}
                                    defaultValue={field.newPatients}
                                    type={"number"}
                                    step={"any"}
                                    name={`root[${index}].newPatients`}
                                    ref={register()}
                                />
                                <SmallTextInput
                                    px={2}
                                    defaultValue={field.visits}
                                    type={"number"}
                                    step={"any"}
                                    name={`root[${index}].visits`}
                                    ref={register()}
                                />

                                <SmallTextInput
                                    px={2}
                                    defaultValue={field.referrals}
                                    type={"number"}
                                    step={"any"}
                                    name={`root[${index}].referrals`}
                                    ref={register()}
                                />
                                <input
                                    defaultValue={field.id}
                                    type={"hidden"}
                                    name={`root[${index}].id`}
                                    ref={register()}
                                />
                            </React.Fragment>
                        );
                    })}
                </Grid>
                <FlexRow justifyContent={"flex-end"} mt={7}>
                    <SmallButton backgroundColor={"balticBlue"} onClick={() => null}>
                        save data
                    </SmallButton>
                </FlexRow>
            </form>
        </Box>
    );
};
