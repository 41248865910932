import { Color } from "../../@types/color";
import React, { useState } from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Label11Medium } from "../Typography";
import { ArrowDown, ArrowRight } from "../Atoms/assets";

export const LocationTrendsContainer = ({
    color,
    name,
    backgroundColor,
    children,
    initialIsOpenState = true,
}: {
    color: Color;
    name: string;
    backgroundColor: Color;
    children: React.ReactNode;
    initialIsOpenState?: boolean;
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(initialIsOpenState);

    return (
        <FlexRowAlignCenter justifyContent={"center"} width={"100%"}>
            <Box pt={3} pb={3} backgroundColor={backgroundColor} width={"254px"} mr={6}>
                <FlexRowAlignCenter
                    ml={6}
                    cursor={"pointer"}
                    width={"166px"}
                    justifyContent={"space-between"}
                    onClick={() => setIsOpen((prevState) => !prevState)}
                    data-testid={"dropdownHeader"}
                >
                    <Label11Medium color={color} uppercase>
                        {name}
                    </Label11Medium>
                    {isOpen ? (
                        <ArrowDown fill={color} data-testid={"arrowDown"} />
                    ) : (
                        <ArrowRight fill={color} data-testid={"arrowRight"} />
                    )}
                </FlexRowAlignCenter>
                {isOpen ? children : null}
            </Box>
        </FlexRowAlignCenter>
    );
};
