import React from "react";
import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { LoadingWheel } from "../Atoms";
import { Color } from "../../@types/color";

export function LoadingWrapper({
    children,
    color,
    isLoading,
}: {
    children: React.ReactNode;
    color: Color;
    isLoading: boolean;
}): JSX.Element {
    return (
        <>
            {isLoading ? (
                <FlexRowAlignCenter justifyContent={"center"} width={"100%"} pt={10}>
                    <LoadingWheel color={color} />
                </FlexRowAlignCenter>
            ) : (
                children
            )}
        </>
    );
}
