import React from "react";
import { Box, FlexRow, FlexRowAlignCenter } from "../styledSystemUtilities";
import { Body, Body14Bold } from "../Typography";

export function NumberedText({ number, children }: { number: number; children: React.ReactNode }): JSX.Element {
    return (
        <FlexRow pb={7}>
            <FlexRowAlignCenter
                justifyContent={"center"}
                border={"graphiteGray"}
                borderRadius={"50%"}
                width={"22px"}
                height={"22px"}
            >
                <Body14Bold color={"graphiteGray"}>{number}</Body14Bold>
            </FlexRowAlignCenter>
            <Box pl={3} pt={1} flexShrink={100}>
                <Body color={"graphiteGray"}>{children}</Body>
            </Box>
        </FlexRow>
    );
}
