import { Box, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body12, Body12Bold, Body14Medium, Header, Header27 } from "../../Typography";
import { CallTrackingMonth, CallTrackingYear } from "../../../@types/reports/Profit";
import { ArrowDown, ArrowRight } from "../../Atoms/assets";
import { formatAsDollars } from "../../../utils/formatter";
import { format, isSameMonth } from "date-fns/fp";
import React, { useState } from "react";
import _ from "lodash/fp";

export function DynamicCallTrackingTable({ report }: { report: CallTrackingYear[] }): JSX.Element {
    const currentYear = report[0];
    const lastYear = report[1];
    const now = new Date();
    const monthsBeforeLastTwo = currentYear.months.slice(2);

    return (
        <Box gridColumn={"1 / -1"} mt={8} pt={8} backgroundColor={"chalk"}>
            <Box px={9}>
                <Header27 color={"balticBlue"}>Your new patients linked to dynamic call tracking:</Header27>
                <YSpacer spaceY={4} />
            </Box>
            <YearHeader year={currentYear}>
                <Grid gridTemplateColumns={"1fr 1fr"}>
                    {currentYear.months[0] ? (
                        <Box pb={5}>
                            <Box pl={"22px"}>
                                <Body14Medium color={"nauticBlue"}>
                                    {isSameMonth(currentYear.months[0].date, now)
                                        ? "Current Month-to-date"
                                        : format("MMMM", currentYear.months[0].date)}
                                </Body14Medium>
                            </Box>

                            <Table month={currentYear.months[0]} />
                        </Box>
                    ) : null}
                    {currentYear.months[1] ? (
                        <Box pb={5}>
                            <Box pl={"22px"}>
                                <Body14Medium color={"nauticBlue"}>
                                    {format("MMMM", currentYear.months[1].date)}
                                </Body14Medium>
                            </Box>

                            <Table month={currentYear.months[1]} />
                        </Box>
                    ) : null}
                </Grid>
                {monthsBeforeLastTwo.length > 0 ? (
                    <>
                        <Box width={"100%"} backgroundColor={"pearlGray"} height={"1px"} />
                        <YSpacer spaceY={4} />
                        {monthsBeforeLastTwo.map((month, index) => (
                            <Box key={index}>
                                <DropdownTable month={month} />
                            </Box>
                        ))}
                    </>
                ) : null}
            </YearHeader>

            {lastYear ? (
                <>
                    <YSpacer spaceY={9} />
                    <YearHeader year={lastYear}>
                        <Box width={"100%"} backgroundColor={"agateGray"} height={"1px"} />
                        {lastYear.months.map((month, index) => (
                            <Box key={index} pt={6}>
                                <DropdownTable month={month} />
                            </Box>
                        ))}
                    </YearHeader>
                </>
            ) : null}
            <YSpacer spaceY={7} />
        </Box>
    );
}

function YearHeader({ year, children }: { year: CallTrackingYear; children: React.ReactNode }): JSX.Element {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <>
            <Box px={9}>
                <FlexRowAlignCenter>
                    <FlexRowAlignCenter cursor={"pointer"} onClick={() => setIsOpen((prevState) => !prevState)}>
                        <Box width={"50px"}>
                            <Header color={"balticBlue"}>{year.yearName}</Header>
                        </Box>

                        <XSpacer spaceX={4} />
                        {isOpen ? <ArrowDown fill={"balticBlue"} /> : <ArrowRight fill={"balticBlue"} />}
                    </FlexRowAlignCenter>
                    <XSpacer spaceX={9} />
                    <Body12>Total Revenue</Body12>
                    <XSpacer spaceX={3} />
                    <FlexRowAlignCenter
                        justifyContent={"center"}
                        height={8}
                        width={"90px"}
                        backgroundColor={"white"}
                        border={"gray"}
                    >
                        <Body12 color={"balticBlue"}>{formatAsDollars(_.round(year.totalRevenue))}</Body12>
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
                <YSpacer spaceY={3} />
            </Box>
            {isOpen ? children : null}
        </>
    );
}

function DropdownTable({ month }: { month: CallTrackingMonth }): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Box>
            <FlexRowAlignCenter cursor={"pointer"} ml={"22px"} onClick={() => setIsOpen((prevState) => !prevState)}>
                <Body14Medium color={"nauticBlue"}>{format("MMMM", month.date)}</Body14Medium>
                <XSpacer spaceX={5} />
                {isOpen ? <ArrowDown fill={"balticBlue"} /> : <ArrowRight fill={"balticBlue"} />}
            </FlexRowAlignCenter>

            {isOpen ? <Table month={month} /> : null}
        </Box>
    );
}

function Table({ month }: { month: CallTrackingMonth }): JSX.Element {
    return (
        <Box pt={4}>
            <Box pl={6} pb={1} borderBottom={"dark"}>
                <TableRowTemplate>
                    <Box pl={5}>
                        <Body12>Patient Name</Body12>
                    </Box>
                    <Box pl={5}>
                        <Body12>Source</Body12>
                    </Box>
                    <Box justifySelf={"center"}>
                        <Body12 color={"balticBlue"}>Revenue</Body12>
                    </Box>
                </TableRowTemplate>
            </Box>
            {month.patients.map((patient, index) => (
                <Box key={index}>
                    <TableRow
                        name={patient.name}
                        source={patient.source}
                        revenue={patient.revenue}
                        displayBorder={month.patients.length - 1 !== index}
                    />
                </Box>
            ))}
            <TableFooter type={"Average"} dollars={month.averageRevenue} />
            <TableFooter type={"Total"} dollars={month.totalRevenue} />
        </Box>
    );
}

function TableRowTemplate({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <Grid gridColumnGap={6} gridTemplateColumns={"125px 80px 90px"}>
            {children}
        </Grid>
    );
}

function TableFooter({ type, dollars }: { type: string; dollars: number }): JSX.Element {
    return (
        <Box pl={6}>
            <TableRowTemplate>
                <Box gridColumn={"1 / 3"} borderTop={"dark"} pl={5} pt={2}>
                    <Body12Bold>{type}</Body12Bold>
                </Box>
                <FlexRowAlignCenter
                    justifyContent={"center"}
                    height={8}
                    border={"gray"}
                    mt={2}
                    backgroundColor={"white"}
                >
                    <Body12 color={"balticBlue"}>{formatAsDollars(_.round(dollars))}</Body12>
                </FlexRowAlignCenter>
            </TableRowTemplate>
        </Box>
    );
}

function TableRow({
    name,
    source,
    revenue,
    displayBorder,
}: {
    name: string;
    source: string;
    revenue: number;
    displayBorder: boolean;
}) {
    const border = displayBorder ? "gray" : "none";
    return (
        <Box pl={6} pt={2}>
            <TableRowTemplate>
                <Box borderBottom={border} pl={5}>
                    <Body12>{name}</Body12>
                </Box>
                <Box borderBottom={border} pl={5}>
                    <Body12>{source}</Body12>
                </Box>
                <FlexRowAlignCenter justifyContent={"center"} height={8} border={"gray"} backgroundColor={"white"}>
                    <Body12 color={"balticBlue"}>{formatAsDollars(_.round(revenue))}</Body12>
                </FlexRowAlignCenter>
            </TableRowTemplate>
        </Box>
    );
}
