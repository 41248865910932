import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { Body14 } from "../Typography";
import { formatAsCents } from "../../utils/formatter";
import React from "react";

export const CurrentSubscriptionRow = ({ name, price }: { name: string; price: number }): JSX.Element => {
    return (
        <FlexRowAlignCenter justifyContent={"space-between"} height={12} pl={5} pr={7} backgroundColor={"white"}>
            <Body14 color={"auratiumGreen"}>{name}</Body14>
            <Body14 color={"agateGray"}>{formatAsCents(price)}</Body14>
        </FlexRowAlignCenter>
    );
};
