import login from "./login";
import loginWithToken from "./loginWithToken";
import logout from "../../utils/logout";
import getClientAccount from "./getClientAccount";
import getAccountPractices from "./getAccountPractices";
import getLocationOverviewReports from "./getLocationOverviewReports";
import getOnlineReport from "./getOnlineReport";
import getGrowthReport from "./getGrowthReport";
import getProfitReport from "./getProfitReport";
import addOnlineProducts from "./addOnlineProducts";
import getAllPractices from "./getAllPractices";
import refreshCampaign from "./refreshCampaign";
import updateCampaignSpend from "./updateCampaignSpend";
import resetPassword from "./resetPassword";
import setNewPassword from "./setNewPassword";
import getAllKpiMonths from "./getAllKpiMonths";
import updateKpiMonths from "./updateKpiMonths";
import getProfitCenters from "./getProfitCenters";
import makeProfitPurchase from "./makeProfitPurchase";
import getActiveCoupons from "./getActiveCoupons";
import getForm from "./getForm";
import saveForm from "./saveForm";
import getWebsiteFiles from "./getWebsiteFiles";
import uploadFiles from "./uploadFiles";
import deleteFile from "./deleteFile";
import getSeoSummaryReport from "./getSeoSummaryReport";

const actions = {
    login,
    loginWithToken,
    logout,
    getClientAccount,
    getAccountPractices,
    getLocationOverviewReports,
    getOnlineReport,
    getGrowthReport,
    getProfitReport,
    addOnlineProducts,
    getAllPractices,
    refreshCampaign,
    updateCampaignSpend,
    resetPassword,
    setNewPassword,
    getAllKpiMonths,
    updateKpiMonths,
    getProfitCenters,
    makeProfitPurchase,
    getActiveCoupons,
    getForm,
    saveForm,
    getWebsiteFiles,
    uploadFiles,
    deleteFile,
    getSeoSummaryReport,
};

export default actions;
