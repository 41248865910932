import React from "react";
import { Box, FlexRowAlignCenter, YSpacer } from "../../styledSystemUtilities";
import {
    CameraIcon,
    PopupCalendarIcon,
    MySocialPracticeLogo,
    X,
    GreenDesktopComputer,
    GreenHandshake,
} from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { WhiteBackground } from "../../Molecules/PopupComponents";
import { Body, Body14, Header27, Header30 } from "../../Typography";

export const MySocialPracticeSelf = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <Box backgroundColor={"seaGreen"} height={"31px"} pl={"23px"} display={"flex"} alignItems={"center"}>
                <Box onClick={setPopup} cursor={"pointer"} display={"flex"} alignItems={"center"}>
                    <X />
                    <Box ml={5} />
                    <Body14 color={"white"}>Close</Body14>
                </Box>
            </Box>
            <Box py={7} backgroundColor={"backgroundGreen"} pl={"21px"}>
                <Header27 color={"auratiumGreen"}>Social Media</Header27>
                <Box pt={7} />
                <Header30 color={"white"}>
                    Engage, Interact
                    <br />
                    and Educate
                </Header30>
            </Box>
            <FlexRowAlignCenter justifyContent={"center"} backgroundColor={"white"} height={"123px"}>
                <MySocialPracticeLogo />
            </FlexRowAlignCenter>
            <Box backgroundColor={"chalk"} height={10} />
            <GrayBackground>
                <SellingPoint
                    header={"Grow your practice with the #1 social media marketing service in dentistry."}
                    body={
                        "Our social media marketing service is a first-of-its-kind service that started our business over 12 years ago! Today, we continue to provide the most creative and effective solution in the dental industry."
                    }
                />
                <IconContainer name={"What's Included"} />
                <Body fontWeight={"bold"} uppercase>
                    self-managed plan
                </Body>
                <YSpacer spaceY={6} />
                <IconBulletPoint
                    Icon={PopupCalendarIcon}
                    header={"Monthly Post Ideas"}
                    body={
                        "Receive photo and video ideas and instructions each month. We’ll even provide you with ideas for contests and giveaways."
                    }
                />
                <IconBulletPoint
                    Icon={CameraIcon}
                    header={"HIPAA-Compliant Patient Photo Apps"}
                    body={
                        "Apps for your Ipad and mobile devices to make patient photography a breeze. We even provide digital HIPAA authorization to ensure it’s all secure. "
                    }
                />
                <IconBulletPoint
                    Icon={GreenDesktopComputer}
                    header={"Access to Our Member Site"}
                    body={
                        "Access to our entire library of dental graphics, gifs, videos, content for educational blogs, additional trending social media posting ideas. Bascially everything you need to be a social media success."
                    }
                />
                <IconBulletPoint
                    Icon={GreenHandshake}
                    header={"Support"}
                    body={"Always here to answer questions and provide peace of mind."}
                />
            </GrayBackground>
            <WhiteBackground>
                <Body color={"forrestGreen"} lineHeight={"21px"}>
                    “My Social Practice is simply the BEST way to get ALL of your social media needs met.”
                </Body>
                <Body14 fontWeight={"bold"} textAlign={"end"}>
                    — Ash & Roberts DDS
                </Body14>
                <Box pb={9} />
                <Body color={"forrestGreen"} lineHeight={"21px"}>
                    “We love working with My Social Practice! They have taken the time consuming part of social media
                    away from us and done a wonderful job at keeping us connected with our patients. What a great
                    value!”
                </Body>
                <Body14 fontWeight={"bold"} textAlign={"end"}>
                    — Becky Sprague
                </Body14>
            </WhiteBackground>
            <GrayBackground>
                <Box pb={9} />
                <SellingPoint
                    header={"About My Social Practice"}
                    body={
                        "The team at My Social Practice is thoughtfully composed of professional social media marketers who have a strong work ethic and embody our company core values. With our office in Salt Lake City, Utah and remote employees both in the country and across the globe, our collaborative, dynamic culture allows our teams to optimize productivity. Over the past 12 years, we’ve maintained a progressive outlook, always identifying social media marketing trends to set ourselves apart from the competition."
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};
