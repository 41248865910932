import { LocalSeoReport } from "../@types/reports/Online";
import {
    KeywordSetOption,
    practiceTypeOptions,
    SearchAreaOption,
    searchAreaOptions,
} from "../constants/seoSearchParamsOptions";

export function getSeoSearchParamsOptionFromReport(
    report: LocalSeoReport
): { keywordSetOption: KeywordSetOption; searchAreaOption: SearchAreaOption } {
    const keywordSetOption =
        practiceTypeOptions.find((option) => option.value === report.keywordSetLabel) ?? practiceTypeOptions[0];
    const searchAreaOption =
        searchAreaOptions.find((option) => option.value === report.searchAreaType) ?? searchAreaOptions[0];

    return {
        keywordSetOption,
        searchAreaOption,
    };
}
