import { createSlice } from "@reduxjs/toolkit";
import { LocationOverviewReport } from "../../@types/components/LocationOverviewReport";
import { MenuTableData } from "../../@types/components/MenuTable";

const initialRowState = {
    average: 0,
    sinceActive: 0,
    lastMonth: 0,
};

const initialTableState: MenuTableData = {
    roiTotals: initialRowState,
    revenueGenerated: initialRowState,
    newPatients: initialRowState,
};

const initialState: LocationOverviewReport = {
    roiTable: initialTableState,
    locationReports: [],
};

export default createSlice({
    name: "locationOverviewReports",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
        unset: () => initialState
    },
});
