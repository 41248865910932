import React from "react";
import { Control, Controller } from "react-hook-form";
import { Box, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../styledSystemUtilities";
import { Body, Body12 } from "../Typography";
import { Checkbox } from "../Atoms";
import _ from "lodash/fp";

export function OptionChecklist({
    options,
    name,
    label,
    control,
}: {
    options: string[];
    name: string;
    label: string;
    control: Control;
}): JSX.Element {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={name}
            render={({ value: selectedOptions, onChange }) => (
                <Box pb={7} data-testid={name}>
                    <Body color={"graphiteGray"}>{label}</Body>
                    <YSpacer spaceY={6} />
                    <Grid gridTemplateColumns={"1fr 1fr"} gridRowGap={4} justifyItems={"left"}>
                        {options.map((option) => {
                            const isSelected = selectedOptions.includes(option);

                            return (
                                <Box key={option}>
                                    <Option
                                        onClick={() => {
                                            onChange(
                                                isSelected
                                                    ? _.reject(_.isEqual(option), selectedOptions)
                                                    : selectedOptions.concat(option)
                                            );
                                        }}
                                        isChecked={isSelected}
                                        option={option}
                                    />
                                </Box>
                            );
                        })}
                    </Grid>
                </Box>
            )}
        />
    );
}

function Option({
    onClick,
    isChecked,
    option,
}: {
    onClick: () => void;
    isChecked: boolean;
    option: string;
}): JSX.Element {
    return (
        <FlexRowAlignCenter onClick={onClick} cursor={"pointer"}>
            <Checkbox isChecked={isChecked} fill={"sienna"} />
            <XSpacer spaceX={6} />
            <Body12>{option}</Body12>
        </FlexRowAlignCenter>
    );
}
