import { StandardProductCard } from "../Atoms/StandardProductCard";
import { PrintProductCard } from "../Atoms/PrintProductCard";
import { TrainingProductCard } from "./TrainingProductCard";
import { Product } from "../../@types/reports/ProfitCenters";

export function ProductCard(product: Product, isPastProduct = false): JSX.Element {
    switch (product.type) {
        case "standard":
            return <StandardProductCard product={product} isPastProduct={isPastProduct} />;
        case "print":
            return <PrintProductCard product={product} isPastProduct={isPastProduct} />;
        case "training":
            return <TrainingProductCard product={product} isPastProduct={isPastProduct} />;
    }
}
