import jwtDecode from "jwt-decode";
import { routerHistory } from "../../routerHistory";
import setAuthToken from "../../utils/setAuthToken";
import slices from "../slices";
import { RootState } from "../store";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { ClientUserFrontEnd, Event } from "../../@types/shared";
import addEvent from "./addEvent";
import theme from "../../theme";

const setJwtToken = (token: string) => localStorage.setItem("jwtToken", token);

const loginWithToken = (token: string): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch) => {
    setJwtToken(token);
    setAuthToken(token);
    const decoded: ClientUserFrontEnd = jwtDecode(token);

    if (decoded.clientAccountId) {
        const loginEvent: Event = {
            accountId: decoded.clientAccountId,
            userId: decoded.id,
            userType: decoded.type,
            timestamp: new Date(),
            eventType: "login",
            deviceType: window.innerWidth < theme.breakpoints[0] ? "MOBILE" : "DESKTOP",
        };

        dispatch(addEvent(loginEvent));
    }

    dispatch(slices.currentUser.actions.set(decoded));
    if (
        window.location.pathname.includes("/reset-password") ||
        window.location.pathname.includes("/new-password") ||
        window.location.pathname.includes("/google-my-business-redirect")
    ) {
        // do nothing
    } else {
        routerHistory.push("/dash");
    }
};

export default loginWithToken;
