export const paymentOptions = [
    {
        value: 12,
        label: "12 payments*",
        disclaimer: "*12 months financing includes 12% security fee.",
        fee: 1.12,
        planCode: "12mos",
    },
    {
        value: 6,
        label: "6 payments*",
        disclaimer: "*6 months financing includes 6% security fee.",
        fee: 1.06,
        planCode: "6mos",
    },
    {
        value: 3,
        label: "3 payments*",
        disclaimer: "*3 months financing includes 3% security fee.",
        fee: 1.03,
        planCode: "3mos",
    },
    {
        value: 1,
        label: "1 payment",
        disclaimer: "",
        fee: 1,
        planCode: "1mos",
    },
];
