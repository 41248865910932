import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash/fp";
import { ServerErrors } from "../../@types/serverErrors";

type ServerError = {
    type?: string;
    message?: string;
};

type ServerErrorsState = {
    login: ServerError;
    register: ServerError;
    resetPassword: ServerError;
    setNewPassword: ServerError;
};

const initialState: ServerErrorsState = {
    login: {},
    register: {},
    resetPassword: {},
    setNewPassword: {},
};

export default createSlice({
    name: "serverLoading",
    initialState: initialState,
    reducers: {
        set: {
            reducer: (state, action: PayloadAction<{ label: ServerErrors; error: string }>) =>
                _.set(action.payload.label, action.payload.error, state),
            prepare: (label: ServerErrors, error: string) => ({ payload: { label, error } }),
        },

        unset: {
            reducer: (state, action: PayloadAction<{ label: ServerErrors }>) => _.set(action.payload.label, {}, state),
            prepare: (label: ServerErrors) => ({ payload: { label } }),
        },
    },
});
