import { createSlice } from "@reduxjs/toolkit";
import { Id, PracticeBackend } from "../../@types/shared";

const initialState: (PracticeBackend & { clientAccountId: Id })[] = [];

export default createSlice({
    name: "clientPractices",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
    },
});
