import React from "react";
import { Grid } from "../styledSystemUtilities";
import { useWindowSize } from "react-use";
import { useTheme } from "styled-components";

export const PageBanner = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const { width } = useWindowSize();
    const theme = useTheme();
    return (
        <Grid
            data-testid={"pageBanner"}
            gridTemplateColumns={width > theme.breakpoints[0] ? "1fr 1fr" : "1fr"}
            backgroundColor={"rgba(255, 255, 255, .8)"}
        >
            {children}
        </Grid>
    );
};
