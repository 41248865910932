import React from "react";
import ReactModal from "react-modal";
import { Box, FlexRow, Grid, YSpacer } from "../styledSystemUtilities";
import "./modal.css";
import { Body12, Body14, Header22 } from "../Typography";
import { useWindowSize } from "react-use";

ReactModal.defaultStyles = {};

export const Modal = ({
    title,
    children,
    isOpen,
    setIsOpen,
    ...props
}: {
    title: string;
    children: React.ReactNode;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    const { width } = useWindowSize();
    return (
        <ReactModal ariaHideApp={false} isOpen={isOpen} {...props}>
            <Box
                pt={8}
                px={8}
                position={"fixed"}
                backgroundColor={"white"}
                width={`${width > 768 ? 768 : width}px`}
                height={"80px"}
            >
                <FlexRow justifyContent={"space-between"}>
                    <Header22>{title}</Header22>
                    <Box cursor={"pointer"} onClick={() => setIsOpen(false)}>
                        <Body12>Close</Body12>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={5} />

                <Grid gridTemplateColumns={"80px 1fr 1fr 1fr 1fr"}>
                    <Box />
                    <Box>
                        <Body14>Revenue</Body14>
                    </Box>
                    <Box>
                        <Body14>New Patients</Body14>
                    </Box>
                    <Box>
                        <Body14>Visits</Body14>
                    </Box>
                    <Box>
                        <Body14>Referrals</Body14>
                    </Box>
                </Grid>
            </Box>
            {children}
            {/*<Box mb={20} />*/}
        </ReactModal>
    );
};
