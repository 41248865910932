import React from "react";
import { Box } from "../styledSystemUtilities";
import { BigCheck, Check } from "./assets";
import { Color } from "../../@types/color";

type CheckboxProps = {
    isChecked: boolean;
    fill: Color;
    border?: string;
    backgroundColor?: Color;
};

export const Checkbox = ({ isChecked, fill, border, backgroundColor }: CheckboxProps): JSX.Element => {
    return (
        <Box
            width={8}
            height={8}
            pt={"1px"}
            pl={"1px"}
            border={border ? border : "gray"}
            cursor={"pointer"}
            data-testid={"checkBox"}
            backgroundColor={backgroundColor ? backgroundColor : "transparent"}
        >
            {isChecked ? <Check fill={fill} className={"check"} data-testid={"check"} /> : null}
        </Box>
    );
};

export const BigCheckbox = ({ isChecked, fill }: CheckboxProps): JSX.Element => {
    return (
        <Box
            width={"30px"}
            height={"30px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            border={"dark"}
            cursor={"pointer"}
        >
            {isChecked ? <BigCheck fill={fill} className={"check"} /> : null}
        </Box>
    );
};
