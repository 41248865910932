import React from "react";
import { Box, FlexColAlignCenter, YSpacer } from "../styledSystemUtilities";
import { LoadingWheel, PaymentModalContainer, SmallButton } from "../Atoms";
import { Body14, Body14Medium, BodyMedium, Header27 } from "../Typography";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions, slices } from "../../redux";
import { formatAsDollars } from "../../utils/formatter";
import { FormattedAdditionalProduct } from "../../@types/reports/Online";
import _ from "lodash/fp";

export function RecurlyLoadingModal({
                                        practiceId,
                                        selectedProducts,
                                        setSelectedProducts,
                                        currentPayment,
                                        newPayment,
                                    }: {
    practiceId: string;
    selectedProducts: FormattedAdditionalProduct[];
    setSelectedProducts: (value: ((prevState: string[]) => string[]) | string[]) => void;
    currentPayment: number;
    newPayment: number;
}): JSX.Element | null {
    const dispatch = useAppDispatch();
    const paymentModal = useAppSelector((state) => state.paymentModal);
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);
    const activeCoupons = useAppSelector((state) => state.activeCoupons);

    const bestForDentistryCoupon = activeCoupons.find((coupon) => coupon.code === "best");

    return paymentModal.openModal === "onlineLoading" ? (
        <PaymentModalContainer>
            <FlexColAlignCenter justifyContent={"center"} data-testid={"recurlyLoadingWheel"} height={"100%"}>
                <LoadingWheel color={"auratiumGreen"} />
                <YSpacer spaceY={8} />
                <Body14Medium>Processing Payment...</Body14Medium>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : paymentModal.openModal === "onlinePaymentConfirmation" ? (
        <PaymentModalContainer>
            <Box
                position={"absolute"}
                data-testid={"close"}
                pt={6}
                pl={6}
                cursor={"pointer"}
                onClick={() => dispatch(slices.paymentModal.actions.set({ openModal: null }))}
            >
                <Body14Medium>X</Body14Medium>
            </Box>
            <FlexColAlignCenter justifyContent={"center"} height={"100%"} data-testid={"paymentConfirmation"} px={8}>
                <Header27 color={"graphiteGray"}>Confirmation</Header27>
                <YSpacer spaceY={10} />
                <Body14 color={"graphiteGray"} textAlign={"center"}>
                    This will change your monthly online subscription payment from {formatAsDollars(currentPayment)} to{" "}
                    {formatAsDollars(newPayment)}
                </Body14>
                <YSpacer spaceY={8} />
                <SmallButton
                    backgroundColor={"auratiumGreen"}
                    onClick={() => {
                        dispatch(slices.paymentModal.actions.set({ openModal: "onlineLoading" }));
                        dispatch(
                            actions.addOnlineProducts(
                                practiceId,
                                _.flow(_.map(_.get("recurlyCode")), _.compact)(selectedProducts),
                                isBestForDentistry && !bestForDentistryCoupon ? ["best"] : [],
                                selectedProducts.flatMap((product) => {
                                    console.log(product);
                                    return product.tasks;
                                })
                            )
                        );
                        setSelectedProducts(() => []);
                    }}
                >
                    confirm purchase
                </SmallButton>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : paymentModal.openModal === "onlineThankYou" ? (
        <PaymentModalContainer>
            <FlexColAlignCenter height={"100%"} justifyContent={"center"} data-testid={"thankYou"} px={8}>
                <Header27 color={"graphiteGray"}>Thank you!</Header27>
                <YSpacer spaceY={3} />
                <BodyMedium color={"graphiteGray"}>Please Note:</BodyMedium>
                <YSpacer spaceY={4} />
                <Body14 color={"graphiteGray"} textAlign={"center"}>
                    Your card has been charged {formatAsDollars(paymentModal.amountCharged ?? 0)} to complete your
                    online billing period for this month. Beginning next month on your regular payment date, your online
                    subscription charge will be: {formatAsDollars(newPayment)}
                </Body14>
                <YSpacer spaceY={5} />
                <SmallButton
                    backgroundColor={"auratiumGreen"}
                    onClick={() => {
                        dispatch(slices.paymentModal.actions.set({ openModal: null, amountCharged: null }));
                    }}
                >
                    got it
                </SmallButton>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : paymentModal.openModal === "noOnlineProductsSelected" ? (
        <PaymentModalContainer>
            <FlexColAlignCenter
                height={"100%"}
                justifyContent={"center"}
                data-testid={"noOnlineProductsSelected"}
                px={6}
            >
                <Header27 color={"graphiteGray"}>No Products Selected</Header27>
                <YSpacer spaceY={8} />
                <Body14 color={"graphiteGray"} textAlign={"center"}>
                    Please select products in order to make a purchase.
                </Body14>
                <YSpacer spaceY={8} />
                <SmallButton
                    backgroundColor={"auratiumGreen"}
                    onClick={() => {
                        dispatch(slices.paymentModal.actions.set({ openModal: null }));
                    }}
                >
                    continue
                </SmallButton>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : null;
}
