const ascentGoogleAds = {
    ASCENT_MANAGEMENT_FEE: 499,
    CALL_TRACKING_FEE: 60,
    nationalAverages: {
        COST_PER_CLICK: 8.25,
        CLICK_TO_CALL: 0.21,
        CALL_CONVERSION: 0.2,
    },
};

export default ascentGoogleAds;
