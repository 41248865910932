import React from "react";
import { Box, FlexColAlignCenter, YSpacer } from "../styledSystemUtilities";
import { LoadingWheel, PaymentModalContainer, SmallButton } from "../Atoms";
import { Body14, Body14Medium, BodyMedium, Header27 } from "../Typography";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions, slices } from "../../redux";
import { formatAsDollars } from "../../utils/formatter";

export function RecurlyGrowthLoadingModal({
    practiceId,
    recurlyCampaignId,
    newGoal,
    currentPayment,
    newPayment,
}: {
    practiceId: string;
    recurlyCampaignId?: string;
    newGoal: number;
    currentPayment: number;
    newPayment: number;
}): JSX.Element | null {
    const dispatch = useAppDispatch();
    const paymentModal = useAppSelector((state) => state.paymentModal);

    return paymentModal.openModal === "growthLoading" ? (
        <PaymentModalContainer>
            <FlexColAlignCenter justifyContent={"center"} data-testid={"recurlyLoadingWheel"} height={"100%"}>
                <LoadingWheel color={"petrolBlue"} />
                <YSpacer spaceY={8} />
                <Body14Medium>Processing Payment...</Body14Medium>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : paymentModal.openModal === "growthPaymentConfirmation" ? (
        <PaymentModalContainer>
            <Box
                position={"absolute"}
                data-testid={"close"}
                pt={6}
                pl={6}
                cursor={"pointer"}
                onClick={() => dispatch(slices.paymentModal.actions.set({ openModal: null }))}
            >
                <Body14Medium>X</Body14Medium>
            </Box>
            <FlexColAlignCenter height={"100%"} justifyContent={"center"} data-testid={"paymentConfirmation"} px={8}>
                <Header27 color={"graphiteGray"}>Confirmation</Header27>
                <YSpacer spaceY={10} />
                <Body14 color={"graphiteGray"} textAlign={"center"}>
                    This will change this monthly campaign subscription payment from {formatAsDollars(currentPayment)}{" "}
                    to {formatAsDollars(newPayment)}
                </Body14>
                <YSpacer spaceY={8} />
                <SmallButton
                    backgroundColor={"ipanemaBlue"}
                    onClick={() => {
                        if (recurlyCampaignId) {
                            dispatch(slices.paymentModal.actions.set({ openModal: "growthLoading" }));
                            dispatch(actions.updateCampaignSpend(practiceId, recurlyCampaignId, newPayment, newGoal));
                        } else {
                            dispatch(slices.paymentModal.actions.set({ openModal: "growthError" }));
                        }
                    }}
                >
                    confirm purchase
                </SmallButton>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : paymentModal.openModal === "growthThankYou" ? (
        <PaymentModalContainer>
            <FlexColAlignCenter height={"100%"} justifyContent={"center"} data-testid={"thankYou"} px={8}>
                <Header27 color={"graphiteGray"}>Thank you!</Header27>
                <YSpacer spaceY={3} />
                <BodyMedium color={"graphiteGray"}>Please Note:</BodyMedium>
                <YSpacer spaceY={4} />
                <Body14 color={"graphiteGray"} textAlign={"center"}>
                    Your card has been charged {formatAsDollars(paymentModal.amountCharged ?? 0)} to complete this
                    campaign's billing period for the month. Beginning next month on your regular payment date, this
                    campaign's charge will be: {formatAsDollars(newPayment)}
                </Body14>
                <YSpacer spaceY={5} />
                <SmallButton
                    backgroundColor={"ipanemaBlue"}
                    onClick={() => {
                        dispatch(slices.paymentModal.actions.set({ openModal: null, amountCharged: null }));
                    }}
                >
                    got it
                </SmallButton>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : paymentModal.openModal === "growthError" ? (
        <PaymentModalContainer>
            <FlexColAlignCenter height={"100%"} justifyContent={"center"} data-testid={"growthModalError"} px={6}>
                <Header27 color={"graphiteGray"}>There's been an error.</Header27>
                <YSpacer spaceY={8} />
                <Body14 color={"graphiteGray"} textAlign={"center"}>
                    Please contact an ascent strategist for help.
                </Body14>
                <YSpacer spaceY={8} />
                <SmallButton
                    backgroundColor={"ipanemaBlue"}
                    onClick={() => {
                        dispatch(slices.paymentModal.actions.set({ openModal: null }));
                    }}
                >
                    continue
                </SmallButton>
            </FlexColAlignCenter>
        </PaymentModalContainer>
    ) : null;
}
