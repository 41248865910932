import { Box, FlexColAlignCenter, FlexRowAlignCenter, Grid, YSpacer } from "../../styledSystemUtilities";
import { Body14Medium } from "../../Typography";
import { TwoDotLegend } from "../../Molecules";
import { Chart } from "../Chart";
import { TwoSetPlot } from "../../Molecules/Plots";
import React from "react";
import { KpiYear } from "../../../@types/reports/Profit";
import { InputIcon } from "../../Atoms/assets";
import { SmallIconButton } from "../../Atoms/Buttons";
import { useAppSelector } from "../../../redux/hooks";

export function ProfitChart({
    years,
    label,
    name,
    yLabelName,
    setIsOpen,
}: {
    years: KpiYear[];
    label: "revenue" | "visits" | "referrals" | "newPatients";
    name: string;
    yLabelName: string;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
    const pmsIntegrationId = useAppSelector((state) => state.openPractice.pmsIntegrationId);

    return (
        <Grid
            gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"}
            data-testid={label + "Chart"}
            justifyItems={"center"}
        >
            <Box pt={9} width={"100%"} gridColumn={2}>
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <Box pl={8}>
                        <Body14Medium>{name}</Body14Medium>
                        <YSpacer spaceY={3} />
                        <TwoDotLegend
                            dotColor1={"balticBlue"}
                            dotColor2={"midGray"}
                            label1={years[0].yearName}
                            label2={years[1].yearName}
                        />
                    </Box>
                    {pmsIntegrationId ? null : (
                        <SmallIconButton
                            backgroundColor={"transparent"}
                            onClick={() => setIsOpen(true)}
                            Icon={InputIcon}
                            color={"agateGray"}
                            border={"mid"}
                        >
                            enter missing data
                        </SmallIconButton>
                    )}
                </FlexRowAlignCenter>

                <YSpacer spaceY={4} />
                <FlexColAlignCenter>
                    <Chart
                        yLabel={yLabelName}
                        renderPlot={() => (
                            <TwoSetPlot
                                firstDataSet={years[0].months.map((month) => ({
                                    x: month.date,
                                    y: month[label] ?? 0,
                                }))}
                                secondDataSet={years[1].months.map((month) => ({
                                    x: new Date(years[0].yearName, month.date.getMonth()),
                                    y: month[label] ?? 0,
                                }))}
                                width={308}
                                color={"balticBlue"}
                            />
                        )}
                    />
                </FlexColAlignCenter>
            </Box>
        </Grid>
    );
}
