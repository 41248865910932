import React from "react";
import { Box } from "../../styledSystemUtilities";
import { ReferralCardsHero } from "../../Atoms/assets";
import { CopyWriting, Pen, Printer, Shipping, Strategy } from "../../Atoms";
import { Dimensions, GrayBackground, IconContainer, PricingNotes, SellingPoint } from "../../Molecules";
import { ProfitHeader } from "../../Molecules/PopupComponents";

export const ReferralCards = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <ProfitHeader setPopup={setPopup} Hero={ReferralCardsHero} />
            <GrayBackground>
                <SellingPoint
                    header={"The perfect tool for a critical system"}
                    body={
                        "Referral cards are folded and perforated. On one side is an appointment reminder, on the other a call to action for a referral. The perforation makes it easy to tear off one side and give it to a friend."
                    }
                />
                <Dimensions bold={"4” x 3.5”"} light={"– Folded and perforated"} />
                <SellingPoint
                    header={"Why Ascent for your referral cards?"}
                    body={
                        "• Better brand Integration\n" +
                        "• High-end, original design \n" +
                        "• We specialize in dental – It’s all we do\n" +
                        "• Smart strategy\n" +
                        "• Professional copywriting\n" +
                        "• It’s easier – We’re a one-stop-shop\n" +
                        "• Timely delivery"
                    }
                    lineHeight={"24px"}
                />
                <IconContainer name={"What's Included"}>
                    <Pen name={"Design"} />
                    <Strategy name={"Strategy"} fill={"balticBlue"} />
                    <CopyWriting name={"Copywriting"} fill={"balticBlue"} />
                    <Printer name={"Printing"} />
                    <Shipping name={"Shipping"} />
                </IconContainer>
            </GrayBackground>
            <PricingNotes>
                Print materials are purchased on a per-item basis and must be paid in full. We offer the choice of a
                1-time payment or 12 payments at a monthly interest rate of 12%.
            </PricingNotes>
        </Box>
    );
};
