import React from "react";
import { Box, FlexRow, FlexRowAlignCenter, Grid } from "../styledSystemUtilities";
import {
    DashboardButton,
    Gradient,
    GrowthMenuItem,
    GrowthWedge,
    LogoutButton,
    MenuLogo,
    OnlineWedge,
    ProfileButton,
    ProfitMenuItem,
    ProfitWedge,
    ScheduleCallButton,
    ViewCartButton,
} from "../Atoms";
import { useTheme } from "styled-components";
import { useWindowSize } from "react-use";
import { MenuButtonRow, MenuHeader, MenuTable } from "../Molecules";
import {
    DesktopAccountPrimary,
    DesktopDashboardPrimary,
    DesktopGrowthPrimary,
    DesktopOnlinePrimary,
    DesktopProfitPrimary,
    OnlineMenuItem,
} from "../Atoms/MenuItem";
import { Color } from "../../@types/color";
import { MenuItemComponent, NavItemComponent } from "../../@types/components";
import { useAppSelector } from "../../redux/hooks";
import { MenuTableData } from "../../@types/components/MenuTable";

export const AccountDesktopMenu = (): JSX.Element => {
    const account = useAppSelector((state) => state.clientAccount);
    const totalRoiTable = useAppSelector((state) => state.locationOverviewReports.roiTable);

    return (
        <DesktopMenu
            logoBackgroundColor={"translucentAgateGray"}
            backColor={"agateGray"}
            tableBackgroundColor={"chalk"}
            frontColor={"slateGray"}
            menuItems={[DesktopAccountPrimary, OnlineWedge, GrowthWedge, ProfitWedge]}
            navButtons={[ScheduleCallButton, DashboardButton, LogoutButton]}
            labelColor={"agateGray"}
            tableName={"grand total roi"}
            name={account.accountName}
            roiTable={totalRoiTable}
            activeSince={account.startDate}
        />
    );
};

export const LocationDesktopMenu = (): JSX.Element => {
    const account = useAppSelector((state) => state.clientAccount);
    const totalRoiTable = useAppSelector((state) => state.locationOverviewReports.roiTable);

    return (
        <DesktopMenu
            logoBackgroundColor={"translucentAgateGray"}
            backColor={"agateGray"}
            tableBackgroundColor={"chalk"}
            frontColor={"slateGray"}
            menuItems={[DesktopDashboardPrimary, OnlineWedge, GrowthWedge, ProfitWedge]}
            navButtons={[ScheduleCallButton, ProfileButton, LogoutButton]}
            labelColor={"agateGray"}
            tableName={"grand total roi"}
            name={account.accountName}
            roiTable={totalRoiTable}
            activeSince={account.startDate}
        />
    );
};

export const ProfitDesktopMenu = (): JSX.Element => {
    const practice = useAppSelector((state) => state.openPractice);
    const roiTable = useAppSelector((state) => state.profitReport.roiTable);
    return (
        <DesktopMenu
            logoBackgroundColor={"translucentBalticBlue"}
            backColor={"nauticBlue"}
            tableBackgroundColor={"chalk"}
            frontColor={"balticBlue"}
            menuItems={[DesktopProfitPrimary, OnlineMenuItem, GrowthMenuItem]}
            navButtons={[ViewCartButton, ScheduleCallButton, DashboardButton, ProfileButton]}
            labelColor={"balticBlue"}
            tableName={"total roi"}
            name={practice.practiceName}
            roiTable={roiTable}
            activeSince={practice.startDate}
        />
    );
};

export const GrowthDesktopMenu = (): JSX.Element => {
    const practice = useAppSelector((state) => state.openPractice);
    const roiTable = useAppSelector((state) => state.growthReport.roiTable);
    return (
        <DesktopMenu
            logoBackgroundColor={"translucentIpanemaBlue"}
            backColor={"deepPetrol"}
            tableBackgroundColor={"aquaBlue"}
            frontColor={"ipanemaBlue"}
            menuItems={[DesktopGrowthPrimary, OnlineMenuItem, ProfitMenuItem]}
            navButtons={[ScheduleCallButton, DashboardButton, ProfileButton]}
            labelColor={"ipanemaBlue"}
            tableName={"growth roi"}
            name={practice.practiceName}
            roiTable={roiTable}
            activeSince={practice.startDate}
        />
    );
};

export const OnlineDesktopMenu = (): JSX.Element => {
    const practice = useAppSelector((state) => state.openPractice);
    const roiTable = useAppSelector((state) => state.onlineReport.roiTable);
    return (
        <DesktopMenu
            logoBackgroundColor={"translucentSeaGreen"}
            backColor={"forrestGreen"}
            tableBackgroundColor={"catskillWhite"}
            frontColor={"seaGreen"}
            menuItems={[DesktopOnlinePrimary, GrowthMenuItem, ProfitMenuItem]}
            navButtons={[ScheduleCallButton, DashboardButton, ProfileButton]}
            labelColor={"auratiumGreen"}
            tableName={"online roi"}
            name={practice.practiceName}
            roiTable={roiTable}
            activeSince={practice.startDate}
        />
    );
};

type DesktopMenuProps = {
    frontColor: Color;
    backColor: Color;
    logoBackgroundColor: Color;
    labelColor: Color;
    tableBackgroundColor: Color;
    tableName: string;
    menuItems: MenuItemComponent[];
    navButtons: NavItemComponent[];
    name: string;
    activeSince: string;
    roiTable: MenuTableData;
};

const DesktopMenu = ({
    frontColor,
    backColor,
    logoBackgroundColor,
    labelColor,
    tableBackgroundColor,
    menuItems,
    navButtons,
    tableName,
    name,
    activeSince,
    roiTable,
}: DesktopMenuProps) => {
    const theme = useTheme();
    const { width } = useWindowSize();

    return (
        <Box>
            <Gradient />
            <Box backgroundColor={frontColor} height={5} />
            <Box height={11} backgroundColor={backColor}>
                <Grid gridTemplateColumns={"1fr minmax(650px, 760px) 1fr"}>
                    <Box backgroundColor={"white"} width={"100%"} mt={11}>
                        <FlexRowAlignCenter
                            width={"100%"}
                            height={"80px"}
                            backgroundColor={logoBackgroundColor}
                            justifyContent={"center"}
                        >
                            {width > theme.breakpoints[1] ? <MenuLogo /> : null}
                        </FlexRowAlignCenter>
                    </Box>

                    <FlexRow gridColumn={2}>
                        <Box backgroundColor={"white"} zIndex={-300} mt={11} width={"49px"} position={"absolute"}>
                            <Box backgroundColor={logoBackgroundColor} zIndex={-200} height={"80px"} width={"49px"} />
                        </Box>
                        <MenuButtonRow menuItems={menuItems} navItems={navButtons} />
                    </FlexRow>
                    <Grid gridColumn={2} height={"80px"} gridTemplateColumns={"1fr 1fr"} mt={"-80px"}>
                        <FlexRowAlignCenter ml={"49px"} backgroundColor={tableBackgroundColor}>
                            <MenuHeader
                                pl={"80px"}
                                labelColor={labelColor}
                                dateColor={backColor}
                                name={name}
                                date={activeSince}
                            />
                        </FlexRowAlignCenter>
                        <FlexRowAlignCenter pr={6} pl={6} backgroundColor={tableBackgroundColor}>
                            <MenuTable name={tableName} labelColor={labelColor} dataColor={backColor} data={roiTable} />
                        </FlexRowAlignCenter>
                    </Grid>
                    <Box>
                        <Box
                            height={"80px"}
                            maxWidth={"38px"}
                            mt={"-80px"}
                            background={`linear-gradient(115deg, transparent 0, ${theme.colors[tableBackgroundColor]} 0 34px, transparent 34px 34px)}`}
                        />
                        <Box backgroundColor={tableBackgroundColor} height={7} mt={"-80px"} />
                    </Box>
                </Grid>
            </Box>
        </Box>
    );
};
