import React from "react";
import { Header16 } from "../Typography";
import { InfoTable } from "./InfoTable";
import { InfoTableRow } from "../../@types/infoTableRow";

type ContactProps = {
    rows: InfoTableRow[];
    children: React.ReactNode;
}

export const Contact = ({ rows, children }: ContactProps): JSX.Element => (
    <>
        <Header16 color={"graphiteGray"}>{children}</Header16>
        <InfoTable rows={rows} />
    </>
);
