import React, { useState } from "react";
import { Color } from "../../@types/color";
import { FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { Label11Medium } from "../Typography";
import { ActiveProfitCenterIcon, ArrowDown, ArrowRight } from "../Atoms/assets";

export function Dropdown({
    name,
    closedColor,
    closedBackgroundColor,
    openColor,
    openBackgroundColor,
    isActiveProfitCenter = false,
    children,
}: {
    name: string;
    closedColor: Color;
    closedBackgroundColor: Color;
    openColor: Color;
    openBackgroundColor: Color;
    isActiveProfitCenter?: boolean;
    children: React.ReactNode;
}): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    return isOpen ? (
        <>
            <FlexRowAlignCenter
                gridColumn={"2 / -1"}
                backgroundColor={openBackgroundColor}
                height={"38px"}
                width={"100%"}
            >
                <FlexRowAlignCenter
                    width={"375px"}
                    justifyContent={"space-between"}
                    cursor={"pointer"}
                    onClick={() => setIsOpen(false)}
                    pl={isActiveProfitCenter ? 7 : 8}
                    pr={10}
                >
                    <FlexRowAlignCenter>
                        {isActiveProfitCenter ? <ActiveProfitCenterIcon /> : <XSpacer spaceX={6} />}
                        <XSpacer spaceX={4} />
                        <Label11Medium color={openColor} uppercase>
                            {name}
                        </Label11Medium>
                    </FlexRowAlignCenter>
                    <ArrowDown fill={openColor} />
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
            {children}
        </>
    ) : (
        <FlexRowAlignCenter
            gridColumn={2}
            maxWidth={"375px"}
            justifyContent={"space-between"}
            pl={isActiveProfitCenter ? 7 : 8}
            pr={10}
            height={"38px"}
            backgroundColor={closedBackgroundColor}
            cursor={"pointer"}
            mb={5}
            onClick={() => setIsOpen(true)}
            key={name}
        >
            <FlexRowAlignCenter>
                {isActiveProfitCenter ? <ActiveProfitCenterIcon /> : <XSpacer spaceX={6} />}
                <XSpacer spaceX={4} />
                <Label11Medium color={closedColor} uppercase>
                    {name}
                </Label11Medium>
            </FlexRowAlignCenter>
            <ArrowRight fill={closedColor} />
        </FlexRowAlignCenter>
    );
}
