import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import { FormCollectionName } from "../../@types/forms/Types";

const getForm = (
    formId: string,
    formCollectionName: FormCollectionName,
    continueLoading?: boolean
): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/product-forms/get-form/${formCollectionName}/${formId}`,
            method: "get",
        },
        success: slices.form.actions.set,
        label: "form",
        continueLoading,
    });

export default getForm;
