import { Task } from "../@types/shared";
import { Color } from "../@types/color";
import _ from "lodash/fp";
import { taskIcons } from "../constants/taskIcons";
import { ProductTasks } from "../@types/components";

export function formatTasks(tasks: Task[], iconFill: Color, taskSet: Set<string>): ProductTasks {
    return _.flow(
        _.filter((task: Task) => taskSet.has(task.taskKey)),
        _.groupBy("parentProduct"),
        _.entries,
        _.map(([productName, tasks]) => ({
            name: productName,
            tasks: _.map(
                (task: Task) => ({
                    ...task,
                    formLink: _.kebabCase(task.taskKey) + "-form",
                    Icon: () => taskIcons[task.taskKey](iconFill),
                }),
                tasks
            ),
        }))
    )(tasks);
}
