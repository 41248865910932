const colors = {
    nobelGray: "#989898",
    dimGray: "#707070",
    deepGreen: "#6B9672",
    forrestGreen: "#5e986f",
    auratiumGreen: "#74AD85",
    darkSeaGreen: "#8CBF9C",
    seaGreen: "#99C2AD",
    backgroundGreen: "#C5DBD0",
    surfCrest: "#CDE1D7",
    greenWhite: "#DDEBE3",
    aquaHaze: "#EEF5F1",
    casal: "#2E6775",
    deepPetrol: "#0d6877",
    petrolBlue: "#107889",
    ipanemaBlue: "#329998",
    northernLights: "#88C4C3",
    edgeWater: "#C4E1E0",
    amaranthRed: "#B94F4F",
    lightAmaranth: "#F5E5E5",
    sienna: "#E27867",
    orangePlot: "#F6D6D1",
    bluewood: "#2D415A",
    nauticBlue: "#28415C",
    balticBlue: "#527991",
    cadetGray: "#90A5B7",
    mercury: "#DEE7ED",
    ghost: "#C5CDD5",
    porcelain: "#ECF1F3",
    graphiteGray: "#343434",
    agateGray: "#7F7F7F",
    slateGray: "#A7A9AC",
    midGray: "#CCCCCC",
    pearlGray: "#E6E7E8",
    chalk: "#F8F8F9",
    white: "#ffffff",
    translucentSeaGreen: "rgba(153, 194, 173, .35)",
    translucentIpanemaBlue: "rgba(50, 153, 152, .35)",
    translucentBalticBlue: "rgba(82, 121, 145, .35)",
    translucentAgateGray: "rgba(127, 127, 127, .35)",
    translucentChartGreen: "hsl(149, 21%, 76%, .5)",
    translucentWhite: "rgba(160, 160, 160, .6)",
    aquaBlue: "#E9F4F4",
    catskillWhite: "#F0F6F5",
    transparent: "transparent",
};

const fontWeights = {
    thin: 100,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
};

const breakpoints = [766, 1118, 1200];

const theme = {
    colors,
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    space: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    sizes: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    fonts: {
        roboto: "Roboto",
        montserrat: "Montserrat",
    },
    fontWeights,
    //         [0, 1,  2,  3,  4,  5,  6,  7,  8,  9 ]
    fontSizes: [9, 10, 11, 12, 14, 16, 18, 20, 27, 44],
    borders: {
        gray: `1px solid ${colors.pearlGray}`,
        mid: `1px solid ${colors.midGray}`,
        dark: `1px solid ${colors.agateGray}`,
        green: `1px solid #CCE1D6`,
        productGreen: `1px solid #80AB88`,
        thickGreen: `2px solid ${colors.seaGreen}`,
        auratiumGreen: `1px solid ${colors.auratiumGreen}`,
        seaGreen: `1px solid ${colors.seaGreen}`,
        petrol: `1px solid #1A7888`,
        petrolBlue: `1px solid ${colors.petrolBlue}`,
        ipanema: `1px solid ${colors.ipanemaBlue}`,
        cart: `1px solid #707070`,
        lightBlue: `1px solid ${colors.northernLights}`,
        graphiteGray: `1px solid ${colors.graphiteGray}`,
    },
    breakpoints,
};

export default theme;
