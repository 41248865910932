import React from "react";
import { LocalRankingPlot } from "../Molecules";
import { Box } from "../styledSystemUtilities";
import { Chart } from "./Chart";
import { SeoChartData } from "../../@types/seoChartData";

export const SeoChartCard = ({ seoChartData }: { seoChartData: SeoChartData }): JSX.Element => {
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Chart
                xLabel={"Overall Rank"}
                yLabel={"Avg. Keyword Rank"}
                renderPlot={() => <LocalRankingPlot data={seoChartData} />}
            />
        </Box>
    );
};
