import { createSlice } from "@reduxjs/toolkit";
import { PracticeFrontEnd } from "../../@types/shared";

const initialState: PracticeFrontEnd[] = [];

export default createSlice({
    name: "accountPractices",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
        unset: () => []
    },
});
