import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormTemplate } from "./FormTemplate";
import { Box, FlexRow, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body, Body12, Header } from "../../Typography";
import { ControlledCheckbox, NumberedText, RegisteredBigInput, RegisteredInput } from "../../Molecules";
import { DomainExplanation } from "../../Atoms/assets";
import { RightButton } from "../../Atoms";
import { useAppSelector } from "../../../redux/hooks";
import { SeoForm } from "../../../@types/forms/Types";
import { useProductFormSubmit } from "../../../hooks/useProductFormSubmit";
import { OptionChecklist } from "../../Organisms";

const formDefaultValues: SeoForm = {
    userName: "",
    previousSeoEfforts: "",
    hasOldWebsite: false,
    hasNoWebsite: false,
    practicePurchasedFromAnotherDoctor: false,
    newDomainName: false,
    otherPreviousSeoInfo: "",
    geographicArea: "",
    businessListings: [],
    otherBusinessListings: "",
    businessListingServices: [],
    otherBusinessListingServices: "",
    createNewAnalyticsAccount: false,
    makeAscentManagerOfAnalyticsAccount: false,
    hostingAccountLogin: "",
    hostingAccountPassword: "",
    cannotFindHostingInfo: false,
    wantsAscentToHost: false,
    topCompetitors: "",
};

export function Seo(): JSX.Element {
    const form = useAppSelector((state) => state.form);
    const superviseSubmit = useProductFormSubmit<SeoForm>("online", "seoForms");
    const { register, errors, handleSubmit, control, reset } = useForm<SeoForm>({
        defaultValues: form || formDefaultValues,
    });

    useEffect(() => {
        reset(form || formDefaultValues);
    }, [form, reset]);

    return (
        <FormTemplate
            productName={"Search Engine Optimization (SEO)"}
            taskName={""}
            redLabel={"Help us help you!"}
            blackLabel={""}
            onSubmit={handleSubmit(superviseSubmit)}
        >
            <Box>
                <Body12>Name of the person filing this form out:</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"userName"} placeholder={""} />
                <Header color={"graphiteGray"}>Previous SEO efforts</Header>
                <YSpacer spaceY={4} />
                <Body12>What, if any, SEO efforts have you done previously?</Body12>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"previousSeoEfforts"} placeholder={""} />
                <Body12>Which of these apply to you:</Body12>
                <YSpacer spaceY={2} />
                <Grid gridTemplateColumns={"1fr 1fr"} gridRowGap={2}>
                    <FlexRowAlignCenter>
                        <ControlledCheckbox name={"hasOldWebsite"} control={control} />
                        <XSpacer spaceX={6} />
                        <Body12>Old website</Body12>
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter>
                        <ControlledCheckbox name={"practicePurchasedFromAnotherDoctor"} control={control} />
                        <XSpacer spaceX={6} />
                        <Body12>Practice purchased from another doctor</Body12>
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter>
                        <ControlledCheckbox name={"hasNoWebsite"} control={control} />
                        <XSpacer spaceX={6} />
                        <Body12>No website</Body12>
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter>
                        <ControlledCheckbox name={"newDomainName"} control={control} />
                        <XSpacer spaceX={6} />
                        <Body12>New domain name</Body12>
                    </FlexRowAlignCenter>
                </Grid>
                <YSpacer spaceY={2} />
                <Body12>Other:</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"otherPreviousSeoInfo"} placeholder={""} />
                <YSpacer spaceY={6} />
                <Header color={"graphiteGray"}>Your Geographic Area</Header>
                <YSpacer spaceY={4} />
                <Body12>List City, County, ZIP or other areas you’d like to rank in:</Body12>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"geographicArea"} placeholder={""} />
                <YSpacer spaceY={6} />
                <Header color={"graphiteGray"}>Business Listing Services</Header>
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    What business listings or citations have you set up or currently manage yourself:
                </Body>
                <OptionChecklist
                    options={["Google My Business", "Yelp", "Bing", "Facebook"]}
                    name={"businessListings"}
                    label={""}
                    control={control}
                />
                <Body12>Other:</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"otherBusinessListings"} placeholder={""} />
                <Body color={"graphiteGray"}>
                    What business listings or citations have you set up or currently manage yourself:
                </Body>
                <OptionChecklist
                    options={["Advice Local", "Moz Local", "Text"]}
                    name={"businessListingServices"}
                    label={""}
                    control={control}
                />
                <Body12>Other:</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput
                    register={register}
                    errors={errors}
                    name={"otherBusinessListingServices"}
                    placeholder={""}
                />
                <YSpacer spaceY={6} />
                <Header color={"graphiteGray"}>Competitors</Header>
                <YSpacer spaceY={4} />
                <Body12>Who do you consider your top 3 competitors:</Body12>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"topCompetitors"} placeholder={""} />
                <Body color={"graphiteGray"}>We need access to your HOSTING ACCOUNT</Body>
            </Box>
            <Box>
                <Header color={"graphiteGray"}>Google Analytics</Header>
                <YSpacer spaceY={7} />
                <Body12>
                    We use Google Analytics to track important data on your website.
                    <br />
                    <br />
                    If you do not have a Google Analytics account or don’t know if you have an account, Ascent will
                    create this for you.
                </Body12>
                <YSpacer spaceY={6} />
                <FlexRowAlignCenter>
                    <ControlledCheckbox name={"createNewAnalyticsAccount"} control={control} />
                    <XSpacer spaceX={6} />
                    <Body12>Create an Analytics account for me.</Body12>
                </FlexRowAlignCenter>
                <YSpacer spaceY={6} />
                <Body12>
                    If you have previously been using Google Analytics and would like us to continue using your current
                    Analytics account, log into your account and click Users on the left-hand menu. From there, add:
                    dentalbrandinganalytics@gmail.com as an owner or manager.
                </Body12>
                <YSpacer spaceY={6} />
                <FlexRowAlignCenter>
                    <ControlledCheckbox name={"makeAscentManagerOfAnalyticsAccount"} control={control} />
                    <XSpacer spaceX={6} />
                    <Body12>I will make ascent an owner or manager of my current Google Analytics account.</Body12>
                </FlexRowAlignCenter>
                <YSpacer spaceY={8} />
                <Body color={"graphiteGray"}>
                    If you have NOT PURCHASED a subscription website through Ascent, we will need access to your hosting
                    account
                </Body>
                <YSpacer spaceY={3} />
                <Body12>
                    This allows us to make ongoing changes to your website that help improve your online visibility. If
                    you purchased your website through Ascent, leave this section blank.
                    <br />
                    <br />
                    What is your login name for your HOSTING ACCOUNT?
                </Body12>
                <RegisteredInput register={register} errors={errors} name={"hostingAccountLogin"} placeholder={""} />
                <Body12>What is your password for your HOSTING ACCOUNT?</Body12>
                <RegisteredInput register={register} errors={errors} name={"hostingAccountPassword"} placeholder={""} />
                <FlexRow>
                    <ControlledCheckbox name={"cannotFindHostingInfo"} control={control} />
                    <Box pl={6} pt={2}>
                        <Body12>I can't find this information</Body12>
                        <Body12>
                            (If you check this box, an Ascent representative will contact you to guide you through this
                            process.)
                        </Body12>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={6} />
                <Body color={"graphiteGray"}>Would you like Ascent to host your website?</Body>
                <YSpacer spaceY={6} />
                <FlexRow>
                    <ControlledCheckbox name={"wantsAscentToHost"} control={control} />
                    <Box pl={6} pt={2}>
                        <Body12>Yes</Body12>
                        <Body12>
                            (If you check this box, an Ascent representative will contact you to guide you through this
                            process.)
                        </Body12>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={11} />
                <Body textAlign={"center"} color={"graphiteGray"}>
                    How hosting works:
                </Body>
                <YSpacer spaceY={6} />
                <Box pl={6} pb={6}>
                    <DomainExplanation />
                </Box>
                <NumberedText number={1}>Your web address is entered into a browser</NumberedText>
                <NumberedText number={2}>
                    The address is identified as your domain by the company that holds the registration of your website
                    address (Domain Registrar)
                </NumberedText>
                <NumberedText number={3}>
                    The domain registrar points the web address to the server where your website data files are being
                    stored (Website Hosting)
                </NumberedText>
                <NumberedText number={4}>
                    The website data is uploaded into the browser and your website is visible.{" "}
                </NumberedText>
                <Body color={"graphiteGray"}>
                    As we will be optimizing your site, we need hosting access to ensure we can make ongoing updates.
                </Body>
            </Box>
            <YSpacer spaceY={12} />
            <FlexRow justifyContent={"center"} gridColumn={"1 / -1"}>
                <Box width={"330px"}>
                    <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                        save & continue
                    </RightButton>
                </Box>
            </FlexRow>
        </FormTemplate>
    );
}
