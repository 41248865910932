import React, { FunctionComponent, SVGProps } from "react";
import { Box } from "../styledSystemUtilities";
import {
    AdvancedLinkRaw,
    CompetitiveAnalysisRaw,
    DynamicCallTrackingRaw,
    ExternalLinkRaw,
    FilterRaw,
    OptimizedLandingPageRaw,
    PenRaw,
    PrinterRaw,
    RawAdvancedSeo,
    RawAirplane,
    RawArt,
    RawCopyWriting,
    RawFacebook,
    RawGoogle,
    RawPhone,
    RawPhoto,
    RawResponsive,
    RawReviewsTracking,
    RawSeoContent,
    RawStrategy,
    ShippingRaw,
} from "./assets";
import theme from "../../theme";
import { Color } from "../../@types/color";
import { Body12Medium } from "../Typography";

type IconProps = {
    name: string;
    fill?: Color;
};

export const Google = ({ name, fill }: IconProps): JSX.Element => {
    return <IconTemplate name={name} Icon={RawGoogle} fill={fill} />;
};

export const Facebook = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate name={name} Icon={RawFacebook} />;
};

export const Responsive = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate name={name} Icon={RawResponsive} />;
};

export const Phone = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate name={name} Icon={RawPhone} />;
};

export const Art = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={RawArt} name={name} />;
};

export const CopyWriting = ({ name, fill }: IconProps): JSX.Element => {
    return <IconTemplate name={name} Icon={RawCopyWriting} fill={fill} />;
};

export const Photo = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={RawPhoto} name={name} />;
};

export const Strategy = ({ name, fill }: IconProps): JSX.Element => {
    return <IconTemplate Icon={RawStrategy} name={name} fill={fill} />;
};

export const AdvancedSeo = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={RawAdvancedSeo} name={name} />;
};

export const SeoContent = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={RawSeoContent} name={name} />;
};

export const Airplane = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={RawAirplane} name={name} />;
};

export const ReviewsTracking = ({ name, fill }: IconProps): JSX.Element => {
    return <IconTemplate Icon={RawReviewsTracking} name={name} fill={fill} />;
};

export const CompetitiveAnalysis = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={CompetitiveAnalysisRaw} name={name} />;
};

export const ExternalLink = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={ExternalLinkRaw} name={name} />;
};

export const AdvancedLink = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={AdvancedLinkRaw} name={name} />;
};

export const DynamicCallTracking = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={DynamicCallTrackingRaw} name={name} />;
};

export const OptimizedLandingPage = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={OptimizedLandingPageRaw} name={name} />;
};

export const Filter = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={FilterRaw} name={name} />;
};

export const Pen = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={PenRaw} name={name} />;
};

export const Printer = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={PrinterRaw} name={name} />;
};

export const Shipping = ({ name }: IconProps): JSX.Element => {
    return <IconTemplate Icon={ShippingRaw} name={name} />;
};

function IconTemplate({
    Icon,
    name,
    fill,
}: {
    Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    name: string;
    fill?: Color;
}): JSX.Element {
    return (
        <Box height={"80px"} width={"98px"}>
            <Box height={"40px"} display={"flex"} alignItems={"flex-end"} pb={4}>
                <Icon fill={fill ? theme.colors[fill] : "white"} />
            </Box>

            <Body12Medium>{name}</Body12Medium>
        </Box>
    );
}
