import { useEffect } from "react";
import { actions, slices } from "../redux";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const useSetup = (): void => {
    const dispatch = useAppDispatch();
    const clientAccountId = useAppSelector((state) => state.currentUser.user.clientAccountId);
    const openPractice = useAppSelector((state) => state.openPractice);

    useEffect(() => {
        if (clientAccountId) {
            dispatch(actions.getAccountPractices(clientAccountId));
        }
    }, [dispatch, clientAccountId, openPractice]);

    useEffect(() => {
        dispatch(slices.locationOverviewReports.actions.unset())
        dispatch(slices.clientAccount.actions.unset())
        dispatch(slices.accountPractices.actions.unset())
        if (clientAccountId) {
            dispatch(actions.getClientAccount(clientAccountId));
            dispatch(actions.getLocationOverviewReports(clientAccountId));
        }
    }, [dispatch, clientAccountId]);
};
export default useSetup;
