import { DomainIcon, ClipboardCheck, ClipboardIcon } from "../Components/Atoms/assets";
import React from "react";
import { Color } from "../@types/color";

export const taskIcons = {
    domain: (fill: Color): JSX.Element => <DomainIcon fill={fill} width={"16px"} height={"14px"} />,
    website: (fill: Color): JSX.Element => <ClipboardIcon fill={fill} width={"13px"} height={"18px"} />,
    seo: (fill: Color): JSX.Element => <ClipboardCheck fill={fill} />,
    socialMedia: (fill: Color): JSX.Element => <ClipboardIcon fill={fill} width={"13px"} height={"18px"} />,
    googleAds: (fill: Color): JSX.Element => <ClipboardCheck fill={fill} />,
    brand: (fill: Color): JSX.Element => <ClipboardIcon fill={fill} width={"13px"} height={"18px"} />,
    googleMyBusiness: (fill: Color): JSX.Element => <ClipboardCheck fill={fill} />,
};
