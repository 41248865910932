import { Box, FlexColAlignCenter, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body12Bold, Body14Bold, Label11Medium } from "../../Typography";
import { PrimaryTable } from "../PrimaryTable";
import { Chart } from "../Chart";
import { OneSetPlot } from "../../Molecules";
import React from "react";
import { GoogleAdsCampaignAverages, GoogleAdsMonth } from "../../../@types/reports/Growth";
import { format } from "date-fns/fp";
import { formatAsDollars } from "../../../utils/formatter";
import _ from "lodash/fp";

export function AdsMonthlyBreakdowns({
    googleAdsMonths,
    averages,
}: {
    googleAdsMonths: GoogleAdsMonth[];
    averages: GoogleAdsCampaignAverages;
}): JSX.Element {
    return (
        <>
            <FlexRowAlignCenter
                gridColumn={"1 / -1"}
                width={"100%"}
                backgroundColor={"aquaBlue"}
                height={"38px"}
                pl={8}
                mt={5}
            >
                <Label11Medium color={"deepPetrol"} uppercase>
                    monthly breakdowns
                </Label11Medium>
            </FlexRowAlignCenter>
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
                <Box width={"100%"} gridColumn={2}>
                    <YSpacer spaceY={8} />
                    <Box pl={8}>
                        <Body14Bold color={"deepPetrol"}>Ad Performance</Body14Bold>
                    </Box>

                    <YSpacer spaceY={6} />
                    <FlexRowAlignCenter justifyContent={"center"} width={"100%"}>
                        <Box data-testid={"adPerformanceTable"}>
                            <PrimaryTable
                                columns={[
                                    {
                                        renderLabel: () => <Box />,
                                    },
                                    {
                                        renderLabel: () => <Body12Bold>Views</Body12Bold>,
                                    },
                                    {
                                        renderLabel: () => <Body12Bold>Clicks</Body12Bold>,
                                    },
                                    {
                                        renderLabel: () => <Body12Bold>Rate</Body12Bold>,
                                    },
                                ]}
                                backgroundColor={"aquaBlue"}
                                rowBorder={"lightBlue"}
                                headerRowBorder={"petrolBlue"}
                                rows={googleAdsMonths.map((month) => [
                                    { value: format("MMM", month.date) },
                                    { value: month.impressions },
                                    { value: month.clicks },
                                    { value: _.round(month.clickThroughRate * 100) + "%" },
                                ])}
                                footerRows={[
                                    [
                                        { value: "Avg." },
                                        { value: _.round(averages.averageViews) },
                                        { value: _.round(averages.averageClicks) },
                                        { value: _.round(averages.averageClickThroughRate * 100) + "%" },
                                    ],
                                ]}
                                footerRowIsBold={true}
                                width={"230px"}
                                rowTestIds={googleAdsMonths.map((month) => month.date.toString())}
                            />
                        </Box>
                        <XSpacer spaceX={4} />
                        <Box data-testid={"costPerClickTable"}>
                            <PrimaryTable
                                columns={[
                                    {
                                        renderLabel: () => <Body12Bold>Cost Per Click</Body12Bold>,
                                    },
                                ]}
                                backgroundColor={"aquaBlue"}
                                rowBorder={"lightBlue"}
                                headerRowBorder={"petrolBlue"}
                                rows={googleAdsMonths.map((month) => [
                                    {
                                        color: "ipanemaBlue",
                                        value: formatAsDollars(_.round(month.costPerClick * 100) / 100),
                                    },
                                ])}
                                footerRows={[
                                    [
                                        {
                                            value: formatAsDollars(_.round(averages.averageCostPerClick * 100) / 100),
                                            color: "ipanemaBlue",
                                        },
                                    ],
                                ]}
                                footerRowIsBold={true}
                                width={"78px"}
                                rowTestIds={googleAdsMonths.map((month) => month.date.toString())}
                            />
                        </Box>
                    </FlexRowAlignCenter>
                </Box>
            </Grid>

            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
                <Box width={"100%"} gridColumn={2}>
                    <YSpacer spaceY={8} />
                    <Box pl={8}>
                        <Body14Bold color={"deepPetrol"}>Cost Per Click Trends</Body14Bold>
                    </Box>

                    <YSpacer spaceY={6} />
                    <FlexColAlignCenter>
                        <Chart
                            renderPlot={() => (
                                <OneSetPlot
                                    data={googleAdsMonths.map((month) => ({ x: month.date, y: month.costPerClick }))}
                                    width={308}
                                    color={"ipanemaBlue"}
                                />
                            )}
                        />
                    </FlexColAlignCenter>
                </Box>
            </Grid>
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
                <Box width={"100%"} gridColumn={2}>
                    <YSpacer spaceY={8} />
                    <Box pl={8}>
                        <Body14Bold color={"deepPetrol"}>Call & Conversion Performance</Body14Bold>
                    </Box>

                    <YSpacer spaceY={6} />
                    <FlexRowAlignCenter justifyContent={"center"}>
                        <Box data-testid={"callConversionPerformanceTable"}>
                            <PrimaryTable
                                columns={[
                                    {
                                        renderLabel: () => <Box />,
                                    },
                                    {
                                        renderLabel: () => <Body12Bold>Calls</Body12Bold>,
                                    },
                                    {
                                        renderLabel: () => <Body12Bold>CTC</Body12Bold>,
                                    },
                                    {
                                        renderLabel: () => <Body12Bold>NP</Body12Bold>,
                                    },
                                    {
                                        renderLabel: () => <Body12Bold>Conv. %</Body12Bold>,
                                    },
                                ]}
                                backgroundColor={"aquaBlue"}
                                rowBorder={"lightBlue"}
                                headerRowBorder={"petrolBlue"}
                                rows={googleAdsMonths.map((month) => [
                                    { value: format("MMM", month.date) },
                                    { value: month.calls },
                                    { value: _.round(month.clickToCall * 100) + "%" },
                                    { value: month.newPatients },
                                    { value: _.round(month.callConversion * 100) + "%" },
                                ])}
                                footerRows={[
                                    [
                                        { value: "Avg." },
                                        { value: _.round(averages.averageCalls) },
                                        { value: _.round(averages.averageClickToCall * 100) + "%" },
                                        { value: _.round(averages.averageNewPatients) },
                                        { value: _.round(averages.averageCallConversion * 100) + "%" },
                                    ],
                                ]}
                                footerRowIsBold={true}
                                width={"230px"}
                                rowTestIds={googleAdsMonths.map((month) => month.date.toString())}
                            />
                        </Box>
                        <XSpacer spaceX={4} />
                        <Box data-testid={"costPerPatientTable"}>
                            <PrimaryTable
                                columns={[
                                    {
                                        renderLabel: () => <Body12Bold>$ Per Patient</Body12Bold>,
                                    },
                                ]}
                                backgroundColor={"aquaBlue"}
                                rowBorder={"lightBlue"}
                                headerRowBorder={"petrolBlue"}
                                rows={googleAdsMonths.map((month) => [
                                    {
                                        color: "amaranthRed",
                                        value:
                                            formatAsDollars(_.round(month.costPerPatient)) === "$0"
                                                ? "-"
                                                : formatAsDollars(_.round(month.costPerPatient)),
                                    },
                                ])}
                                footerRows={[
                                    [
                                        {
                                            value: formatAsDollars(_.round(averages.averageCostPerPatient)),
                                            color: "amaranthRed",
                                        },
                                    ],
                                ]}
                                footerRowIsBold={true}
                                width={"78px"}
                                rowTestIds={googleAdsMonths.map((month) => month.date.toString())}
                            />
                        </Box>
                    </FlexRowAlignCenter>
                </Box>
            </Grid>
            <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
                <Box width={"100%"} gridColumn={2}>
                    <YSpacer spaceY={8} />
                    <Box pl={8}>
                        <Body14Bold color={"deepPetrol"}>Cost Per Patient Trends</Body14Bold>
                    </Box>
                    <YSpacer spaceY={6} />
                    <FlexColAlignCenter>
                        <Chart
                            renderPlot={() => (
                                <OneSetPlot
                                    data={googleAdsMonths.map((month) => ({ x: month.date, y: month.costPerPatient }))}
                                    width={308}
                                    color={"amaranthRed"}
                                />
                            )}
                        />
                    </FlexColAlignCenter>
                </Box>
            </Grid>
        </>
    );
}
