import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import { GrowthReport } from "../../@types/reports/Growth";

const refreshCampaign = (locationId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/reports/get-growth-report/${locationId}`,
            method: "get",
        },
        success: (report: GrowthReport) => [
            slices.growthReport.actions.set(report),
            slices.paymentModal.actions.set({ openModal: "growthThankYou" }),
        ],
        label: "noLoading",
    });

export default refreshCampaign;
