import React, { useEffect } from "react";
import { FormTemplate } from "./FormTemplate";
import { Box, FlexRow, FlexRowAlignCenter, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body, Body12, Header } from "../../Typography";
import { useForm } from "react-hook-form";
import { ControlledCheckbox, RegisteredBigInput, RegisteredInput } from "../../Molecules";
import { RightButton } from "../../Atoms";
import { useAppSelector } from "../../../redux/hooks";
import { BrandForm } from "../../../@types/forms/Types";
import { useProductFormSubmit } from "../../../hooks/useProductFormSubmit";

const formDefaultValues = {
    fulfillment: "",
    goal: "",
    visionStatement: "",
    otherVisionThoughts: "",
    differentiation: "",
    convincingStatement: "",
    patientComplements: "",
    wantToBeKnownFor: "",
    hasTagline: false,
    likesTagline: false,
    tagline: "",
    personalStory: "",
    topFiveServices: "",
    servicesToPromote: "",
    reasonToChooseForThisService: "",
    technologies: "",
    covidPrecautions: "",
    patientExperienceImprovements: "",
    reasonsForTrust: "",
    valuedCharacterQualities: "",
    patientPerceptionDesires: "",
    employeeAttitudes: "",
    topSixCompetitors: "",
    competitionInformation: "",
    author: "",
};

export function Brand(): JSX.Element {
    const form = useAppSelector((state) => state.form);
    const superviseSubmit = useProductFormSubmit<BrandForm>("profit", "brandForms");
    const { register, handleSubmit, errors, control, reset } = useForm<BrandForm>({
        defaultValues: form || formDefaultValues,
    });

    useEffect(() => {
        reset(form || formDefaultValues);
    }, [form, reset]);

    return (
        <FormTemplate
            productName={""}
            taskName={"Brand Questionnaire"}
            redLabel={"Build trust and improve visibility."}
            blackLabel={""}
            onSubmit={handleSubmit(superviseSubmit)}
        >
            <Box gridColumn={"1 / -1"}>
                <YSpacer spaceY={16} />
                <Header color={"graphiteGray"}>Foundation & Strategy</Header>
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    1. Other than money, what gives you a sense of fulfillment in your work, or why do you want to come
                    to work each day?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"fulfillment"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>2. What are you ultimately trying to achieve with your business?</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"goal"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>3. Do you already have a vision statement? If so, what is it?</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"visionStatement"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    4. Are there any other thoughts on your vision that you feel would be helpful for us to understand?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"otherVisionThoughts"} placeholder={""} />
                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>Awareness - Differentiation</Header>
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    1. What do you do that is different and/or better than your competitors?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"differentiation"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    2. What would you say, in two sentences, to convince a stranger to come to your practice(s) instead
                    of a competitor’s?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"convincingStatement"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    3. What have patients told you they like most about your practice(s)?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"patientComplements"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>4. What do you want to be known for?</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"wantToBeKnownFor"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>Do you have a tagline?</Body>
                <YSpacer spaceY={2} />
                <FlexRowAlignCenter>
                    <ControlledCheckbox name={"hasTagline"} control={control} />
                    <XSpacer spaceX={6} />
                    <Body12>Yes</Body12>
                </FlexRowAlignCenter>
                <YSpacer spaceY={7} />
                <Body color={"graphiteGray"}>Do you like it?</Body>
                <YSpacer spaceY={2} />
                <FlexRowAlignCenter>
                    <ControlledCheckbox name={"likesTagline"} control={control} />
                    <XSpacer spaceX={6} />
                    <Body12>Yes</Body12>
                </FlexRowAlignCenter>
                <YSpacer spaceY={7} />
                <Body color={"graphiteGray"}>What is it?</Body>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"tagline"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    6. What other details, if any, would you like to share about your personal story to help us get to
                    know you?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"personalStory"} placeholder={""} />
                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>Trust & Value</Header>
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>1. List your top 5 services in order:</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"topFiveServices"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    2. What service(s) do you want your patients to be more aware of? (What do you want to sell more
                    of?)
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"servicesToPromote"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    3. Why should patients choose you for this service? What experience do you have over other doctors?
                    (Please be as specific as possible; ie, # of cases, years doing this service, additional training,
                    etc.)
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput
                    register={register}
                    errors={errors}
                    name={"reasonToChooseForThisService"}
                    placeholder={""}
                />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>4. List the technologies that you utilize in your practice(s).</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"technologies"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    5. Due to COVID-19, what precautions do you take to ensure health and safety during a visit? (Ie.
                    Sterilizing equipment, air purifiers, peroxide rinse stations, check-in/check =-out procedures,etc.)
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"covidPrecautions"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>6. What are some things you do to improve the patient experience?</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput
                    register={register}
                    errors={errors}
                    name={"patientExperienceImprovements"}
                    placeholder={""}
                />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>7. Why else might someone trust his or her teeth to you?</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"reasonsForTrust"} placeholder={""} />
                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>Loyalty & Referrals</Header>
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    1. What character qualities do you most value within your organization?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput
                    register={register}
                    errors={errors}
                    name={"valuedCharacterQualities"}
                    placeholder={""}
                />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>
                    2. What experience do you want your patients to perceive while in your office?
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput
                    register={register}
                    errors={errors}
                    name={"patientPerceptionDesires"}
                    placeholder={""}
                />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>3. What should the attitude of the employees be while at work?</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"employeeAttitudes"} placeholder={""} />
                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>Competition</Header>
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>1. List your top 6 competitors with their website addresses.</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"topSixCompetitors"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>2. Is there anything you feel we should know about your competition?</Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput
                    register={register}
                    errors={errors}
                    name={"competitionInformation"}
                    placeholder={""}
                />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>Completed By:</Body>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"author"} placeholder={""} />
            </Box>
            <YSpacer spaceY={8} />
            <FlexRow justifyContent={"center"} gridColumn={"1 / -1"}>
                <Box width={"330px"}>
                    <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                        save & continue
                    </RightButton>
                </Box>
            </FlexRow>
        </FormTemplate>
    );
}
