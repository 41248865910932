import React, { useEffect, useLayoutEffect } from "react";
import { Normalize } from "styled-normalize";
import { ThemeProvider } from "styled-components";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import theme from "./theme";
import { routerHistory } from "./routerHistory";
import GlobalStyle from "./GlobalStyle";
import jwtDecode from "jwt-decode";
import { slices } from "./redux";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import { Login } from "./Components/Pages";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import logout from "./utils/logout";
import { SetNewPassword } from "./Components/Pages/SetNewPassword";
import { ResetPassword } from "./Components/Pages/PasswordReset";
import { ClientMain, EmployeeMain } from "./PrivateRoute";
import { ClientUserFrontEnd } from "./@types/shared";
import loginWithToken from "./redux/actions/loginWithToken";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "./redux/store";
import { AnyAction } from "redux";

function App(): JSX.Element {
    const currentUser = useAppSelector((state) => state.currentUser);
    const origin = window.location.origin;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (origin.includes("bestfordentistry")) {
            dispatch(slices.isBestForDentistry.actions.set(true));
            setFaviconToBestLogo();
        } else if (origin.includes("hpa")) {
            dispatch(slices.isHpa.actions.set(true));
            setFaviconToHpaLogo();
        }
    }, [origin, dispatch]);
    useTokenInitialization();

    return (
        <>
            <Normalize />
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                <Router history={routerHistory}>
                    <Switch>
                        <Route path={"/login"} render={() => <Login />} />
                        <Route path={"/reset-password"} render={() => <ResetPassword />} />
                        <Route path={"/new-password/:passwordResetToken"} render={() => <SetNewPassword />} />
                        <PrivateRoute path={"/dash"} render={() => renderMain(currentUser.user.type)} />
                        <Route>
                            {currentUser.isAuthenticated ? <Redirect to={"/dash"} /> : <Redirect to={"/login"} />}
                        </Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </>
    );
}

export default App;

function renderMain(userType: string) {
    if (userType === "EMPLOYEE") {
        return <EmployeeMain />;
    } else {
        return <ClientMain />;
    }
}

function useTokenInitialization() {
    const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>();
    const currentUser = useAppSelector((state) => state.currentUser);
    const isAuthenticated = currentUser.isAuthenticated;
    useLayoutEffect(() => {
        try {
            if (localStorage.jwtToken && !isAuthenticated) {
                const token = localStorage.jwtToken;

                const decoded: {
                    exp: number;
                } & ClientUserFrontEnd = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decoded.exp < currentTime) {
                    logout();
                    routerHistory.push("/login");
                } else {
                    dispatch(loginWithToken(token));
                }
            }
        } catch (e) {
            logout()
        }

    }, [isAuthenticated, dispatch]);
}

function setFaviconToBestLogo() {
    return document.getElementById("favicon")?.setAttribute("href", process.env.PUBLIC_URL + "/bestFavicon.ico");
}

function setFaviconToHpaLogo() {
    return document.getElementById("favicon")?.setAttribute("href", process.env.PUBLIC_URL + "/hpaFavicon.ico");
}
