import React from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import {
    DashboardIconButton,
    Gradient,
    GrowthWedge,
    LogoutIconButton,
    OnlineWedge,
    ProfileIconButton,
    ProfitWedge,
    ScheduleCallIconButton,
} from "../Atoms";
import { MenuButtonRow, MenuHeader, MenuTable } from "../Molecules";
import {
    MobileAccountPrimary,
    MobileDashboardPrimary,
    MobileGrowthMenuItem,
    MobileGrowthPrimary,
    MobileOnlineMenuItem,
    MobileOnlinePrimary,
    MobileProfitMenuItem,
    MobileProfitPrimary,
} from "../Atoms/MenuItem";
import { Color } from "../../@types/color";
import { MenuItemComponent, NavItemComponent } from "../../@types/components";
import { MenuTableData } from "../../@types/components/MenuTable";
import { useAppSelector } from "../../redux/hooks";

export const AccountMobileMenu = (): JSX.Element => {
    const account = useAppSelector((state) => state.clientAccount);
    const totalRoiTable = useAppSelector((state) => state.locationOverviewReports.roiTable);

    return (
        <MobileMenu
            backColor={"agateGray"}
            tableBackgroundColor={"chalk"}
            frontColor={"slateGray"}
            menuItems={[MobileAccountPrimary, OnlineWedge, GrowthWedge, ProfitWedge]}
            navButtons={[ScheduleCallIconButton, DashboardIconButton, LogoutIconButton]}
            labelColor={"agateGray"}
            tableName={"grand total roi"}
            name={account.accountName}
            roiTable={totalRoiTable}
            activeSince={account.startDate}
        />
    );
};

export const LocationMobileMenu = (): JSX.Element => {
    const account = useAppSelector((state) => state.clientAccount);
    const totalRoiTable = useAppSelector((state) => state.locationOverviewReports.roiTable);
    return (
        <MobileMenu
            backColor={"agateGray"}
            tableBackgroundColor={"chalk"}
            frontColor={"slateGray"}
            menuItems={[MobileDashboardPrimary, OnlineWedge, GrowthWedge, ProfitWedge]}
            navButtons={[ScheduleCallIconButton, ProfileIconButton, LogoutIconButton]}
            labelColor={"agateGray"}
            tableName={"grand total roi"}
            name={account.accountName}
            roiTable={totalRoiTable}
            activeSince={account.startDate}
        />
    );
};

export const ProfitMobileMenu = (): JSX.Element => {
    const location = useAppSelector((state) => state.openPractice);
    const roiTable = useAppSelector((state) => state.profitReport.roiTable);
    return (
        <MobileMenu
            backColor={"nauticBlue"}
            tableBackgroundColor={"chalk"}
            frontColor={"balticBlue"}
            menuItems={[MobileProfitPrimary, MobileOnlineMenuItem, MobileGrowthMenuItem]}
            navButtons={[ScheduleCallIconButton, DashboardIconButton, ProfileIconButton]}
            labelColor={"balticBlue"}
            tableName={"total roi"}
            name={location.practiceName}
            roiTable={roiTable}
            activeSince={location.startDate}
        />
    );
};

export const GrowthMobileMenu = (): JSX.Element => {
    const location = useAppSelector((state) => state.openPractice);
    const roiTable = useAppSelector((state) => state.growthReport.roiTable);
    return (
        <MobileMenu
            backColor={"deepPetrol"}
            tableBackgroundColor={"aquaBlue"}
            frontColor={"ipanemaBlue"}
            menuItems={[MobileGrowthPrimary, MobileOnlineMenuItem, MobileProfitMenuItem]}
            navButtons={[ScheduleCallIconButton, DashboardIconButton, ProfileIconButton]}
            labelColor={"ipanemaBlue"}
            tableName={"growth roi"}
            name={location.practiceName}
            roiTable={roiTable}
            activeSince={location.startDate}
        />
    );
};

export const OnlineMobileMenu = (): JSX.Element => {
    const location = useAppSelector((state) => state.openPractice);
    const roiTable = useAppSelector((state) => state.onlineReport.roiTable);
    return (
        <MobileMenu
            backColor={"forrestGreen"}
            tableBackgroundColor={"catskillWhite"}
            frontColor={"seaGreen"}
            menuItems={[MobileOnlinePrimary, MobileGrowthMenuItem, MobileProfitMenuItem]}
            navButtons={[ScheduleCallIconButton, DashboardIconButton, ProfileIconButton]}
            labelColor={"auratiumGreen"}
            tableName={"online roi"}
            name={location.practiceName}
            roiTable={roiTable}
            activeSince={location.startDate}
        />
    );
};

type MobileMenuProps = {
    frontColor: Color;
    backColor: Color;
    labelColor: Color;
    tableBackgroundColor: Color;
    menuItems: MenuItemComponent[];
    navButtons: NavItemComponent[];
    name: string;
    activeSince: string;
    roiTable: MenuTableData;
    tableName: string;
};

const MobileMenu = ({
    frontColor,
    backColor,
    labelColor,
    tableBackgroundColor,
    menuItems,
    navButtons,
    tableName,
    name,
    activeSince,
    roiTable,
}: MobileMenuProps) => {
    return (
        <Box>
            <Gradient />
            <Box backgroundColor={frontColor} height={5} />
            <Box height={11} backgroundColor={backColor}>
                <MenuButtonRow menuItems={menuItems} navItems={navButtons} />
            </Box>
            <Box backgroundColor={tableBackgroundColor}>
                <FlexRowAlignCenter height={16} pl={15}>
                    <MenuHeader labelColor={labelColor} name={name} dateColor={backColor} date={activeSince} />
                </FlexRowAlignCenter>
                <FlexRowAlignCenter height={"92px"} justifyContent={"center"} px={6}>
                    <MenuTable name={tableName} labelColor={labelColor} dataColor={backColor} data={roiTable} />
                </FlexRowAlignCenter>
            </Box>
        </Box>
    );
};
