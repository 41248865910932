import React, { useEffect } from "react";
import { FormTemplate } from "./FormTemplate";
import { Box, FlexRow, YSpacer } from "../../styledSystemUtilities";
import { Body, Body12, Header } from "../../Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { bool, object, string } from "yup";
import { ControlledCheckbox, NumberedText, RegisteredBigInput, RegisteredInput } from "../../Molecules";
import { RightButton } from "../../Atoms";
import { DomainExplanation } from "../../Atoms/assets";
import { useAppSelector } from "../../../redux/hooks";
import { DomainForm } from "../../../@types/forms/Types";
import { useProductFormSubmit } from "../../../hooks/useProductFormSubmit";

// domainRegistrarLogin: string()
//         .when("cannotFindDomainRegistrarInformation", {
//             is: false,
//             then: string().required("Must enter login"),
//         })
//         .trim(),

const schema = object().shape({
    currentWebsiteAddress: string().trim(),
    domainRegistrar: string().trim(),
    domainRegistrarLogin: string().trim(),
    domainRegistrarPassword: string().trim(),
    cannotFindDomainRegistrarInformation: bool(),
    wantsNewWebsiteAddress: bool(),
    hasDomainBrandedEmails: bool(),
    emailsInUse: string().trim(),
    accessesEmailsInBrowser: bool(),
    patientContactEmailAddress: string().trim(),
});

const formDefaultValues = {
    currentWebsiteAddress: "",
    domainRegistrar: "",
    domainRegistrarLogin: "",
    domainRegistrarPassword: "",
    cannotFindDomainRegistrarInformation: false,
    wantsNewWebsiteAddress: false,
    hasDomainBrandedEmails: false,
    emailsInUse: "",
    accessesEmailsInBrowser: false,
    patientContactEmailAddress: "",
};

export function Domain(): JSX.Element {
    const form = useAppSelector((state) => state.form);
    const superviseSubmit = useProductFormSubmit<DomainForm>("online", "domainForms");
    const { register, handleSubmit, errors, control, reset } = useForm<DomainForm>({
        resolver: yupResolver(schema),
        defaultValues: form || formDefaultValues,
    });

    useEffect(() => {
        reset(form || formDefaultValues);
    }, [form, reset]);

    return (
        <FormTemplate
            productName={"Website"}
            taskName={"Domain & emails"}
            redLabel={"Required Information"}
            blackLabel={"Info we need to set up your site"}
            onSubmit={handleSubmit(superviseSubmit)}
        >
            <Box>
                <Header color={"graphiteGray"}>Domain</Header>
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>What is your current website address?</Body>
                <Body12>Also known as your DOMAIN</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"currentWebsiteAddress"} placeholder={""} />
                <Body color={"graphiteGray"}>
                    What company currently holds the registration of your website address?
                </Body>
                <Body12>Also known as your DOMAIN REGISTRAR</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"domainRegistrar"} placeholder={""} />
                <Body color={"graphiteGray"}>We need to login to your DOMAIN REGISTRAR</Body>
                <Body12>
                    This allows us to ensure that when someone enters your website address, your website appears in the
                    browser.
                    <br />
                    <br />
                    What is your login name for your DOMAIN REGISTRAR?
                </Body12>
                <RegisteredInput register={register} errors={errors} name={"domainRegistrarLogin"} placeholder={""} />
                <Body12>What is your password for your DOMAIN REGISTRAR?</Body12>
                <RegisteredInput
                    register={register}
                    errors={errors}
                    name={"domainRegistrarPassword"}
                    placeholder={""}
                />
                <FlexRow>
                    <ControlledCheckbox name={"cannotFindDomainRegistrarInformation"} control={control} />
                    <Box pl={6} pt={2}>
                        <Body12>I can't find this information</Body12>
                        <Body12>
                            (If you check this box, an Ascent representative will contact you to guide you through this
                            process.)
                        </Body12>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={6} />
                <Body color={"graphiteGray"}>Would you like a new website address?</Body>
                <YSpacer spaceY={6} />
                <FlexRow>
                    <ControlledCheckbox name={"wantsNewWebsiteAddress"} control={control} />
                    <Box pl={6} pt={2}>
                        <Body12>Yes</Body12>
                        <Body12>
                            (If you check this box, an Ascent representative will contact you to guide you through this
                            process.)
                        </Body12>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={8} />
                <Header color={"graphiteGray"}>Domain Branded Emails</Header>
                <YSpacer spaceY={2} />
                <Body12>
                    When you purchase your domain, it generally comes with domain branded emails (name@yourwebsite.com)
                </Body12>
                <YSpacer spaceY={5} />
                <FlexRow>
                    <ControlledCheckbox name={"hasDomainBrandedEmails"} control={control} />
                    <Box pl={6} pt={2}>
                        <Body12>Practice currently has domain-branded emails</Body12>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={6} />
                <Body12>List of email addresses in use in the practice</Body12>
                <YSpacer spaceY={6} />
                <RegisteredBigInput register={register} errors={errors} name={"emailsInUse"} placeholder={""} />
                <YSpacer spaceY={6} />
                <FlexRow>
                    <ControlledCheckbox name={"accessesEmailsInBrowser"} control={control} />
                    <Box pl={6} pt={2}>
                        <Body12>Check if you access your emails in a web browser</Body12>
                    </Box>
                </FlexRow>
                <YSpacer spaceY={6} />
                <Body12>Email address used for patient contact"</Body12>
                <RegisteredInput
                    register={register}
                    errors={errors}
                    name={"patientContactEmailAddress"}
                    placeholder={""}
                />
            </Box>
            <Box>
                <Body textAlign={"center"} color={"graphiteGray"}>
                    How your Domain works:
                </Body>
                <YSpacer spaceY={6} />
                <Box pl={6} pb={6}>
                    <DomainExplanation />
                </Box>
                <NumberedText number={1}>Your web address is entered into a browser</NumberedText>
                <NumberedText number={2}>
                    The address is identified as your domain by the company that holds the registration of your website
                    address (Domain Registrar)
                </NumberedText>
                <NumberedText number={3}>
                    The domain registrar points the web address to the server where your website data files are being
                    stored (Website Hosting)
                </NumberedText>
                <NumberedText number={4}>
                    The website data is uploaded into the browser and your website is visible.{" "}
                </NumberedText>
                <Body color={"graphiteGray"}>
                    As we will be hosting your site, we need domain access to ensure your domain points to our servers
                </Body>
            </Box>
            <YSpacer spaceY={8} />
            <FlexRow justifyContent={"center"} gridColumn={"1 / -1"}>
                <Box width={"330px"}>
                    <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                        save & continue
                    </RightButton>
                </Box>
            </FlexRow>
        </FormTemplate>
    );
}
