import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash/fp";

const initialState = {
    accountPractices: false,
    clientAccount: false,
    growthReport: false,
    locationOverviewReports: false,
    onlineReport: false,
    profitReport: false,
    totalRoiTable: false,
    onlineProducts: false,
    profitCenters: false,
    form: false,
};

type LoadingLabels = keyof typeof initialState;

export default createSlice({
    name: "loading",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<LoadingLabels>) => _.set(action.payload, true, state),
        unset: (state, action: PayloadAction<LoadingLabels>) => _.set(action.payload, false, state),
    },
});
