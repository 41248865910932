import { combineReducers } from "redux";
import slices from "./slices";

const rootReducer = combineReducers({
    currentUser: slices.currentUser.reducer,
    serverErrors: slices.serverErrors.reducer,
    loading: slices.loading.reducer,
    clientAccount: slices.clientAccount.reducer,
    accountPractices: slices.accountPractices.reducer,
    locationOverviewReports: slices.locationOverviewReports.reducer,
    openPractice: slices.openPractice.reducer,
    onlineReport: slices.onlineReport.reducer,
    growthReport: slices.growthReport.reducer,
    profitReport: slices.profitReport.reducer,
    isBestForDentistry: slices.isBestForDentistry.reducer,
    paymentModal: slices.paymentModal.reducer,
    clientPractices: slices.clientPractices.reducer,
    kpiMonths: slices.kpiMonths.reducer,
    profitCenters: slices.profitCenters.reducer,
    cart: slices.cart.reducer,
    popup: slices.popup.reducer,
    activeCoupons: slices.activeCoupons.reducer,
    form: slices.form.reducer,
    openTask: slices.openTask.reducer,
    websiteFiles: slices.websiteFiles.reducer,
    isHpa: slices.isHpa.reducer,
});

export default rootReducer;
