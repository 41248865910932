import { createSlice } from "@reduxjs/toolkit";
import { Product } from "../../@types/reports/ProfitCenters";
import _ from "lodash/fp";

const initialState: Product[] = [];

export default createSlice({
    name: "cart",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
        toggleProduct: (state, action) =>
            includesById(action.payload.id, state)
                ? _.reject({ id: action.payload.id }, state)
                : state.concat(action.payload),
        updateProduct: (state, action) =>
            _.map((item) => (item.id === action.payload.id ? action.payload : item), state),
    },
});

const includesById = (id: string, cart: Product[]) => _.flow(_.find(["id", id]), Boolean)(cart);
