import { Box, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { AdsIcon } from "../../Atoms/assets";
import { Body14Bold, Header, Label } from "../../Typography";
import { BoldDataLabelAndCell, DataLabelAndCell } from "../../Molecules";
import { CampaignMonthlyAverages } from "../CampaignMonthlyAverages";
import { MonthToDateAnalyticsTable } from "../MonthToDateAnalyticsTable";
import React from "react";
import { GoogleAdsCampaignReport } from "../../../@types/reports/Growth";
import { formatAsDollars } from "../../../utils/formatter";
import _ from "lodash/fp";
import constants from "../../../constants";
import { format } from "date-fns/fp";

export function AdsCampaignOverview({
    googleAdsCampaignReport,
}: {
    googleAdsCampaignReport: GoogleAdsCampaignReport;
}): JSX.Element {
    return (
        <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
            <Box
                backgroundColor={"aquaBlue"}
                px={8}
                pt={8}
                data-testid={"adsCampaignOverview"}
                width={"100%"}
                gridColumn={2}
            >
                <FlexRowAlignCenter backgroundColor={"white"} pl={6} height={13} width={"100%"}>
                    <AdsIcon />
                    <XSpacer spaceX={3} />
                    <Header color={"deepPetrol"}>Google Ads</Header>
                </FlexRowAlignCenter>
                <YSpacer spaceY={5} />
                <BoldDataLabelAndCell
                    label={"Months Active:"}
                    data={googleAdsCampaignReport.monthsActive}
                    color={"deepPetrol"}
                    border={"lightBlue"}
                />
                <YSpacer spaceY={5} />
                <BoldDataLabelAndCell
                    label={"Patient Acquisition Cost:"}
                    data={formatAsDollars(_.round(googleAdsCampaignReport.averages.averageCostPerPatient))}
                    color={"deepPetrol"}
                    border={"lightBlue"}
                    testId={"patientAcquisitionCost"}
                />
                <YSpacer spaceY={7} />
                <Body14Bold color={"deepPetrol"}>Current Monthly</Body14Bold>
                <YSpacer spaceY={4} />
                <DataLabelAndCell
                    label={"New Patients Average:"}
                    data={_.round(googleAdsCampaignReport.averages.averageNewPatients * 10) / 10}
                    color={"petrolBlue"}
                    dataColor={"sienna"}
                    cellColor={"white"}
                    testId={"newPatientsAverage"}
                />
                <YSpacer spaceY={5} />
                <DataLabelAndCell
                    label={"Google Ads Spend Average:"}
                    data={formatAsDollars(_.round(googleAdsCampaignReport.averages.averageAdSpend))}
                    color={"petrolBlue"}
                    dataColor={"white"}
                    cellColor={"ipanemaBlue"}
                    testId={"googleAdSpend"}
                />
                <YSpacer spaceY={3} />
                <Label color={"petrolBlue"}>
                    + Ascent's fixed management (${constants.ascentGoogleAds.ASCENT_MANAGEMENT_FEE}) & call tracking ($
                    {constants.ascentGoogleAds.CALL_TRACKING_FEE}) fees:
                </Label>
                <YSpacer spaceY={3} />
                <DataLabelAndCell
                    label={"Total Cost Average:"}
                    data={formatAsDollars(
                        _.round(
                            googleAdsCampaignReport.averages.averageAdSpend +
                                constants.ascentGoogleAds.ASCENT_MANAGEMENT_FEE +
                                constants.ascentGoogleAds.CALL_TRACKING_FEE
                        )
                    )}
                    color={"petrolBlue"}
                    dataColor={"deepPetrol"}
                    cellColor={"edgeWater"}
                    testId={"totalCost"}
                />
                <YSpacer spaceY={7} />
                <Header color={"deepPetrol"}>Campaign Performance</Header>
                <YSpacer spaceY={6} />
                <Body14Bold color={"petrolBlue"}>Campaign Monthly Averages:</Body14Bold>
                <YSpacer spaceY={5} />
                <CampaignMonthlyAverages campaignAverages={googleAdsCampaignReport.averages} />
                <YSpacer spaceY={8} />
                <MonthToDateAnalyticsTable
                    monthToDateAnalytics={[
                        {
                            label: "Views",
                            data: googleAdsCampaignReport.dailyUpdate.impressions,
                        },
                        {
                            label: "Clicks",
                            data: googleAdsCampaignReport.dailyUpdate.clicks,
                        },
                        {
                            label: "Click Through Rate",
                            data: _.round(googleAdsCampaignReport.dailyUpdate.clickThroughRate * 100) + "%",
                        },
                        {
                            label: "Calls",
                            data: googleAdsCampaignReport.dailyUpdate.calls,
                        },
                        {
                            label: "Click to Call %",
                            data: _.round(googleAdsCampaignReport.dailyUpdate.clickToCall * 100) + "%",
                        },
                        {
                            label: "Call Conversion %",
                            data: _.round(googleAdsCampaignReport.dailyUpdate.callConversion * 100) + "%",
                        },
                        {
                            label: "Appointments",
                            data: googleAdsCampaignReport.dailyUpdate.newPatients,
                        },
                    ]}
                    primaryColor={"deepPetrol"}
                    secondaryColor={"petrolBlue"}
                    monthAndYear={format("MMMM u", googleAdsCampaignReport.dailyUpdate.date)}
                    lastUpdate={format("P", googleAdsCampaignReport.dailyUpdate.date)}
                />
                <YSpacer spaceY={8} />
            </Box>
        </Grid>
    );
}
