import React, { FunctionComponent, SVGProps } from "react";
import { Box, Span } from "../styledSystemUtilities";
import { LionSpeakLogo, X } from "../Atoms/assets";
import { useWindowSize } from "react-use";
import { Body14, Body14Bold, Body14Medium, Body20Bold, BodyBold, Header27, Header30, Label } from "../Typography";
import { Color } from "../../@types/color";

type Header = {
    setPopup: () => void;
    name?: string;
    tagline?: string;
};

export function OnlineHeader({
    setPopup,
    Hero,
}: Header & { Hero: ({ name, tagline }: { name?: string; tagline?: string }) => JSX.Element }): JSX.Element {
    const { width } = useWindowSize();
    return (
        <>
            <Box
                backgroundColor={"auratiumGreen"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box onClick={setPopup} cursor={"pointer"} display={"flex"} alignItems={"center"} data-testid={"close"}>
                    <X />
                    <Box ml={5} />
                    <Body14 color={"white"}>Close</Body14>
                </Box>
            </Box>
            <Hero />

            <Box backgroundColor={"chalk"} mt={"-28px"} height={"28px"} />
        </>
    );
}

export function GrowthHeader({
    setPopup,
    Hero,
}: Header & { Hero: ({ name, tagline }: { name?: string; tagline?: string }) => JSX.Element }): JSX.Element {
    const { width } = useWindowSize();
    return (
        <>
            <Box
                backgroundColor={"petrolBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box onClick={setPopup} cursor={"pointer"} display={"flex"} alignItems={"center"} data-testid={"close"}>
                    <X />
                    <Box ml={5} />
                    <Body14 color={"white"}>Close</Body14>
                </Box>
            </Box>
            <Hero />
            <Box backgroundColor={"chalk"} mt={"-27px"} height={"27px"} />
        </>
    );
}

export function ProfitHeader({
    setPopup,
    Hero,
    name,
    tagline,
}: Header & { Hero: ({ name, tagline }: { name?: string; tagline?: string }) => JSX.Element }): JSX.Element {
    const { width } = useWindowSize();
    return (
        <>
            <Box
                backgroundColor={"balticBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box onClick={setPopup} cursor={"pointer"} display={"flex"} alignItems={"center"} data-testid={"close"}>
                    <X />
                    <Box ml={5} />
                    <Body14 color={"white"}>Close</Body14>
                </Box>
            </Box>
            <Hero name={name} tagline={tagline} />
            <Box backgroundColor={"chalk"} mt={"-27px"} height={"27px"} />
        </>
    );
}

export function LionSpeakHeader({ name, tagline, setPopup }: Header): JSX.Element {
    const { width } = useWindowSize();
    return (
        <Box>
            <Box
                backgroundColor={"balticBlue"}
                height={"31px"}
                pl={"23px"}
                display={"flex"}
                alignItems={"center"}
                position={"fixed"}
                width={`${width > 375 ? 375 : width}px`}
            >
                <Box onClick={setPopup} cursor={"pointer"} display={"flex"} alignItems={"center"} data-testid={"close"}>
                    <X />
                    <Box ml={5} />
                    <Body14 color={"white"}>Close</Body14>
                </Box>
            </Box>
            <Box pb={"21px"} />
            <Box pl={"21px"} height={"158px"} backgroundColor={"backgroundGray"}>
                <Box pb={8} />
                <Header27 color={"nauticBlue"}>{name}</Header27>
                <Box pb={9} />
                <Header30 color={"white"}>{tagline}</Header30>
            </Box>
            <Box
                backgroundColor={"white"}
                height={"269px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <LionSpeakLogo />
            </Box>
            <Box height={"50px"} backgroundColor={"chalk"} />
        </Box>
    );
}

export function Disclaimers(): JSX.Element {
    return (
        <Box pb={6}>
            <Label lineHeight={"14px"}>
                *Estimated Revenue is based on your annual per patient value and your average monthly new patients.
            </Label>
            <Label lineHeight={"14px"}>
                **ROI calculations use projected revenue and minus your ads-end budget and Ascent management fees.
            </Label>
        </Box>
    );
}

export function SamplesHeader({ color }: { color: Color }): JSX.Element {
    return (
        <Box mx={"11px"} pt={7} pb={7}>
            <Header27 color={color}>Samples</Header27>
        </Box>
    );
}

export function PricingNotes({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <GrayBackground>
            <Box pt={10} />
            <BodyBold>Pricing Notes</BodyBold>
            <Box mb={5} />
            <Body14>{children}</Body14>
            <Box pb={11} />
        </GrayBackground>
    );
}

export function SellingPoint({
    header,
    body,
    lineHeight = "20px",
}: {
    header: string;
    body: string;
    lineHeight?: string;
}): JSX.Element {
    return (
        <Box pb={7}>
            <BodyBold>{header}</BodyBold>
            <Box ml={"17px"} mt={2}>
                <Body14 lineHeight={lineHeight} whiteSpace={"pre-line"}>
                    {body}
                </Body14>
            </Box>
        </Box>
    );
}

export function IconContainer({ name, children }: { name: string; children?: React.ReactNode }): JSX.Element {
    return (
        <Box mt={4}>
            <Box borderBottom={"mid"}>
                <Body14Bold>{name}</Body14Bold>
                <Box mb={2} />
            </Box>
            <Box display={"grid"} gridRowGap={"8px"} gridTemplateColumns={"1fr 1fr 1fr"} mt={"14px"}>
                {children}
            </Box>
        </Box>
    );
}

export function ReportingHeader({ product, color }: { product: string; color: Color }): JSX.Element {
    return (
        <Box py={5}>
            <Header27 color={color}>Reporting</Header27>
            <Box mb={2} />
            <Body14Medium>How will you know if your {product} is effective?</Body14Medium>
        </Box>
    );
}

export function ReportingCard({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <>
            <Box px={"21px"} pt={"18px"} backgroundColor={"white"}>
                {children}
            </Box>
            <Box height={6} backgroundColor={"chalk"} />
        </>
    );
}

export function NumberedParagraph({ header, body }: { header: string; body: string }): JSX.Element {
    return (
        <Box pb={6}>
            <Body14 lineHeight={"20px"}>
                <Span fontWeight={"medium"}>{header}</Span> - {body}
            </Body14>
        </Box>
    );
}

export function Paragraph({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <Box mb={6}>
            <Body14 lineHeight={"20px"}>{children}</Body14>
        </Box>
    );
}

export function GrayBackground({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <Box px={"21px"} backgroundColor={"chalk"}>
            {children}
        </Box>
    );
}

export function WhiteBackground({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <>
            <Box px={"21px"} py={"22px"} backgroundColor={"white"}>
                {children}
            </Box>
            <Box backgroundColor={"chalk"} height={"14px"} />
        </>
    );
}

export function SampleContainer({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <Box px={5} backgroundColor={"chalk"}>
            {children}
        </Box>
    );
}

export function Dimensions({ bold, light }: { bold: string; light: string }): JSX.Element {
    return (
        <>
            <IconContainer name={"Dimensions"} />
            <Box display={"flex"} alignItems={"center"} pl={3} pb={7}>
                <Body20Bold>{bold}&nbsp;</Body20Bold>
                <Body14>{light}</Body14>
            </Box>
        </>
    );
}

export function IconBulletPoint({
    Icon,
    header,
    body,
}: {
    Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    header: string;
    body: string;
}): JSX.Element {
    return (
        <Box display={"flex"} pt={5} pb={10}>
            <Box width={"36px"} display={"flex"} justifyContent={"center"}>
                <Icon />
            </Box>

            <Box ml={"13px"} />
            <Box>
                <BodyBold>{header}</BodyBold>
                <Box ml={7}>
                    <Body14>{body}</Body14>
                </Box>
            </Box>
        </Box>
    );
}
