import { api } from "../utilityActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import slices from "../slices";
import { PaymentOption, Product } from "../../@types/reports/ProfitCenters";
import getProfitCenters from "./getProfitCenters";
import getActiveCoupons from "./getActiveCoupons";

const makeProfitPurchase = (
    practiceId: string,
    products: Product[],
    paymentOption: PaymentOption,
    couponCodes: string[]
): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/recurly/profit/make-purchase`,
            method: "post",
            data: {
                practiceId,
                products,
                paymentOption,
                couponCodes,
            },
        },
        success: (amountCharged: number) => [
            getProfitCenters(practiceId),
            getActiveCoupons(practiceId),
            slices.paymentModal.actions.set({ openModal: "profitThankYou", amountCharged }),
        ],
        label: "makeProfitPurchase",
    });

export default makeProfitPurchase;
