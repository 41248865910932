import React from "react";
import { FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { Color } from "../../@types/color";
import { Body12 } from "../Typography";
import { Dot } from "./Dot";

export const LegendDot = ({
    label,
    fill,
    labelFill,
}: {
    label: string | number;
    fill: Color;
    labelFill?: Color;
}): JSX.Element => (
    <FlexRowAlignCenter>
        <Dot fill={fill} />
        <XSpacer spaceX={3} />
        <Body12 color={labelFill}>{label}</Body12>
    </FlexRowAlignCenter>
);
