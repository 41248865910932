import { api } from "../utilityActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import getWebsiteFiles from "./getWebsiteFiles";

const uploadFiles = (practiceId: string, formData: FormData, folder: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/product-forms/upload-file`,
            data: formData,
            method: "post",
            headers: { bucket: practiceId, folder: folder },
        },
        success: () => getWebsiteFiles(practiceId),
        label: "form",
    });

export default uploadFiles;
