import React from "react";
import { Box, FlexColAlignCenter, Grid, YSpacer } from "../../styledSystemUtilities";
import { AbsolutePositionLogo, Gradient } from "../../Atoms";
import { Body, Header27 } from "../../Typography";
import { useWindowSize } from "react-use";
import theme from "../../../theme";
import { useAppSelector } from "../../../redux/hooks";
import { LoadingWrapper } from "../../Molecules";

export const FormTemplate = ({
    productName,
    taskName,
    redLabel,
    blackLabel,
    onSubmit,
    children,
}: {
    productName: string;
    taskName: string;
    redLabel: string;
    blackLabel: string;
    onSubmit: React.FormEventHandler<HTMLFormElement>;
    children: React.ReactNode;
}): JSX.Element => {
    const { width } = useWindowSize();
    const isLoading = useAppSelector((state) => state.loading.form);

    return (
        <Box>
            <Gradient />
            <AbsolutePositionLogo />
            <LoadingWrapper color={"auratiumGreen"} isLoading={isLoading}>
                <Grid gridTemplateColumns={"1fr minmax(320px, 724px) 1fr"} pb={16}>
                    <Box gridColumn={2}>
                        <form onSubmit={onSubmit}>
                            <Grid
                                gridTemplateColumns={width > theme.breakpoints[0] ? "1fr 1fr" : "1fr"}
                                gridColumnGap={13}
                            >
                                <FlexColAlignCenter gridColumn={"1/-1"} pt={14} pb={7}>
                                    <Header27 color={"graphiteGray"}>{productName}</Header27>
                                    <YSpacer spaceY={6} />
                                    <Header27 color={"graphiteGray"}>{taskName}</Header27>
                                    <Body color={"amaranthRed"}>{redLabel}</Body>
                                    <Body color={"graphiteGray"}>{blackLabel}</Body>
                                </FlexColAlignCenter>
                                {children}
                            </Grid>
                        </form>
                    </Box>
                </Grid>
            </LoadingWrapper>
        </Box>
    );
};
