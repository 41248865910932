import {
    AccordianBrochure,
    AncillaryServices,
    BrandDefinition,
    BusinessCards,
    CaseAcceptance,
    EightPageBrochure,
    EnhancedSeo,
    EnhancedWebsite,
    Envelopes,
    EssentialSeo,
    EssentialWebsite,
    InsuranceManagement,
    Letterhead,
    LogoDesign,
    PatientReferrals,
    PhoneSkills,
    PresentationFolders,
    PromotionalCards,
    PromotionalFlyers,
    Reactivation,
    ReferralCards,
    ReviewGeneration,
    SocialForSeo,
    TriFoldBrochure,
    MySocialPracticeSelf,
    MySocialPracticePro,
    PremiumDentalMenu,
    StandardDentalMenu,
} from "../Components/Organisms/Popups";

export const popups = {
    essentialWebsite: EssentialWebsite,
    reviewGeneration: ReviewGeneration,
    essentialSeo: EssentialSeo,
    enhancedSeo: EnhancedSeo,
    socialForSeo: SocialForSeo,
    enhancedWebsite: EnhancedWebsite,
    brandDefinition: BrandDefinition,
    logoDesign: LogoDesign,
    insuranceManagement: InsuranceManagement,
    phoneSkillsTraining: PhoneSkills,
    patientReferralTraining: PatientReferrals,
    caseAcceptanceTraining: CaseAcceptance,
    ancillaryServicesTraining: AncillaryServices,
    reactivationTraining: Reactivation,
    businessCards: BusinessCards,
    envelopes: Envelopes,
    letterhead: Letterhead,
    triFoldBrochure: TriFoldBrochure,
    eightPageBrochure: EightPageBrochure,
    accordionBrochure: AccordianBrochure,
    presentationFolders: PresentationFolders,
    promotionalFlyers: PromotionalFlyers,
    promotionalCards: PromotionalCards,
    patientReferralCards: ReferralCards,
    ancillaryServicesBrochure: AncillaryServices,
    reactivationCards: Reactivation,
    mySocialPracticeSelf: MySocialPracticeSelf,
    mySocialPracticePro: MySocialPracticePro,
    standardDentalMenu: StandardDentalMenu,
    premiumDentalMenu: PremiumDentalMenu,
};
