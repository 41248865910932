import React from "react";
import { Box } from "../styledSystemUtilities";
import onlineBackgroundGradient from "../Atoms/assets/onlineBackgroundGradient.jpg";
import growthBackgroundGradient from "../Atoms/assets/growthBackgroundGradient.jpg";
import profitBackgroundGradient from "../Atoms/assets/profitBackgroundGradient.jpg";
import overviewBackgroundGradient from "../Atoms/assets/overviewBackgroundGradient.jpg";
import { OnlineMenu, GrowthMenu, ProfitMenu } from "../Organisms";
import { GrowthPhaseLabel, OnlinePhaseLabel, ProfitPhaseLabel } from "../Molecules";
import { useWindowSize } from "react-use";
import { useTheme } from "styled-components";
import { useAppSelector } from "../../redux/hooks";

type SuperTemplateProps = {
    children: React.ReactNode;
};

export const OnlineTemplate = ({ children }: SuperTemplateProps): JSX.Element => (
    <Template
        backgroundGradient={onlineBackgroundGradient}
        Menu={OnlineMenu}
        PhaseLabel={OnlinePhaseLabel}
        children={children}
    />
);

export const GrowthTemplate = ({ children }: SuperTemplateProps): JSX.Element => (
    <Template
        backgroundGradient={growthBackgroundGradient}
        Menu={GrowthMenu}
        PhaseLabel={GrowthPhaseLabel}
        children={children}
    />
);

export const ProfitTemplate = ({ children }: SuperTemplateProps): JSX.Element => (
    <Template
        backgroundGradient={profitBackgroundGradient}
        Menu={ProfitMenu}
        PhaseLabel={ProfitPhaseLabel}
        children={children}
    />
);

export const OverviewTemplate = ({ children, Menu }: SuperTemplateProps & { Menu: () => JSX.Element }): JSX.Element => (
    <Template
        backgroundGradient={overviewBackgroundGradient}
        Menu={Menu}
        PhaseLabel={() => <div />}
        children={children}
    />
);

type TemplateProps = {
    backgroundGradient: React.ReactNode;
    Menu: () => JSX.Element;
    PhaseLabel: () => JSX.Element;
    children: React.ReactNode;
};

const Template = ({ backgroundGradient, Menu, PhaseLabel, children }: TemplateProps) => {
    const { width } = useWindowSize();
    const openModal = useAppSelector((state) => state.paymentModal.openModal);
    const popup = useAppSelector((state) => state.popup);
    const theme = useTheme();

    return (
        <Box position={openModal || popup ? "fixed" : "relative"} width={"100%"}>
            <Menu />
            <Box
                zIndex={-300}
                position={"absolute"}
                height={"100vh"}
                width={"100vw"}
                backgroundImage={`url(${backgroundGradient})`}
                backgroundPosition={"center top"}
                backgroundSize={"cover"}
            />
            <PhaseLabel />
            <Box pt={width > theme.breakpoints[0] ? "142px" : "236px"} />
            {children}
        </Box>
    );
};
