import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import { api } from "../utilityActions";
import { Event } from "../../@types/shared"

const addEvent = (event: Event): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/events/add-event`,
            data: {
                event: event,
            },
            method: "post",
        },
        label: "events",
    });

export default addEvent;
