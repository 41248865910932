import { api, historyPush } from "../utilityActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import loginWithToken from "./loginWithToken";

const setNewPassword = (newPassword: string, passwordResetToken: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: "/open-routes/authentication/set-new-password",
            method: "post",
            data: {
                newPassword,
                passwordResetToken,
            },
        },
        success: (token: string) => [loginWithToken(token), historyPush("/dash")],
        label: "setNewPassword",
    });

export default setNewPassword;
