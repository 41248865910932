import React from "react";
import { Box, FlexRow, FlexRowAlignCenter, YSpacer } from "../styledSystemUtilities";
import { Cell, LeftCornerLine, MiddleLine, Operator, RightCornerLine, Row, WhiteCell } from "../Molecules";
import { Body12Bold, Label, LabelBold } from "../Typography";
import { GoogleAdsCampaignReport } from "../../@types/reports/Growth";
import _ from "lodash/fp";
import { formatAsDollars } from "../../utils/formatter";
import constants from "../../constants";
import ascentGoogleAds from "../../constants/ascentGoogleAds";

export const PPCDataPath = ({
    campaignReport,
    newGoal,
}: {
    campaignReport: GoogleAdsCampaignReport;
    newGoal: number;
}): JSX.Element => {
    const safeCallConversion = campaignReport.averages.averageCallConversion
        ? campaignReport.averages.averageCallConversion
        : 0.1;
    const safeClickToCall = campaignReport.averages.averageClickToCall
        ? campaignReport.averages.averageClickToCall
        : ascentGoogleAds.nationalAverages.CLICK_TO_CALL;
    const neededCalls = newGoal / safeCallConversion;
    const neededClicks = neededCalls / safeClickToCall;
    const newAdSpend = neededClicks * campaignReport.averages.averageCostPerClick;

    return (
        <Box px={"10px"}>
            <Box display={"flex"}>
                <Box width={6} />
                <Row>
                    <WhiteCell value={newGoal} color={"sienna"} label={"New Patient Goal"} />
                    <Operator operator={"/"} />
                    <Cell
                        value={`${_.round(safeCallConversion * 100)}%`}
                        color={"ipanemaBlue"}
                        label={"Call Conversion %"}
                    />
                    <Operator operator={"="} />
                    <Cell value={_.round(neededCalls * 10) / 10} label={"Needed calls"} />
                </Row>
                <RightCornerLine />
            </Box>
            <MiddleLine />
            <Box display={"flex"}>
                <LeftCornerLine operator={"/"} />
                <Row>
                    <Cell
                        value={`${_.round(safeClickToCall * 100)}%`}
                        color={"ipanemaBlue"}
                        label={"Click to call %"}
                    />
                    <Operator operator={"="} />
                    <Cell value={_.round(neededClicks)} label={"Needed clicks"} />
                    <Operator operator={"x"} />
                    <Cell
                        value={formatAsDollars(_.round(campaignReport.averages.averageCostPerClick * 100) / 100)}
                        color={"ipanemaBlue"}
                        label={"Cost per click"}
                    />
                </Row>
                <RightCornerLine />
            </Box>
            <MiddleLine />
            <Box display={"flex"}>
                <LeftCornerLine operator={"="} height={"10px"} />
                <Row>
                    <FlexRowAlignCenter
                        backgroundColor={"ipanemaBlue"}
                        height={"24px"}
                        width={"100%"}
                        justifyContent={"center"}
                    >
                        <Body12Bold color={"white"}>{formatAsDollars(_.round(newAdSpend))}</Body12Bold>
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter gridColumn={"2 / -1"} height={"24px"} justifySelf={"start"} pl={4}>
                        <LabelBold color={"petrolBlue"}>New Google Ads monthly spend</LabelBold>
                    </FlexRowAlignCenter>
                </Row>
                <Box width={"8px"} />
            </Box>
            <YSpacer spaceY={6} />

            <FlexRow alignItems={"flex-end"}>
                <Operator operator={"+"} />
                <Box pl={"4px"} />
                <Row>
                    <Cell
                        value={formatAsDollars(constants.ascentGoogleAds.ASCENT_MANAGEMENT_FEE)}
                        label={"Ascent Management"}
                    />
                    <Operator operator={"+"} />
                    <Cell
                        value={formatAsDollars(constants.ascentGoogleAds.CALL_TRACKING_FEE)}
                        label={"Call Tracking"}
                    />
                    <Operator operator={"="} />
                    <Box width={"100%"}>
                        <LabelBold color={"petrolBlue"} textAlign={"center"}>
                            New Total Cost
                        </LabelBold>{" "}
                        <YSpacer spaceY={2} />
                        <FlexRowAlignCenter
                            backgroundColor={"edgeWater"}
                            height={"24px"}
                            width={"100%"}
                            justifyContent={"center"}
                        >
                            <Body12Bold color={"deepPetrol"}>
                                {formatAsDollars(
                                    _.round(
                                        newAdSpend +
                                            constants.ascentGoogleAds.ASCENT_MANAGEMENT_FEE +
                                            constants.ascentGoogleAds.CALL_TRACKING_FEE
                                    )
                                )}
                            </Body12Bold>
                        </FlexRowAlignCenter>
                    </Box>
                </Row>
                <Box width={"8px"} />
            </FlexRow>
            <FlexRow>
                <Box width={"13px"} />
                <Row>
                    <FlexRowAlignCenter
                        height={"19px"}
                        justifyContent={"space-between"}
                        gridColumn={"1 / 4"}
                        width={"100%"}
                    >
                        <Box height={"1px"} backgroundColor={"northernLights"} flexGrow={1} />
                        <Label color={"petrolBlue"} px={"8px"}>
                            Fixed monthly fees
                        </Label>
                        <Box height={"1px"} backgroundColor={"northernLights"} flexGrow={1} />
                    </FlexRowAlignCenter>
                </Row>
                <Box width={"8px"} />
            </FlexRow>
        </Box>
    );
};
