import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormTemplate } from "./FormTemplate";
import { Box, FlexRow, YSpacer } from "../../styledSystemUtilities";
import { Body, Body12, Header } from "../../Typography";
import { RegisteredBigInput, RegisteredInput } from "../../Molecules";
import { RightButton } from "../../Atoms";
import { useAppSelector } from "../../../redux/hooks";
import { GoogleAdsForm } from "../../../@types/forms/Types";
import { useProductFormSubmit } from "../../../hooks/useProductFormSubmit";

const schema = object().shape({ currentWebsiteAddress: string().trim() });

const formDefaultValues = {
    acceptedInsurances: "",
    insurancesToAvoid: "",
    geoTargetsToAvoid: "",
    topCompetitors: "",
};

export function GoogleAds(): JSX.Element {
    const form = useAppSelector((state) => state.form);
    const superviseSubmit = useProductFormSubmit<GoogleAdsForm>("growth", "googleAdsForms");
    const { register, errors, handleSubmit, reset } = useForm<GoogleAdsForm>({
        resolver: yupResolver(schema),
        defaultValues: form || formDefaultValues,
    });

    useEffect(() => {
        reset(form || formDefaultValues);
    }, [form, reset]);

    return (
        <FormTemplate
            productName={"Google Ads"}
            taskName={""}
            redLabel={"Improves the effectiveness of your ad"}
            blackLabel={"Helpful Information"}
            onSubmit={handleSubmit(superviseSubmit)}
        >
            <Box>
                <Header color={"graphiteGray"}>Insurances</Header>
                <YSpacer spaceY={2} />
                <Body color={"graphiteGray"}>What insurances do you accept:</Body>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"acceptedInsurances"} placeholder={""} />
                <YSpacer spaceY={4} />
                <Body color={"graphiteGray"}>What insurances should we avoid:</Body>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"insurancesToAvoid"} placeholder={""} />
                <YSpacer spaceY={8} />
                <Header color={"graphiteGray"}>Location (GEO) Targets</Header>
                <YSpacer spaceY={2} />
                <Body12>
                    We will target the ad to appear in a radius around your practice. The size of the radius depends on
                    population and search volume. If there are areas you would not like the ad to appear near your
                    practice, indicate those below.
                </Body12>
                <YSpacer spaceY={2} />
                <Body color={"graphiteGray"}>What GEO-Targets should we avoid</Body>
                <YSpacer spaceY={2} />
                <Body12>i.e. Cities, Zip Codes, & Other Geographical barriers</Body12>
                <YSpacer spaceY={2} />
                <RegisteredInput register={register} errors={errors} name={"geoTargetsToAvoid"} placeholder={""} />
            </Box>
            <Box>
                <Header color={"graphiteGray"}>Competitors</Header>
                <YSpacer spaceY={2} />
                <Body color={"graphiteGray"}>
                    Who do you consider your top competitors:
                    <br />
                    (List up to 10)
                </Body>
                <YSpacer spaceY={2} />
                <RegisteredBigInput register={register} errors={errors} name={"topCompetitors"} placeholder={""} />
            </Box>
            <YSpacer spaceY={8} />
            <FlexRow justifyContent={"center"} gridColumn={"1 / -1"}>
                <Box width={"330px"}>
                    <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                        save & continue
                    </RightButton>
                </Box>
            </FlexRow>
        </FormTemplate>
    );
}
