import { OnlineLocationOverviewReport } from "../../@types/components/LocationOverviewReport";
import { PracticeFrontEnd } from "../../@types/shared";
import { Box, FlexCol, FlexRowAlignCenter, XSpacer, YSpacer } from "../styledSystemUtilities";
import {
    GridSpaceBox,
    LocationOverviewLayerButton,
    LocationTrendsContainer,
    OnlineThreeMonthTrendRow,
    ThreeMonthTrendChartHeader,
    TodoLink,
} from "../Molecules";
import { Body12, Label, Label11 } from "../Typography";
import _ from "lodash/fp";
import { formatAsDollars } from "../../utils/formatter";
import React from "react";
import { onlineTasks } from "../../constants/tasks";
import { useLocationOverviewLinks } from "../../hooks/useLocationOverviewLinks";

export const LastThreeMonthOnlineTrends = ({
    onlineReport,
    currentPractice,
    lastThreeMonthDates,
}: {
    onlineReport: OnlineLocationOverviewReport;
    currentPractice?: PracticeFrontEnd;
    lastThreeMonthDates: string[];
}): JSX.Element => {
    const reportIsempty = !_.flow(_.omit("onlineSubscriptions"), _.values, _.compact, _.isEmpty)(onlineReport);
    const onClick = useLocationOverviewLinks("online", currentPractice);

    return (
        <Box data-testid={"onlineReport"}>
            <GridSpaceBox />
            <Box pb={5}>
                <LocationOverviewLayerButton
                    backgroundColor={"greenWhite"}
                    buttonColor={"auratiumGreen"}
                    onClick={onClick}
                />
                <TodoLink
                    numUncompletedTasks={
                        currentPractice?.tasks
                            .filter((task) => onlineTasks.has(task.taskKey))
                            .filter((task) => !task.completed).length ?? 0
                    }
                    onClick={onClick}
                />
                <YSpacer spaceY={5} />
                {reportIsempty ? (
                    <>
                        <LocationTrendsContainer
                            color={"forrestGreen"}
                            name={"online visibility"}
                            backgroundColor={"aquaHaze"}
                        >
                            <YSpacer spaceY={4} />
                            <FlexCol alignItems={"center"}>
                                {onlineReport.competitiveAreaScore ? (
                                    <FlexRowAlignCenter>
                                        <Label color={"auratiumGreen"}>Competitive Area Score</Label>
                                        <XSpacer spaceX={4} />
                                        <FlexRowAlignCenter
                                            justifyContent={"center"}
                                            backgroundColor={"white"}
                                            height={"25px"}
                                            width={"99px"}
                                        >
                                            <Body12 color={"forrestGreen"}>{`${_.round(
                                                onlineReport.competitiveAreaScore * 100
                                            )}%`}</Body12>
                                        </FlexRowAlignCenter>
                                    </FlexRowAlignCenter>
                                ) : null}

                                <YSpacer spaceY={3} />
                                <ThreeMonthTrendChartHeader
                                    color={"auratiumGreen"}
                                    finalColor={"forrestGreen"}
                                    lastThreeMonthDates={lastThreeMonthDates}
                                />
                                <OnlineThreeMonthTrendRow labelLeft={"Search Rank"} dataSet={onlineReport.searchRank} />
                                {/*<OnlineChartRow labelLeft={"Reviews Rank"} dataSet={onlineReport.reviewsRank} />*/}
                                <OnlineThreeMonthTrendRow labelLeft={"Calls"} dataSet={onlineReport.calls} />
                                <OnlineThreeMonthTrendRow
                                    labelLeft={"New Patients"}
                                    dataSet={onlineReport.newPatients}
                                />
                                <OnlineThreeMonthTrendRow
                                    labelLeft={"Call Conv."}
                                    dataSet={onlineReport.callConversion}
                                    rightLabelFormatter={(number) => `${_.round(number * 100)}%`}
                                />
                                <OnlineThreeMonthTrendRow
                                    labelLeft={"NP Cost"}
                                    dataSet={onlineReport.costPerPatient}
                                    rightLabelFormatter={formatAsDollars}
                                />
                                <OnlineThreeMonthTrendRow
                                    labelLeft={"ROI"}
                                    dataSet={onlineReport.roi}
                                    rightLabelFormatter={formatAsDollars}
                                />
                            </FlexCol>
                        </LocationTrendsContainer>
                        <YSpacer spaceY={5} />
                    </>
                ) : null}

                <Box data-testid={"onlineSubscriptions"}>
                    <LocationTrendsContainer
                        color={"forrestGreen"}
                        name={"online subscriptions"}
                        backgroundColor={"aquaHaze"}
                    >
                        {onlineReport.onlineSubscriptions.map((subscription, index) => {
                            return (
                                <Box key={index} pl={10} pt={3}>
                                    <Label11 color={"forrestGreen"}>{subscription}</Label11>
                                </Box>
                            );
                        })}
                    </LocationTrendsContainer>
                </Box>
            </Box>
            <GridSpaceBox backgroundColor={"greenWhite"} />
        </Box>
    );
};
