import styled from "styled-components";
import React from "react";
import { all } from "../styledSystemUtilities";
import { AllStyledSystemProps } from "../styledSystemAll";
import { RankingFromGoogleSearch } from "../../@types/reports/Online";

const Table = styled.table<AllStyledSystemProps>`
    width: 100%;
    border-collapse: collapse;
`;

const Td = styled.td<AllStyledSystemProps>`
    color: #7f7f7f;
    height: 35px;

    border-top: ${(props) => props.theme.borders.green};
    border-bottom: ${(props) => props.theme.borders.green};
    text-align: center;
    font-size: 12px;
    ${all}
`;

const TdB = styled(Td)<AllStyledSystemProps>`
    background-color: white;
    width: 52px;
    border-right: ${(props) => props.theme.borders.green};
    ${all}
`;

const TdName = styled(Td)<AllStyledSystemProps>`
    border-left: ${(props) => props.theme.borders.green};
    padding-left: 12px;
    text-align: left;
    ${all}
`;

const Th = styled.th<AllStyledSystemProps>`
    color: #a7a9ac;
    font-size: 9px;
    font-weight: 500;
    padding-bottom: 5px;
    ${all}
`;

export const LocalKeywordsTable = ({ keywords }: { keywords: RankingFromGoogleSearch[] }): JSX.Element => {
    return (
        <Table>
            <thead>
                <tr>
                    <Th style={{ textAlign: "left" }}>KEYWORD</Th>
                    <Th whiteSpace={"nowrap"}>YOUR RANK:</Th>
                </tr>
            </thead>
            <tbody>
                {keywords.map((keyword, index) => {
                    return (
                        <tr key={index} data-testid={`row${index + 1}`}>
                            <TdName data-testid={"name"}>{keyword.googleSearchTerm}</TdName>
                            <TdB data-testid={"rank"}>{keyword.rank}</TdB>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
