import { api } from "../utilityActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import slices from "../slices";
import refreshCampaign from "./refreshCampaign";

const updateCampaignSpend = (
    practiceId: string,
    recurlyCampaignId: string,
    newSpend: number,
    newGoal: number
): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/recurly/growth/update-google-ads-campaign`,
            method: "post",
            data: {
                practiceId,
                recurlyCampaignId,
                newSpend,
                newNewPatientGoal: newGoal,
            },
        },
        success: (amountCharged: number) => [
            refreshCampaign(practiceId),
            slices.paymentModal.actions.set({ amountCharged }),
        ],
        label: "updateCampaign",
    });

export default updateCampaignSpend;
