import {
    AccountMobileMenu,
    GrowthMobileMenu,
    LocationMobileMenu,
    OnlineMobileMenu,
    ProfitMobileMenu,
} from "./MobileMenu";
import React from "react";
import { useWindowSize } from "react-use";
import { useTheme } from "styled-components";
import {
    AccountDesktopMenu,
    GrowthDesktopMenu,
    LocationDesktopMenu,
    OnlineDesktopMenu,
    ProfitDesktopMenu,
} from "./DesktopMenu";
import { Box } from "../styledSystemUtilities";

export const OnlineMenu = (): JSX.Element => <Menu MobileMenu={OnlineMobileMenu} DesktopMenu={OnlineDesktopMenu} />;
export const AccountMenu = (): JSX.Element => <Menu MobileMenu={AccountMobileMenu} DesktopMenu={AccountDesktopMenu} />;
export const LocationMenu = (): JSX.Element => (
    <Menu MobileMenu={LocationMobileMenu} DesktopMenu={LocationDesktopMenu} />
);
export const GrowthMenu = (): JSX.Element => <Menu MobileMenu={GrowthMobileMenu} DesktopMenu={GrowthDesktopMenu} />;
export const ProfitMenu = (): JSX.Element => <Menu MobileMenu={ProfitMobileMenu} DesktopMenu={ProfitDesktopMenu} />;

type MenuProps = {
    DesktopMenu: () => JSX.Element;
    MobileMenu: () => JSX.Element;
};

const Menu = ({ DesktopMenu, MobileMenu }: MenuProps) => {
    const { width } = useWindowSize();
    const theme = useTheme();
    return (
        <Box position={"fixed"} data-testid={"menu"} zIndex={100}>
            {width > theme.breakpoints[0] ? <DesktopMenu /> : <MobileMenu />}
        </Box>
    );
};
