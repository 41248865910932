import getProfitReport from "./getProfitReport";
import { api } from "../utilityActions";
import { UpdateMonth } from "../../@types/reports/Profit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const updateKpiMonths = (updates: UpdateMonth[], locationId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: "/secure-routes/kpi/update-months",
            method: "patch",
            data: { updates },
        },
        success: () => getProfitReport(locationId),
        label: "updateKpiMonths",
    });

export default updateKpiMonths;
