import { FormattedAdditionalProduct, OnlineSubscription } from "../@types/reports/Online";
import _ from "lodash/fp";
import { formatAsCents } from "./formatter";
import { RawTask, TaskKeys } from "../@types/shared";

function formatAdditionalProducts(
    currentProducts: OnlineSubscription[],
    additionalProducts: OnlineSubscription[],
    currentTaskKeys: Set<TaskKeys>
): FormattedAdditionalProduct[] {
    const essentialSeo = _.find({ recurlyCode: "seoe-01" }, currentProducts);
    const enhancedSeo = _.find({ recurlyCode: "seoe-02" }, currentProducts);

    return additionalProducts.map((product) => {
        const formattedTasks = _.flow(_.map(getFormattedTask(product.name, currentTaskKeys)), _.compact)(product.tasks);

        if (product.recurlyCode === "seoe-02") {
            return {
                ...product,
                tasks: formattedTasks,
                monthlyPrice: essentialSeo ? product.monthlyPrice - essentialSeo.monthlyPrice : product.monthlyPrice,
                formattedMonthlyPrice: essentialSeo
                    ? `+ ${formatAsCents(product.monthlyPrice - essentialSeo.monthlyPrice)}/mo.`
                    : `${formatAsCents(product.monthlyPrice)}/mo.`,
            };
        }
        if (product.recurlyCode === "seoe-01") {
            return {
                ...product,
                tasks: formattedTasks,
                monthlyPrice: enhancedSeo ? product.monthlyPrice - enhancedSeo.monthlyPrice : product.monthlyPrice,
                formattedMonthlyPrice: enhancedSeo
                    ? `- ${formatAsCents(enhancedSeo.monthlyPrice - product.monthlyPrice)}/mo.`
                    : `${formatAsCents(product.monthlyPrice)}/mo.`,
            };
        }
        return {
            ...product,
            tasks: formattedTasks,
            formattedMonthlyPrice: `${formatAsCents(product.monthlyPrice)}/mo.`,
        };
    });
}

export default formatAdditionalProducts;

const getFormattedTask = (productName: string, currentTaskKeys: Set<TaskKeys>) => (task: RawTask) => {
    if (currentTaskKeys.has(task.key)) return null;
    return {
        taskKey: task.key,
        completed: false,
        parentProduct: productName,
        taskName: task.name,
    };
};
