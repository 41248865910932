import { OriginalVsCurrentBarGraph, ReportingDropdown, ReportingDropdownContent } from "../../Molecules";
import React, { useState } from "react";
import { Box, FlexColAlignCenter, Grid, YSpacer } from "../../styledSystemUtilities";
import { Body12Bold, Body14Medium } from "../../Typography";
import { PrimaryTable } from "../PrimaryTable";
import { GoogleSearchConsoleReport } from "../../../@types/reports/Online";
import _ from "lodash/fp";
import { useWindowSize } from "react-use";
import theme from "../../../theme";
import { Color } from "../../../@types/color";
import { DotLegend } from "../../Molecules/DotLegend";
import { Chart } from "../Chart";
import { VariableSetPlot } from "../../Molecules/Plots";

export const GoogleOrganicDropdown = ({
    searchConsoleReport,
}: {
    searchConsoleReport: GoogleSearchConsoleReport;
}): JSX.Element => {
    const { width } = useWindowSize();
    const orderedKeywords = _.orderBy("impressions", "desc", searchConsoleReport.expandedKeywordVisibilityReport);
    const lengthOfHalfOfTheKeywords = _.round(orderedKeywords.length / 2);
    const firstHalfKeywords = orderedKeywords.slice(0, lengthOfHalfOfTheKeywords);
    const secondHalfKeywords = orderedKeywords.slice(lengthOfHalfOfTheKeywords);
    const [isOpen, setIsOpen] = useState(true);
    const maxNumberOfKeywords =
        _.maxBy("numberOfKeywords", searchConsoleReport.numberOfKeywordsByMonth)?.numberOfKeywords ??
        searchConsoleReport.currentNumberOfKeywords;

    return (
        <>
            <ReportingDropdown
                text={"visibility data details — google organic"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            {isOpen ? (
                <ReportingDropdownContent testId={"googleOrganicDropdown"}>
                    <Box pl={8} pt={8} pb={10}>
                        <OriginalVsCurrentBarGraph
                            title={"Expanded Keyword Visibility"}
                            currentPercentage={
                                (searchConsoleReport.currentNumberOfKeywords / maxNumberOfKeywords) * 100
                            }
                            currentLabel={searchConsoleReport.currentNumberOfKeywords}
                            originalLabel={searchConsoleReport.originalNumberOfKeywords}
                            originalPercentage={
                                (searchConsoleReport.originalNumberOfKeywords / maxNumberOfKeywords) * 100
                            }
                        />
                    </Box>
                    <FlexColAlignCenter pt={8} pb={10}>
                        <Box>
                            <Body14Medium>Number of Keywords Ranked For Over Time</Body14Medium>
                            <DotLegend dots={[{ label: "Keywords", color: "auratiumGreen" }]} />
                            <Chart
                                yLabel={"# of Keywords"}
                                renderPlot={() => (
                                    <VariableSetPlot
                                        dataSetsWithFormat={[
                                            {
                                                dataSet: searchConsoleReport.numberOfKeywordsByMonth.map((month) => ({
                                                    x: month.date,
                                                    y: month.numberOfKeywords,
                                                })),
                                                color: "auratiumGreen",
                                            },
                                        ]}
                                        width={290}
                                    />
                                )}
                            />
                        </Box>
                    </FlexColAlignCenter>
                    <Grid gridTemplateColumns={"1fr minmax(320px, 375px) 1fr"} justifyItems={"center"}>
                        <Box width={"100%"} px={8} backgroundColor={"aquaHaze"} mb={8} gridColumn={2}>
                            <YSpacer spaceY={5} />
                            <Body14Medium>Your Top Keyword Impressions</Body14Medium>
                            <YSpacer spaceY={9} />
                            <PrimaryTable
                                firstColumnAlign={"start"}
                                backgroundColor={"aquaHaze"}
                                rowBorder={"green"}
                                headerRowBorder={"seaGreen"}
                                columns={[
                                    {
                                        renderLabel: () => (
                                            <Box justifySelf={"start"}>
                                                <Body12Bold>Keyword</Body12Bold>
                                            </Box>
                                        ),
                                    },
                                    { renderLabel: () => <Body12Bold color={"auratiumGreen"}>Volume</Body12Bold> },
                                ]}
                                rows={formatKeywordData(
                                    width > theme.breakpoints[0] ? firstHalfKeywords : orderedKeywords
                                )}
                                width={"100%"}
                                rowTestIds={firstHalfKeywords.map((keyword) => _.camelCase(keyword.keyword))}
                                footerRows={[]}
                            />
                            <YSpacer spaceY={9} />
                        </Box>
                    </Grid>

                    {width > theme.breakpoints[0] ? (
                        <Box width={"100%"} px={8} backgroundColor={"aquaHaze"} mb={8}>
                            <YSpacer spaceY={"55px"} />
                            <PrimaryTable
                                backgroundColor={"aquaHaze"}
                                rowBorder={"green"}
                                headerRowBorder={"seaGreen"}
                                firstColumnAlign={"start"}
                                columns={[
                                    {
                                        renderLabel: () => (
                                            <Box justifySelf={"start"}>
                                                <Body12Bold>Keyword</Body12Bold>
                                            </Box>
                                        ),
                                    },
                                    { renderLabel: () => <Body12Bold color={"auratiumGreen"}>Volume</Body12Bold> },
                                ]}
                                rows={formatKeywordData(secondHalfKeywords)}
                                width={"100%"}
                                rowTestIds={secondHalfKeywords.map((keyword) => _.camelCase(keyword.keyword))}
                                footerRows={[]}
                            />
                            <YSpacer spaceY={9} />
                        </Box>
                    ) : null}
                </ReportingDropdownContent>
            ) : null}
        </>
    );
};

function formatKeywordData(
    keywords: { keyword: string; clicks: number; impressions: number }[]
): { value: string; color?: Color }[][] {
    return keywords.map((keyword) => {
        return [
            { value: keyword.keyword.length > 25 ? keyword.keyword.slice(0, 25).trimEnd() + "..." : keyword.keyword },
            { value: `${keyword.impressions}`, color: "auratiumGreen" },
        ];
    });
}
