import React from "react";
import { PageBanner } from "./PageBanner";
import { FlexCol, FlexColAlignCenter, FlexRow, FlexRowAlignCenter, XSpacer, YSpacer } from "../styledSystemUtilities";
import { Body12, Body14, Header, Label } from "../Typography";
import { ScheduleCallButton } from "../Atoms";
import { ResetIcon } from "../Atoms/assets";
import { SmallIconButton } from "../Atoms/Buttons";
import { useAppSelector } from "../../redux/hooks";
import { routerHistory } from "../../routerHistory";

export const AccountPageBanner = (): JSX.Element => {
    const strategist = useAppSelector((state) => state.clientAccount.strategist);
    return (
        <PageBanner>
            <FlexRowAlignCenter height={"98px"} pl={9}>
                <FlexCol>
                    <Header color={"graphiteGray"}>Your Ascent Strategist</Header>
                    <Body14>{strategist.name}</Body14>
                    <YSpacer spaceY={5} />
                    <FlexRow alignItems={"flex-end"}>
                        <Label>Email:</Label>
                        <XSpacer spaceX={3} />
                        <Body12 color={"ipanemaBlue"}>{strategist.email}</Body12>
                        <XSpacer spaceX={8} />
                        <ScheduleCallButton color={"graphiteGray"} />
                    </FlexRow>
                </FlexCol>
            </FlexRowAlignCenter>
            <FlexColAlignCenter justifyContent={"center"}>
                {/*<Box>*/}
                {/*    <SmallIconButton*/}
                {/*        border={"gray"}*/}
                {/*        Icon={AddIcon}*/}
                {/*        backgroundColor={"transparent"}*/}
                {/*        color={"graphiteGray"}*/}
                {/*        onClick={() => null}*/}
                {/*    >*/}
                {/*        add location*/}
                {/*    </SmallIconButton>*/}
                {/*    <YSpacer spaceY={5} />*/}
                <SmallIconButton
                    border={"gray"}
                    Icon={ResetIcon}
                    backgroundColor={"transparent"}
                    color={"graphiteGray"}
                    onClick={() => routerHistory.push("/reset-password")}
                >
                    password reset
                </SmallIconButton>
                {/*</Box>*/}
            </FlexColAlignCenter>
        </PageBanner>
    );
};
