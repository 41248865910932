import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const getWebsiteFiles = (practiceId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/product-forms/get-website-files/${practiceId}`,
            method: "get",
        },
        success: slices.websiteFiles.actions.set,
        label: "form",
    });

export default getWebsiteFiles;
