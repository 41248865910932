import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { DentalMenuLogo } from "./assets";
import React from "react";

export const DentalMenu = (): JSX.Element => {
    return (
        <FlexRowAlignCenter pt={"14px"} pl={"11px"}>
            <DentalMenuLogo />
        </FlexRowAlignCenter>
    );
};
