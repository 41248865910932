import { OneSetPlot, ReportingDropdown, ReportingDropdownContent } from "../../Molecules";
import { Box, FlexCol, FlexRow, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body12Bold, Body14Medium } from "../../Typography";
import { PrimaryTable } from "../PrimaryTable";
import { Chart } from "../Chart";
import React, { useState } from "react";
import { Color } from "../../../@types/color";
import { SeoCallsMonth, SeoCallsReport } from "../../../@types/reports/Online";
import { format } from "date-fns/fp";
import _ from "lodash/fp";
import { formatAsDollars } from "../../../utils/formatter";

export const RoiDropdown = ({ seoCallsReport }: { seoCallsReport: SeoCallsReport }): JSX.Element => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <>
            <ReportingDropdown
                text={"online visibility – your return on investment"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            {isOpen ? (
                <ReportingDropdownContent>
                    <FlexCol pt={9} data-testid={"roiDropdown"}>
                        <Box pl={8}>
                            <Body14Medium>Website Performance Data</Body14Medium>
                            <YSpacer spaceY={6} />
                        </Box>

                        <FlexRow alignSelf={"center"}>
                            <Box data-testid={"callTable"}>
                                <PrimaryTable
                                    backgroundColor={"aquaHaze"}
                                    rowBorder={"green"}
                                    headerRowBorder={"seaGreen"}
                                    columns={[
                                        { renderLabel: () => <Box /> },
                                        { renderLabel: () => <Body12Bold>Calls</Body12Bold> },
                                        {
                                            renderLabel: () => (
                                                <Box pr={2}>
                                                    <Body12Bold>Appointments</Body12Bold>
                                                </Box>
                                            ),
                                        },
                                    ]}
                                    rows={formatTableData(seoCallsReport.months)}
                                    width={"240px"}
                                    footerRows={[
                                        [
                                            { value: "Avg." },
                                            { value: _.round(seoCallsReport.averages.calls) },
                                            {
                                                value: _.round(seoCallsReport.averages.newPatients),
                                                color: "forrestGreen",
                                            },
                                        ],
                                    ]}
                                    rowTestIds={seoCallsReport.months.map((month) => month.date.toString())}
                                />
                            </Box>

                            <XSpacer spaceX={4} />
                            <Box data-testid={"costPerPatientTable"}>
                                <PrimaryTable
                                    backgroundColor={"aquaHaze"}
                                    rowBorder={"green"}
                                    headerRowBorder={"seaGreen"}
                                    width={"75px"}
                                    columns={[{ renderLabel: () => <Body12Bold>Cost/Apt.</Body12Bold> }]}
                                    rows={seoCallsReport.months.map((month) => [
                                        { value: formatAsDollars(_.round(month.costPerPatient)), color: "sienna" },
                                    ])}
                                    footerRows={[
                                        [
                                            {
                                                value: formatAsDollars(_.round(seoCallsReport.averages.costPerPatient)),
                                                color: "sienna",
                                            },
                                        ],
                                    ]}
                                    rowTestIds={seoCallsReport.months.map((month) => month.date.toString())}
                                />
                            </Box>
                        </FlexRow>
                    </FlexCol>
                    <FlexCol alignItems={"center"} pt={"22px"}>
                        <Box>
                            <Box pl={8}>
                                <Body14Medium>New Patients</Body14Medium>
                            </Box>

                            <YSpacer spaceY={4} />
                            <Chart
                                renderPlot={() => (
                                    <OneSetPlot
                                        data={seoCallsReport.months.map((month) => ({
                                            x: month.date,
                                            y: month.newPatients,
                                        }))}
                                        width={308}
                                        color={"forrestGreen"}
                                    />
                                )}
                            />
                        </Box>
                        <YSpacer spaceY={6} />
                        <Box>
                            <Box pl={8}>
                                <Body14Medium>Cost Per Appointment</Body14Medium>
                            </Box>
                            <YSpacer spaceY={4} />
                            <Chart
                                renderPlot={() => (
                                    <OneSetPlot
                                        data={seoCallsReport.months.map((month) => ({
                                            x: month.date,
                                            y: month.costPerPatient,
                                        }))}
                                        width={308}
                                        color={"sienna"}
                                    />
                                )}
                            />
                        </Box>
                    </FlexCol>
                </ReportingDropdownContent>
            ) : null}
        </>
    );
};

function formatTableData(
    seoCallMonths: SeoCallsMonth[]
): {
    value: string | number;
    color?: Color;
}[][] {
    return seoCallMonths.map((month) => {
        return [
            { value: format("MMM", month.date) },
            { value: month.calls },
            { value: month.newPatients, color: "forrestGreen" },
        ];
    });
}
