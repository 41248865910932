import { OriginalVsCurrentBarGraph, ReportingDropdown, ReportingDropdownContent, TwoDotLegend } from "../../Molecules";
import { Box, FlexColAlignCenter, YSpacer } from "../../styledSystemUtilities";
import { Body14Medium, Label } from "../../Typography";
import { Chart } from "../Chart";
import { TwoSetPlot } from "../../Molecules/Plots";
import React, { useState } from "react";
import { AdviceLocalReport, GoogleAnalyticsReport } from "../../../@types/reports/Online";
import { LineTable } from "../../Molecules/LineTable";

export const InternetActionsDropdown = ({
    googleAnalyticsReport,
    adviceLocalReport,
}: {
    googleAnalyticsReport: GoogleAnalyticsReport | null;
    adviceLocalReport: AdviceLocalReport | null;
}): JSX.Element => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <>
            <ReportingDropdown text={"seo actions and website data"} isOpen={isOpen} setIsOpen={setIsOpen} />
            {isOpen ? (
                <ReportingDropdownContent>
                    {adviceLocalReport ? (
                        <Box px={8} pt={8}>
                            <OriginalVsCurrentBarGraph
                                displayChange={true}
                                title={"Overall Visibility Breakdown"}
                                currentPercentage={adviceLocalReport.visibility.overall.current}
                                originalPercentage={adviceLocalReport.visibility.overall.original}
                                currentLabel={adviceLocalReport.visibility.overall.current + "%"}
                                originalLabel={adviceLocalReport.visibility.overall.original + "%"}
                            />
                            <LineTable
                                rows={[
                                    {
                                        label: "Google My Business",
                                        value: adviceLocalReport.visibility.googleMyBusiness + "%",
                                    },
                                    {
                                        label: "Yelp Local",
                                        value: adviceLocalReport.visibility.yelp + "%",
                                    },
                                    {
                                        label: "Bing Local",
                                        value: adviceLocalReport.visibility.bing + "%",
                                    },
                                    {
                                        label: "NAP Score",
                                        value: adviceLocalReport.visibility.nap + "%",
                                    },
                                    {
                                        label: "Voice Search Readiness",
                                        value: adviceLocalReport.visibility.voiceSearch + "%",
                                    },
                                ]}
                            />
                        </Box>
                    ) : null}
                    {googleAnalyticsReport ? (
                        <Box pt={8}>
                            <Box pl={8}>
                                <Body14Medium>Website Analytics</Body14Medium>
                                <TwoDotLegend
                                    dotColor1={"auratiumGreen"}
                                    dotColor2={"midGray"}
                                    label1={"New Users"}
                                    label2={"Total Users"}
                                />
                            </Box>
                            <YSpacer spaceY={4} />
                            <FlexColAlignCenter>
                                <Chart
                                    yLabel={"Users"}
                                    renderPlot={() => (
                                        <TwoSetPlot
                                            firstDataSet={googleAnalyticsReport.map((month) => ({
                                                x: month.date,
                                                y: month.newUsers,
                                            }))}
                                            secondDataSet={googleAnalyticsReport.map((month) => ({
                                                x: month.date,
                                                y: month.totalUsers,
                                            }))}
                                            width={300}
                                            color={"auratiumGreen"}
                                        />
                                    )}
                                />
                            </FlexColAlignCenter>
                        </Box>
                    ) : null}
                    {adviceLocalReport ? (
                        <Box px={8} pt={8}>
                            <OriginalVsCurrentBarGraph
                                displayChange={true}
                                title={"Directory Score"}
                                currentPercentage={adviceLocalReport.directories.score.current}
                                originalPercentage={adviceLocalReport.directories.score.original}
                                currentLabel={adviceLocalReport.directories.score.current + "%"}
                                originalLabel={adviceLocalReport.directories.score.original + "%"}
                            />
                            <Label>
                                Based on the number of places your listings are present divided by the number of local
                                page and directories we’ve checked. Successful scores are most often obtained when all
                                listings are claimed, active and contain consistent information such as business name
                                and phone number.
                            </Label>
                        </Box>
                    ) : null}
                </ReportingDropdownContent>
            ) : null}
        </>
    );
};
