import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body{
      font-family: Roboto, sans-serif;
      height: 100%;
  }
  html {
  box-sizing: border-box;
  -webkit-text-size-adjust:100%;
  height:100%;
  }
  *, *:before, *:after {
  box-sizing: inherit;
}
`;
