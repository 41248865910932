import { api } from "../utilityActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import getOnlineProducts from "./getOnlineProducts";
import slices from "../slices";
import getActiveCoupons from "./getActiveCoupons";
import { Task } from "../../@types/shared";

const addOnlineProducts = (
    practiceId: string,
    itemCodesToAdd: string[],
    couponCodes: string[],
    tasks: Task[]
): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/recurly/add-online-products`,
            method: "post",
            data: {
                practiceId,
                itemCodesToAdd,
                couponCodes,
                tasks,
            },
        },
        success: ({ amountCharged, updatedTasks }: { amountCharged: number; updatedTasks: Task[] }) => [
            getOnlineProducts(),
            getActiveCoupons(practiceId),
            slices.openPractice.actions.setTasks(updatedTasks),
            slices.paymentModal.actions.set({ amountCharged }),
        ],
        label: "onlineProducts",
    });

export default addOnlineProducts;
