const bestDiscountedProductCodes = new Set([
    "webe-01",
    "seoe-01",
    "seoe-02",
    "soci-01",
    "webe-02",
    "bran-01",
    "logo-01",
    "imgt-01",
    "busc-02",
    "busc-03",
    "enve-02",
    "enve-03",
    "lett-02",
    "lett-03",
    "prof-02",
    "prof-03",
    "proc-02",
    "proc-03",
    "refc-02",
    "refc-03",
    "ansb-02",
    "ansb-03",
]);

export default bestDiscountedProductCodes;
