import React, { useState } from "react";
import { Box, FlexRowAlignCenter, Grid, YSpacer } from "../styledSystemUtilities";
import { Body12Medium } from "../Typography";
import { ArrowDown, ArrowRight } from "../Atoms/assets";
import { InfoTable } from "./InfoTable";
import { Contact } from "./Contact";
import { PracticeFrontEnd } from "../../@types/shared";

type LocationInfoDropdownProps = {
    initialState: boolean;
    practice: PracticeFrontEnd;
};

export const LocationInfoDropdown = ({ initialState = false, practice }: LocationInfoDropdownProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(initialState);

    const color = isOpen ? "graphiteGray" : "agateGray";
    const backgroundColor = isOpen ? "midGray" : "pearlGray";

    const doctors = practice.doctors.map((doctor) => ({ label: "Dr. Name:", data: doctor }));

    return (
        <Box pb={5} data-testid={practice.id}>
            <Grid
                height={"38px"}
                alignItems={"center"}
                px={8}
                backgroundColor={backgroundColor}
                // gridTemplateColumns={"240px 1fr"}
            >
                <FlexRowAlignCenter
                    cursor={"pointer"}
                    justifyContent={"space-between"}
                    onClick={() => setIsOpen((prevState) => !prevState)}
                    height={"100%"}
                >
                    <Body12Medium color={color} uppercase>
                        {practice.practiceName}
                    </Body12Medium>
                    {isOpen ? (
                        <>
                            <ArrowDown fill={color} />
                        </>
                    ) : (
                        <ArrowRight fill={color} />
                    )}
                </FlexRowAlignCenter>


            </Grid>
            {isOpen ? (
                <Box pt={5} pb={7} px={8} backgroundColor={"chalk"}>


                            <InfoTable
                                rows={[
                                    { label: "Practice Phone:", data: practice.phone },
                                    { label: "Website Address:", data: practice.website },
                                    { label: "Practice Email:", data: practice.email },
                                    ...doctors,
                                    { label: "Main Address:", data: practice.address },
                                ]}
                            />
                            <YSpacer spaceY={5} />
                            <Box data-testid={"billingContact"}>
                                <Contact
                                    rows={[
                                        { label: "Name on Card:", data: practice.billingContact.name },
                                        { label: "Billing Address:", data: practice.billingContact.address },
                                        { label: "Card Type:", data: practice.billingContact.cardType },
                                        { label: "Last Four Digits:", data: practice.billingContact.lastFour },
                                    ]}
                                >
                                    Practice Billing Contact
                                </Contact>
                            </Box>

                            <Box data-testid={"locationContacts"}>
                                {practice.contacts.map((contact, index) => {
                                    return (
                                        <Box key={index}>
                                            <Contact
                                                rows={[
                                                    { label: "Name:", data: contact.name },
                                                    { label: "Role:", data: contact.label },
                                                    { label: "Phone:", data: contact.phone },
                                                    { label: "Email:", data: contact.email },
                                                ]}
                                            >
                                                Practice Secondary Contact
                                            </Contact>
                                        </Box>
                                    );
                                })}
                            </Box>

                            <YSpacer spaceY={5} />

                </Box>
            ) : null}
        </Box>
    );
};
