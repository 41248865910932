import React from "react";
import ReactSelect, { GroupBase, Props, StylesConfig } from "react-select";
import { DropdownArrowLeft, ProductDropdownArrow } from "./assets";

export const Select = <
    SelectOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(
    props: Props<SelectOption, IsMulti, Group>
): JSX.Element => {
    const customStyles: StylesConfig<SelectOption, IsMulti, Group> = {
        control: (base, state) => ({
            ...base,
            border: "1px solid #C5DBD0",
            height: "50px",
            fontSize: "14px",
            fontFamily: "Roboto",
            fontWeight: "normal",
            color: "#74AD85",
            paddingLeft: "10px",
            paddingRight: "20px",
            borderRadius: 0,
            boxShadow: state.isFocused ? "0" : "0",
            ":hover": {
                cursor: "pointer",
                border: "1px solid #99C2AD",
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: "#74AD85",
            fontWeight: 500,
        }),
        placeholder: (base) => ({
            ...base,
            color: "#74AD85",
        }),
        menu: (base) => ({
            ...base,
            padding: 0,
            borderRadius: 0,
            margin: 0,
            bottom: "450px",
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: "500px",
            padding: 0,
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.2)",
        }),
        option: (base) => ({
            ...base,
            paddingLeft: "20px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "normal",
            color: "#74AD85",
            backgroundColor: "white",
            height: "50px",
            "&:hover": {
                backgroundColor: "#F8F8F9",
            },
        }),
    };
    return (
        <ReactSelect
            styles={customStyles}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: ({ selectProps }) => {
                    return selectProps.menuIsOpen ? (
                        <DropdownArrowLeft fill={"auratiumGreen"} />
                    ) : (
                        <ProductDropdownArrow fill={"auratiumGreen"} />
                    );
                },
            }}
            isSearchable={false}
            {...props}
        />
    );
};
