import React from "react";
import { Box, YSpacer } from "../styledSystemUtilities";
import { Header16 } from "../Typography";
import { LocationInfoDropdown } from "../Molecules";
import { useAppSelector } from "../../redux/hooks";

export const LocationsInfo = (): JSX.Element => {
    const practices = useAppSelector((state) => state.accountPractices);

    return (
        <Box data-testid={"locations"}>
            <Header16 color={"graphiteGray"}>Locations</Header16>
            <YSpacer spaceY={5} />
            {/*<SmallIconButton*/}
            {/*    backgroundColor={"transparent"}*/}
            {/*    onClick={() => null}*/}
            {/*    Icon={SortIcon}*/}
            {/*    color={"agateGray"}*/}
            {/*    border={"mid"}*/}
            {/*>*/}
            {/*    sort*/}
            {/*</SmallIconButton>*/}
            {/*<YSpacer spaceY={6} />*/}

            {practices.length ? <LocationInfoDropdown initialState={true} practice={practices[0]} /> : null}
            {practices.slice(1).map((practice, index) => {
                return (
                    <Box key={index}>
                        <LocationInfoDropdown initialState={false} practice={practice} />
                    </Box>
                );
            })}
        </Box>
    );
};
