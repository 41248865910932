import React from "react";
import { LegendDot } from "../Atoms";
import { Color } from "../../@types/color";
import { FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";

export const TwoDotLegend = ({
    dotColor1,
    dotColor2,
    label1,
    label2,
}: {
    dotColor1: Color;
    dotColor2: Color;
    label1: string | number;
    label2: string | number;
}): JSX.Element => (
    <FlexRowAlignCenter>
        <LegendDot label={label1} fill={dotColor1} />
        <XSpacer spaceX={5} />
        <LegendDot label={label2} fill={dotColor2} />
    </FlexRowAlignCenter>
);
