import { api } from "../utilityActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";
import getWebsiteFiles from "./getWebsiteFiles";

const deleteFile = (practiceId: string, folder: string, fileName: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/product-forms/delete-file`,
            data: {
                bucket: practiceId,
                key: `${folder}/${fileName}`,
            },
            method: "delete",
        },
        success: () => getWebsiteFiles(practiceId),
        label: "form",
    });

export default deleteFile;
