import { GrowthLocationOverviewReport } from "../../@types/components/LocationOverviewReport";
import { PracticeFrontEnd } from "../../@types/shared";
import { Box, FlexCol, FlexRowAlignCenter, YSpacer } from "../styledSystemUtilities";
import {
    GridSpaceBox,
    GrowthThreeMonthTrendRow,
    LocationOverviewLayerButton,
    LocationTrendsContainer,
    ThreeMonthTrendChartHeader,
    TodoLink,
} from "../Molecules";
import { Body12 } from "../Typography";
import { formatAsDollars } from "../../utils/formatter";
import _ from "lodash/fp";
import React from "react";
import { growthTasks } from "../../constants/tasks";
import { useLocationOverviewLinks } from "../../hooks/useLocationOverviewLinks";

export const LastThreeMonthGrowthTrends = ({
    growthReport,
    currentPractice,
    lastThreeMonthDates,
}: {
    growthReport: GrowthLocationOverviewReport;
    currentPractice?: PracticeFrontEnd;
    lastThreeMonthDates: string[];
}): JSX.Element => {
    const onClick = useLocationOverviewLinks("growth", currentPractice);

    return (
        <Box data-testid={"growthReport"}>
            <GridSpaceBox />
            <Box pb={5}>
                <LocationOverviewLayerButton
                    backgroundColor={"edgeWater"}
                    buttonColor={"ipanemaBlue"}
                    onClick={onClick}
                />
                <TodoLink
                    numUncompletedTasks={
                        currentPractice?.tasks
                            .filter((task) => growthTasks.has(task.taskKey))
                            .filter((task) => !task.completed).length ?? 0
                    }
                    onClick={onClick}
                />

                <FlexRowAlignCenter justifyContent={"center"} width={"100%"}>
                    <Box pl={4} pt={6} width={"254px"}>
                        <Body12 color={"deepPetrol"}>Growth Subscriptions</Body12>
                    </Box>
                </FlexRowAlignCenter>
                {growthReport.googleAdsCampaigns.map((campaign, index) => {
                    return (
                        <Box key={index} pt={2}>
                            <LocationTrendsContainer
                                color={"deepPetrol"}
                                name={campaign.campaignName}
                                backgroundColor={"aquaBlue"}
                                initialIsOpenState={index === 0}
                            >
                                <YSpacer spaceY={4} />
                                <FlexCol alignItems={"center"}>
                                    <ThreeMonthTrendChartHeader
                                        color={"ipanemaBlue"}
                                        finalColor={"deepPetrol"}
                                        lastThreeMonthDates={lastThreeMonthDates}
                                    />
                                    <GrowthThreeMonthTrendRow
                                        dataSet={campaign.pricePerClick}
                                        labelLeft={"Price Per Click"}
                                        rightLabelFormatter={formatAsDollars}
                                    />
                                    <GrowthThreeMonthTrendRow dataSet={campaign.clicks} labelLeft={"Clicks"} />
                                    <GrowthThreeMonthTrendRow dataSet={campaign.calls} labelLeft={"Calls"} />
                                    <GrowthThreeMonthTrendRow
                                        dataSet={campaign.clickToCall}
                                        labelLeft={"Click To Call"}
                                        rightLabelFormatter={(number) => `${_.round(number * 100)}%`}
                                    />
                                    <GrowthThreeMonthTrendRow
                                        dataSet={campaign.newPatients}
                                        labelLeft={"New Patients"}
                                    />
                                    <GrowthThreeMonthTrendRow
                                        dataSet={campaign.callConversion}
                                        labelLeft={"Call Conv."}
                                        rightLabelFormatter={(number) => `${_.round(number * 100)}%`}
                                    />
                                    <GrowthThreeMonthTrendRow
                                        dataSet={campaign.costPerPatient}
                                        labelLeft={"NP Cost"}
                                        rightLabelFormatter={formatAsDollars}
                                    />
                                    <GrowthThreeMonthTrendRow
                                        dataSet={campaign.roi}
                                        labelLeft={"ROI"}
                                        rightLabelFormatter={formatAsDollars}
                                    />
                                </FlexCol>
                            </LocationTrendsContainer>
                        </Box>
                    );
                })}
            </Box>
            <GridSpaceBox backgroundColor={"edgeWater"} />
        </Box>
    );
};
