import React from "react";
import { LegendDot } from "../Atoms";
import { Color } from "../../@types/color";
import { FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";

export const DotLegend = ({ dots }: { dots: { color: Color; label: string }[] }): JSX.Element => (
    <FlexRowAlignCenter>
        {dots.map(({ label, color }, index) => (
            <FlexRowAlignCenter key={index}>
                <LegendDot label={label} fill={color} />
                {index !== dots.length - 1 ? <XSpacer spaceX={5} /> : null}
            </FlexRowAlignCenter>
        ))}
    </FlexRowAlignCenter>
);
