import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import ReactSelect, { GroupBase, Props, StylesConfig } from "react-select";
import { DropdownArrowLeft, ProductDropdownArrow } from "./assets";
import { DynamicCart } from "./assets";
import React from "react";
import { Label11Bold } from "../Typography";

export function AddToCart({
    toggleProduct,
    isAdded,
    isReOrder,
}: {
    toggleProduct: () => void;
    isAdded: boolean;
    isReOrder: boolean;
}): JSX.Element {
    return (
        <FlexRowAlignCenter cursor={"pointer"} onClick={toggleProduct}>
            {isAdded ? (
                <Label11Bold color={"nauticBlue"} uppercase>
                    remove from cart
                </Label11Bold>
            ) : (
                <Label11Bold color={"nauticBlue"} uppercase>
                    {isReOrder ? " re-order — " : ""}add to cart
                </Label11Bold>
            )}
            <Box pl={"8px"} /> <DynamicCart fill={"nauticBlue"} isChecked={isAdded} />
        </FlexRowAlignCenter>
    );
}

export function ProductCardSelect<
    SelectOption,
    IsMulti extends boolean = false,
    Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(props: Props<SelectOption, IsMulti, Group>): JSX.Element {
    const customStyles: StylesConfig<SelectOption, IsMulti, Group> = {
        control: (base) => ({
            ...base,
            border: "1px solid #94A5B5",
            height: "25px",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "normal",
            color: "#28415C",
            padding: 0,
            borderRadius: 0,
            minHeight: 0,
            boxShadow: "none",
            ":hover": {
                cursor: "pointer",
                border: "1px solid #94A5B5",
            },
        }),
        valueContainer: (base) => ({
            ...base,
            display: "flex",
            justifyContent: "center",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            height: "25px",
            width: "24px",
            paddingRight: "11px",
        }),
        singleValue: (base) => ({
            ...base,
            color: "#28415C",
        }),
        placeholder: (base) => ({
            ...base,
            color: "#28415C",
        }),
        menu: (base) => ({
            ...base,
            padding: 0,
            borderRadius: 0,
            margin: 0,
            borderBottom: "1px solid #94A5B5",
            borderRight: "1px solid #94A5B5",
            borderLeft: "1px solid #94A5B5",
            boxShadow: "none",
        }),
        menuList: (base) => ({
            ...base,
            padding: 0,
        }),
        input: (base) => ({
            ...base,
            color: "#28415C",
            padding: 0,
        }),
        option: (base) => ({
            ...base,
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "normal",
            color: "#28415C",
            backgroundColor: "white",
            height: "25px",
            "&:hover": {
                backgroundColor: "#F8F8F9",
            },
        }),
    };
    return (
        <ReactSelect
            {...props}
            styles={customStyles}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: ({ selectProps }) => {
                    return selectProps.menuIsOpen ? (
                        <DropdownArrowLeft fill={"cadetGray"} />
                    ) : (
                        <ProductDropdownArrow fill={"cadetGray"} />
                    );
                },
            }}
            isSearchable={false}
        />
    );
}
