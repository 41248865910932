import React from "react";
import { Box, FlexRow, FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { Checkbox, ProductCardSelect } from "../Atoms";
import _ from "lodash/fp";
import { useCart } from "../../hooks/useCart";
import { Product } from "../../@types/reports/ProfitCenters";
import { Body12, Body14, Label, Label11 } from "../Typography";
import { formatAsCents } from "../../utils/formatter";
import { useAppSelector } from "../../redux/hooks";
import bestDiscountedProductCodes from "../../constants/bestDiscountedProductCodes";
import { BEST_DISCOUNT } from "../../constants/bestDiscount";

const getIsChecked = (product: Product, checkedProducts: Product[]) =>
    Boolean(_.find((checkedProduct) => product.id === checkedProduct.id, checkedProducts));

export const StandardCheckoutCard = ({
    product,
    checkedProducts,
    setChecked,
    testId,
}: {
    product: Product;
    checkedProducts: Product[];
    setChecked: React.Dispatch<React.SetStateAction<Product[]>>;
    testId?: string;
}): JSX.Element => {
    const isChecked = getIsChecked(product, checkedProducts);
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);

    const discount =
        bestDiscountedProductCodes.has(product.recurlyCode) && isBestForDentistry ? product.price * BEST_DISCOUNT : 0;

    return (
        <FlexRowAlignCenter
            py={"4px"}
            pl={5}
            pr={7}
            justifyContent={"space-between"}
            backgroundColor={"white"}
            data-testid={testId ? testId : product.recurlyCode}
        >
            <FlexRowAlignCenter>
                <Box
                    onClick={() =>
                        isChecked
                            ? setChecked(_.reject((checkedProduct) => checkedProduct.id === product.id))
                            : setChecked((prevState) => prevState.concat(product))
                    }
                >
                    <Checkbox fill={"nauticBlue"} border={"1px solid #5A788F"} isChecked={isChecked} />
                </Box>

                <Box pl={"20px"} />
                <Box>
                    <Body14 color={"nauticBlue"} opacity={isChecked ? 1 : 0.31}>
                        {product.name}
                    </Body14>
                    <Label11 color={"balticBlue"} opacity={isChecked ? 1 : 0.27}>
                        {product.description}
                    </Label11>
                </Box>
            </FlexRowAlignCenter>
            {isChecked ? <Body14 color={"agateGray"}>{formatAsCents(product.price - discount)}</Body14> : null}
        </FlexRowAlignCenter>
    );
};

export const PrintCheckoutCard = ({
    product,
    checkedProducts,
    setChecked,
}: {
    product: Product;
    checkedProducts: Product[];
    setChecked: React.Dispatch<React.SetStateAction<Product[]>>;
}): JSX.Element => {
    const { 2: updateProduct, 3: watchedProduct } = useCart(product);

    if (watchedProduct.type === "print") {
        return (
            <Box data-testid={watchedProduct.recurlyCode}>
                <StandardCheckoutCard
                    product={watchedProduct}
                    setChecked={setChecked}
                    checkedProducts={checkedProducts}
                    testId={"base"}
                />
                <FlexRow justifyContent={"space-between"} backgroundColor={"white"} px={"13px"}>
                    <FlexRowAlignCenter height={"46px"}>
                        <Label color={"balticBlue"}>Quantity</Label>
                        <XSpacer spaceX={2} />
                        <Box width={"93px"} data-testid={`quantitySelect${watchedProduct.id}`}>
                            <ProductCardSelect
                                onChange={(quantityOption) => {
                                    if (quantityOption) {
                                        updateProduct({
                                            ...watchedProduct,
                                            price: quantityOption.price,
                                            recurlyCode: quantityOption.recurlyCode,
                                            quantity: quantityOption,
                                        });
                                        setChecked((prevState) =>
                                            prevState.map((checkedProduct) =>
                                                checkedProduct.id === watchedProduct.id
                                                    ? {
                                                          ...watchedProduct,
                                                          price: quantityOption.price,
                                                          recurlyCode: quantityOption.recurlyCode,
                                                          quantity: quantityOption,
                                                      }
                                                    : checkedProduct
                                            )
                                        );
                                    }
                                }}
                                defaultValue={watchedProduct.quantity}
                                options={watchedProduct.quantityOptions}
                            />
                        </Box>
                        <Box ml={"34px"} />
                    </FlexRowAlignCenter>
                    <Box width={"132px"} textAlign={"center"}>
                        <Label position={"relative"} bottom={"2px"} color={"balticBlue"}>
                            Paper type:
                        </Label>
                        <Box data-testid={"paperTypeSelect"}>
                            <Box
                                display={"flex"}
                                backgroundColor={"white"}
                                height={"25px"}
                                justifyContent={"center"}
                                border={"1px solid #94A5B5"}
                                alignItems={"center"}
                            >
                                <Body12 color={"nauticBlue"}>{watchedProduct.paperType}</Body12>
                            </Box>
                        </Box>
                    </Box>
                </FlexRow>
            </Box>
        );
    }
    return <Box />;
};

export const TrainingCheckoutCard = ({
    product,
    setChecked,
    checkedProducts,
}: {
    product: Product;
    checkedProducts: Product[];
    setChecked: React.Dispatch<React.SetStateAction<Product[]>>;
}): JSX.Element => {
    const { 2: updateProduct, 3: watchedProduct } = useCart(product);

    if (watchedProduct.type === "training") {
        return (
            <Box data-testid={watchedProduct.recurlyCode}>
                <StandardCheckoutCard
                    product={watchedProduct}
                    checkedProducts={checkedProducts}
                    setChecked={setChecked}
                    testId={"base"}
                />
                <FlexRowAlignCenter justifyContent={"space-between"} backgroundColor={"white"} pb={"13px"} px={"13px"}>
                    <Label color={"balticBlue"}>Choose Training Level:</Label>
                    <Box width={"192px"} data-testid={`trainingLevelSelect${watchedProduct.id}`}>
                        <ProductCardSelect
                            onChange={(option) => {
                                if (option) {
                                    updateProduct({
                                        ...watchedProduct,
                                        trainingLevel: option,
                                        price: option.price,
                                        recurlyCode: option.recurlyCode,
                                    });
                                    setChecked((prevState) =>
                                        prevState.map((checkedProduct) =>
                                            checkedProduct.id === watchedProduct.id
                                                ? {
                                                      ...watchedProduct,
                                                      trainingLevel: option,
                                                      price: option.price,
                                                      recurlyCode: option.recurlyCode,
                                                  }
                                                : checkedProduct
                                        )
                                    );
                                }
                            }}
                            defaultValue={watchedProduct.trainingLevel}
                            options={watchedProduct.trainingLevelOptions}
                            classNamePrefix={"trainingLevel"}
                        />
                    </Box>
                </FlexRowAlignCenter>
            </Box>
        );
    }
    return <Box />;
};
