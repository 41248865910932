import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { ArrowRight } from "./assets";
import React from "react";
import { Label11Bold } from "../Typography";
import { Color } from "../../@types/color";
import { Checkbox } from "./Checkboxes";

export const LearnMore = ({
    color,
    setPopup,
    learnMoreText,
}: {
    color: Color;
    setPopup: () => void;
    learnMoreText?: string;
}): JSX.Element => (
    <FlexRowAlignCenter cursor={"pointer"} onClick={setPopup}>
        <ButtonText color={color}>{learnMoreText ? learnMoreText : "learn more"}</ButtonText> <Box pl={"10px"} />{" "}
        <ArrowRight fill={color} />
    </FlexRowAlignCenter>
);

export const SelectBox = ({
    color,
    selectedProducts,
    setSelectedProducts,
    recurlyCode,
}: {
    color: Color;
    selectedProducts: string[];
    setSelectedProducts: (value: ((prevState: string[]) => string[]) | string[]) => void;
    recurlyCode: string;
}): JSX.Element => {
    const isChecked = selectedProducts.includes(recurlyCode);

    return (
        <FlexRowAlignCenter
            data-testid={"select"}
            cursor={"pointer"}
            onClick={() => {
                if (isChecked) {
                    setSelectedProducts((prevState) => prevState.filter((code) => code !== recurlyCode));
                } else {
                    if (recurlyCode === "seoe-01") {
                        setSelectedProducts((prevState) =>
                            prevState.filter((code) => code !== "seoe-02").concat(recurlyCode)
                        );
                    } else if (recurlyCode === "seoe-02") {
                        setSelectedProducts((prevState) =>
                            prevState.filter((code) => code !== "seoe-01").concat(recurlyCode)
                        );
                    } else {
                        setSelectedProducts((prevState) => prevState.concat(recurlyCode));
                    }
                }
            }}
        >
            <ButtonText color={color}>select</ButtonText> <Box pl={"10px"} />{" "}
            <Checkbox isChecked={isChecked} backgroundColor={"white"} fill={"forrestGreen"} border={"auratiumGreen"} />
        </FlexRowAlignCenter>
    );
};

const ButtonText = ({ color, children }: { color: Color; children: React.ReactNode }) => (
    <Label11Bold color={color} uppercase>
        {children}
    </Label11Bold>
);
