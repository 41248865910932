import { Box, FlexRowAlignCenter, YSpacer } from "../../styledSystemUtilities";
import { Body12, Body12Medium, Body14, Body14Bold, Header, Label9Medium } from "../../Typography";
import {
    AdditionalSubscriptionCard,
    CurrentSubscriptionRow,
    RecurlyLoadingModal,
    TotalPriceRow,
} from "../../Molecules";
import _ from "lodash/fp";
import { BigSubscriptionIcon, CalendarIcon } from "../../Atoms/assets";
import { IconButton, SmallIconButton } from "../../Atoms/Buttons";
import React, { useState } from "react";
import { FormattedAdditionalProduct, OnlineSubscription } from "../../../@types/reports/Online";
import utils from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { slices } from "../../../redux";
import { PopupContainer } from "../PopupContainer";
import bestDiscountedProductCodes from "../../../constants/bestDiscountedProductCodes";
import { BEST_DISCOUNT } from "../../../constants/bestDiscount";
import { TaskKeys } from "../../../@types/shared";

export const OnlineSubscriptions = ({
    onlineSubscriptions,
    recommendedSubscriptions,
    otherSubscriptions,
}: {
    onlineSubscriptions: OnlineSubscription[];
    recommendedSubscriptions: OnlineSubscription[];
    otherSubscriptions: OnlineSubscription[];
}): JSX.Element => {
    const dispatch = useAppDispatch();
    const practiceId = useAppSelector((state) => state.openPractice.id);
    const calendlyLink = useAppSelector((state) => state.clientAccount.strategist.calendlyLink);
    const activeCoupons = useAppSelector((state) => state.activeCoupons);
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);
    const tasks = useAppSelector((state) => state.openPractice.tasks);
    const popup = useAppSelector((state) => state.popup);
    const [selectedProductCodes, setSelectedProductCodes] = useState<string[]>([]);
    const currentSubscriptionsWithDiscounts = utils.getOnlineSubscriptionsWithDiscounts(
        onlineSubscriptions,
        activeCoupons
    );

    const currentTaskKeys = new Set(_.flow(_.map(_.get("taskKey")), _.compact)(tasks));

    const recommendedNormalizedSubscriptions = getNormalizedSubscriptions(
        currentSubscriptionsWithDiscounts,
        recommendedSubscriptions,
        currentTaskKeys,
        isBestForDentistry
    );
    const additionalNormalizedSubscriptions = getNormalizedSubscriptions(
        currentSubscriptionsWithDiscounts,
        otherSubscriptions,
        currentTaskKeys,
        isBestForDentistry
    );

    const selectedProducts = _.flow(
        _.map((code) =>
            [...recommendedNormalizedSubscriptions, ...additionalNormalizedSubscriptions].find(
                (product) => product.recurlyCode === code
            )
        ),
        _.compact
    )(selectedProductCodes);

    const proposedPayment =
        _.sumBy("monthlyPrice", recommendedNormalizedSubscriptions) +
        _.sumBy("monthlyPrice", currentSubscriptionsWithDiscounts);

    const currentPayment = _.sumBy("monthlyPrice", currentSubscriptionsWithDiscounts);
    const selectedProductsPayment = _.sumBy("monthlyPrice", selectedProducts);

    const purchaseProducts = () => {
        if (selectedProducts.length > 0) {
            dispatch(slices.paymentModal.actions.set({ openModal: "onlinePaymentConfirmation" }));
        } else {
            dispatch(slices.paymentModal.actions.set({ openModal: "noOnlineProductsSelected" }));
        }
    };

    return (
        <>
            <RecurlyLoadingModal
                practiceId={practiceId}
                selectedProducts={selectedProducts}
                currentPayment={currentPayment}
                newPayment={selectedProductsPayment + currentPayment}
                setSelectedProducts={setSelectedProductCodes}
            />
            {popup ? <PopupContainer popupId={popup} /> : null}

            <Box data-testid={"subscriptions"} maxWidth={"375px"} mb={5}>
                <Box backgroundColor={"aquaHaze"} px={8} pt={7}>
                    <Header color={"forrestGreen"}>Your Current Subscriptions</Header>
                    <YSpacer spaceY={5} />
                    <FlexRowAlignCenter justifyContent={"space-between"} px={5}>
                        <Label9Medium color={"auratiumGreen"} uppercase>
                            product
                        </Label9Medium>
                        <Label9Medium color={"auratiumGreen"} uppercase>
                            monthly
                        </Label9Medium>
                    </FlexRowAlignCenter>
                    <YSpacer spaceY={2} />
                    <Box data-testid={"currentSubscriptions"}>
                        {currentSubscriptionsWithDiscounts.map((subscription) => {
                            return (
                                <Box key={subscription.recurlyCode} data-testid={subscription.recurlyCode} pb={1}>
                                    <CurrentSubscriptionRow
                                        name={subscription.name}
                                        price={subscription.monthlyPrice}
                                    />
                                </Box>
                            );
                        })}
                    </Box>

                    <YSpacer spaceY={4} />
                    <Box data-testid={"monthlyTotal"}>
                        <TotalPriceRow price={currentPayment} text={"Current Online Monthly Total"} />
                        {isBestForDentistry ? (
                            <Box pl={5} pt={4}>
                                <Body12 color={"deepGreen"}>Prices include Best For Dentistry discount.</Body12>
                            </Box>
                        ) : null}
                    </Box>

                    <YSpacer spaceY={7} />
                    {recommendedNormalizedSubscriptions.length > 0 ? (
                        <>
                            <Box pl={5}>
                                <Body14Bold color={"forrestGreen"}>
                                    To further improve your online visibility,
                                    <br /> we recommend adding these subscriptions:
                                </Body14Bold>
                            </Box>
                            <YSpacer spaceY={5} />
                            <Box data-testid={"recommendations"}>
                                {recommendedNormalizedSubscriptions.map((subscription, index) => {
                                    return (
                                        <Box key={index} pb={3}>
                                            <AdditionalSubscriptionCard
                                                subscription={subscription}
                                                selectedProducts={selectedProductCodes}
                                                setSelectedProducts={setSelectedProductCodes}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                            <YSpacer spaceY={2} />
                            <Box data-testid={"proposedMonthlyTotal"}>
                                <TotalPriceRow text={"Proposed New Online Monthly Total"} price={proposedPayment} />
                            </Box>

                            <YSpacer spaceY={8} />
                        </>
                    ) : null}
                </Box>
                {recommendedNormalizedSubscriptions.length > 0 ? (
                    <IconButton
                        backgroundColor={"auratiumGreen"}
                        Icon={BigSubscriptionIcon}
                        onClick={purchaseProducts}
                        color={"white"}
                    >
                        update my subscriptions to add selected products
                    </IconButton>
                ) : null}
                <Box backgroundColor={"aquaHaze"} px={8} pb={7}>
                    <YSpacer spaceY={6} />
                    <Body12Medium color={"forrestGreen"} lineHeight={"16px"}>
                        If you have questions about current subscriptions, strategy, recommendations, or to cancel any
                        subscriptions, schedule a call with your Ascent Marketing Strategist.
                    </Body12Medium>
                    <YSpacer spaceY={6} />
                    <SmallIconButton
                        Icon={CalendarIcon}
                        backgroundColor={"auratiumGreen"}
                        onClick={() => window.open(calendlyLink, "_blank")}
                        color={"white"}
                    >
                        schedule call
                    </SmallIconButton>
                    <YSpacer spaceY={6} />
                    <Body14 color={"forrestGreen"}>Other available products to consider:</Body14>
                    <YSpacer spaceY={2} />
                    <Box data-testid={"otherProducts"}>
                        {additionalNormalizedSubscriptions.map((subscription, index) => {
                            return (
                                <Box key={index} pb={3}>
                                    <AdditionalSubscriptionCard
                                        subscription={subscription}
                                        setSelectedProducts={setSelectedProductCodes}
                                        selectedProducts={selectedProductCodes}
                                    />
                                </Box>
                            );
                        })}
                    </Box>

                    <YSpacer spaceY={8} />
                </Box>
                <IconButton
                    backgroundColor={"auratiumGreen"}
                    Icon={BigSubscriptionIcon}
                    onClick={purchaseProducts}
                    color={"white"}
                >
                    update my subscriptions to add selected products
                </IconButton>
            </Box>
        </>
    );
};

function getNormalizedSubscriptions(
    current: OnlineSubscription[],
    subscriptions: OnlineSubscription[],
    currentTaskKeys: Set<TaskKeys>,
    isBestForDentistry: boolean
): FormattedAdditionalProduct[] {
    const subscriptionsWithDiscounts = isBestForDentistry
        ? subscriptions.map((subscription) => ({
              ...subscription,
              monthlyPrice: bestDiscountedProductCodes.has(subscription.recurlyCode)
                  ? subscription.monthlyPrice * (1 - BEST_DISCOUNT)
                  : subscription.monthlyPrice,
          }))
        : subscriptions;

    return utils.formatAdditionalProducts(current, subscriptionsWithDiscounts, currentTaskKeys);
}
