import React from "react";
import styled, { css } from "styled-components";
import { all } from "../styledSystemUtilities";
import { Color } from "../../@types/color";
import { AllStyledSystemProps } from "../styledSystemAll";
import { FontWeight } from "../../@types/fontWeight";
import { OpacityProps, TextAlignProps } from "styled-system";

const base = css`
    font-weight: 400;
    color: #7f7f7f;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin: 0;
`;
export const Body = styled("p")<AllStyledSystemProps>`
    ${base};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 20px;
    ${all}
`;
export const Header = styled("h2")<AllStyledSystemProps>`
    ${base};
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    line-height: 24px;
    ${all}
`;
export const Label = styled("p")<AllStyledSystemProps>`
    ${base};
    font-family: Roboto, sans-serif;
    font-size: 10px;
    line-height: 14px;
    ${all}
`;

type FontProps = TextAlignProps &
    OpacityProps & {
        color?: Color;
        fontWeight?: FontWeight;
        lineHeight?: string;
        uppercase?: boolean;
        fontFamily?: "montserrat" | "roboto";
        whiteSpace?: string;
        children: React.ReactNode;
    };

export const Header200 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"200px"} lineHeight={1.2} fontWeight={"semiBold"} opacity={0.3} {...props} />
);
export const Header100 = (props: FontProps): JSX.Element => (
    <Body as={"h1"} fontSize={"100px"} lineHeight={1.2} fontWeight={"thin"} {...props} />
);
export const Header30 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"30px"} lineHeight={"37px"} fontWeight={"bold"} {...props} />
);
export const Header27 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"27px"} lineHeight={"33px"} {...props} />
);
export const Header22 = (props: FontProps): JSX.Element => (
    <Header as={"h1"} fontSize={"22px"} lineHeight={"27px"} {...props} />
);
export const Body14 = (props: FontProps): JSX.Element => <Body fontSize={"14px"} {...props} />;
export const Body14Medium = (props: FontProps): JSX.Element => (
    <Body14 fontWeight={"medium"} {...props} children={props.children} />
);
export const Body14Bold = (props: FontProps): JSX.Element => (
    <Body14 fontWeight={"bold"} {...props} children={props.children} />
);

export const Body12 = (props: FontProps): JSX.Element => <Body fontSize={"12px"} lineHeight={"16px"} {...props} />;
export const Body12Medium = (props: FontProps): JSX.Element => (
    <Body12 fontWeight={"medium"} {...props} children={props.children} />
);
export const Body12Bold = (props: FontProps): JSX.Element => (
    <Body12 fontWeight={"bold"} {...props} children={props.children} />
);
export const BodyMedium = (props: FontProps): JSX.Element => <Body fontWeight={"medium"} {...props} />;
export const BodyBold = (props: FontProps): JSX.Element => <Body fontWeight={"bold"} {...props} />;
export const Body20Bold = (props: FontProps): JSX.Element => <Body fontWeight={"bold"} fontSize={"20px"} {...props} />;

export const Header16 = (props: FontProps): JSX.Element => <Header as={"h3"} fontSize={"16px"} {...props} />;
export const Header16Bold = (props: FontProps): JSX.Element => (
    <Header16 fontWeight={"bold"} {...props} children={props.children} />
);

export const Label9 = (props: FontProps): JSX.Element => <Label fontSize={"9px"} {...props} />;
export const Label9Medium = (props: FontProps): JSX.Element => (
    <Label fontSize={"9px"} fontWeight={"medium"} {...props} />
);
export const LabelBold = (props: FontProps): JSX.Element => <Label fontWeight={"bold"} {...props} />;
export const LabelBlack = (props: FontProps): JSX.Element => <Label fontWeight={"black"} {...props} />;
export const LabelMedium = (props: FontProps): JSX.Element => <Label fontWeight={"medium"} {...props} />;
export const Label11 = (props: FontProps): JSX.Element => <Label fontSize={"11px"} {...props} />;
export const Label11Bold = (props: FontProps): JSX.Element => (
    <Label11 fontWeight={"bold"} {...props} children={props.children} />
);
export const Label11Medium = (props: FontProps): JSX.Element => (
    <Label11 fontWeight={"medium"} {...props} children={props.children} />
);
