import { Box, FlexCol, FlexRowAlignCenter, Span, XSpacer, YSpacer } from "../styledSystemUtilities";
import React, { useState } from "react";
import { X } from "../Atoms/assets";
import { Body12, Body14, Body14Bold, Header27, Label, Label9Medium } from "../Typography";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CheckoutCard } from "./CheckoutCard";
import _ from "lodash/fp";
import { formatAsCents } from "../../utils/formatter";
import { paymentOptions } from "../../constants/paymentOptions";
import { ProductCardSelect, SmallButton } from "../Atoms";
import { actions, slices } from "../../redux";
import { ProfitModalContainer } from "./ProfitModalContainer";
import { PaymentOption } from "../../@types/reports/ProfitCenters";
import { BEST_DISCOUNT } from "../../constants/bestDiscount";

export function CartModal(): JSX.Element {
    const cart = useAppSelector((state) => state.cart);
    const dispatch = useAppDispatch();
    const [checkedProducts, setChecked] = useState(cart);
    const [paymentOption, setPaymentOption] = useState(paymentOptions[0]);
    const practiceId = useAppSelector((state) => state.openPractice.id);
    const activeCoupons = useAppSelector((state) => state.activeCoupons);
    const bestForDentistryCoupon = activeCoupons.find((coupon) => coupon.code === "best");
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);
    const coupons = isBestForDentistry && !bestForDentistryCoupon ? ["best"] : [];

    const rawTotalPrice = _.sumBy("price", checkedProducts);
    const totalPrice = rawTotalPrice * (isBestForDentistry ? 1 - BEST_DISCOUNT : 1);
    const monthlyPrice = getPaymentAmount(rawTotalPrice, paymentOption) * (isBestForDentistry ? 1 - BEST_DISCOUNT : 1);
    return (
        <ProfitModalContainer>
            <FlexRowAlignCenter
                backgroundColor={"balticBlue"}
                pl={8}
                width={"375px"}
                height={11}
                cursor={"pointer"}
                onClick={() => dispatch(slices.paymentModal.actions.set({ openModal: null }))}
                position={"fixed"}
                zIndex={2000}
            >
                <X />
                <XSpacer spaceX={6} />
                <Body14 color={"white"}>Close and continue with Profit.</Body14>
            </FlexRowAlignCenter>
            <YSpacer spaceY={12} />
            {cart.length > 0 ? (
                <>
                    <Header27 color={"balticBlue"} textAlign={"center"}>
                        Your Cart
                    </Header27>
                    <Body12 color={"nauticBlue"} textAlign={"center"}>
                        <Span fontWeight={"bold"}>Product Purchases</Span> – Must be paid in full.
                    </Body12>
                    <YSpacer spaceY={5} />
                    <Box px={7}>
                        <FlexRowAlignCenter justifyContent={"space-between"} pr={7}>
                            <Label9Medium color={"cadetGray"} uppercase>
                                remove?
                            </Label9Medium>
                            <Label9Medium color={"cadetGray"} uppercase>
                                price
                            </Label9Medium>
                        </FlexRowAlignCenter>
                        {cart.map((product) => (
                            <Box key={product.id} pb={1}>
                                {CheckoutCard(product, checkedProducts, setChecked)}
                            </Box>
                        ))}
                        <YSpacer spaceY={3} />
                        <FlexRowAlignCenter justifyContent={"space-between"} pl={5} pr={7}>
                            <Body12 color={"balticBlue"}>Regular Total:</Body12>
                            <Body14Bold>{formatAsCents(rawTotalPrice)}</Body14Bold>
                        </FlexRowAlignCenter>
                        <YSpacer spaceY={1} />
                        <FlexRowAlignCenter justifyContent={"space-between"} pl={5} pr={7}>
                            <Body12 color={"amaranthRed"}>Best Savings:</Body12>
                            <Body14Bold color={"amaranthRed"}>- {formatAsCents(rawTotalPrice - totalPrice)}</Body14Bold>
                        </FlexRowAlignCenter>
                        <YSpacer spaceY={3} />
                        <FlexRowAlignCenter
                            justifyContent={"space-between"}
                            pl={5}
                            pr={7}
                            height={11}
                            backgroundColor={"white"}
                        >
                            <Body14Bold color={"nauticBlue"}>Profit Total</Body14Bold>
                            <Body14Bold color={"nauticBlue"}>{formatAsCents(totalPrice)}</Body14Bold>
                        </FlexRowAlignCenter>
                    </Box>
                    <YSpacer spaceY={8} />
                    <Box pl={"26px"} pr={11}>
                        <Body14Bold color={"nauticBlue"}>Payment Options</Body14Bold>
                        <Body12 color={"balticBlue"}>Choose a payment plan</Body12>
                        <YSpacer spaceY={3} />
                        <FlexRowAlignCenter justifyContent={"space-between"}>
                            <Box width={"192px"}>
                                <ProductCardSelect
                                    onChange={(option) => {
                                        if (option) setPaymentOption(option);
                                    }}
                                    defaultValue={paymentOption}
                                    options={paymentOptions}
                                />
                            </Box>
                            <Body14Bold color={"nauticBlue"}>
                                {formatAsCents(monthlyPrice) + (paymentOption.value > 1 ? "/mo." : "")}
                            </Body14Bold>
                        </FlexRowAlignCenter>
                        <YSpacer spaceY={4} />
                        <Label color={"balticBlue"}>{paymentOption.disclaimer}</Label>
                    </Box>
                    <YSpacer spaceY={7} />
                    <FlexCol alignItems={"center"}>
                        <SmallButton
                            backgroundColor={"balticBlue"}
                            onClick={() => {
                                dispatch(slices.paymentModal.actions.set({ openModal: "profitLoading" }));
                                dispatch(
                                    actions.makeProfitPurchase(practiceId, checkedProducts, paymentOption, coupons)
                                );
                                dispatch(slices.cart.actions.set([]));
                            }}
                        >
                            buy now
                        </SmallButton>
                        <YSpacer spaceY={7} />
                        <Box width={"194px"}>
                            <Label color={"balticBlue"} textAlign={"center"}>
                                Clicking BUY NOW will charge the card on file the total listed under “Payment Options”
                            </Label>
                        </Box>
                    </FlexCol>
                    <YSpacer spaceY={9} />
                </>
            ) : (
                <Box>
                    <YSpacer spaceY={14} />
                    <Body14 color={"nauticBlue"} textAlign={"center"}>
                        Your cart is empty.
                    </Body14>
                    <YSpacer spaceY={14} />
                </Box>
            )}
        </ProfitModalContainer>
    );
}

function getPaymentAmount(price: number, paymentOption: PaymentOption) {
    return _.round((price * paymentOption.fee) / paymentOption.value);
}
