import React from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";
import { LionSpeakProductCardLogo } from "../Atoms/assets";
import { ProductBottomRow, ProductDetails, ProductCardSelect, PastProductDetails, PaymentsRow } from "../Atoms";
import { useCart } from "../../hooks/useCart";
import { TrainingProduct } from "../../@types/reports/ProfitCenters";
import { Label } from "../Typography";
import { useSetPopup } from "../../hooks/useSetPopup";

export function TrainingProductCard({
    product,
    isPastProduct = false,
}: {
    product: TrainingProduct;
    isPastProduct?: boolean;
}): JSX.Element {
    const [isAdded, toggleProduct, updateProduct, watchedProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    if (watchedProduct.type === "training") {
        return (
            <Box data-testid={watchedProduct.recurlyCode} mb={5} backgroundColor={"white"}>
                <FlexRowAlignCenter justifyContent={"center"} pt={"14px"} pb={"6px"}>
                    <LionSpeakProductCardLogo />
                </FlexRowAlignCenter>
                {isPastProduct ? <PastProductDetails product={product} /> : <ProductDetails product={product} />}
                {product.payments ? (
                    <PaymentsRow payments={product.payments} productCode={product.recurlyCode} />
                ) : null}
                {isPastProduct ? null : (
                    <FlexRowAlignCenter justifyContent={"space-between"} px={"13px"} pb={3}>
                        <Label color={"balticBlue"}>Choose Training Level:</Label>
                        <Box width={"192px"} data-testid={`trainingLevelSelect${product.id}`}>
                            <ProductCardSelect
                                onChange={(option) => {
                                    if (option !== null) {
                                        updateProduct({
                                            ...watchedProduct,
                                            trainingLevel: option,
                                            price: option.price,
                                            recurlyCode: option.recurlyCode,
                                        });
                                    }
                                }}
                                defaultValue={watchedProduct.trainingLevel}
                                options={watchedProduct.trainingLevelOptions}
                            />
                        </Box>
                    </FlexRowAlignCenter>
                )}
                <ProductBottomRow
                    isAdded={isAdded}
                    toggleProduct={toggleProduct}
                    isReOrder={isPastProduct}
                    setPopup={setPopup}
                />
            </Box>
        );
    }
    return <Box />;
}
