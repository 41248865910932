import styled from "styled-components";
import React from "react";
import _ from "lodash/fp";
import { Dot } from "../Atoms";
import { all, Box, FlexRow, Span } from "../styledSystemUtilities";
import { AllStyledSystemProps } from "../styledSystemAll";

const Table = styled.table<AllStyledSystemProps>`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
`;

const Td = styled.td<AllStyledSystemProps>`
    color: #7f7f7f;
    height: 35px;

    border-top: ${(props) => props.theme.borders.green};
    text-align: center;
    font-size: 12px;
    ${all}
`;

const TdA = styled(Td)<AllStyledSystemProps>`
    background-color: #ddebe3;
    width: 52px;
    ${all}
`;

const TdB = styled(Td)<AllStyledSystemProps>`
    background-color: white;
    width: 52px;
    border-right: ${(props) => props.theme.borders.green};
    ${all}
`;

const TdName = styled(Td)<AllStyledSystemProps>`
    border-left: ${(props) => props.theme.borders.green};
    padding-left: 12px;
    text-align: left;
    ${all}
`;

const Th = styled.th<AllStyledSystemProps>`
    color: #a7a9ac;
    font-size: 9px;
    font-weight: 500;
    padding-bottom: 5px;
    ${all}
`;

type TablePractice = {
    practiceName: string;
    placeId: string;
    x: number;
    y: number;
};

export const CompetitorsTable = ({
    competitors,
    target,
    headings,
    order = "asc",
    dataTestId,
}: {
    competitors: TablePractice[];
    target: TablePractice[];
    headings: string[];
    order?: "desc" | "asc";
    dataTestId: string;
}): JSX.Element => {
    const sortedCompetitors = _.orderBy("x", order, competitors);

    const sorted: TablePractice[] = _.flow([_.take(5), _.concat(target[0]), _.orderBy("x", order)])(sortedCompetitors);
    const hideTdA = headings.length === 2;

    const topCompData = sortedCompetitors[0];

    return (
        <Table data-testid={dataTestId}>
            <thead>
                <tr>
                    <Th style={{ textAlign: "left" }} uppercase>
                        {headings[0]}
                    </Th>
                    {hideTdA ? null : <Th uppercase>{headings[1]}</Th>}
                    <Th whiteSpace={hideTdA ? "nowrap" : "normal"} uppercase>
                        {_.takeRight(1, headings)}
                    </Th>
                </tr>
            </thead>
            <tbody>
                {sorted.map((practice, index, array) => {
                    if (practice.placeId === target[0].placeId) {
                        return (
                            <tr key={practice.placeId} data-testid={`row${index + 1}`}>
                                <TdName border={"thickGreen"} borderRight={"none"} data-testid={"name"}>
                                    <FlexRow alignItems={"center"}>
                                        <Dot flexShrink={0} fill={"sienna"} />
                                        <Box mr={2} />
                                        <Span fontWeight={"bold"}>{practice.practiceName}</Span>
                                    </FlexRow>
                                </TdName>
                                {hideTdA ? null : (
                                    <TdA
                                        color={"white"}
                                        backgroundColor={"sienna"}
                                        borderTop={"thickGreen"}
                                        borderBottom={"thickGreen"}
                                        fontWeight={"bold"}
                                        data-testid={"y"}
                                    >
                                        {practice.y}
                                    </TdA>
                                )}
                                <TdB
                                    color={"white"}
                                    backgroundColor={hideTdA ? "sienna" : "amaranthRed"}
                                    border={"thickGreen"}
                                    borderLeft={"none"}
                                    fontWeight={"bold"}
                                    data-testid={"x"}
                                >
                                    {practice.x}
                                </TdB>
                            </tr>
                        );
                    } else if (practice.placeId === topCompData.placeId) {
                        return (
                            <tr key={practice.placeId} data-testid={`row${index + 1}`}>
                                <TdName data-testid={"name"}>
                                    <FlexRow alignItems={"center"}>
                                        <Dot flexShrink={0} fill={"auratiumGreen"} />
                                        <Box mr={2} />
                                        {practice.practiceName}
                                    </FlexRow>
                                </TdName>
                                {hideTdA ? null : <TdA data-testid={"y"}>{practice.y}</TdA>}
                                <TdB data-testid={"x"}>{practice.x}</TdB>
                            </tr>
                        );
                    }
                    const borderBottom = index === array.length - 1 ? "green" : "none";
                    return (
                        <tr key={practice.placeId} data-testid={`row${index + 1}`}>
                            <TdName borderBottom={borderBottom} data-testid={"name"}>
                                {practice.practiceName}
                            </TdName>
                            {hideTdA ? null : (
                                <TdA data-testid={"y"} borderBottom={borderBottom}>
                                    {practice.y}
                                </TdA>
                            )}
                            <TdB data-testid={"x"} borderBottom={borderBottom}>
                                {practice.x}
                            </TdB>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
