import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PracticeFrontEnd } from "../../@types/shared";
import _ from "lodash/fp";

const initialState: PracticeFrontEnd = {
    billingContact: {
        address: "",
        accountId: "",
        cardType: "",
        lastFour: "",
        name: "",
    },
    practiceName: "",
    contacts: [],
    id: "",
    doctors: [],
    startDate: "",
    tasks: [],
    pmsIntegrationId: "",
};

export default createSlice({
    name: "openPractice",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<PracticeFrontEnd>) => action.payload,
        setTasks: (state, action) => _.set("tasks", action.payload, state),
    },
});
