import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CartModal } from "../../Molecules/CartModal";
import { ProfitModalContainer } from "../../Molecules/ProfitModalContainer";
import { LoadingWheel, SmallButton } from "../../Atoms";
import { FlexColAlignCenter, FlexRowAlignCenter, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body14, Body14Medium, BodyMedium, Header27 } from "../../Typography";
import { slices } from "../../../redux";
import { X } from "../../Atoms/assets";
import { formatAsDollars } from "../../../utils/formatter";

export function ProfitPaymentModal(): JSX.Element | null {
    const dispatch = useAppDispatch();
    const openModal = useAppSelector((state) => state.paymentModal.openModal);
    const amountCharged = useAppSelector((state) => state.paymentModal.amountCharged);

    return openModal === "cart" ? (
        <CartModal />
    ) : openModal === "profitLoading" ? (
        <ProfitModalContainer>
            <FlexColAlignCenter justifyContent={"center"} data-testid={"recurlyLoadingWheel"} height={"100%"}>
                <YSpacer spaceY={12} />
                <LoadingWheel color={"nauticBlue"} />
                <YSpacer spaceY={8} />
                <Body14Medium>Processing Payment...</Body14Medium>
                <YSpacer spaceY={12} />
            </FlexColAlignCenter>
        </ProfitModalContainer>
    ) : openModal === "profitThankYou" ? (
        <ProfitModalContainer>
            <FlexRowAlignCenter
                backgroundColor={"balticBlue"}
                pl={8}
                width={"375px"}
                height={11}
                cursor={"pointer"}
                onClick={() => dispatch(slices.paymentModal.actions.set({ openModal: null }))}
                position={"fixed"}
                zIndex={2000}
            >
                <X />
                <XSpacer spaceX={6} />
                <Body14 color={"white"}>Close and continue with Profit.</Body14>
            </FlexRowAlignCenter>
            <FlexColAlignCenter>
                <YSpacer spaceY={14} />
                <Header27 color={"nauticBlue"}>Thank you!</Header27>
                <YSpacer spaceY={3} />
                <BodyMedium color={"nauticBlue"}>Please Note:</BodyMedium>
                <YSpacer spaceY={4} />
                <Body14 color={"nauticBlue"} textAlign={"center"}>
                    Your card has been charged {formatAsDollars(amountCharged ?? 0)}.
                </Body14>
                <YSpacer spaceY={5} />
                <SmallButton
                    backgroundColor={"balticBlue"}
                    onClick={() => {
                        dispatch(slices.paymentModal.actions.set({ openModal: null, amountCharged: null }));
                    }}
                >
                    got it
                </SmallButton>
                <YSpacer spaceY={12} />
            </FlexColAlignCenter>
        </ProfitModalContainer>
    ) : null;
}
