import _ from "lodash/fp";
import { slices } from "../redux";
import { useState } from "react";
import { Product } from "../@types/reports/ProfitCenters";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

export function useCart(product: Product): [boolean, () => void, (update: Product) => void, Product] {
    const dispatch = useAppDispatch();
    const cart = useAppSelector((state) => state.cart);
    const productInCart = _.find(["id", product.id], cart);
    const isAdded = Boolean(productInCart);

    const [watchedProduct, setWatchedProduct] = useState(productInCart || product);

    return [
        isAdded,
        () => dispatch(slices.cart.actions.toggleProduct(watchedProduct)),
        (update: Product) => {
            if (isAdded) dispatch(slices.cart.actions.updateProduct(update));
            setWatchedProduct(update);
        },
        watchedProduct,
    ];
}
