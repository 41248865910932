import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Modal = {
    openModal?:
        | "onlinePaymentConfirmation"
        | "onlineLoading"
        | "onlineThankYou"
        | "noOnlineProductsSelected"
        | "growthPaymentConfirmation"
        | "growthLoading"
        | "growthThankYou"
        | "growthError"
        | "cart"
        | "profitLoading"
        | "profitThankYou"
        | null;
    amountCharged?: number | null;
};

const initialState: Modal = {
    openModal: null,
    amountCharged: null,
};

export default createSlice({
    name: "paymentModal",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<Modal>) => ({
            ...state,
            ...action.payload,
        }),
    },
});
