import React from "react";
import { Control, Controller } from "react-hook-form";
import { Box, FlexRow, YSpacer } from "../styledSystemUtilities";
import { Checkbox } from "../Atoms";
import { Body12 } from "../Typography";

export function TwoOptionCheckbox({
    name,
    control,
    yesText,
    noText,
}: {
    name: string;
    control: Control;
    yesText: string;
    noText: string;
}): JSX.Element {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={name}
            render={({ onChange, value }) => (
                <Box data-testid={name}>
                    <FlexRow>
                        <Box onClick={() => onChange(value ? null : true)} data-testid={"yes"}>
                            <Checkbox isChecked={value} fill={"sienna"} />
                        </Box>
                        <Box pl={6} pt={1}>
                            <Body12>Yes</Body12>
                            <YSpacer spaceY={1} />
                            <Body12>{yesText}</Body12>
                        </Box>
                    </FlexRow>
                    <YSpacer spaceY={6} />
                    <FlexRow>
                        <Box onClick={() => onChange(value === false ? null : false)} data-testid={"no"}>
                            <Checkbox isChecked={value === false} fill={"sienna"} />
                        </Box>
                        <Box pl={6} pt={1}>
                            <Body12>No</Body12>
                            <YSpacer spaceY={1} />
                            <Body12>{noText}</Body12>
                        </Box>
                    </FlexRow>
                </Box>
            )}
        />
    );
}
