import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const getAllPractices = (): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/practices/get-all`,
            method: "get",
        },
        success: slices.clientPractices.actions.set,
        label: "clientPractices",
    });

export default getAllPractices;
