import React from "react";
import styled from "styled-components";
import { all, Box } from "../styledSystemUtilities";
import { ArrowLeft, ArrowRight, CartIcon } from "./assets";
import { AllStyledSystemProps } from "../styledSystemAll";
import { Color } from "../../@types/color";

const BaseInputButton = styled.input<AllStyledSystemProps>(
    ({ theme }) => ({
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        fontSize: theme.fontSizes[2],
        textTransform: "uppercase",
        fontFamily: theme.fonts.roboto,
        fontWeight: theme.fontWeights.bold,
        color: theme.colors.white,
        height: theme.sizes[13],
        type: "submit",
        minWidth: "100%",
        ":focus": {
            outline: "none",
        },
        ":hover": {
            cursor: "pointer",
        },
    }),
    all
);

const BaseButton = styled.button<AllStyledSystemProps>(
    ({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        border: "none",
        fontSize: theme.fontSizes[2],
        textTransform: "uppercase",
        fontFamily: theme.fonts.roboto,
        fontWeight: theme.fontWeights.bold,
        color: theme.colors.white,
        height: theme.sizes[13],
        minWidth: "100%",
        ":focus": {
            outline: "none",
        },
        ":hover": {
            cursor: "pointer",
        },
    }),
    all
);

type ButtonProps = {
    backgroundColor: Color;
    children: React.ReactNode;
    type?: "button" | "reset" | "submit" | undefined;
    onClick: () => void;
};

export const LeftButton = ({ backgroundColor, children, onClick }: ButtonProps): JSX.Element => (
    <BaseButton backgroundColor={backgroundColor} onClick={onClick}>
        <ArrowLeft fill={"white"} />
        <Box mr={"9px"} />
        {children}
    </BaseButton>
);

export const RightButton = ({ backgroundColor, children, onClick, type = "submit" }: ButtonProps): JSX.Element => (
    <BaseButton backgroundColor={backgroundColor} onClick={onClick} type={type}>
        {children}
        <Box mr={"9px"} />
        <ArrowRight fill={"white"} />
    </BaseButton>
);

export const RightSubmitButton = ({ backgroundColor }: { backgroundColor: Color }): JSX.Element => (
    <BaseInputButton backgroundColor={backgroundColor} />
);

type IconButtonProps = ButtonProps & {
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    color: string;
    border?: string;
};

export const IconButton = ({ Icon, backgroundColor, border, children, onClick }: IconButtonProps): JSX.Element => (
    <BaseButton backgroundColor={backgroundColor} border={border} onClick={onClick}>
        {children}
        <Box pl={"8px"} />
        <Icon fill={"white"} />
    </BaseButton>
);

export const SmallIconButton = ({
    backgroundColor,
    color,
    children,
    border,
    Icon,
    onClick,
}: IconButtonProps): JSX.Element => (
    <BaseButton
        height={"28px"}
        color={color}
        border={border}
        minWidth={"180px"}
        backgroundColor={backgroundColor}
        width={"180px"}
        onClick={onClick}
    >
        {children}
        <Box mr={6} />
        <Icon fill={color} />
    </BaseButton>
);

export const SmallButton = ({ children, backgroundColor, onClick }: ButtonProps): JSX.Element => (
    <SmallIconButton Icon={ArrowRight} color={"white"} backgroundColor={backgroundColor} onClick={onClick}>
        {children}
    </SmallIconButton>
);

export const ShoppingCartButton = ({ backgroundColor, onClick }: ButtonProps): JSX.Element => (
    <SmallIconButton
        Icon={CartIcon}
        color={"white"}
        border={"gray"}
        backgroundColor={backgroundColor}
        onClick={onClick}
    >
        view cart
    </SmallIconButton>
);
