import _ from "lodash/fp";
import { useEffect } from "react";
import { slices } from "../redux";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { FieldError } from "react-hook-form";
import { ServerErrors } from "../@types/serverErrors";

const useErrors = (label: ServerErrors): FieldError => {
    const dispatch = useAppDispatch();
    const serverErrors = useAppSelector((store) => store.serverErrors);

    useEffect(() => {
        dispatch(slices.serverErrors.actions.unset(label));
    }, [dispatch, label]);

    return { message: _.get([label, "message"], serverErrors), type: label };
};
export default useErrors;
