import { FlexRowAlignCenter, XSpacer } from "../styledSystemUtilities";
import { Body12, Body12Medium, LabelBold } from "../Typography";
import { ArrowRight } from "../Atoms/assets";
import React from "react";

export const TodoLink = ({
    numUncompletedTasks,
    onClick,
}: {
    numUncompletedTasks: number;
    onClick: () => void;
}): JSX.Element | null =>
    numUncompletedTasks ? (
        <FlexRowAlignCenter data-testid={"todos"} cursor={"pointer"} onClick={onClick} pl={4} pt={5}>
            <Body12Medium color={"sienna"}>To Do Items:</Body12Medium>
            <FlexRowAlignCenter
                mx={2}
                height={7}
                width={7}
                borderRadius={"100%"}
                backgroundColor={"amaranthRed"}
                justifyContent={"center"}
            >
                <LabelBold color={"white"} fontFamily={"montserrat"}>
                    {numUncompletedTasks}
                </LabelBold>
            </FlexRowAlignCenter>
            <Body12 color={"sienna"}>Incomplete</Body12>
            <XSpacer spaceX={4} />
            <ArrowRight fill={"sienna"} />
        </FlexRowAlignCenter>
    ) : null;
