import { Body12Medium, Body14Medium, Header16, Header22, Header30, Label } from "../Typography";
import { Box, FlexRowAlignCenter, XSpacer, YSpacer } from "../styledSystemUtilities";
import React from "react";

export const OriginalVsCurrentBarGraph = ({
    title,
    currentLabel,
    currentPercentage,
    originalLabel,
    originalPercentage,
    displayChange,
}: {
    title: string;
    currentPercentage: number;
    originalPercentage: number;
    currentLabel: string | number;
    originalLabel: string | number;
    displayChange?: boolean;
}): JSX.Element => (
    <Box maxWidth={"330px"}>
        <Body14Medium>{title}</Body14Medium>
        <YSpacer spaceY={4} />
        <Body12Medium color={"auratiumGreen"}>Current</Body12Medium>
        <FlexRowAlignCenter data-testid={"currentVisibility"}>
            <Header30 color={"auratiumGreen"}>{currentLabel}</Header30>
            {displayChange ? (
                <>
                    <XSpacer spaceX={4} />
                    <Header16>+{currentPercentage - originalPercentage}% Growth</Header16>
                </>
            ) : null}
        </FlexRowAlignCenter>
        <YSpacer spaceY={1} />
        <FlexRowAlignCenter width={"100%"} height={"11px"}>
            <Box height={"100%"} width={`${currentPercentage}%`} backgroundColor={"auratiumGreen"} />
            <Box height={"100%"} width={`${100 - currentPercentage}%`} backgroundColor={"aquaHaze"} />
        </FlexRowAlignCenter>
        <YSpacer spaceY={4} />
        <FlexRowAlignCenter width={"100%"} height={"11px"}>
            <Box height={"100%"} width={`${originalPercentage}%`} backgroundColor={"amaranthRed"} />
            <Box height={"100%"} width={`${100 - originalPercentage}%`} backgroundColor={"lightAmaranth"} />
        </FlexRowAlignCenter>
        <YSpacer spaceY={2} />
        <Label color={"amaranthRed"}>Original</Label>
        <Box data-testid={"originalVisibility"}>
            <Header22 color={"amaranthRed"}>{originalLabel}</Header22>
        </Box>
        <YSpacer spaceY={9} />
    </Box>
);
