import { api } from "../utilityActions";
import slices from "../slices";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const getProfitReport = (locationId: string): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: `/secure-routes/reports/get-profit-report/${locationId}`,
            method: "get",
        },
        success: slices.profitReport.actions.set,
        label: "profitReport",
    });

export default getProfitReport;
