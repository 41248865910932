import { FlexRowAlignCenter } from "../styledSystemUtilities";
import { Body14Bold } from "../Typography";
import { formatAsDollars } from "../../utils/formatter";
import React from "react";

export const TotalPriceRow = ({ text, price }: { text: string; price: number }): JSX.Element => {
    return (
        <FlexRowAlignCenter justifyContent={"space-between"} pl={5} pr={7} height={11} backgroundColor={"white"}>
            <Body14Bold color={"auratiumGreen"}>{text}</Body14Bold>
            <Body14Bold>{formatAsDollars(price)}</Body14Bold>
        </FlexRowAlignCenter>
    );
};
