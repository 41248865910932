import { Color } from "../../@types/color";
import { Box, FlexRow } from "../styledSystemUtilities";
import theme from "../../theme";
import React from "react";

export const ThreeMonthTrendCells = ({
    dotColor,
    strokeColor,
    backgroundColor,
    lastThreeMonths,
}: {
    dotColor: Color;
    strokeColor: Color;
    backgroundColor: Color;
    lastThreeMonths: number[];
}): JSX.Element => (
    <FlexRow justifySelf={"center"} backgroundColor={"white"}>
        <Box
            width={"1px"}
            height={"25px"}
            backgroundColor={backgroundColor}
            position={"absolute"}
            transform={`translate(49px, 0)`}
            zIndex={0}
        />
        <Line color={strokeColor} point1={lastThreeMonths[0]} point2={lastThreeMonths[1]} />
        <Line color={strokeColor} point1={lastThreeMonths[1]} point2={lastThreeMonths[2]} />
        <Box
            height={"9px"}
            width={"9px"}
            borderRadius={"50%"}
            backgroundColor={dotColor}
            position={"absolute"}
            transform={`translate(94px, ${-5 + (1 - lastThreeMonths[2]) * 25}px)`}
        />
    </FlexRow>
);

const Line = ({ color, point1, point2 }: { color: Color; point1: number; point2: number }): JSX.Element => (
    <Box width={"50px"} height={"25px"} position={"relative"} zIndex={10}>
        <svg style={{ width: "100%", height: "100%" }}>
            <line
                x1="0"
                y1={`${(1 - point1) * 100}%`}
                x2="100%"
                y2={`${(1 - point2) * 100}%`}
                style={{ stroke: theme.colors[color], strokeWidth: 1 }}
            />
        </svg>
    </Box>
);
