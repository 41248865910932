import { Box, FlexRowAlignCenter, Grid } from "../../styledSystemUtilities";
import { Label11Medium } from "../../Typography";
import { ArrowDown, ArrowRight } from "../../Atoms/assets";
import { AdsCampaignOverview } from "./AdsCampaignOverview";
import { AdsCampaignManager } from "./AdsCampaignManager";
import { AdsMonthlyBreakdowns } from "./AdsMonthlyBreakdowns";
import React, { useState } from "react";
import { GoogleAdsCampaignReport } from "../../../@types/reports/Growth";
import { useWindowSize } from "react-use";
import theme from "../../../theme";

export function GoogleAdsCampaign({
    campaign,
    index,
    initialOpenState = true,
}: {
    campaign: GoogleAdsCampaignReport;
    index: number;
    initialOpenState?: boolean;
}): JSX.Element {
    const { width } = useWindowSize();
    const [isOpen, setIsOpen] = useState(initialOpenState);

    return (
        <Grid
            gridTemplateColumns={"1fr minmax(320px, 762px) 1fr"}
            key={campaign.campaignId}
            data-testid={campaign.campaignId}
            pb={5}
        >
            {isOpen ? (
                <>
                    <Box gridColumn={"2 / -1"} width={"100%"} height={"38px"} backgroundColor={"edgeWater"}>
                        <FlexRowAlignCenter
                            maxWidth={"375px"}
                            height={"38px"}
                            justifyContent={"space-between"}
                            pl={8}
                            pr={10}
                            cursor={"pointer"}
                            onClick={() => setIsOpen(false)}
                        >
                            <Label11Medium color={"deepPetrol"} uppercase>
                                campaign {index + 1}
                            </Label11Medium>
                            <ArrowDown fill={"deepPetrol"} />
                        </FlexRowAlignCenter>
                    </Box>

                    <Grid
                        pt={5}
                        gridColumn={2}
                        gridTemplateColumns={width > theme.breakpoints[0] ? "1fr 1fr" : "1fr"}
                        gridColumnGap={5}
                    >
                        <AdsCampaignOverview googleAdsCampaignReport={campaign} />
                        <AdsCampaignManager googleAdsCampaignReport={campaign} />
                        <AdsMonthlyBreakdowns googleAdsMonths={campaign.months} averages={campaign.averages} />
                    </Grid>
                </>
            ) : (
                <FlexRowAlignCenter
                    gridColumn={2}
                    maxWidth={"375px"}
                    justifyContent={"space-between"}
                    pl={8}
                    pr={10}
                    height={"38px"}
                    backgroundColor={"aquaBlue"}
                    cursor={"pointer"}
                    onClick={() => setIsOpen(true)}
                >
                    <Label11Medium color={"ipanemaBlue"} uppercase>
                        campaign {index + 1} – {campaign.campaignName}
                    </Label11Medium>
                    <ArrowRight fill={"ipanemaBlue"} />
                </FlexRowAlignCenter>
            )}
        </Grid>
    );
}
