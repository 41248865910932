import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfitCenter } from "../../@types/reports/ProfitCenters";

const initialState: ProfitCenter[] = [];

export default createSlice({
    name: "profitCenters",
    initialState,
    reducers: {
        set: (state, action: PayloadAction<ProfitCenter[]>) => action.payload,
        unset: () => initialState,
    },
});
