import { Body12, Body12Bold, Body14, Body14Medium, Header, Header16Bold, Label11Bold } from "../../Typography";
import { Box, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { MonthToDateAnalyticsTable } from "../MonthToDateAnalyticsTable";
import { ArrowRight, LocalPin } from "../../Atoms/assets";
import React from "react";
import { LocalSeoReport, OnlineReport } from "../../../@types/reports/Online";
import { useAppSelector } from "../../../redux/hooks";
import _ from "lodash/fp";
import { formatAsDollars } from "../../../utils/formatter";
import { format } from "date-fns/fp";
import { StartFromLeftPinPlacement } from "../GradientRankIndicator";
import { Gradient } from "../../Atoms";
import { OriginalVsCurrentBarGraph } from "../../Molecules";
import { KeywordParamsSelect } from "../../Molecules/KeywordParamsSelect";
import { getSeoSearchParamsOptionFromReport } from "../../../utils/getSeoSearchParamsOptionFromReport";

export const OnlineSummary = ({
    onlineReport,
    scrollToLocalSeo,
    scrollToInternetActions,
}: {
    onlineReport: OnlineReport;
    scrollToLocalSeo: () => void;
    scrollToInternetActions: () => void;
}): JSX.Element => {
    const location = useAppSelector((state) => state.openPractice);

    return (
        <Grid gridTemplateColumns={"minmax(320px, 375px)"}>
            <Box backgroundColor={"aquaHaze"} px={8} py={7} mb={5} data-testid={"summary"} width={"100%"}>
                <Header color={"forrestGreen"}>Your Current Visibility</Header>
                <YSpacer spaceY={6} />
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <FlexRowAlignCenter data-testid={"monthsActive"}>
                        <Body12Bold color={"forrestGreen"}>Months active:</Body12Bold>
                        <XSpacer spaceX={3} />
                        <FlexRowAlignCenter
                            justifyContent={"center"}
                            border={"auratiumGreen"}
                            width={"41px"}
                            height={9}
                        >
                            <Body14Medium color={"forrestGreen"}>{onlineReport.monthsActive}</Body14Medium>
                        </FlexRowAlignCenter>
                    </FlexRowAlignCenter>
                    <FlexRowAlignCenter data-testid={"competitionLevel"}>
                        <Body12Bold color={"forrestGreen"}>{_.take(5, location.zipCode)} Competition Level:</Body12Bold>
                        <XSpacer spaceX={3} />
                        <FlexRowAlignCenter
                            justifyContent={"center"}
                            border={"auratiumGreen"}
                            width={"41px"}
                            height={9}
                        >
                            <Body14Medium color={"forrestGreen"}>
                                {(onlineReport.localSeo ? _.round(onlineReport.localSeo.competitionLevel * 100) : 0) +
                                    "%"}
                            </Body14Medium>
                        </FlexRowAlignCenter>
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
                <YSpacer spaceY={5} />
                {onlineReport.seoCalls ? (
                    <>
                        <FlexRowAlignCenter justifyContent={"space-between"} data-testid={"patientAcquisitionCost"}>
                            <Body12Bold color={"forrestGreen"}>Patient Acquisition Cost:</Body12Bold>
                            <FlexRowAlignCenter
                                justifyContent={"center"}
                                border={"auratiumGreen"}
                                width={"100px"}
                                height={9}
                            >
                                <Body14Medium color={"forrestGreen"}>
                                    {formatAsDollars(_.round(onlineReport.seoCalls.averages.costPerPatient))}
                                </Body14Medium>
                            </FlexRowAlignCenter>
                        </FlexRowAlignCenter>
                        <YSpacer spaceY={6} />
                        {onlineReport.seoCalls ? (
                            <MonthToDateAnalyticsTable
                                monthToDateAnalytics={[
                                    { label: "Calls", data: onlineReport.seoCalls.monthToDate.calls },
                                    {
                                        label: "Call Conversion %",
                                        data: _.round(onlineReport.seoCalls.monthToDate.callConversionRate * 100) + "%",
                                    },
                                    { label: "Appointments", data: onlineReport.seoCalls.monthToDate.newPatients },
                                ]}
                                primaryColor={"forrestGreen"}
                                secondaryColor={"auratiumGreen"}
                                monthAndYear={format("MMMM u", onlineReport.seoCalls.monthToDate.date)}
                                lastUpdate={format("P", onlineReport.seoCalls.monthToDate.date)}
                            />
                        ) : null}
                    </>
                ) : null}

                <YSpacer spaceY={5} />
                {onlineReport.adviceLocalReport ? (
                    <Box
                        data-testid={"adviceLocalVisibility"}
                        backgroundColor={"white"}
                        py={"15px"}
                        gridColumn={2}
                        px={7}
                    >
                        <Header color={"forrestGreen"}>Your Current Visibility Score:</Header>
                        <YSpacer spaceY={3} />
                        <Body12 color={"auratiumGreen"}>
                            This ranks you for overall visibility through all internet sources that refer to your
                            business.
                        </Body12>
                        <OriginalVsCurrentBarGraph
                            title={""}
                            displayChange={true}
                            currentPercentage={onlineReport.adviceLocalReport.visibility.overall.current}
                            originalPercentage={onlineReport.adviceLocalReport.visibility.overall.original}
                            currentLabel={onlineReport.adviceLocalReport.visibility.overall.current + "%"}
                            originalLabel={onlineReport.adviceLocalReport.visibility.overall.original + "%"}
                        />
                        <Body14 color={"auratiumGreen"}>
                            The higher you rank, the easier it is to find you when searching using the top 5 keywords.
                        </Body14>

                        <FlexRowAlignCenter pt={"8px"} onClick={() => scrollToInternetActions()} cursor={"pointer"}>
                            <Label11Bold color={"forrestGreen"} uppercase>
                                view details
                            </Label11Bold>
                            <Box pl={"8px"} />
                            <ArrowRight fill={"forrestGreen"} />
                        </FlexRowAlignCenter>
                    </Box>
                ) : null}
                <YSpacer spaceY={8} />
                {onlineReport.localSeo ? (
                    <Box
                        data-testid={"googleLocalVisibility"}
                        backgroundColor={"white"}
                        py={"15px"}
                        gridColumn={2}
                        px={7}
                    >
                        <Box display={"flex"} alignItems={"center"}>
                            <LocalPin />
                            <Box pl={"8px"} />
                            <Header16Bold fontWeight={"medium"}>Google Local</Header16Bold>
                        </Box>
                        <YSpacer spaceY={3} />
                        <Header color={"forrestGreen"}>Top 5 Keywords</Header>
                        <YSpacer spaceY={5} />
                        <KeywordParamsSelect localSeoReport={onlineReport.localSeo} locationId={location.id} />
                        <Header color={"forrestGreen"}>Your Competitive Rank:</Header>
                        <SeoSearchDescription localSeoReport={onlineReport.localSeo} />

                        <YSpacer spaceY={4} />

                        <Box width={"88%"} pl={"20px"} data-testid={"currentRank"}>
                            <StartFromLeftPinPlacement
                                percent={
                                    (1 -
                                        onlineReport.localSeo.overallRank /
                                            (onlineReport.localSeo.competitors.length + 1)) *
                                    100
                                }
                                formatter={(score) => score}
                                score={onlineReport.localSeo.overallRank}
                            />
                        </Box>

                        <FlexRowAlignCenter justifyContent={"space-between"} height={"10px"}>
                            <Body12 color={"auratiumGreen"}>Low</Body12>
                            <Gradient width={"80%"} />
                            <Body12 color={"auratiumGreen"}>Ideal</Body12>
                        </FlexRowAlignCenter>
                        <YSpacer spaceY={7} />
                        <Body14 color={"auratiumGreen"}>
                            The higher you rank, the easier it is to find you when searching using the top 5 keywords.
                        </Body14>

                        <FlexRowAlignCenter pt={"8px"} onClick={() => scrollToLocalSeo()} cursor={"pointer"}>
                            <Label11Bold color={"forrestGreen"} uppercase>
                                view details
                            </Label11Bold>
                            <Box pl={"8px"} />
                            <ArrowRight fill={"forrestGreen"} />
                        </FlexRowAlignCenter>
                    </Box>
                ) : null}
            </Box>
        </Grid>
    );
};

function SeoSearchDescription({ localSeoReport }: { localSeoReport: LocalSeoReport }): JSX.Element {
    const options = getSeoSearchParamsOptionFromReport(localSeoReport);

    return (
        <Body14 color={"auratiumGreen"}>
            Results for a search in your {_.toLower(options.searchAreaOption.label)} using the top 5{" "}
            {_.toLower(options.keywordSetOption.label)} keywords.
        </Body14>
    );
}
