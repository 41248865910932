import { api } from "../utilityActions";
import loginWithToken from "./loginWithToken";
import { LoginInfo } from "../../@types/shared";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiActionPayload } from "../../@types/apiActionPayload";

const login = (credentials: LoginInfo): PayloadAction<ApiActionPayload> =>
    api({
        call: {
            url: "/open-routes/authentication/login",
            method: "post",
            data: credentials,
        },
        success: loginWithToken,
        label: "login",
    });

export default login;
