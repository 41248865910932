import React from "react";
import { all, Box, XSpacer } from "../styledSystemUtilities";
import { CalendarIcon, DashboardIcon, EmptyCart, LogoutIcon, ProfileIcon, WhiteCartIcon } from "./assets";
import { Label11Bold, LabelBold } from "../Typography";
import styled from "styled-components";
import { Color } from "../../@types/color";
import _ from "lodash/fp";
import { routerHistory } from "../../routerHistory";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import logout from "../../utils/logout";
import { slices } from "../../redux";

const BaseButton = styled("button")`
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0;
    border: none;
    height: 16px;
    :focus {
        outline: none;
    }
    :hover {
        cursor: pointer;
    }
    ${all}
`;

type BaseLinkButtonProps = {
    color: Color;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    children: React.ReactNode;
    onClick: () => void;
};

const BaseLinkButton = ({ color, Icon, children, onClick }: BaseLinkButtonProps): JSX.Element => (
    <BaseButton onClick={onClick}>
        <Icon fill={color} />
        <XSpacer spaceX={2} />
        <Label11Bold color={color} uppercase>
            {children}
        </Label11Bold>
    </BaseButton>
);

export const ViewCartButton = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const cart = useAppSelector((state) => state.cart);

    return (
        <BaseButton onClick={() => dispatch(slices.paymentModal.actions.set({ openModal: "cart" }))}>
            {_.isEmpty(cart) ? (
                <WhiteCartIcon />
            ) : (
                <>
                    <Box position={"absolute"} transform={`translate(${cart.length > 9 ? 5 : 8}px, -4px)`}>
                        <LabelBold color={"white"}>{cart.length}</LabelBold>
                    </Box>
                    <EmptyCart />
                </>
            )}
            <XSpacer spaceX={2} />
            <Label11Bold color={"white"} uppercase>
                view cart
            </Label11Bold>
        </BaseButton>
    );
};

export const ScheduleCallButton = ({ color = "white" }: { color?: Color }): JSX.Element => {
    const strategist = useAppSelector((state) => state.clientAccount.strategist);

    return (
        <BaseLinkButton
            color={color}
            Icon={CalendarIcon}
            onClick={() => {
                window.open(strategist.calendlyLink, "_blank");
            }}
        >
            schedule call
        </BaseLinkButton>
    );
};

export const DashboardButton = (): JSX.Element => (
    <BaseLinkButton color={"white"} Icon={DashboardIcon} onClick={() => routerHistory.push("/dash/location-overview")}>
        dashboard
    </BaseLinkButton>
);

export const ProfileButton = (): JSX.Element => (
    <BaseLinkButton onClick={() => routerHistory.push("/dash/account-overview")} Icon={ProfileIcon} color={"white"}>
        account
    </BaseLinkButton>
);

export const LogoutButton = (): JSX.Element => (
    <BaseLinkButton color={"white"} Icon={LogoutIcon} onClick={() => logout()}>
        logout
    </BaseLinkButton>
);

export const ScheduleCallIconButton = (): JSX.Element => {
    const calendlyLink = useAppSelector((state) => state.clientAccount.strategist.calendlyLink);

    return (
        <BaseButton
            onClick={() => {
                window.open(calendlyLink, "_blank");
            }}
        >
            <CalendarIcon fill={"white"} />
        </BaseButton>
    );
};

export const DashboardIconButton = (): JSX.Element => (
    <BaseButton onClick={() => routerHistory.push("/dash/location-overview")}>
        <DashboardIcon fill={"white"} />
    </BaseButton>
);

export const LogoutIconButton = (): JSX.Element => (
    <BaseButton onClick={() => logout()}>
        <LogoutIcon fill={"white"} />
    </BaseButton>
);

export const ProfileIconButton = (): JSX.Element => (
    <BaseButton onClick={() => routerHistory.push("/dash/account-overview")}>
        <ProfileIcon fill={"white"} />
    </BaseButton>
);
