import React from "react";
import { Box, FlexRowAlignCenter, Grid, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body12Medium, Body14, Body14Bold, Header } from "../../Typography";
import { ProfitCenter } from "../../../@types/reports/ProfitCenters";
import { ProductCard } from "../../Molecules";
import { ActiveProfitCenterIcon, CalendarIcon } from "../../Atoms/assets";
import { SmallIconButton } from "../../Atoms/Buttons";

export function Center({ profitCenter }: { profitCenter: ProfitCenter }): JSX.Element {
    return (
        <Box py={5} gridColumn={2}>
            <Box pl={8}>
                <Header color={"nauticBlue"}>{profitCenter.name.split(" - ")[1]}</Header>
                <YSpacer spaceY={3} />
                <Body14 color={"nauticBlue"}>{profitCenter.subtitle}</Body14>
            </Box>
            <Grid gridTemplateColumns={"1fr 1fr"} gridColumnGap={6}>
                <Box px={8} py={7} backgroundColor={"porcelain"}>
                    {profitCenter.currentPurchases.length > 0 ? (
                        <>
                            <FlexRowAlignCenter>
                                <Body14Bold color={"nauticBlue"}>Manage Current Payments</Body14Bold>
                                <XSpacer spaceX={4} />
                                <ActiveProfitCenterIcon />
                            </FlexRowAlignCenter>
                            <YSpacer spaceY={6} />
                            {profitCenter.currentPurchases.map((product) => (
                                <Box key={product.id}>{ProductCard(product, true)}</Box>
                            ))}
                            <YSpacer spaceY={4} />
                        </>
                    ) : null}
                    {profitCenter.pastPurchases.length > 0 ? (
                        <>
                            <Body14Bold color={"nauticBlue"}>Past Product Purchases</Body14Bold>
                            <YSpacer spaceY={6} />
                            {profitCenter.pastPurchases.map((product) => (
                                <Box key={product.id}>{ProductCard(product, true)}</Box>
                            ))}
                            <YSpacer spaceY={4} />
                        </>
                    ) : null}
                    <Body12Medium color={"nauticBlue"}>
                        If you have questions about current products, strategy, or any products you are considering,
                        schedule a call with your Ascent Marketing Strategist.
                    </Body12Medium>
                    <YSpacer spaceY={6} />
                    <SmallIconButton
                        backgroundColor={"balticBlue"}
                        onClick={() => null}
                        Icon={CalendarIcon}
                        color={"white"}
                    >
                        schedule call
                    </SmallIconButton>
                </Box>
                <Box px={8} py={7} backgroundColor={"porcelain"}>
                    {profitCenter.additionalProducts.length > 0 ? (
                        <>
                            <Body14Bold color={"nauticBlue"}>Additional Products to Consider</Body14Bold>
                            <YSpacer spaceY={6} />
                            {profitCenter.additionalProducts.map((product) => (
                                <Box key={product.id}>{ProductCard(product)}</Box>
                            ))}
                        </>
                    ) : null}
                </Box>
            </Grid>
        </Box>
    );
}
