import React from "react";
import { Box, FlexRowAlignCenter } from "../styledSystemUtilities";

export function PaymentModalContainer({ children }: { children: React.ReactNode }): JSX.Element {
    return (
        <FlexRowAlignCenter
            top={0}
            left={0}
            justifyContent={"center"}
            height={"100vh"}
            width={"100vw"}
            position={"fixed"}
            zIndex={1000}
            backgroundColor={"translucentWhite"}
        >
            <Box width={"320px"} height={"240px"} backgroundColor={"white"} borderRadius={"6px"}>
                {children}
            </Box>
        </FlexRowAlignCenter>
    );
}
