import { BestForDentistryLogo, GrayAscentLogo, HPALogo } from "./assets";
import { Box } from "../styledSystemUtilities";
import React from "react";
import { useWindowSize } from "react-use";
import { useAppSelector } from "../../redux/hooks";

export const AbsolutePositionLogo = (): JSX.Element | null => {
    const { width } = useWindowSize();
    const isBestForDentistry = useAppSelector((state) => state.isBestForDentistry);
    const isHpa = useAppSelector((state) => state.isHpa);

    return width > 940 ? (
        <Box position={"absolute"} top={"55px"} left={"26px"}>
            {isBestForDentistry ? <BestForDentistryLogo /> : isHpa ? <HPALogo /> : <GrayAscentLogo />}
        </Box>
    ) : null;
};
