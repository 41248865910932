import { Box, Grid } from "../styledSystemUtilities";
import { Body12Medium, Label11, LabelBold, LabelMedium } from "../Typography";
import React from "react";
import { formatAsDollars } from "../../utils/formatter";
import { Color } from "../../@types/color";
import { MenuTableProps, MenuTableRowProps } from "../../@types/components/MenuTable";
import _ from "lodash/fp";

export const MenuTable = ({ data, labelColor, dataColor, name }: MenuTableProps): JSX.Element => (
    <Box width={"100%"} maxWidth={"351px"} data-testid={"summaryTable"}>
        <HeaderRow labelColor={labelColor} name={name} />
        <Row
            label={"new patients"}
            data={data.newPatients}
            dataColor={dataColor}
            labelColor={labelColor}
            dataFormatter={(num: number): number => _.round(num)}
        />
        <Row
            label={"revenue generated"}
            data={data.revenueGenerated}
            dataColor={dataColor}
            labelColor={labelColor}
            dataFormatter={(num: number): string => formatAsDollars(_.round(num))}
        />
        <Box height={"1px"} mt={1} backgroundColor={labelColor} />
        <Row
            label={"roi totals"}
            data={data.roiTotals}
            dataColor={"graphiteGray"}
            labelColor={"graphiteGray"}
            dataFormatter={(num: number): string => formatAsDollars(_.round(num))}
        />
    </Box>
);

type HeaderRowProps = {
    labelColor: Color;
    name: string;
};

const tableColumns = "124px 63px 1fr 63px 1fr 63px";

const HeaderRow = ({ labelColor, name }: HeaderRowProps) => (
    <Grid gridTemplateColumns={tableColumns}>
        <LabelBold color={"graphiteGray"} uppercase>
            {name}
        </LabelBold>
        <Box justifySelf={"end"}>
            <Label11 color={labelColor}>Last Month</Label11>
        </Box>

        <Box />
        <Box justifySelf={"end"}>
            <Label11 color={labelColor}>Average</Label11>
        </Box>
        <Box />
        <Box justifySelf={"end"}>
            <Label11 color={labelColor}>Since Active</Label11>
        </Box>
    </Grid>
);

const Row = ({ labelColor, dataColor, label, data, dataFormatter }: MenuTableRowProps) => (
    <Grid gridTemplateColumns={tableColumns}>
        <LabelMedium lineHeight={"16px"} color={labelColor} uppercase>
            {label}
        </LabelMedium>
        <Box justifySelf={"end"} data-testid={_.camelCase(label + " last month")}>
            <Body12Medium lineHeight={"16px"} color={dataColor}>
                {dataFormatter(data.lastMonth)}
            </Body12Medium>
        </Box>
        <Box />
        <Box justifySelf={"end"} data-testid={_.camelCase(label + " average")}>
            <Body12Medium lineHeight={"16px"} color={dataColor}>
                {dataFormatter(data.average)}
            </Body12Medium>
        </Box>
        <Box />
        <Box justifySelf={"end"} data-testid={_.camelCase(label + " since active")}>
            <Body12Medium lineHeight={"16px"} color={dataColor}>
                {dataFormatter(data.sinceActive)}
            </Body12Medium>
        </Box>
    </Grid>
);
