import React, { ChangeEvent, useEffect, useRef } from "react";
import { Control, FieldErrors, useForm } from "react-hook-form";
import { FormTemplate } from "./FormTemplate";
import { Box, FlexRow, FlexRowAlignCenter, Span, XSpacer, YSpacer } from "../../styledSystemUtilities";
import { Body, Body12, Body14, Header } from "../../Typography";
import { ControlledCheckbox, RegisteredBigInput, RegisteredInput } from "../../Molecules";
import { RightButton } from "../../Atoms";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { WebsiteForm } from "../../../@types/forms/Types";
import { useProductFormSubmit } from "../../../hooks/useProductFormSubmit";
import { DeleteAttachmentButton } from "../../Atoms/assets";
import { OptionChecklist } from "../../Organisms";
import { actions, slices } from "../../../redux";

const servicesToInclude = [
    "Bonding",
    "Botox",
    "Bridges",
    "Crowns",
    "Dental Cleaning",
    "Dentures",
    "Dermal Fillers",
    "Emergency Exams",
    "Extractions",
    "Fillings",
    "Fluoride Treatment",
    "Gum (Perio) Treatment",
    "Implant Restoration",
    "Implant Placement",
    "Mouth Guards",
    "Implant Sup. Dentures",
    "Nitrous Oxide Sedation",
    "Night Guards",
    "IV Sedation",
    "Oral Sedation",
    "Orthodontics",
    "Partials",
    "Clear Correct",
    "Invisalign",
    "Root Canals",
    "Six Month Smiles",
    "Sleep Apnea",
    "Sealants",
    "Veneers",
    "TMJ Treatment",
    "Whitening Trays",
    "Whitening Strips",
    "Wisdom Teeth",
    "In-Office Whitening",
];

const technologiesToInclude = [
    "Air Purifier System",
    "Ultrasonic Scaler",
    "Auto Appt Reminders",
    "Laser Cavity Detection",
    "CEREC",
    "Digital Shade Matching",
    "Cone Beam Technology",
    "CAD/CAM Technology",
    "Digital X-rays",
    "E4D",
    "Electronic Claims",
    "Intraoral Camera",
    "Laser Dentistry",
    "Oral Cancer Screening",
    "Contactless Check-in",
    "Panoramic X-rays",
    "X-nav Implant Tech",
    "Same-Day Crowns",
    "Virtual Visit",
    "Telescopic Loupes",
];

const paymentOptions = [
    "Cash",
    "American Express",
    "Checks",
    "Discover Card",
    "Care Credit",
    "Mastercard",
    "Capitol One Financing",
    "Visa",
    "Wells Fargo",
    "Major Insurance",
    "Lending Club",
    "Participating Insurance",
    "Citi Health",
    "In-office Discount Plan",
    "Chase Health Advance",
    "Venmo",
    "Apple Pay",
    "Pay Pal",
];

const formDefaultValues = {
    ascentDesignedLogo: false,
    useAscentStockPhotos: false,
    useCurrentDoctorBios: false,
    newDoctorBios: "",
    useCurrentTeamBios: false,
    newTeamBios: "",
    newPatientFormLink: "",
    patientTestimonials: "",
    testimonialLink: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    yelp: "",
    angiesList: "",
    pinterest: "",
    linkedIn: "",
    servicesToInclude: [],
    additionalServices: "",
    technologiesToInclude: [],
    additionalTechnologies: "",
    paymentOptions: [],
    additionalPaymentOptions: "",
    additionalInformation: "",
};

export function Website(): JSX.Element {
    const dispatch = useAppDispatch();
    const form = useAppSelector((state) => state.form);
    const websiteFiles = useAppSelector((state) => state.websiteFiles);
    const practiceId = useAppSelector((state) => state.openPractice.id);
    const superviseSubmit = useProductFormSubmit<WebsiteForm>("online", "websiteForms");
    const { register, errors, handleSubmit, reset, control, getValues } = useForm<WebsiteForm>({
        defaultValues: form || formDefaultValues,
    });

    const imageInput = useRef<HTMLInputElement>(null);
    const logoInput = useRef<HTMLInputElement>(null);
    const newPatientFormInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        reset(form || formDefaultValues);
    }, [form, reset]);

    const deleteFile = (folder: "logos" | "images" | "newPatientForms", practiceId: string, fileName: string) => () => {
        const formValues = getValues();
        dispatch(slices.form.actions.set(formValues));
        dispatch(actions.deleteFile(practiceId, "website/" + folder, fileName));
    };

    const uploadFiles = (folder: "logos" | "images" | "newPatientForms", maxUploads: number) => async (
        event: ChangeEvent
    ) => {
        const formData = new FormData();
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;

        const numFilesRemaining = Math.max(maxUploads - (websiteFiles[folder]?.length ?? 0), 0);
        let numUploads = 0;

        for (const file of files) {
            numUploads++;
            if (file.size > 1024 * 1024 * 5) {
                alert(file.name + " is over the maximum filesize of 5 mb.");
            } else if (numUploads > numFilesRemaining) {
                alert(file.name + " could not be uploaded because you have exceed the maximum number of file uploads.");
            } else {
                const b = await file.arrayBuffer()
                const blob = new Blob([b])
                formData.append("file", file);
            }
        }

        const formValues = getValues();
        dispatch(slices.form.actions.set(formValues));
        dispatch(actions.uploadFiles(practiceId, formData, "website/" + folder));
    };

    return (
        <FormTemplate
            productName={"Website"}
            taskName={"Content Checklist"}
            redLabel={"Required Information"}
            blackLabel={"Info we need to complete your site"}
            onSubmit={handleSubmit(superviseSubmit)}
        >
            <Box>
                <Header color={"graphiteGray"}>Your Logo</Header>
                <YSpacer spaceY={6} />
                <CheckboxAndLabel
                    control={control}
                    name={"ascentDesignedLogo"}
                    label={"Ascent Practice Growth Strategies designed our logo"}
                />
                <YSpacer spaceY={7} />
                <Body12>We will upload a Vector version of our logo</Body12>
                <YSpacer spaceY={3} />
                <Box width={"158px"}>
                    <RightButton
                        backgroundColor={"graphiteGray"}
                        onClick={() => {
                            if (logoInput.current) {
                                logoInput.current.click();
                            }
                        }}
                        type={"button"}
                    >
                        upload file
                    </RightButton>
                    <input
                        type="file"
                        data-testid={"uploadLogo"}
                        accept={".ai, .eps, .pdf"}
                        ref={logoInput}
                        onChange={uploadFiles("logos", 1)}
                        style={{ display: "none" }}
                    />
                </Box>
                <YSpacer spaceY={2} />
                <Body12>(Acceptable file types: .ai .eps, .pdf)</Body12>
                {websiteFiles.logos ? (
                    <>
                        <YSpacer spaceY={4} />
                        <Body14 color={"petrolBlue"}>Uploaded files:</Body14>
                        <YSpacer spaceY={2} />
                        {websiteFiles.logos.map((logo) => (
                            <Box key={logo.url}>
                                <FileNameContainer
                                    url={logo.url}
                                    fileName={logo.name}
                                    deleteFile={deleteFile("logos", practiceId, logo.name)}
                                />
                            </Box>
                        ))}
                    </>
                ) : null}

                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>Photography</Header>
                <YSpacer spaceY={4} />
                <CheckboxAndLabel
                    control={control}
                    name={"useAscentStockPhotos"}
                    label={"We’ll use stock photos from the Ascent photo library"}
                />
                <YSpacer spaceY={7} />
                <Body12>We will upload custom photography</Body12>
                <YSpacer spaceY={3} />
                <Box width={"158px"}>
                    <RightButton
                        backgroundColor={"graphiteGray"}
                        onClick={() => {
                            if (imageInput.current) {
                                imageInput.current.click();
                            }
                        }}
                        type={"button"}
                    >
                        upload file(s)
                    </RightButton>
                    <input
                        type="file"
                        multiple
                        accept={"image/*"}
                        ref={imageInput}
                        onChange={uploadFiles("images", 100)}
                        style={{ display: "none" }}
                    />
                </Box>
                <YSpacer spaceY={2} />
                <Body12>(Acceptable file types: .jpg, .tiff, .png)</Body12>
                {websiteFiles.images ? (
                    <>
                        <YSpacer spaceY={4} />
                        <Body14 color={"petrolBlue"}>Uploaded images:</Body14>
                        <YSpacer spaceY={6} />
                        <FlexRowAlignCenter flexWrap={"wrap"} gap={"16px"}>
                            {websiteFiles.images.map((file) => (
                                <Box key={file.url}>
                                    <ImageContainer
                                        url={file.url}
                                        deleteFile={deleteFile("images", practiceId, file.name)}
                                    />
                                </Box>
                            ))}
                        </FlexRowAlignCenter>
                    </>
                ) : null}

                <YSpacer spaceY={9} />
                <Body12>
                    Failure to fill out a section below will result in an omission of corresponding information.
                </Body12>
                <YSpacer spaceY={2} />
                <Header color={"graphiteGray"}>Biographies</Header>
                <YSpacer spaceY={3} />
                <Body color={"graphiteGray"}>Doctor Bios:</Body>
                <YSpacer spaceY={4} />
                <CheckboxAndLabel
                    control={control}
                    name={"useCurrentDoctorBios"}
                    label={"We will use the Bio(s) from the current website"}
                />
                <YSpacer spaceY={7} />
                <BigInputAndLabel
                    register={register}
                    errors={errors}
                    name={"newDoctorBios"}
                    label={"We will use new bios (Paste the new doctor bios below):"}
                />
                <Body color={"graphiteGray"}>Team Bios</Body>
                <YSpacer spaceY={4} />
                <CheckboxAndLabel
                    control={control}
                    name={"useCurrentTeamBios"}
                    label={"We will use the Bio(s) from the current website"}
                />
                <YSpacer spaceY={7} />
                <BigInputAndLabel
                    register={register}
                    errors={errors}
                    name={"newTeamBios"}
                    label={"We will use new bios (Paste the new team bios below):"}
                />
                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>New PatientForms</Header>
                <YSpacer spaceY={3} />
                <Body12>We will upload new patent forms</Body12>
                <YSpacer spaceY={3} />
                <Box width={"158px"}>
                    <RightButton
                        backgroundColor={"graphiteGray"}
                        onClick={() => {
                            if (newPatientFormInput.current) {
                                newPatientFormInput.current.click();
                            }
                        }}
                        type={"button"}
                    >
                        upload file(s)
                    </RightButton>
                    <input
                        type="file"
                        multiple
                        accept={".pdf, .docx, .pages"}
                        ref={newPatientFormInput}
                        onChange={uploadFiles("newPatientForms", 10)}
                        style={{ display: "none" }}
                    />
                </Box>
                <YSpacer spaceY={2} />
                <Body12>
                    (Acceptable file types: .pdf, .docx, .pages, or files from your practice management software.)
                </Body12>
                {websiteFiles.newPatientForms ? (
                    <>
                        {" "}
                        <YSpacer spaceY={4} />
                        <Body14 color={"petrolBlue"}>Uploaded files:</Body14>
                        <YSpacer spaceY={2} />
                        {websiteFiles.newPatientForms.map((file) => (
                            <Box key={file.url}>
                                <FileNameContainer
                                    url={file.url}
                                    fileName={file.name}
                                    deleteFile={deleteFile("newPatientForms", practiceId, file.name)}
                                />
                            </Box>
                        ))}
                    </>
                ) : null}
                <YSpacer spaceY={7} />
                <InputAndLabel
                    register={register}
                    errors={errors}
                    name={"newPatientFormLink"}
                    label={"We have a link or code to a forms widget:"}
                />
                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>Testimonials</Header>
                <YSpacer spaceY={3} />
                <BigInputAndLabel
                    register={register}
                    errors={errors}
                    name={"patientTestimonials"}
                    label={"Paste patient testimonials to be highlighted on website:"}
                />
                <InputAndLabel
                    register={register}
                    errors={errors}
                    name={"testimonialLink"}
                    label={"We have a link or code to testimonials plug-in:"}
                />
                <YSpacer spaceY={7} />
                <Header color={"graphiteGray"}>Social Media Links</Header>
                <YSpacer spaceY={2} />
                <Body12>
                    Provide the web address for each social media account you would like linked to your website
                </Body12>
                <YSpacer spaceY={5} />
                <InputAndLabel register={register} errors={errors} name={"facebook"} label={"Facebook"} />
                <InputAndLabel register={register} errors={errors} name={"instagram"} label={"Instagram"} />
                <InputAndLabel register={register} errors={errors} name={"twitter"} label={"Twitter"} />
                <InputAndLabel register={register} errors={errors} name={"youtube"} label={"Youtube"} />
                <InputAndLabel register={register} errors={errors} name={"yelp"} label={"Yelp"} />
                <InputAndLabel register={register} errors={errors} name={"angiesList"} label={"Angies List"} />
                <InputAndLabel register={register} errors={errors} name={"pinterest"} label={"Pinterest"} />
                <InputAndLabel register={register} errors={errors} name={"linkedIn"} label={"Linked In"} />
            </Box>
            <Box>
                <Header color={"graphiteGray"}>Office Hours</Header>
                <YSpacer spaceY={6} />
                <Body12>
                    Your office hours will be taken from your Google My Business account. Please verify that your hours
                    are correct in your GMB account:{" "}
                    <Span
                        color={"petrolBlue"}
                        cursor={"pointer"}
                        onClick={() => window.open("https://www.google.com/business/", "_blank")}
                    >
                        Link to your GMB Account
                    </Span>
                </Body12>
                <YSpacer spaceY={8} />
                <OptionChecklist
                    options={servicesToInclude}
                    name={"servicesToInclude"}
                    label={"Services to Include"}
                    control={control}
                />
                <BigInputAndLabel
                    register={register}
                    errors={errors}
                    name={"additionalServices"}
                    label={"Additional services to include:"}
                />
                <YSpacer spaceY={5} />
                <OptionChecklist
                    options={technologiesToInclude}
                    name={"technologiesToInclude"}
                    label={"Technologies to Include"}
                    control={control}
                />
                <BigInputAndLabel
                    register={register}
                    errors={errors}
                    name={"additionalTechnologies"}
                    label={"Additional technologies to include"}
                />
                <YSpacer spaceY={5} />
                <OptionChecklist
                    options={paymentOptions}
                    name={"paymentOptions"}
                    label={"Payment Options You Accept"}
                    control={control}
                />
                <InputAndLabel
                    register={register}
                    errors={errors}
                    name={"additionalPaymentOptions"}
                    label={"Additional payment options to include"}
                />
                <YSpacer spaceY={5} />
                <Header color={"graphiteGray"}>Other things to include</Header>
                <YSpacer spaceY={4} />
                <BigInputAndLabel
                    register={register}
                    errors={errors}
                    name={"additionalInformation"}
                    label={
                        "Please list any additional information, photos, etc. from your current website that you would like transferred to your new website, or any information you want to be sure we include:"
                    }
                />
            </Box>
            <YSpacer spaceY={8} />
            <FlexRow justifyContent={"center"} gridColumn={"1 / -1"}>
                <Box width={"330px"}>
                    <RightButton backgroundColor={"graphiteGray"} onClick={() => null}>
                        save & continue
                    </RightButton>
                </Box>
            </FlexRow>
        </FormTemplate>
    );
}

function CheckboxAndLabel({ control, name, label }: { control: Control; name: string; label: string }): JSX.Element {
    return (
        <FlexRowAlignCenter>
            <ControlledCheckbox name={name} control={control} />
            <XSpacer spaceX={6} />
            <Body12>{label}</Body12>
        </FlexRowAlignCenter>
    );
}

function BigInputAndLabel({
    register,
    errors,
    name,
    label,
}: {
    register:
        | ((instance: HTMLTextAreaElement | null) => void)
        | React.RefObject<HTMLTextAreaElement>
        | null
        | undefined;
    errors: FieldErrors;
    name: string;
    label: string;
}): JSX.Element {
    return (
        <Box pb={5}>
            <Body12>{label}</Body12>
            <YSpacer spaceY={5} />
            <RegisteredBigInput register={register} errors={errors} name={name} placeholder={""} />
        </Box>
    );
}

function InputAndLabel({
    register,
    errors,
    name,
    label,
}: {
    register: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
    errors: FieldErrors;
    name: string;
    label: string;
}): JSX.Element {
    return (
        <Box pb={5}>
            <Body12>{label}</Body12>
            <YSpacer spaceY={5} />
            <RegisteredInput register={register} errors={errors} name={name} placeholder={""} />
        </Box>
    );
}

function ImageContainer({ url, deleteFile }: { url: string; deleteFile: () => void }): JSX.Element {
    return (
        <Box>
            <DeleteAttachmentButton position={"absolute"} ml={"65px"} mt={"-6px"} onClick={deleteFile} />
            <a href={url}>
                <Box
                    height={"76px"}
                    width={"76px"}
                    border={"gray"}
                    backgroundSize={"contain"}
                    backgroundPosition={"center center"}
                    backgroundRepeat={"no-repeat"}
                    backgroundImage={`url(${url})`}
                />
            </a>
        </Box>
    );
}

function FileNameContainer({ url, fileName, deleteFile }: { url: string; fileName: string; deleteFile: () => void }) {
    return (
        <FlexRowAlignCenter>
            <DeleteAttachmentButton onClick={deleteFile} />
            <XSpacer spaceX={2} />
            <a href={url} style={{ textDecoration: "none" }}>
                <Body14 color={"graphiteGray"}>{fileName}</Body14>
            </a>
        </FlexRowAlignCenter>
    );
}
