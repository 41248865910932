import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

function PrivateRoute({ render, path }: { render: () => JSX.Element; path: string }): JSX.Element {
    const currentUser = useAppSelector((state) => state.currentUser);
    return <Route path={path} render={() => (currentUser.isAuthenticated ? render() : <Redirect to="/login" />)} />;
}

export default PrivateRoute;
