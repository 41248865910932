import { Body, Body12, Body14Bold, Label, Label9Medium } from "../Typography";
import { Box, FlexCol, FlexRowAlignCenter, Grid, Span, YSpacer } from "../styledSystemUtilities";
import React from "react";
import { Color } from "../../@types/color";
import _ from "lodash/fp";

export const MonthToDateAnalyticsTable = ({
    monthToDateAnalytics,
    primaryColor,
    secondaryColor,
    monthAndYear,
    lastUpdate,
}: {
    monthToDateAnalytics: { label: string; data: string | number }[];
    primaryColor: Color;
    secondaryColor: Color;
    monthAndYear: string;
    lastUpdate: string;
}): JSX.Element => (
    <Box data-testid={"monthToDate"}>
        <Body14Bold color={primaryColor}>Month-To-Date Analytics — Updated Daily</Body14Bold>
        <YSpacer spaceY={1} />
        <FlexRowAlignCenter justifyContent={"space-between"}>
            <Box data-testid={"monthAndYear"}>
                <Body color={primaryColor}>{monthAndYear}</Body>
            </Box>
            <Box data-testid={"lastUpdateDate"}>
                <Label color={secondaryColor}>
                    Last Update:&nbsp;&nbsp;&nbsp;
                    <Span color={primaryColor} fontSize={"12px"}>
                        {lastUpdate}
                    </Span>
                </Label>
            </Box>
        </FlexRowAlignCenter>
        <YSpacer spaceY={4} />
        <Grid gridTemplateColumns={"1fr 1fr"} justifyItems={"center"} gridColumnGap={"10px"} maxWidth={"375px"}>
            <FlexCol width={"100%"} alignItems={"flex-end"}>
                <Box pr={7} pb={1}>
                    <Label9Medium color={secondaryColor} uppercase>
                        metric
                    </Label9Medium>
                </Box>
                <FlexCol backgroundColor={"white"} width={"100%"} pr={7} alignItems={"flex-end"} pt={4}>
                    {monthToDateAnalytics.map((metric, index) => {
                        return (
                            <Box pb={4} key={index}>
                                <Body12 color={primaryColor}>{metric.label}</Body12>
                            </Box>
                        );
                    })}
                </FlexCol>
            </FlexCol>
            <FlexCol width={"100%"} alignItems={"center"}>
                <Box pb={1}>
                    <Label9Medium color={secondaryColor} uppercase>
                        result
                    </Label9Medium>
                </Box>
                <FlexCol backgroundColor={"white"} width={"100%"} alignItems={"center"} pt={4}>
                    {monthToDateAnalytics.map((metric, index) => {
                        return (
                            <Box pb={4} key={index} data-testid={_.camelCase(metric.label)}>
                                <Body12 color={primaryColor}>{metric.data}</Body12>
                            </Box>
                        );
                    })}
                </FlexCol>
            </FlexCol>
        </Grid>
    </Box>
);
