import React from "react";
import { Box } from "../../styledSystemUtilities";
import { EnhancedSeoHero } from "../../Atoms/assets";
import {
    AdvancedLink,
    AdvancedSeo,
    CompetitiveAnalysis,
    GaGraphSample,
    GmbGraphSample,
    Google,
    SeoContent,
    SeoOverTimeSample,
    SeoPlotSample,
    SeoTableSample,
    Strategy,
    VisibilityTableSample,
    VisibilityWheelSample,
} from "../../Atoms";
import {
    GrayBackground,
    IconContainer,
    NumberedParagraph,
    OnlineHeader,
    Paragraph,
    PricingNotes,
    ReportingCard,
    ReportingHeader,
    SellingPoint,
} from "../../Molecules";
import { Body14Medium } from "../../Typography";

export const EnhancedSeo = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <OnlineHeader setPopup={setPopup} Hero={EnhancedSeoHero} />
            <GrayBackground>
                <SellingPoint
                    header={"Advanced  SEO when it’s most needed"}
                    body={
                        "When your practice is located in a more competitive area, you need the benefit of Ascent’s enhanced SEO. Advanced content strategy and link building, along with strategic content creation, make all the difference against sophisticated competition and highly competitive volume."
                    }
                />
                <SellingPoint
                    header={"Clear, standardized reporting"}
                    body={
                        "Rather than show you reporting that makes us look good, we report in a way that shines a light on the truth. This holds us to a higher standard and, in turn, delivers the best results. (See examples and learn more about our reporting below.)"
                    }
                />
                <SellingPoint
                    header={"Accurate diagnosis"}
                    body={
                        "While most SEO companies are willing to sell you the cheapest packages and promise the moon, we diagnose your needs accurately, set correct expectations, and align results with reality."
                    }
                />
                <SellingPoint
                    header={"Emphasis on Google My Business and Local"}
                    body={
                        "Again, we’ve chosen the harder path because it is also the right path. We focus on producing and reporting results for Google Local and Google My Business. Why? Because that’s where everyone finds their dentist!"
                    }
                />
                <SellingPoint
                    header={"Experience that matters"}
                    body={
                        "We’ve spent over a decade focusing specifically on the dental industry. Our in-house SEO experts know how to get the results you need to achieve your ideal ranking."
                    }
                />
                <IconContainer name={"Technology"}>
                    <Strategy name={"Advanced Content Strategy"} fill={"auratiumGreen"} />
                    <Google name={"Google Local & GMB Optimized"} fill={"auratiumGreen"} />
                    <AdvancedSeo name={"Ascent Website Integration"} />
                    <SeoContent name={"Content Creation"} />
                    <CompetitiveAnalysis name={"Competitive Analysis"} />
                    <AdvancedLink name={"Advanced Link Building"} />
                </IconContainer>
                <ReportingHeader product={"SEO"} color={"auratiumGreen"} />
            </GrayBackground>
            <ReportingCard>
                <NumberedParagraph
                    header={"1. Top keyword averages"}
                    body={"Where do you rank on average for the most searched keywords?"}
                />
                <Paragraph>
                    80% of local search for dentists can be narrowed down to a handful of top keywords. Monitoring where
                    you rank for these top keywords over time is the best indicator of meaningful improvement. Because
                    this type of reporting holds the SEO company to a higher standard, most SEO companies choose to
                    report long lists of varied keywords that make the SEO company look good but are unclear in
                    conveying your position.
                </Paragraph>
                <SeoOverTimeSample />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"Are you doing enough to pass the competition?"}
                    header={"2. Competitive Marketplace"}
                />
                <Paragraph>
                    Local ranking is based on how Google sees you vs. how they see your competition. To improve, you
                    must outperform your competition. Therefore, understanding how competitive your area is and where
                    you sit in regards to those ranking above you is critical to devise a strategy to move you up. Our
                    reporting shows total competitors, where you sit in the landscape and identifies the top competitors
                    in your area.
                </Paragraph>
                <SeoPlotSample />
                <Box mb={7}>
                    <Body14Medium color={"auratiumGreen"}>Your Market’s Top 5 Competitors & You</Body14Medium>
                </Box>
                <SeoTableSample />
            </ReportingCard>
            <ReportingCard>
                <NumberedParagraph
                    body={"What else will give you actionable insights?"}
                    header={"3. Relevant Details"}
                />
                <Paragraph>
                    We also look broadly at your organic ranking and track your expanded keyword visibility over time.
                    We also show which keywords were actually searched in your area last month, your ranking, and
                    movement for those keywords.
                </Paragraph>
                <VisibilityWheelSample />
                <VisibilityTableSample />
                <Paragraph>
                    Finally, by tying this to both Google My Business actions and your website analytics, you get a full
                    picture of your online situation and the knowledge of how to improve.
                </Paragraph>
                <GaGraphSample name={"Website Analytics"} />
                <GmbGraphSample name={"Google Business Listing"} />
            </ReportingCard>
            <PricingNotes>
                Enhanced SEO is purchased on a subscription basis and requires 30 days notice to cancel.
            </PricingNotes>
        </Box>
    );
};
