const dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export const formatAsDollars = (number: number): string => dollarFormatter.format(number);

const centsFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
});

export const formatAsCents = (number: number): string => {
    if (number.toString().includes(".")) {
        return centsFormatter.format(number);
    }
    return formatAsDollars(number);
};
