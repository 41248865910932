import React from "react";
import { Box } from "../../styledSystemUtilities";
import { Clipboard, Recording, ScreenShare, ThreeLevelsEngagement } from "../../Atoms/assets";
import { GrayBackground, IconBulletPoint, IconContainer, SellingPoint } from "../../Molecules";
import { LionSpeakHeader, WhiteBackground } from "../../Molecules/PopupComponents";

export const CaseAcceptance = ({ setPopup }: { setPopup: () => void }): JSX.Element => {
    return (
        <Box width={"100%"}>
            <LionSpeakHeader setPopup={setPopup} name={"Case Acceptance"} tagline={"Sell value, increase profits."} />
            <GrayBackground>
                <SellingPoint
                    header={"Increase revenue and improve profitability"}
                    body={
                        "If you’re not closing as many treatment plans as you’d like or maybe just not as much of the plan as you’d like, learning to be more effective in your case presentation could have a major impact on your bottom line. Let us help you engage your patients and help you sell the value of the plan."
                    }
                />
                <Box display={"flex"} justifyContent={"center"} pb={8}>
                    <ThreeLevelsEngagement />
                </Box>
            </GrayBackground>
            <WhiteBackground>
                <SellingPoint
                    header={"1. Essential"}
                    body={"1 training session is great for a refresher and those that are self motivated."}
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={ScreenShare}
                    header={"(1) 90-minute Virtual Training Session"}
                    body={"Interactive and immersive."}
                />
                <IconBulletPoint
                    Icon={Recording}
                    header={"Recording of your Training Session"}
                    body={"A reference to help with implementation."}
                />
                <IconBulletPoint
                    Icon={Clipboard}
                    header={"Online Practice Questionnaire"}
                    body={"Allows for training customization"}
                />
            </WhiteBackground>
            <WhiteBackground>
                <SellingPoint
                    header={"2. Enhanced"}
                    body={"3 training sessions allow for proper understanding  and correct implementation."}
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={ScreenShare}
                    header={"(3) 90-minute Virtual Training Sessions"}
                    body={"Interactive and immersive."}
                />
                <IconBulletPoint
                    Icon={Recording}
                    header={"Recording of your Training Session"}
                    body={"A reference to help with implementation."}
                />
                <IconBulletPoint
                    Icon={Clipboard}
                    header={"Online Practice Questionnaire"}
                    body={"Allows for training customization"}
                />
            </WhiteBackground>
            <WhiteBackground>
                <SellingPoint
                    header={"3. Enhanced +"}
                    body={"5 training sessions are the best option to build lasting habits and correct implementation."}
                />
                <IconContainer name={"What's Included"} />
                <IconBulletPoint
                    Icon={ScreenShare}
                    header={"(5) 90-minute Virtual Training Sessions"}
                    body={"Interactive and immersive."}
                />
                <IconBulletPoint
                    Icon={Recording}
                    header={"Recording of your Training Session"}
                    body={"A reference to help with implementation."}
                />
                <IconBulletPoint
                    Icon={Clipboard}
                    header={"Online Practice Questionnaire"}
                    body={"Allows for training customization"}
                />
            </WhiteBackground>
            <GrayBackground>
                <Box pb={4} />
                <SellingPoint
                    header={"About LionSpeak"}
                    body={
                        "LionSpeak is a communications coaching company dedicated to teaching the powerful art of un-scripted, authentic communication and personal leadership skills to professionals who strive to speak with greater clarity and inspiration to achieve extraordinary results. Master speaker, trainer, and coach, Katherine Eitel Belt founded the company in 1990. Katherine and her team of experienced coaches, provide customized coaching programs to hundreds of dental teams worldwide."
                    }
                />
                <Box pb={6} />
            </GrayBackground>
        </Box>
    );
};
