import { createSlice } from "@reduxjs/toolkit";

type File = {
    name: string;
    type: string;
    url: string;
};

type WebsiteFiles = {
    images?: File[];
    logos?: File[];
    newPatientForms?: File[];
};

const initialState: WebsiteFiles = {};

export default createSlice({
    name: "websiteFiles",
    initialState,
    reducers: {
        set: (state, action) => action.payload,
    },
});
