import React from "react";
import { StandardProduct } from "../../@types/reports/ProfitCenters";
import { useCart } from "../../hooks/useCart";
import { Box } from "../styledSystemUtilities";
import { PastProductDetails, PaymentsRow, ProductBottomRow, ProductDetails } from "./ProductCardComponents";
import { useSetPopup } from "../../hooks/useSetPopup";
import { productCardLogos } from "../../constants/productCardLogos";

export function StandardProductCard({
    product,
    isPastProduct = false,
}: {
    product: StandardProduct;
    isPastProduct?: boolean;
}): JSX.Element {
    const [isAdded, toggleProduct] = useCart(product);
    const setPopup = useSetPopup(product.popup);

    return (
        <Box data-testid={product.recurlyCode} mb={5} backgroundColor={"white"}>
            {product.logo ? productCardLogos[product.logo]() : null}
            {isPastProduct ? <PastProductDetails product={product} /> : <ProductDetails product={product} />}
            {product.payments ? <PaymentsRow payments={product.payments} productCode={product.recurlyCode} /> : null}
            <ProductBottomRow
                toggleProduct={toggleProduct}
                isAdded={isAdded}
                isReOrder={isPastProduct}
                setPopup={setPopup}
                learnMoreText={product.learnMoreText}
            />
        </Box>
    );
}
